'use client';

import { BigNum, MarketStatus, PRICE_PRECISION_EXP } from '@drift-labs/sdk';
import {
	COMMON_MATH,
	ENUM_UTILS,
	MarketId,
	PollingSequenceGuard,
} from '@drift/common';
import { useEffect, useRef } from 'react';
import { NavigationOptionKey } from '../../components/Navigation';
import Env from '../../environmentVariables/EnvironmentVariables';
import useMarketStateStore, {
	DlobListeningSelection,
	MarketState,
} from '../../stores/useMarketStateStore';
import UI_UTILS from '../../utils/uiUtils';
import useMarketsForDlobToTrack, {
	MarketStatePriority,
} from '../useMarketsForDlobToTrack';
import useIdleLevels, { IdleLevelsState } from '../utils/useIdleLevels';
import {
	L2WithOracleAndMarketData,
	deserializeL2Response,
} from './dlobServerUtils';
import useInterval from '../useInterval';
import { ResultSlotIncrementer } from '../../utils/resultSlotIncrementer';
import { UNCROSS_ONLY_FOR_UI } from '../../providers/orderbookDisplayProvider';
import { useUncrossOrderbook } from './useUncrossOrderbook';
import useDevStore from '../../stores/useDevStore';
import { DriftWindow } from 'src/window/driftWindow';
import useNavigationStore from 'src/stores/useNavigationStore';
import useDriftClientIsReady from '../useDriftClientIsReady';
import useDriftClient from '../useDriftClient';

const DEFAULT_L2_STATE: L2WithOracleAndMarketData = {
	asks: [],
	bids: [],
	oracleData: undefined,
	marketSlot: 0,
	marketIndex: 0,
	marketName: '',
};

const SHALLOW_PRICE_L2_DEPTH = 5;
const DEEP_PRICE_L2_DEPTH = 20;
const SELECTED_MARKET_L2_DEPTH = 100;

const BASE_TICK_INTERVAL_MS = 1000;

const LIVE_MARKET_BASE_TICK_INTERVAL_MULTIPLIER = 1;
const BACKGROUND_DEEP_TICK_INTERVAL_MULTIPLIER = 3;
const BACKGROUND_SHALLOW_TICK_INTERVAL_MULTIPLIER = 5;

const IDLE_1_BACKGROUND_TICK_INTERVAL_MULTIPLIER = 10;
const IDLE_2_BACKGROUND_TICK_INTERVAL_MULTIPLIER = 30;

const BULK_FETCH_DLOB_L2_URL = `${Env.dlobServerHttpUrl}/batchL2`;
const BULK_FETCH_DLOB_L2_CACHED_URL = `${Env.dlobServerHttpUrl}/batchL2Cache`;

type BulkL2FetchingParams = {
	markets: {
		marketIndex: number;
		marketType: string;
		depth: number;
		includeVamm: boolean;
		includePhoenix: boolean;
		includeOpenbook: boolean;
		includeSerum: boolean;
		includeOracle: boolean;
		intervalMultiplier: number;
	}[];
	grouping?: number;
};

const BACKGROUND_L2_POLLING_KEY = Symbol('BACKGROUND_L2_POLLING_KEY');

const resultSlotIncrementer = new ResultSlotIncrementer();

/*
// TODO : BETTER-OLD-SLOT-HANDLING ... need to add better handling for results which are GOOD|BAD|JUST-NEED-TO-IGNORE
*/

const BULK_DLOB_L2_FETCHER = (params: BulkL2FetchingParams) => {
	const queryParamsMap = {
		marketType: params.markets.map((market) => market.marketType).join(','),
		marketIndex: params.markets.map((market) => market.marketIndex).join(','),
		depth: params.markets.map((market) => market.depth).join(','),
		includeVamm: params.markets.map((market) => market.includeVamm).join(','),
		includePhoenix: params.markets
			.map((market) => market.includePhoenix)
			.join(','),
		includeOpenbook: params.markets
			.map((market) => market.includeOpenbook)
			.join(','),
		includeSerum: params.markets.map((market) => market.includeSerum).join(','),
		grouping: params.grouping
			? params.markets.map(() => params.grouping).join(',')
			: undefined,
		includeOracle: params.markets
			.map((market) => market.includeOracle)
			.join(','),
	};

	const queryParams = UI_UTILS.encodeQueryParams(queryParamsMap);

	const useCachedEndpoint =
		params.markets.findIndex(
			(market) =>
				market.intervalMultiplier === LIVE_MARKET_BASE_TICK_INTERVAL_MULTIPLIER
		) === -1; // Only use the cached endpoint when we're exclusively fetching "background" markets, which don't have the same liveness requirements.

	return new Promise<L2WithOracleAndMarketData[]>((res, rej) => {
		PollingSequenceGuard.fetch(BACKGROUND_L2_POLLING_KEY, () => {
			return fetch(
				`${
					useCachedEndpoint
						? BULK_FETCH_DLOB_L2_CACHED_URL
						: BULK_FETCH_DLOB_L2_URL
				}?${queryParams}`
			);
		})
			.then(async (r) => {
				const resp = await r.json();

				const resultsArray = resp.l2s as any[];

				const deserializedL2 = resultsArray.map(deserializeL2Response);

				const resultSlot = resp?.l2s?.[0]?.slot;

				const isValidResult = resultSlotIncrementer.handleResult(
					BULK_FETCH_DLOB_L2_URL,
					resultSlot
				);

				if (!isValidResult) {
					res(undefined); // TODO : BETTER-OLD-SLOT-HANDLING
				}

				res(deserializedL2);
			})
			.catch((e) => {
				if (e === PollingSequenceGuard.LATE_POLLING_RESPONSE) {
					rej();
				} else {
					console.error('Error fetching dlob', e);
					res(params.markets.map(() => DEFAULT_L2_STATE));
				}
			});
	});
};

const getBulkDlobL2ForMarkets = async (props: {
	markets: {
		marketId: MarketId;
		depth: number;
		intervalMultiplier: number;
	}[];
	groupingSize?: number;
}): Promise<L2WithOracleAndMarketData[]> => {
	const l2State = await BULK_DLOB_L2_FETCHER({
		markets: props.markets.map((m) => ({
			marketIndex: m.marketId.marketIndex,
			marketType: m.marketId.marketTypeStr,
			depth: m.depth,
			includeVamm: m.marketId.isPerp,
			includePhoenix: m.marketId.isSpot,
			includeSerum: m.marketId.isSpot,
			includeOpenbook: m.marketId.isSpot,
			includeOracle: true,
			intervalMultiplier: m.intervalMultiplier,
		})),
		grouping: props.groupingSize,
	});
	return l2State;
};

const fetchBulkMarketL2Data = async (
	markets: {
		marketId: MarketId;
		depth: number;
		intervalMultiplier: number;
	}[]
): Promise<L2WithOracleAndMarketData[]> => {
	const dlobL2 = await getBulkDlobL2ForMarkets({
		markets,
	});

	return dlobL2;
};

const PAGES_NOT_REQUIRING_DLOB_SYNCING: NavigationOptionKey[] = [
	'stats',
	'leaderboard',
	'insuranceStaking',
	'liquidityPools',
	'borrowLend',
];

type PollingSpec = {
	baseIntervalMultiplier: number;
	dlobDepth: number;
};

const getPollingSpec = (
	type: MarketStatePriority,
	idleLevels: IdleLevelsState,
	navigationSelection: NavigationOptionKey
): PollingSpec => {
	// Get the multiplier to use for background markets (Ones which aren't the currently selected market)
	const tickMultiplier = (() => {
		// Reduce polling when on a page where dlob price doesn't need high resolution updates
		if (PAGES_NOT_REQUIRING_DLOB_SYNCING.includes(navigationSelection)) {
			return IDLE_1_BACKGROUND_TICK_INTERVAL_MULTIPLIER;
		}

		// Reduce polling when page is idle
		if (idleLevels.idle10Min) {
			return IDLE_2_BACKGROUND_TICK_INTERVAL_MULTIPLIER;
		}
		if (idleLevels.idle1Min) {
			return IDLE_1_BACKGROUND_TICK_INTERVAL_MULTIPLIER;
		}

		if (type === MarketStatePriority.SelectedMarket) {
			return LIVE_MARKET_BASE_TICK_INTERVAL_MULTIPLIER;
		}

		if (type === MarketStatePriority.BackgroundDeep) {
			return BACKGROUND_DEEP_TICK_INTERVAL_MULTIPLIER;
		}

		return BACKGROUND_SHALLOW_TICK_INTERVAL_MULTIPLIER;
	})();

	switch (type) {
		case MarketStatePriority.BackgroundDeep: {
			return {
				baseIntervalMultiplier: tickMultiplier,
				dlobDepth: DEEP_PRICE_L2_DEPTH,
			};
		}
		case MarketStatePriority.BackgroundShallow: {
			return {
				baseIntervalMultiplier: tickMultiplier,
				dlobDepth: SHALLOW_PRICE_L2_DEPTH,
			};
		}
		case MarketStatePriority.SelectedMarket: {
			return {
				baseIntervalMultiplier: tickMultiplier,
				dlobDepth: SELECTED_MARKET_L2_DEPTH,
			};
		}
		default: {
			const exhaustiveCheck: never = type;
			throw new Error(`Unhandled case: ${exhaustiveCheck}`);
		}
	}
};

/**
 * A hook which handles keeping market data in sync using a polling stategy against the dlob-server.
 *
 * Key Aspects:
 *
 * # Polling Specs
 * We don't need to fetch all markets with the same depth or frequency. For each different class of detail required we fetch a different amount of depth and at a different frequency. This is encapsulated in the "Polling Spec"
 *
 * # Single Interval Loop
 * The code is structed in a way that all of the different polling specs are handled by a single interval, which discriminates against specs using their "inteval multiplier" and a counter that increments for each fetch loop. The reason for this is because the DLOB-Server has a maximum polling rate (1 per second at the time of this writing) we can exceed by having multiple loops creating seperate fetch requests, even though we'd still be fetching data with the same resolution. By running all of the fetches in a single loop, with varying query params, we can fetch the same amount of data without accidentally exceeding the request rate.
 * @param enabled
 */
const useSyncMarketDataWithDlobServerPolling = () => {
	const marketsToTrack = useMarketsForDlobToTrack(
		DlobListeningSelection.DLOB_SERVER_POLLING
	);

	const selectedMarkets =
		marketsToTrack.categories[MarketStatePriority.SelectedMarket];

	const deepPriceMarkets =
		marketsToTrack.categories[MarketStatePriority.BackgroundDeep];
	const shallowPriceMarkets =
		marketsToTrack.categories[MarketStatePriority.BackgroundShallow];

	const enabled = marketsToTrack.totalMarkets > 0;

	const idleLevels = useIdleLevels();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const setDlobStoreState = useMarketStateStore((s) => s.set);

	const uncrossDisabled_ = useDevStore((s) => s.dlobSettings.disableUncross);
	const uncrossDisabled = useRef(uncrossDisabled_);
	useEffect(() => {
		uncrossDisabled.current = uncrossDisabled_;
	}, [uncrossDisabled_]);

	const uncross = useUncrossOrderbook();

	const currentNavigationSelection = useNavigationStore(
		(s) => s.currentNavigationSelection
	);

	const getFetchParamsFromPollingSpec = (
		marketId: MarketId,
		pollingSpec: PollingSpec
	) => {
		return {
			marketId,
			depth: pollingSpec.dlobDepth,
			intervalMultiplier: pollingSpec.baseIntervalMultiplier,
		};
	};

	const fetchL2Data = async (
		marketsToFetch: {
			marketId: MarketId;
			pollingSpec: PollingSpec;
		}[]
	) => {
		if (!enabled || marketsToFetch.length === 0) {
			return Promise.resolve([]) as Promise<L2WithOracleAndMarketData[]>;
		}

		const fetchingParams = marketsToFetch.map((spec) =>
			getFetchParamsFromPollingSpec(spec.marketId, spec.pollingSpec)
		);

		const l2Data = await fetchBulkMarketL2Data(fetchingParams);

		return l2Data;
	};

	const fetchNewMarketData = async (
		marketsToFetch: {
			marketId: MarketId;
			pollingSpec: PollingSpec;
		}[]
	): Promise<MarketState[]> => {
		const l2State = await fetchL2Data(marketsToFetch);

		if (!l2State) {
			return undefined; // TODO : BETTER-OLD-SLOT-HANDLING
		}

		const marketData = l2State.map((l2ForMarket, index) => {
			const spreadBidAskMark = COMMON_MATH.calculateSpreadBidAskMark(
				l2ForMarket,
				l2ForMarket?.oracleData?.price
			);

			const orderbookToUse = uncrossDisabled.current
				? l2ForMarket
				: UNCROSS_ONLY_FOR_UI
				? l2ForMarket
				: uncross.uncrossL2OrderbookWithOracle(
						l2ForMarket,
						marketsToFetch[index].marketId
				  );

			return {
				marketId: marketsToFetch[index].marketId,
				orderbook: orderbookToUse,
				oracle: {
					price: BigNum.from(l2ForMarket.oracleData.price, PRICE_PRECISION_EXP),
					twap: BigNum.from(l2ForMarket.oracleData.twap, PRICE_PRECISION_EXP),
					slot: l2ForMarket.oracleData.slot,
					confidence: l2ForMarket.oracleData.confidence,
					hasSufficientNumberOfDataPoints:
						l2ForMarket.oracleData.hasSufficientNumberOfDataPoints,
					twapConfidence: l2ForMarket.oracleData.twapConfidence,
					maxPrice: BigNum.from(
						l2ForMarket.oracleData.maxPrice,
						PRICE_PRECISION_EXP
					),
				},
				derivedState: {
					bestAsk: BigNum.from(
						spreadBidAskMark.bestAskPrice,
						PRICE_PRECISION_EXP
					),
					bestBid: BigNum.from(
						spreadBidAskMark.bestBidPrice,
						PRICE_PRECISION_EXP
					),
					markPrice: BigNum.from(
						spreadBidAskMark.markPrice,
						PRICE_PRECISION_EXP
					),
					// TODO : how to properly do fallback bid and ask???? Does dlob-server need to respond with it??
					fallbackAsk: BigNum.from(
						spreadBidAskMark.bestAskPrice,
						PRICE_PRECISION_EXP
					),
					fallbackBid: BigNum.from(
						spreadBidAskMark.bestBidPrice,
						PRICE_PRECISION_EXP
					),
					updateSlot: l2ForMarket.slot,
				},
				marketSlot: l2ForMarket.marketSlot,
			} as MarketState;
		});

		return marketData;
	};

	const fetchAndUpdateMarketDataForMarkets = async (
		marketsToPoll: {
			marketId: MarketId;
			pollingSpec: PollingSpec;
		}[]
	) => {
		try {
			if (DriftWindow.BREAK_DLOB_SERVER_POLLING) {
				// Dev setting for testing fallback logic
				return;
			}

			const newData = await fetchNewMarketData(marketsToPoll);

			if (!newData) {
				return; // TODO : BETTER-OLD-SLOT-HANDLING
			}

			setDlobStoreState((s) => {
				for (const dataForMarket of newData) {
					s.marketDataState[dataForMarket.marketId.key] = dataForMarket;
					s.latestUpdateSlot = Math.max(
						s.latestUpdateSlot ?? 0,
						dataForMarket.derivedState.updateSlot,
						dataForMarket.marketSlot
					);
				}
			});
		} catch (e) {
			// do nothing
		}
	};

	/**
	 * This is a lookup which holds the polling spec for each individual market.
	 */
	const marketPollingSpecLookup = useRef<
		Map<string, { marketId: MarketId; pollingSpec: PollingSpec }>
	>(new Map());
	const tickCounter = useRef(0);

	/**
	 * This handler ensures the polling spec lookup remains correct as required
	 * @param markets
	 * @param pollingType
	 * @returns
	 */
	const marketPollingSpecSyncHandler = (
		markets: MarketId[],
		pollingType: MarketStatePriority
	) => {
		if (!enabled) return;

		// # Selected Market Handler
		const pollingProps = getPollingSpec(
			pollingType,
			idleLevels,
			currentNavigationSelection
		);

		for (const marketId of markets) {
			marketPollingSpecLookup.current.set(marketId.key, {
				marketId: marketId,
				pollingSpec: pollingProps,
			});
		}
	};

	const marketPollingSpecRemovalHandler = (markets: MarketId[]) => {
		if (!enabled) return;

		for (const marketId of markets) {
			marketPollingSpecLookup.current.delete(marketId.key);
		}
	};

	/**
	 * Handler to actually fetch the l2 data on each interval tick.
	 *
	 * We filter markets to poll in each tick based on their multiplier .. when the modulus of the multiplier and the interval tick count is 0 then we know that we should poll for this market.
	 *
	 * The reason we do it like this is so that we can keep a single interval running to cover all different polling specs, instead of needing to set up one for each different type. Reasoning for this is explained in the large comment at the top of the file.
	 */
	const ticksStarted = useRef(false);
	const intervalTickHandler = () => {
		if (marketPollingSpecLookup.current.size === 0 && !ticksStarted.current) {
			// Skip ticks until the initial markets have been placed into the polling spec lookup
			return;
		}

		if (!driftClientIsReady) {
			return;
		}

		if (!ticksStarted.current) {
			ticksStarted.current = true;
		}

		const marketsToPoll = Array.from(marketPollingSpecLookup.current.values())
			.filter((pollingInfo) => {
				return (
					tickCounter.current %
						pollingInfo.pollingSpec.baseIntervalMultiplier ===
					0
				);
			})
			.filter((market) => {
				const marketAccount = market.marketId.isSpot
					? driftClient.getSpotMarketAccount(market.marketId.marketIndex)
					: driftClient.getPerpMarketAccount(market.marketId.marketIndex);
				const status = marketAccount.status;
				return !ENUM_UTILS.match(status, MarketStatus.DELISTED);
			});

		fetchAndUpdateMarketDataForMarkets(marketsToPoll);

		tickCounter.current++;
	};

	// Reset tick counter when disabled
	useEffect(() => {
		if (!enabled) {
			tickCounter.current = 0;
		}
	}, [enabled]);

	// Set up selected market polling interval
	useEffect(() => {
		marketPollingSpecSyncHandler(
			selectedMarkets,
			MarketStatePriority.SelectedMarket
		);

		return () => {
			marketPollingSpecRemovalHandler(selectedMarkets);
		};
	}, [selectedMarkets, enabled]);

	// Set up deep market polling interval
	useEffect(() => {
		marketPollingSpecSyncHandler(
			deepPriceMarkets,
			MarketStatePriority.BackgroundDeep
		);

		return () => {
			marketPollingSpecRemovalHandler(deepPriceMarkets);
		};
	}, [deepPriceMarkets, enabled]);

	// Set up shallow market polling interval
	useEffect(() => {
		marketPollingSpecSyncHandler(
			shallowPriceMarkets,
			MarketStatePriority.BackgroundShallow
		);

		return () => {
			marketPollingSpecRemovalHandler(shallowPriceMarkets);
		};
	}, [shallowPriceMarkets, enabled]);

	// Set up the tick interval handler
	useInterval(intervalTickHandler, BASE_TICK_INTERVAL_MS);
};

export default useSyncMarketDataWithDlobServerPolling;
