import { Typo } from 'src/components/Text/Typo';

export const VaultStrategy = ({ strategy }: { strategy: string }) => {
	return (
		<div className="flex flex-col gap-4">
			<Typo.T4 className="text-text-default">Strategy</Typo.T4>
			<Typo.B4 className="text-text-secondary">{strategy}</Typo.B4>
		</div>
	);
};
