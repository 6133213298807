import Tooltip from 'src/components/Tooltip/Tooltip';

export const VaultPerformanceStatLabel = (props: {
	label: string;
	tooltip?: string;
}) => {
	return (
		<div className="flex items-center gap-1">
			{props.tooltip ? (
				<Tooltip content={props.tooltip}>
					<div className="border-b border-text-label border-dashed">
						{props.label}
					</div>
				</Tooltip>
			) : (
				<>{props.label}</>
			)}
		</div>
	);
};
