'use client';

import { useSheetsStore } from 'src/stores/useSheetsStore';
import Sheet from '../Modals/Sheet';
import Text from '../Text/Text';
import MarketIcon from '../Utils/MarketIcon';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useSafePush from 'src/hooks/useSafePush';
import useDriftActions from '../../hooks/useDriftActions';

const sheetName = 'balanceCardMore';

export default function BalanceCardMoreSheet() {
	const safePush = useSafePush();
	const marketIndex = useSheetsStore((s) => s[sheetName].marketIndex);
	const handleCloseSheet = useSheetsStore((s) => s.handleCloseSheet);
	const marketSymbol = SPOT_MARKETS_LOOKUP[marketIndex].symbol;
	const showCollateralModal = useDriftActions().showCollateralModal;

	const handleSwap = () => {
		handleCloseSheet(sheetName);

		if (marketSymbol === 'USDC') {
			safePush('/swap');
		} else {
			safePush(`/swap/${marketSymbol}-USDC`);
		}
	};

	const handleTransfer = () => {
		handleCloseSheet(sheetName);

		showCollateralModal('transfer', {
			bankIndex: marketIndex,
		});
	};

	const handleBorrow = () => {
		handleCloseSheet(sheetName);

		showCollateralModal('borrow', {
			bankIndex: marketIndex,
		});
	};

	const handleWithdraw = () => {
		handleCloseSheet(sheetName);

		showCollateralModal('withdraw', {
			bankIndex: marketIndex,
		});
	};

	const handleDeposit = () => {
		handleCloseSheet(sheetName);

		showCollateralModal('deposit', {
			bankIndex: marketIndex,
		});
	};

	return (
		<Sheet sheetName={sheetName}>
			<Sheet.Header sheetName={sheetName}>
				<div className="flex flex-row items-center space-x-3">
					<MarketIcon marketSymbol={marketSymbol} />
					<Text.BODY2 className="text-text-default">{marketSymbol}</Text.BODY2>
				</div>
			</Sheet.Header>
			<div className="w-full p-2 text-text-default flex flex-col space-y-1 pb-4">
				<button onClick={handleDeposit} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Deposit</Text.BODY2>
				</button>
				<button onClick={handleWithdraw} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Withdraw</Text.BODY2>
				</button>
				<button onClick={handleBorrow} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Borrow</Text.BODY2>
				</button>
				<button onClick={handleTransfer} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Transfer</Text.BODY2>
				</button>
				<button onClick={handleSwap} className="w-full px-4 py-3 text-left">
					<Text.BODY2>Swap</Text.BODY2>
				</button>
			</div>
		</Sheet>
	);
}
