'use client';

import { SPOT_MARKETS_LOOKUP } from '../../environmentVariables/EnvironmentVariables';
import useDriftActions from '../../hooks/useDriftActions';
import UIHelpTextLink from '../UIHelpTextLink';
import MarketIcon from '../Utils/MarketIcon';
import PopupAlert from './PopupAlert';

const CollateralAlert = ({
	marketBaseAssetSymbol,
}: {
	marketBaseAssetSymbol: string;
}) => {
	const showCollateralModal = useDriftActions().showCollateralModal;

	const spotMarket = SPOT_MARKETS_LOOKUP.find(
		(spotMarket) => spotMarket.symbol === marketBaseAssetSymbol
	);

	return (
		<PopupAlert
			heading={`${marketBaseAssetSymbol} is now live!`}
			featureKey={`newCollateral_${marketBaseAssetSymbol}`}
			icon={
				<MarketIcon marketSymbol={marketBaseAssetSymbol} sizeClass="w-6 h-6" />
			}
		>
			<div className="flex flex-col space-y-2">
				<span>You can now deposit {marketBaseAssetSymbol}.</span>
				<UIHelpTextLink
					text="Deposit"
					onClick={() => {
						showCollateralModal('deposit', {
							bankIndex: spotMarket.marketIndex,
						});
					}}
				/>
			</div>
		</PopupAlert>
	);
};

export default CollateralAlert;
