'use client';

import { OrderType } from '@drift-labs/sdk';
import { ENUM_UTILS, UISerializableOrder } from '@drift/common';
import { Transaction } from '@solana/web3.js';
import { useEffect, useMemo, useRef } from 'react';
import useDriftAccountStore from '../stores/useDriftAccountsStore';
import { AuctionOrderSignedTxHandler } from '../utils/DriftAppEvents';
import useAppEventEmitter from './useAppEventEmitter';
import useUiUpdateInterval from './useUiUpdateInterval';
import useDriftClient from './useDriftClient';
import { dlog } from '../dev';
import { DriftWindow } from '../window/driftWindow';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';

const getSelfFillSlot = (order: UISerializableOrder) => {
	const orderSlot = order.slot.toNumber();
	const auctionDuration = order.auctionDuration;
	return Math.round(orderSlot + auctionDuration);
};

const SELF_FILLING_DISABLED = false;

/**
 * This hook should do all of the work necessary to self-fill market orders when the auction expires. It listens for events containing the signed transactions, and the current order state for the user. It will try to send the signed self-fill transaction when it detects that an order's auction state is newly expired.
 * @returns
 */
const useSelfFilling = () => {
	if (SELF_FILLING_DISABLED) {
		return;
	}

	const appEventEmitter = useAppEventEmitter();

	const selfFillTxCache = useRef<Record<number, Transaction>>({});
	const knownAuctionSelfFillSlots = useRef<Record<number, number>>({});
	const alreadySelfFilledOrders = useRef<Set<number>>(new Set());
	const driftClient = useDriftClient();
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);

	const clearSelfFillCache = () => {
		selfFillTxCache.current = {};
		knownAuctionSelfFillSlots.current = {};
		alreadySelfFilledOrders.current = new Set();
	};

	// # Clear any cached txs if the selected user account changes :: protects against order IDs clashing, and an edge case we can ignore.
	useEffect(() => {
		clearSelfFillCache();
	}, [currentUserKey]);

	// # Handler to listen for signed transactions and add them to the cache
	useEffect(() => {
		const handler: AuctionOrderSignedTxHandler = ({ orderId, tx }) => {
			dlog('self_filling', `Received signed tx for order ${orderId}`);

			if (selfFillTxCache.current[orderId]) {
				dlog(
					'self_filling',
					`Received clashing orderId for self fill tx :: ${orderId} .. clearing self fill cache`
				);
				clearSelfFillCache();
			} else {
				selfFillTxCache.current[orderId] = tx;
			}
		};

		appEventEmitter.on('fallbackAuctionOrderSignedTx', handler);

		return () => {
			appEventEmitter.removeListener('fallbackAuctionOrderSignedTx', handler);
		};
	}, [appEventEmitter]);

	// # Logic to keep track of auction expiry slots for all open orders
	const openOrders = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.openOrders
	);

	const marketOrders = useMemo(() => {
		if (!openOrders) return [];

		return openOrders.filter(
			(order) =>
				ENUM_UTILS.match(order.orderType, OrderType.MARKET) ||
				ENUM_UTILS.match(order.orderType, OrderType.ORACLE)
		);
	}, [openOrders]);

	const marketOrderIdsUniqueness = useMemo(() => {
		return (marketOrders ?? []).map((order) => order.orderId).join(',');
	}, [marketOrders]);

	const clearInactiveOrdersFromTxCache = (orders: UISerializableOrder[]) => {
		const orderIds = orders.map((order) => order.orderId);

		// Clear the cache of any orders that are no longer in the user's open orders
		Object.keys(selfFillTxCache.current).forEach((key) => {
			const orderId = parseInt(key);
			if (!orderIds.includes(orderId)) {
				dlog('self_filling', `Removing order ${orderId} from cache`);
				delete selfFillTxCache.current[orderId];
			}
		});
	};

	const clearInactiveOrdersFromKnownSelfFillSlots = (
		orders: UISerializableOrder[]
	) => {
		const orderIds = orders.map((order) => order.orderId);

		// Clear the cache of any orders that are no longer in the user's open orders
		Object.keys(knownAuctionSelfFillSlots.current).forEach((key) => {
			const orderId = parseInt(key);
			if (!orderIds.includes(orderId)) {
				dlog('self_filling', `Removing known self fill slot from cache`);
				delete knownAuctionSelfFillSlots.current[orderId];
			}
		});
	};

	useEffect(() => {
		/**
		 * Every time the current market orders change .. we should:
		 * - Remove any orders from the cache that are no longer in the user's open orders
		 * - Update the known auction expiry times
		 */
		dlog(
			'self_filling',
			`Syncing market order ids: ${marketOrderIdsUniqueness}`
		);

		clearInactiveOrdersFromTxCache(marketOrders);
		clearInactiveOrdersFromKnownSelfFillSlots(marketOrders);

		// Update the known auction expiry times for the user's open orders
		marketOrders.forEach((order) => {
			const selfFillSlot = getSelfFillSlot(order);

			if (
				selfFillSlot <=
				DriftWindow?.chainClock?.getState?.(DEFAULT_COMMITMENT_LEVEL)?.slot
			) {
				dlog(
					'self_filling',
					`Order ${order.orderId} self-fill slot already expired. Skipping`
				);
				return;
			}

			dlog('self_filling', `Updating expiry time for order ${order.orderId}`);
			knownAuctionSelfFillSlots.current[order.orderId] = selfFillSlot;
		});
	}, [marketOrderIdsUniqueness]);

	// # Logic to trigger self-fills when we detect a newly expired order
	useUiUpdateInterval(
		() => {
			const currentSlot = DriftWindow?.chainClock?.getState?.(
				DEFAULT_COMMITMENT_LEVEL
			)?.slot;

			if (!currentSlot) return;
			if (Object.keys(knownAuctionSelfFillSlots.current).length === 0) return;

			dlog(
				'self_filling',
				`Checking for self-fills .. currentSlot:${currentSlot}, knownAuctionExpiryTimes:${Object.entries(
					knownAuctionSelfFillSlots.current
				)
					.map(([orderId, slot]) => `${orderId}:${slot}`)
					.join(', ')}`
			);

			Object.entries(knownAuctionSelfFillSlots.current).forEach(
				([key, selfFillSlot]) => {
					const orderId = parseInt(key);
					const typedSelfFillSlot = selfFillSlot as number;

					if (
						typedSelfFillSlot <= currentSlot &&
						!alreadySelfFilledOrders.current.has(orderId) &&
						selfFillTxCache.current[orderId]
					) {
						const tx = selfFillTxCache.current[orderId];

						if (tx) {
							dlog(
								'self_filling',
								`Sending self-fill transaction for order ${orderId}`
							);
							alreadySelfFilledOrders.current.add(orderId);
							driftClient.sendSignedTx(tx, {
								skipPreflight: true,
							});
							// Clear the tx cache for this order
							delete selfFillTxCache.current[orderId];
						}
					}
				}
			);
		},
		true,
		false
	);
};

export default useSelfFilling;
