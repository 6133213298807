import {
	JLP_MARKET_INDEX,
	JLP_POOL_USDC_MARKET_INDEX,
	JLP_POOL_JLP_MARKET_INDEX,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import useCurrentUserAccount from '../useCurrentUserAccount';
import { useMemo, useState } from 'react';
import { BigNum, BN, SIX } from '@drift-labs/sdk';
import { JlpMigrationCalculator } from './JlpMigrationCalculator';
import useDriftClient from '../useDriftClient';
import useDriftClientIsReady from '../useDriftClientIsReady';
import { JLP_POOL_ID, MarketId, USDC_SPOT_MARKET_INDEX } from '@drift/common';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { DEFAULT_ACCOUNT_NAMES_BY_POOL_ID } from 'src/constants/constants';
import useDriftActions from '../useDriftActions';
import { notify } from 'src/utils/notifications';
import usePostHogCapture from '../posthog/usePostHogCapture';
import useBorrowLendDataForMarket from '../useBorrowLendDataForMarket';
import { useAccountCreationCost } from '../useAccountCreationCost';
import TransactionErrorHandler from 'src/utils/TransactionErrorHandler';

const JLP_PRECISION_EXP =
	SPOT_MARKETS_LOOKUP[JLP_MARKET_INDEX]?.precisionExp ?? SIX;

const JLP_POOL_JLP_MARKET_ID = MarketId.createSpotMarket(
	JLP_POOL_JLP_MARKET_INDEX
);

export enum MigrationDisabledReason {
	NO_JLP_INPUT,
	NO_SOL_FOR_RENT,
	IS_MIGRATING,
	MIGRATION_OK,
}

export const useJlpMigration = () => {
	const currentAccount = useCurrentUserAccount();
	const getAccountsStore = useDriftAccountStore((s) => s.get);
	const getDriftStore = useDriftStore((s) => s.get);
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const actions = useDriftActions();
	const setDriftStore = useDriftStore((s) => s.set);
	const { captureEvent } = usePostHogCapture();

	const jlpPoolJlpSpotMarketData = useBorrowLendDataForMarket(
		JLP_POOL_JLP_MARKET_ID
	);

	const jlpMigrationCalculator = useMemo(() => {
		return new JlpMigrationCalculator({
			marginInfo: currentAccount?.marginInfo,
			client: currentAccount?.client,
			spotBalances: currentAccount?.spotBalances ?? [],
		});
	}, [currentAccount]);

	const maxJlpMigrationAmountBigNum =
		jlpMigrationCalculator.getMaxJlpMigrationAmount();

	// states
	const [jlpInputAmount, setJlpInputAmount] = useState<string>(
		maxJlpMigrationAmountBigNum.toNum().toString()
	);
	const [isMigrating, setIsMigrating] = useState(false);

	// derived states
	const jlpInputBigNum = useMemo(() => {
		return BigNum.fromPrint(jlpInputAmount, JLP_PRECISION_EXP);
	}, [jlpInputAmount]);

	const usdcBorrowDerivedAmount = useMemo(
		() =>
			jlpMigrationCalculator.getUsdcBorrowMigrationAmountFromJlp(
				BigNum.fromPrint(jlpInputAmount, JLP_PRECISION_EXP).val,
				new BN(jlpPoolJlpSpotMarketData?.assetWeight ?? 0).muln(10_000)
			),
		[jlpInputAmount]
	);

	// get need for new JLP isolated pool account
	const jlpIsolatedAccount = Object.values(getAccountsStore().accounts).find(
		(acc) =>
			acc.poolId === JLP_POOL_ID &&
			acc.name === DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[JLP_POOL_ID]
	);
	const toCreateJlpIsolatedPoolAccount = !jlpIsolatedAccount;

	// calculate SOL for new account rent
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	const accountCostBreakdown = useAccountCreationCost();
	const baseAccountRent = accountCostBreakdown.baseAccountRent;

	const migrationDisabled = getMigrationDisabled();

	const handleJlpMigration = async () => {
		if (!driftClientIsReady || !driftClient || jlpInputBigNum.eqZero()) {
			return;
		}

		setIsMigrating(true);

		const toastId = 'jlp-migration-toast';

		notify({
			type: 'awaiting',
			message: 'Migrating JLP',
			description: 'Awaiting transaction confirmation',
			id: toastId,
		});

		try {
			const jlpIsolatedPoolAccountId =
				jlpIsolatedAccount?.userId ??
				getDriftStore().userStatsAccount?.numberOfSubAccountsCreated; // new user id to be created

			const ixs = [];

			if (toCreateJlpIsolatedPoolAccount) {
				const [createJlpIsolatedPoolAccountIxs] =
					await driftClient.getInitializeUserAccountIxs(
						jlpIsolatedPoolAccountId,
						DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[JLP_POOL_ID],
						undefined,
						JLP_POOL_ID
					);

				ixs.push(...createJlpIsolatedPoolAccountIxs);
			}

			const transferPoolsIx = await driftClient.getTransferPoolsIx(
				JLP_MARKET_INDEX,
				JLP_POOL_JLP_MARKET_INDEX,
				USDC_SPOT_MARKET_INDEX,
				JLP_POOL_USDC_MARKET_INDEX,
				jlpInputBigNum.val,
				usdcBorrowDerivedAmount.val,
				currentAccount.userId,
				jlpIsolatedPoolAccountId,
				toCreateJlpIsolatedPoolAccount
			);

			ixs.push(transferPoolsIx);

			const tx = await driftClient.buildTransaction(ixs);
			const { txSig } = await driftClient.sendTransaction(tx);

			await driftClient.addUser(jlpIsolatedPoolAccountId);
			await actions.fetchAndSubscribeSubAccounts();

			captureEvent('jlp_migration', {
				jlp_amount: jlpInputBigNum.toNum(),
				usdc_borrow_amount: usdcBorrowDerivedAmount.toNum(),
				createdNewJlpAccount: toCreateJlpIsolatedPoolAccount,
			});

			notify({
				type: 'success',
				message: 'Successful migration',
				description: `Migrated ${jlpInputBigNum.toNum()} JLP${
					usdcBorrowDerivedAmount.gtZero()
						? ` and ${usdcBorrowDerivedAmount.toNum()} USDC`
						: ''
				} to JLP isolated pool account`,
				id: toastId,
			});

			setDriftStore((s) => {
				s.modals.showJLPMigrationFormModal = false;
			});

			return txSig;
		} catch (err: any) {
			TransactionErrorHandler.handleError({
				error: err,
				toastId,
				wallet: getDriftStore()?.wallet?.current?.adapter,
			});
			console.error(err);
		} finally {
			setIsMigrating(false);
		}
	};

	function getMigrationDisabled() {
		if (jlpInputBigNum.eqZero()) {
			return { disabled: true, reason: MigrationDisabledReason.NO_JLP_INPUT };
		}

		if (solBalance.lt(baseAccountRent)) {
			return {
				disabled: true,
				reason: MigrationDisabledReason.NO_SOL_FOR_RENT,
			};
		}

		if (isMigrating) {
			return {
				disabled: true,
				reason: MigrationDisabledReason.IS_MIGRATING,
			};
		}

		return {
			disabled: false,
			reason: MigrationDisabledReason.MIGRATION_OK,
		};
	}

	return {
		maxJlpMigrationAmountBigNum,
		jlpInputBigNum,
		setJlpInputAmount,
		usdcBorrowDerivedAmount,
		handleJlpMigration,
		toCreateJlpIsolatedPoolAccount,
		migrationDisabled,
	};
};
