import { useTheme } from 'next-themes';
import { useEffect } from 'react';
import { DriftTheme } from 'src/environmentVariables/EnvironmentVariableTypes';
import useCurrentSettings from 'src/hooks/useCurrentSettings';

export const ThemeSetter = (props: {
	setTheme: (value: DriftTheme) => void;
}): React.ReactNode => {
	const { theme: nextTheme, setTheme } = useTheme();
	const [savedSettings, updateSettings] = useCurrentSettings();
	const { theme } = savedSettings;

	const handleThemeChange = (theme: 'dark' | 'light') => {
		const driftTheme = theme === 'dark' ? DriftTheme.dark : DriftTheme.light;
		setTheme(driftTheme);
		props.setTheme(driftTheme);
	};

	const setThemeBasedOnDevice = (): 'dark' | 'light' => {
		const isDark =
			window.matchMedia &&
			window.matchMedia('(prefers-color-scheme: dark)').matches;
		const theme = isDark ? 'dark' : 'light';
		handleThemeChange(theme);
		return theme;
	};

	useEffect(() => {
		if (theme === 'light' && nextTheme === DriftTheme.light) {
			return;
		}
		if (theme === 'dark' && nextTheme === DriftTheme.dark) {
			return;
		}
		if (theme === 'dark' || theme === 'light') {
			handleThemeChange(theme);
		}
		if (theme === 'device') {
			// eslint-disable-next-line no-inner-declarations
			function deviceThemeListener(e: MediaQueryListEvent) {
				const matchesTheme = e.matches ? DriftTheme.dark : DriftTheme.light;
				setTheme(matchesTheme);
				props.setTheme(matchesTheme);
			}
			setThemeBasedOnDevice();
			window
				.matchMedia('(prefers-color-scheme: dark)')
				.addEventListener('change', deviceThemeListener);

			return window
				.matchMedia('(prefers-color-scheme: dark)')
				.removeEventListener('change', deviceThemeListener);
		}
		if (theme === null) {
			if (
				nextTheme === null ||
				nextTheme === 'system' ||
				nextTheme === 'device'
			) {
				const deviceTheme = setThemeBasedOnDevice();
				// we need to update user settings based off device preference theme as well
				console.log('setting theme to device theme', deviceTheme);
				updateSettings({ ...savedSettings, theme: deviceTheme });
			} else if (nextTheme === 'dark-theme' || nextTheme === 'light-theme') {
				handleThemeChange(nextTheme === 'dark-theme' ? 'dark' : 'light');
			}
		}
	}, [theme]);

	useEffect(() => {
		function storageListener(event: StorageEvent) {
			if (event.key === 'theme') {
				handleThemeChange(event.newValue === 'dark-theme' ? 'dark' : 'light');
			}
		}
		window.addEventListener('storage', storageListener);
	}, []);

	return null;
};
