import useAppSetup from 'src/hooks/useAppSetup';
import React from 'react';

const AsyncAppSetup = (): null => {
	useAppSetup();

	return null;
};

export default React.memo(AsyncAppSetup);
