'use client';

import { Info } from '@drift-labs/icons';
import { useRef, useState } from 'react';
import TextField from 'src/components/Inputs/TextField';
import Text from 'src/components/Text/Text';
import SlippageToleranceDisplay from 'src/components/TradeForm/SlippageToleranceDisplay';
import {
	TRADER_PROFILE_OPTIONS,
	UserSettings,
} from 'src/environmentVariables/EnvironmentVariables';
import Select from '../Inputs/Select';
import Utility from '../Inputs/Utility';
import Tooltip from '../Tooltip/Tooltip';
import SettingsSwitch from './SettingsSwitch';
import { AdvancedChip } from '../Chips/AdvancedFeatureChip';
import UI_UTILS from 'src/utils/uiUtils';
import { TradeOffsetPrice } from '@drift/common';

interface TradeSettingsProps {
	localSettings: UserSettings;
	setLocalSettings: (setting: UserSettings) => void;
}

const START_FROM_OPTIONS = [
	{ label: 'Oracle Price', value: 'oracle' },
	{ label: 'Mark Price', value: 'mark' },
	{ label: 'Best Bid/Ask', value: 'bestOffer' },
	{ label: 'Est. Entry Price', value: 'entry' },
	{ label: 'Best Overall Price', value: 'best' },
	{ label: 'Market Based', value: 'marketBased' },
];

const END_FROM_OPTIONS = [
	{ label: 'Worst', value: 'worst' },
	{ label: 'Oracle Price', value: 'oracle' },
	{ label: 'Mark Price', value: 'mark' },
	{ label: 'Best Bid/Ask', value: 'bestOffer' },
	{ label: 'Est. Entry Price', value: 'entry' },
	{ label: 'Best Overall Price', value: 'best' },
];

const MAX_DURATION = 100;
const MAX_OFFSET = 10;

const TradeSettings = ({
	localSettings,
	setLocalSettings,
}: TradeSettingsProps) => {
	const initialSettingsRef = useRef(localSettings);
	const currentSettingsRef = useRef(localSettings);

	const slippageTolerance = useRef(localSettings.slippageTolerance);
	const allowInfSlippage = useRef(localSettings.allowInfSlippage);

	const auctionDuration = useRef(localSettings.auctionDuration.toString());
	const auctionStartPriceOffset = useRef(
		localSettings.auctionStartPriceOffset.toString()
	);
	const auctionEndPriceOffset = useRef(
		localSettings.auctionEndPriceOffset.toString()
	);
	const auctionStartPriceOffsetFrom = useRef(
		localSettings.auctionStartPriceOffsetFrom
	);
	const auctionEndPriceOffsetFrom = useRef(
		localSettings.auctionEndPriceOffsetFrom
	);
	const oracleOffsetOrdersEnabled = useRef(
		localSettings.oracleOffsetOrdersEnabled
	);

	const placeAndTakeEnabled = useRef(localSettings.placeAndTakeEnabled);

	const traderProfile = useRef(UI_UTILS.getCurrentTraderProfile(localSettings));

	const [settingsEditable, setSettingsEditable] = useState(
		traderProfile.current === 'custom'
	);

	const updateSettingsValue = (updatedSettings: Partial<UserSettings>) => {
		const newSettings = {
			...currentSettingsRef.current,
			...updatedSettings,
		};

		setLocalSettings(newSettings);

		currentSettingsRef.current = newSettings;
	};

	const handleUpdateTradingProfile = (
		newVal: Partial<UserSettings> | undefined
	) => {
		let settingsToUse;

		if (newVal) {
			traderProfile.current = UI_UTILS.getCurrentTraderProfile(newVal);
			settingsToUse = newVal;
			setSettingsEditable(false);
		} else {
			traderProfile.current = 'custom';
			settingsToUse = initialSettingsRef.current;
			setSettingsEditable(true);
		}

		updateSettingsValue({ traderProfile: traderProfile.current });

		handleSlippageToleranceChange(settingsToUse.slippageTolerance);
		handleAuctionDurationChange(settingsToUse.auctionDuration?.toString());
		if (typeof settingsToUse.auctionStartPriceOffset === 'number') {
			handleAuctionStartPriceOffsetChange(
				Math.abs(settingsToUse.auctionStartPriceOffset)?.toString()
			);
		}
		handleAuctionEndPriceOffsetChange(
			settingsToUse.auctionEndPriceOffset?.toString()
		);
		handleAuctionStartPriceOffsetFrom(
			settingsToUse.auctionStartPriceOffsetFrom
		);
		handleAuctionEndPriceOffsetFrom(settingsToUse.auctionEndPriceOffsetFrom);
		handleToggleOracleOrders(settingsToUse.oracleOffsetOrdersEnabled);
		handleTogglePlaceAndTake(settingsToUse.placeAndTakeEnabled);
	};

	const handleSlippageToleranceChange = (newVal: string) => {
		if (
			newVal?.length > 0 &&
			(!isNaN(Number(newVal)) || newVal === 'dynamic')
		) {
			updateSettingsValue({
				slippageTolerance: newVal,
				allowInfSlippage: false,
			});
			allowInfSlippage.current = false;
		} else if (newVal === 'inf' || newVal === undefined) {
			updateSettingsValue({
				slippageTolerance: newVal,
				allowInfSlippage: true,
			});
			allowInfSlippage.current = true;
		}

		slippageTolerance.current = newVal;
	};

	const handleAuctionDurationChange = (newVal: string) => {
		if (newVal?.length > 0 && !isNaN(Number(newVal)) && Number(newVal) >= 0) {
			if (Number(newVal) >= MAX_DURATION) {
				newVal = MAX_DURATION.toString();
			}
			updateSettingsValue({ auctionDuration: Number(newVal) });
		}

		auctionDuration.current = newVal;
	};

	const handleAuctionStartPriceOffsetChange = (newVal: string) => {
		if (newVal?.length > 0 && !isNaN(Number(newVal)) && Number(newVal) >= 0) {
			if (Math.abs(Number(newVal)) >= MAX_OFFSET) {
				newVal = MAX_OFFSET.toString();
			}
			updateSettingsValue({ auctionStartPriceOffset: -Number(newVal) });
		}

		auctionStartPriceOffset.current = newVal;
	};

	const handleAuctionEndPriceOffsetChange = (newVal: string) => {
		if (newVal?.length > 0 && !isNaN(Number(newVal)) && Number(newVal) >= 0) {
			if (Math.abs(Number(newVal)) >= MAX_OFFSET) {
				newVal = MAX_OFFSET.toString();
			}
			updateSettingsValue({ auctionEndPriceOffset: Number(newVal) });
		}
		auctionEndPriceOffset.current = newVal;
	};

	const handleAuctionStartPriceOffsetFrom = (
		newVal: TradeOffsetPrice | 'marketBased'
	) => {
		updateSettingsValue({ auctionStartPriceOffsetFrom: newVal });
		auctionStartPriceOffsetFrom.current = newVal;
	};

	const handleAuctionEndPriceOffsetFrom = (newVal: TradeOffsetPrice) => {
		updateSettingsValue({ auctionEndPriceOffsetFrom: newVal });
		auctionEndPriceOffsetFrom.current = newVal;
	};

	const handleToggleOracleOrders = (val?: boolean) => {
		const newVal = val !== undefined ? val : !oracleOffsetOrdersEnabled.current;

		updateSettingsValue({
			oracleOffsetOrdersEnabled: newVal,
		});
		oracleOffsetOrdersEnabled.current = newVal;
	};

	const handleTogglePlaceAndTake = (val?: boolean) => {
		const newVal = val !== undefined ? val : !placeAndTakeEnabled.current;

		updateSettingsValue({
			placeAndTakeEnabled: newVal,
		});
		placeAndTakeEnabled.current = newVal;
	};

	return (
		<div className="flex flex-col">
			<div className="flex flex-col border-b scroll-none border-container-border">
				<div className="flex flex-col pb-3">
					<div className="flex items-center justify-start w-full">
						<Text.BODY1 className="text-text-emphasis">
							Trader Profile
						</Text.BODY1>
						<AdvancedChip
							customGradient="var(--app-gradient)"
							customLabel={'NEW'}
							className="w-auto py-0"
						/>
					</div>
					<Text.BODY3 className="mt-1 mb-2 text-text-default">
						Use pre-set trading params to best suit your needs.
					</Text.BODY3>
					<Select.TraderProfileSelector
						id="trading_profile_selector"
						currentSelection={undefined}
						selectedIndex={TRADER_PROFILE_OPTIONS.findIndex(
							(option) => option.key == traderProfile.current
						)}
						options={TRADER_PROFILE_OPTIONS}
						onChange={handleUpdateTradingProfile}
					/>
				</div>
				{settingsEditable ? (
					<>
						<div className="flex flex-col">
							<Text.BODY1 className="mb-1 text-text-emphasis">
								Default Slippage Tolerance
							</Text.BODY1>
							<Text.BODY3 className="mb-2 text-text-secondary">
								Maximum slippage tolerated for every order
							</Text.BODY3>
							<SlippageToleranceDisplay
								updateHandler={handleSlippageToleranceChange}
								hideToggle
								customAtEnd
								slippageTolerance={slippageTolerance.current}
								allowInfSlippage={allowInfSlippage.current}
							/>
						</div>
						<Utility.VERTSPACERXL />
						<div className="flex flex-col mb-1">
							<Text.BODY1 className="inline-flex items-center mb-1 text-text-emphasis">
								Order Auction Parameters
								<AdvancedChip />
							</Text.BODY1>
							<Text.BODY3 className="mb-2 text-text-secondary">
								Customize how orders are executed. These settings apply to all
								Market orders and Limit orders that cross the spread.{' '}
								<a
									href="https://docs.drift.trade/trading/auction-parameters"
									target="_blank"
									rel="noreferrer"
								>
									Learn more
								</a>
							</Text.BODY3>
							<div className="flex items-center justify-between">
								<Tooltip content="Duration is the amount of time the auction lasts for. A single slot is approximately 0.5 sec.">
									<Text.BODY2 className="inline-flex items-center text-text-label">
										<>Duration</>
										<Info className="ml-1" size={12} />
									</Text.BODY2>
								</Tooltip>
								<div className="inline-flex items-center">
									<Text.BODY2 className="text-text-secondary">{`~${
										isNaN(Number(auctionDuration.current))
											? 0
											: Number(auctionDuration.current) / 2
									} sec`}</Text.BODY2>
									<div className="max-w-[110px] min-w-[110px] ml-1">
										<TextField.Default
											type="number"
											stepAmount={1}
											onChange={handleAuctionDurationChange}
											value={auctionDuration.current}
											suffix={`slots`}
										/>
									</div>
								</div>
							</div>
						</div>
						<div className="flex items-center justify-between mb-1">
							<Tooltip
								content={`Start price for auctions will be the ${
									START_FROM_OPTIONS.find(
										(option) =>
											option.value === auctionStartPriceOffsetFrom.current
									).label
								} with a ${Math.abs(
									Number(auctionStartPriceOffset.current)
								).toString()}% discount.`}
							>
								<Text.BODY2 className="inline-flex items-center text-text-label">
									<>Start Price</>

									<Info className="ml-1" size={12} />
								</Text.BODY2>
							</Tooltip>
							<div className="inline-flex w-[60%] justify-end">
								<Select.Default
									id="start_from_selector"
									currentSelection={auctionStartPriceOffsetFrom.current}
									options={START_FROM_OPTIONS}
									onChange={(value) => {
										//@ts-ignore
										handleAuctionStartPriceOffsetFrom(value);
									}}
								/>
								<div className="max-w-[100px] min-w-[100px] ml-1">
									<TextField.Default
										type="number"
										stepAmount={0.01}
										onChange={handleAuctionStartPriceOffsetChange}
										value={auctionStartPriceOffset.current?.replace('-', '')}
										suffix={`%`}
									/>
								</div>
							</div>
						</div>
						<div className="flex items-center justify-between">
							<Tooltip
								content={`End price for auctions will be the ${
									END_FROM_OPTIONS.find(
										(option) =>
											option.value === auctionEndPriceOffsetFrom.current
									).label
								} with a ${
									auctionEndPriceOffset.current
								}% premium. If end price is better than start price at the time of order, start price plus one tick will be used as the end price.`}
							>
								<Text.BODY2 className="inline-flex items-center text-text-label">
									<>End Price</>

									<Info className="ml-1" size={12} />
								</Text.BODY2>
							</Tooltip>
							<div className="inline-flex w-[60%] justify-end">
								<Select.Default
									id="end_from_selector"
									currentSelection={auctionEndPriceOffsetFrom.current}
									options={END_FROM_OPTIONS}
									onChange={(value) => {
										//@ts-ignore
										handleAuctionEndPriceOffsetFrom(value);
									}}
								/>
								<div className="max-w-[100px] min-w-[100px] ml-1">
									<TextField.Default
										type="number"
										stepAmount={0.01}
										onChange={handleAuctionEndPriceOffsetChange}
										value={auctionEndPriceOffset.current}
										suffix={`%`}
									/>
								</div>
							</div>
						</div>
						<div>
							<SettingsSwitch
								className="my-3"
								textClass="text-text-label"
								key={`oracle_offset_toggle`}
								label={'Use Oracle Auctions'}
								checked={oracleOffsetOrdersEnabled.current}
								onChange={handleToggleOracleOrders}
								includeLineSpace={false}
								tooltipContent={
									<div>
										For Market orders, the auction parameters above will
										automatically be based on offsets from the Oracle Price.
										This doesn&apos;t apply to Stop Market or Take Profit Market
										orders.{' '}
										<a
											href="https://docs.drift.trade/trading/auction-parameters"
											target="_blank"
											rel="noreferrer"
										>
											Learn more
										</a>
									</div>
								}
							/>
						</div>
						<div>
							<SettingsSwitch
								className="mb-3"
								textClass="text-text-label"
								key={`place_and_take_toggle`}
								label={'Use Place And Take'}
								betaFeature
								includeLineSpace={false}
								checked={placeAndTakeEnabled.current}
								onChange={handleTogglePlaceAndTake}
								tooltipContent={
									'Place And Take lets you atomically fill up to your size vs. maker orders until the auction end price. This applies to market orders and is meant to provide faster + more predictable fills. Any portion of the order size leftover goes through normal auction. Requires versioned transactions.'
								}
							/>
						</div>
					</>
				) : (
					/* Fixed settings view for trader profile preset */
					<div>
						<div className="w-full pb-2 space-y-1">
							<div className="flex flex-row items-start items-center justify-between w-full space-x-4">
								<Text.BODY3 className="text-text-label">Slippage</Text.BODY3>
								<Text.BODY3 className="text-text-default">
									{slippageTolerance.current === 'dynamic'
										? `Dynamic`
										: `${slippageTolerance.current}%`}
								</Text.BODY3>
							</div>
							<div className="flex flex-row items-start items-center justify-between w-full space-x-4">
								<Text.BODY3 className="text-text-label">
									Auction duration
								</Text.BODY3>
								<Text.BODY3 className="text-text-default">{`~${
									isNaN(Number(auctionDuration.current))
										? 0
										: Number(auctionDuration.current) / 2
								} sec`}</Text.BODY3>
							</div>
							<div className="flex flex-row items-start items-center justify-between w-full space-x-4">
								<Text.BODY3 className="text-text-label">
									Auctions will be based on
								</Text.BODY3>
								<Text.BODY3 className="text-text-default">
									{oracleOffsetOrdersEnabled.current
										? 'Oracle Price'
										: 'Market Price'}
								</Text.BODY3>
							</div>
							<div className="flex flex-row items-start items-center justify-between w-full space-x-4">
								<Text.BODY3 className="text-text-label">
									Auction start price
								</Text.BODY3>
								<Text.BODY3 className="text-text-default">
									{`${
										START_FROM_OPTIONS.find(
											(opt) => opt.value === auctionStartPriceOffsetFrom.current
										)?.label
									} - ${
										auctionStartPriceOffset.current === 'marketBased'
											? 'Market Based'
											: Math.abs(Number(auctionStartPriceOffset.current))
									}%`}
								</Text.BODY3>
							</div>
							<div className="flex flex-row items-start items-center justify-between w-full space-x-4">
								<Text.BODY3 className="text-text-label">
									Auction end price
								</Text.BODY3>
								<Text.BODY3 className="text-text-default">
									{`Worst Overall Price + ${Math.abs(
										Number(auctionEndPriceOffset.current)
									)}%`}
								</Text.BODY3>
							</div>
							<div className="flex flex-row items-start items-center justify-between w-full space-x-4">
								<Text.BODY3 className="text-text-label">
									Fill against resting liquidity first
								</Text.BODY3>
								<Text.BODY3 className="text-text-default">
									{placeAndTakeEnabled.current ? 'Yes' : 'No'}
								</Text.BODY3>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default TradeSettings;
