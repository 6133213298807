'use client';

import React, { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import Text from './Text/Text';
import { LoadingChart } from './Charts/LoadingChart';
import {
	BigNum,
	BN,
	calculateBorrowRate,
	calculateDepositRate,
	calculateUtilization,
	PERCENTAGE_PRECISION_EXP,
	SPOT_MARKET_UTILIZATION_PRECISION_EXP,
	SpotMarketAccount,
} from '@drift-labs/sdk';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import useDriftClient from 'src/hooks/useDriftClient';
import { ZERO_BIGNUM } from 'src/constants/math';
import NumberDisplayV2 from './Utils/NumberDisplayV2';

import {
	Line,
	LineChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Tooltip as ChartsTooltip,
	Legend,
	ReferenceLine,
	TooltipProps,
	LabelProps,
} from 'recharts';

import type { CartesianViewBox } from 'recharts/types/util/types';

type UtilizationDataPoint = {
	utilization: number;
	depositRate: number;
	borrowRate: number;
};

const dataKeyLabelsMap = {
	depositRate: 'Deposit Rate',
	borrowRate: 'Borrow Rate',
};

const CustomizedLabel = (props: LabelProps) => {
	const { value } = props;
	const viewBox = props.viewBox as CartesianViewBox;

	const x = Number(value) < 45 ? viewBox.x + 10 : viewBox.x - 10;

	return (
		<text
			x={x}
			y={viewBox.height / 2}
			fill={'var(--text-label)'}
			fontSize={10}
			textAnchor={Number(value) < 45 ? 'start' : 'end'}
		>
			Utilization {Number(value).toFixed(2)}%
		</text>
	);
};

// Generate utilization to borrow rate data
const generateBorrowLendChartData = (
	spotMarketAccount: SpotMarketAccount,
	currentUtilization: BigNum,
	numPoints = 20
): UtilizationDataPoint[] => {
	const data = [];
	for (let i = 0; i <= numPoints; i++) {
		const utilization = BigNum.fromPrint(
			`${i / numPoints}`,
			SPOT_MARKET_UTILIZATION_PRECISION_EXP
		);
		const borrowRate = BigNum.from(
			calculateBorrowRate(spotMarketAccount, undefined, utilization.val),
			PERCENTAGE_PRECISION_EXP
		);
		const depositRate = BigNum.from(
			calculateDepositRate(spotMarketAccount, undefined, utilization.val),
			PERCENTAGE_PRECISION_EXP
		);
		data.push({
			utilization: utilization.mul(new BN(100)).toNum(),
			borrowRate: borrowRate.mul(new BN(100)).toNum(),
			depositRate: depositRate.mul(new BN(100)).toNum(),
		});

		// Add in the current utilization point
		if (
			currentUtilization.toNum() > i / numPoints &&
			currentUtilization.toNum() < (i + 1) / numPoints
		) {
			const utilizedBorrowRate = BigNum.from(
				calculateBorrowRate(
					spotMarketAccount,
					undefined,
					currentUtilization.val
				),
				PERCENTAGE_PRECISION_EXP
			);
			const utilizedDepositRate = BigNum.from(
				calculateDepositRate(
					spotMarketAccount,
					undefined,
					currentUtilization.val
				),
				PERCENTAGE_PRECISION_EXP
			);
			data.push({
				utilization: currentUtilization.mul(new BN(100)).toNum(),
				borrowRate: utilizedBorrowRate.mul(new BN(100)).toNum(),
				depositRate: utilizedDepositRate.mul(new BN(100)).toNum(),
			});
		}
	}
	return data;
};

const CustomTooltip = ({ active, payload }: TooltipProps<any, any>) => {
	if (active && payload && payload.length) {
		return (
			<div className="bg-tooltip-bg p-2 flex flex-col text-text-default">
				<div className="flex flex-row items-center w-full justify-between gap-2">
					<Text.BODY3 className="text-text-default">Utilization</Text.BODY3>
					<NumberDisplayV2
						value={BigNum.fromPrint(
							`${payload?.[0]?.payload?.utilization ?? 0}`,
							PERCENTAGE_PRECISION_EXP
						)}
						displayType="percentage"
						toFixed={2}
					/>
				</div>
				{payload.map((item) => (
					<div
						className="flex flex-row items-center w-full justify-between gap-2"
						key={`${JSON.stringify(item)}`}
					>
						<Text.BODY3 className="text-text-default">{`${
							dataKeyLabelsMap[item.dataKey as keyof typeof dataKeyLabelsMap] ||
							item.dataKey
						}`}</Text.BODY3>
						{/* <Text.BODY3>{`${item.value}%`}</Text.BODY3> */}
						<Text.BODY3
							className={twMerge(
								'',
								item.dataKey === 'depositRate' && 'text-positive-green',
								item.dataKey === 'borrowRate' && 'text-warn-yellow'
							)}
						>
							<NumberDisplayV2
								value={BigNum.fromPrint(
									`${item.value}`,
									PERCENTAGE_PRECISION_EXP
								)}
								displayType="percentage"
								toFixed={2}
							/>
						</Text.BODY3>
					</div>
				))}
			</div>
		);
	}

	return null;
};

const BorrowLendRateToUtilizationChart = (props: {
	//timeframeIndex: number;
	marketIndex: number;
	className?: string;
	chartClassName?: string;
	loadingClassName?: string;
}) => {
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const [isLoading, setIsLoading] = React.useState(true);
	const [chartData, setChartData] = React.useState([]);
	const [currentUtilization, setCurrentUtilization] =
		React.useState(ZERO_BIGNUM);
	const [optimalUtilization, setOptimalUtilization] =
		React.useState(ZERO_BIGNUM);

	useEffect(() => {
		if (driftClient && driftClientIsReady) {
			const spotMarketAccount = driftClient.getSpotMarketAccount(
				props.marketIndex
			);
			if (!spotMarketAccount) return;

			const utilization = BigNum.from(
				calculateUtilization(spotMarketAccount),
				SPOT_MARKET_UTILIZATION_PRECISION_EXP
			);
			const chartData = generateBorrowLendChartData(
				spotMarketAccount,
				utilization
			);
			setCurrentUtilization(utilization);
			setOptimalUtilization(
				BigNum.from(
					new BN(spotMarketAccount.optimalUtilization),
					SPOT_MARKET_UTILIZATION_PRECISION_EXP
				)
			);
			setChartData(chartData);
			setIsLoading(false);
		}
	}, [driftClient, driftClientIsReady, props.marketIndex]);

	const maxY = chartData.length
		? Math.max(
				chartData[chartData.length - 1].borrowRate,
				chartData[chartData.length - 1].depositRate
		  )
		: 0;

	// Calculating the Y ticks is kinda tricky... but if we assuming it's mostly always going to max out somewhere between 50% and 1000%, maybe just break it down into either 25%, 50%, 100%, 250% increments.
	const yTicks =
		maxY < 100
			? [0, 25, 50, 75, 100]
			: maxY <= 200
			? [0, 50, 100, 150, 200]
			: maxY <= 500
			? [0, 100, 200, 300, 400, 500]
			: [0, 250, 500, 750, 1000];

	return (
		<div>
			<div className="flex flex-row justify-between items-center">
				<div className="flex flex-row items-center gap-1">
					<Text.BODY2 className="text-text-label">Utilization</Text.BODY2>
					<Text.BODY2 className="text-text-default">
						<NumberDisplayV2
							value={currentUtilization.mul(new BN(100))}
							displayType="percentage"
							toFixed={2}
						/>
					</Text.BODY2>
				</div>
				<div className="flex flex-row items-center gap-1">
					<Text.BODY2 className="text-text-label">
						Optimal Utilization
					</Text.BODY2>
					<Text.BODY2 className="text-text-default">
						<NumberDisplayV2
							value={optimalUtilization.mul(new BN(100))}
							displayType="percentage"
							toFixed={2}
						/>
					</Text.BODY2>
				</div>
			</div>
			<div
				className={twMerge(
					'items-center justify-center flex-grow min-h-[230px] w-full mt-4 mb-6',
					props.className
				)}
			>
				{isLoading ? (
					<div
						className={twMerge(
							`flex flex-col justify-center items-center h-[230px] w-full text-center rounded-md`,
							props.loadingClassName
						)}
					>
						<LoadingChart />
					</div>
				) : (
					<ResponsiveContainer
						className={twMerge('pnl-chart', props.className)}
						width="100%"
						height="100%"
					>
						<LineChart data={chartData}>
							<CartesianGrid stroke={'var(--stroke-secondary)'} />
							<YAxis
								type="number"
								domain={[0, yTicks[yTicks.length - 1]]}
								stroke={'var(--container-border)'}
								tickMargin={4}
								ticks={yTicks}
								tickFormatter={(tick) => `${tick}%`}
								// text css are found in index.css
							></YAxis>
							<XAxis
								type="number"
								domain={[0, 100]}
								dataKey={'utilization'}
								stroke={'var(--container-border)'}
								tickMargin={8}
								ticks={[0, 25, 50, 75, 100]}
								tickFormatter={(tick) => `${tick}%`}
								// text css are found in index.css
							/>

							<Line
								type="monotone"
								dot={false}
								dataKey="depositRate"
								stroke={'var(--positive-green)'}
							/>
							<Line
								type="monotone"
								dot={false}
								dataKey="borrowRate"
								stroke="var(--warn-yellow)"
							/>

							<ReferenceLine
								x={currentUtilization.mul(new BN(100)).toNum()}
								stroke="var(--text-label)"
								label={
									<CustomizedLabel
										value={currentUtilization.mul(new BN(100)).toNum()}
									/>
								}
							/>

							<ChartsTooltip
								content={(tooltipProps) => <CustomTooltip {...tooltipProps} />}
								cursor={{
									stroke: 'var(--text-label)',
									strokeDasharray: '4',
								}}
							/>

							<Legend
								verticalAlign="bottom"
								height={30}
								formatter={(value) =>
									`${
										dataKeyLabelsMap[value as keyof typeof dataKeyLabelsMap] ??
										value
									}`
								}
							/>
						</LineChart>
					</ResponsiveContainer>
				)}
			</div>
		</div>
	);
};

export default React.memo(BorrowLendRateToUtilizationChart);
