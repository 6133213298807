import { StakeProgram } from '@solana/web3.js';
import { useQuery } from '@tanstack/react-query';
import useDriftClient from '../useDriftClient';
import useCurrentAuthority from '../useCurrentAuthority';
import { StakeAccountWithValidatorInfo } from 'src/@types/assets';
import { useCurrentEpochInfo } from '../epoch/useCurrentEpochInfo';
export const useAuthorityNativeStakeAccounts = () => {
	const driftClient = useDriftClient();
	const authority = useCurrentAuthority();
	const currentEpoch = useCurrentEpochInfo();

	return useQuery<StakeAccountWithValidatorInfo[]>({
		queryKey: ['authority-native-stake-accounts', authority?.toBase58()],
		queryFn: async () => {
			const accounts = await driftClient.connection.getParsedProgramAccounts(
				StakeProgram.programId,
				{
					filters: [
						{
							memcmp: {
								offset: 44, // Authority offset
								bytes: authority.toBase58(),
							},
						},
					],
				}
			);

			const stakeAccountsWithValidatorInfo = await Promise.all(
				accounts
					.map(async (account) => {
						const stakeAddress = account.pubkey.toString();
						const accountData = account.account.data;

						try {
							const voterPubKey =
								accountData instanceof Buffer
									? null
									: accountData?.parsed?.info?.stake?.delegation?.voter;
							if (!voterPubKey) {
								return null;
							}
							const validatorInfo = await fetch(
								`https://api.stakewiz.com/validator/${voterPubKey}`
							).then((res) => res.json());

							return {
								stakeAddress,
								lamports: account.account.lamports,
								validatorName: validatorInfo.name,
								validatorVoteAccount: validatorInfo.vote_identity,
								validatorLogo: validatorInfo.image,
								status: getStakeAccountStatus(
									accountData,
									currentEpoch.data.epoch
								),
							};
						} catch (e) {
							console.error('Error processing stake account:', e);
							return null;
						}
					})
					.filter(Boolean)
			);

			return stakeAccountsWithValidatorInfo;
		},
		enabled: !!authority && !!driftClient && !!currentEpoch,
	});
};

function getStakeAccountStatus(
	accountData: any,
	epochNumber: number
): 'deactivated' | 'deactivating' | 'activating' | 'active' {
	const activationEpoch =
		accountData instanceof Buffer
			? null
			: Number(accountData?.parsed?.info?.stake?.delegation?.activationEpoch);
	const deactivationEpoch =
		accountData instanceof Buffer
			? null
			: Number(accountData?.parsed?.info?.stake?.delegation?.deactivationEpoch);

	if (deactivationEpoch) {
		if (deactivationEpoch < epochNumber) {
			return 'deactivated';
		} else if (deactivationEpoch <= epochNumber + 3) {
			return 'deactivating';
		}
	}
	if (activationEpoch) {
		if (activationEpoch >= epochNumber) {
			return 'activating';
		} else {
			return 'active';
		}
	}
}
