import { ENUM_UTILS, MarketId, Opaque } from '@drift/common';
import {
	KnownMarketOrderId,
	MarketOrderToastId,
	UnknownMarketOrderId,
} from './MarketOrderToastStateTypes';
import { BN, PositionDirection, PublicKey } from '@drift-labs/sdk';
import { dlog } from '../../dev';

export type OrderWithUnknownIdProps = {
	idNonce: number;
	user: PublicKey;
	marketId: MarketId;
	baseSize: BN;
	direction: PositionDirection;
};

export type OrderWithKnownIdProps = Omit<OrderWithUnknownIdProps, 'idNonce'> & {
	orderId: number;
};

export type BridgingQueueId = Opaque<'BridgingQueueId', string>;

export const getBridgingQueueId = (
	user: PublicKey,
	marketId: MarketId,
	baseSize: BN,
	direction: PositionDirection
): BridgingQueueId => {
	return `user:${user.toString()}_market:${
		marketId.key
	}_base:${baseSize.toString()}_${ENUM_UTILS.toStr(
		direction
	)}` as BridgingQueueId;
};

export const getKnownMarketOrderId = (
	user: PublicKey,
	marketId: MarketId,
	orderId: number
): KnownMarketOrderId => {
	return `user:${user.toString()}_market:${
		marketId.key
	}_order:${orderId}` as KnownMarketOrderId;
};

const getUnknownMarketOrderId = ({
	idNonce,
	user,
	marketId,
	baseSize,
	direction,
}: OrderWithUnknownIdProps) => {
	return `nonce:${idNonce}_user:${user.toString()}_${
		marketId.key
	}_base:${baseSize.toString()}_${ENUM_UTILS.toStr(
		direction
	)}` as UnknownMarketOrderId;
};

const generateMarketOrderToastId = () => {
	return window.crypto.randomUUID() as MarketOrderToastId;
};

/**
 *
 * Explanation of necessity of this system:
 *
 * We need to be able to associate state changes to the correct toast for incoming events.
 *
 * We don't know the Order ID for an order until the transaction has confirmed, and a blockchain event or user account state update has occured to confirm what the Order ID is.
 *
 * The user MAY make multiple orders while waiting for any specific order to be confirmed, hence we can have multiple toats in flight without a known Order ID.
 *
 * Some events that affect the state don't have the Order Id attached, so if these come before we have confirmed the Order ID, we need to be able to associate the event with the correct toast.
 *
 * The same applies for the first event that does confirm the Order ID - we need to be able to associate it to the correct toast.
 *
 * ----
 *
 * Explanation of different types of IDs:
 * - MarketOrderToastId : This is a unique guid generated for each new market order toast. It only gets generated when we receive the LOCAL event which informs us that a new toast needs to be created. (when we know we've submitted a new order).
 * - UnknownMarketOrderId: This is a unique ID we generate to identify an order when we don't yet know the Order ID. It contains a unique NONCE, which should be attached to the toast state for each order. These help us link any further LOCAL EVENTS to the correct toast, as we will have the nonce available. We store a lookup of these pointing to the unique MarketOrderToastId for further events with Unknown Order ID. And we also store a queue of these in a map of bridging queue ID -> list of unlinked IDs while we wait for the order ID to be confirmed.
 * - KnownMarketOrderId: This is a unique ID we generate to identify an order once we know the Order ID.
 * - BridgingQueueId: This is a non-unique ID we generate to bridge an incoming KnownMarketOrderId and its associated unlinked UnknownMarketOrderId. The bridging ID basically contains the KNOWN details of the order (market, direction, size), these can be duplicated for an order with the same parameters. The BridgingQueueId allows us to store a Queue of unlinked UnknownMarketOrderIds which we can then link to the KnownMarketOrderId when it arrives. The reason we can do this is because we can generate the same BridgingQueueId with the KnownOrderId and UnknownOrderId details we have available for an order. This logic does assume that the Order Ids will come in the same order that the auctions were created - which isn't strictly true - however it is not dangerous in cases where this doesn't hold - because as far as the user is concerned they'd be interchangable because both orders have the same parameters.
 *
 * ----
 *
 * Process explained:
 * 1. New order comes in with unknown order ID (because the order hasn't been confirmed on chain yet).
 * 2. We generate a MarketOrderToastId for the order's toast. (random guid).
 * 3. We generate a UnknownMarketOrderId for the order. (order_nonce + user + market + baseSize + direction).
 * 4. We generate a BridgingQueueId consisting of the known order details. (user + market + baseSize + direction).
 * 5. We store a reference to the MarketOrderToastId in the UnknownMarketOrderId lookup. (Map<UnknownMarketOrderId, MarketOrderToastId>). So that further events with an unknown order ID can be linked to the correct toast. (remember, they'll have the same nonce).
 * 5. We store the UnknownMarketOrderId in the bridging queue. (Map<BridgingQueueId, List<UnknownMarketOrderId>>).
 * 6. When the order is confirmed and we have the Order Id, we pull the first UnknownMarketOrderId off the queue with the matching BridgingQueueId. Now we know which MarketOrderToastId to link to the KnownMarketOrderId event.
 * 7. Once the known order Id has been linked, we'll have both the unique nonce and unique order ID available in the same toast state so any further events will always know which toast to use.
 */
export class BaseMarketOrderToastIdHandler {
	protected static knownIdToastLinkCache: Map<
		KnownMarketOrderId,
		MarketOrderToastId
	> = new Map();
	protected static unknownIdToastLinkCache: Map<
		UnknownMarketOrderId,
		MarketOrderToastId
	> = new Map();
	protected static unlinkedIdQueue: Map<BridgingQueueId, MarketOrderToastId[]> =
		new Map();

	// Cleanup timeout in milliseconds (1 minutes)
	protected static readonly CLEANUP_TIMEOUT = 1 * 60 * 1000;

	protected static logState(context: string) {
		dlog('market_order_toast_id_handler', `state_after_${context}`, {
			knownIdToastLinkCache: Object.fromEntries(
				this.knownIdToastLinkCache ?? new Map()
			),
			unknownIdToastLinkCache: Object.fromEntries(
				this.unknownIdToastLinkCache ?? new Map()
			),
			unlinkedIdQueue: Object.fromEntries(this.unlinkedIdQueue ?? new Map()),
		});
	}

	static addUnlinkedIdQueueClearingTimeout(
		bridgingQueueId: BridgingQueueId,
		marketOrderToastId: MarketOrderToastId
	) {
		setTimeout(() => {
			if (this.unlinkedIdQueue.has(bridgingQueueId)) {
				this.unlinkedIdQueue.set(
					bridgingQueueId,
					this.unlinkedIdQueue
						.get(bridgingQueueId)
						?.filter((id) => id !== marketOrderToastId)
				);
				this.logState('cleanup_unlinked_id_queue_timeout');
			}
		}, this.CLEANUP_TIMEOUT);
	}

	static handleNewMarketOrderWithUnknownOrderId(
		props: OrderWithUnknownIdProps
	) {
		const unknownMarketOrderId = getUnknownMarketOrderId(props);
		const marketOrderToastId = generateMarketOrderToastId();
		const bridgingQueueId = getBridgingQueueId(
			props.user,
			props.marketId,
			props.baseSize,
			props.direction
		);

		if (!this.unknownIdToastLinkCache.has(unknownMarketOrderId)) {
			this.unknownIdToastLinkCache.set(
				unknownMarketOrderId,
				marketOrderToastId
			);
		} else {
			throw new Error(`Market order id already exists for unknown Id props`);
		}

		// Add new abstracted market order ID to the unlinked queue
		if (!this.unlinkedIdQueue.has(bridgingQueueId)) {
			this.unlinkedIdQueue.set(bridgingQueueId, [marketOrderToastId]);

			// For sanity we'll clear out this Id from the unlinked Id queue after 1 minute because it shouldn't be possible for the Order ID to not come through within this amount of time.
			this.addUnlinkedIdQueueClearingTimeout(
				bridgingQueueId,
				marketOrderToastId
			);
		} else {
			this.unlinkedIdQueue.get(bridgingQueueId)?.push(marketOrderToastId);
		}

		this.logState('handleNewMarketOrderWithUnknownOrderId');
		return marketOrderToastId;
	}

	static getToastIdWithUnknownOrderId(props: OrderWithUnknownIdProps) {
		const unknownMarketOrderId = getUnknownMarketOrderId(props);

		if (this.unknownIdToastLinkCache.has(unknownMarketOrderId)) {
			const marketOrderToastId =
				this.unknownIdToastLinkCache.get(unknownMarketOrderId);

			if (!marketOrderToastId || marketOrderToastId.length === 0) {
				throw new Error(
					`Couldn't find abstracted market order ID for unknown ID ${unknownMarketOrderId}`
				);
			}

			return marketOrderToastId;
		} else {
			throw new Error(
				`Couldn't find abstracted market order ID for unknown ID ${unknownMarketOrderId}`
			);
		}
	}

	static getToastIdWithKnownOrderId(props: OrderWithKnownIdProps) {
		const knownMarketOrderId = getKnownMarketOrderId(
			props.user,
			props.marketId,
			props.orderId
		);

		// If we already have the abstracted market order id, return it
		if (this.knownIdToastLinkCache.has(knownMarketOrderId)) {
			const id = this.knownIdToastLinkCache.get(knownMarketOrderId);
			return id;
		}

		// Else we can pull the unlinked id off the queue, and create the link
		const bridgingQueueId = getBridgingQueueId(
			props.user,
			props.marketId,
			props.baseSize,
			props.direction
		);

		const nextUnlinkedId = this.unlinkedIdQueue.get(bridgingQueueId)?.shift();

		if (!nextUnlinkedId) {
			return;
		}

		this.knownIdToastLinkCache.set(knownMarketOrderId, nextUnlinkedId);

		this.logState('getToastIdWithKnownOrderId');
		return nextUnlinkedId;
	}

	static clearStateForUnknownOrderId(props: OrderWithUnknownIdProps) {
		const bridgingQueueId = getBridgingQueueId(
			props.user,
			props.marketId,
			props.baseSize,
			props.direction
		);

		const unknownMarketOrderId = getUnknownMarketOrderId(props);

		const toastId = this.unknownIdToastLinkCache.get(unknownMarketOrderId);

		// Clear the toast id from the unknown id cache
		this.unknownIdToastLinkCache.delete(unknownMarketOrderId);

		// Clear the toast id from the unlinked queue
		if (this.unlinkedIdQueue.has(bridgingQueueId)) {
			this.unlinkedIdQueue.set(
				bridgingQueueId,
				this.unlinkedIdQueue
					.get(bridgingQueueId)
					?.filter((id) => id !== toastId)
			);

			// If queue is empty after filtering, remove it for cleanliness (not strictly necessary for functionality)
			if (!this.unlinkedIdQueue.get(bridgingQueueId)?.length) {
				this.unlinkedIdQueue.delete(bridgingQueueId);
			}
		}

		this.logState('clearStateForUnknownOrderId');
	}
}

// Used by our toast handling logic
export class MarketOrderToastIdHandler extends BaseMarketOrderToastIdHandler {}
