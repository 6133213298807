'use client';

import {
	BigNum,
	BN,
	MarketStatus,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import { ENUM_UTILS, MAIN_POOL_ID, MarketId } from '@drift/common';
import { Open } from '@drift-labs/icons';
import React, { useEffect, useState } from 'react';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useDisplayPrecisionForCollateral from 'src/hooks/useDisplayPrecisionForCollateral';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftClient from 'src/hooks/useDriftClient';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useSafePush from 'src/hooks/useSafePush';
// import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { DEFAULT_ACCOUNT_NAMES_BY_POOL_ID } from 'src/constants/constants';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import UI_UTILS from 'src/utils/uiUtils';
import useAccountsBeingLiquidated from '../hooks/Liquidations/useAccountsBeingLiquidated';
import useCollateralModalState from '../hooks/useCollateralModalState';
import useMarkPrice from '../hooks/useMarkPrice';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import IsolatedPoolsHelpLink from './Earn/IsolatedPoolsHelpLink';
import { CollateralInput } from './Inputs/CollateralInput';
import LabelledInput from './Inputs/LabelledInput';
import Select from './Inputs/Select';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import {
	BorrowsDisabledMessage,
	getShouldBlockBorrows,
} from './PreLaunchMarketAlerts/PreLaunchMarketAlertUtils';
import Text from './Text/Text';
import InfoMessage from './TradeForm/InfoMessage';
import SettleUnsettledInfoMessage from './Utils/SettleUnsettledInfoMessage';
import ValueDisplay from './ValueDisplay';

const BorrowModalContent = ({ onClose }: { onClose: () => void }) => {
	// # Constants
	const borrowData = useDriftStore((s) => s.borrowLendData);
	const actions = useDriftActions();
	const availableSubaccounts = useLazySubAccounts();
	const accountsBeingLiquidated = useAccountsBeingLiquidated();
	const safePush = useSafePush();
	const driftClient = useDriftClient();
	const { captureEvent } = usePostHogCapture();

	// # State
	const [submitting, setSubmitting] = useState(false);
	const [maxButtonTransition, setMaxButtonTransition] = useState(false);
	const [collatTransferAmount, setCollatTransferAmount] = useState('');
	const [isMax, setIsMax] = useState(false);

	const {
		selectedTargetAccountKey,
		selectedPoolId,
		selectedCollateralMarket,
		selectedTargetAccount,
		handleTargetAccountChange,
		updateCollateralIndex,
	} = useCollateralModalState();

	const interestRateBigNum =
		borrowData
			?.find((item) => item.bankIndex == selectedCollateralMarket.marketIndex)
			?.borrowApr.mul(new BN(100)) ?? BigNum.zero();

	const [afterBorrowInterestRateBigNum, setAfterBorrowInterestRateBigNum] =
		useState(interestRateBigNum);

	const currentLiqPriceBigNum = BigNum.from(
		selectedTargetAccount?.client?.spotLiquidationPrice(
			selectedCollateralMarket.marketIndex
		) ?? ZERO,
		PRICE_PRECISION_EXP
	);

	const [afterLiqPriceBigNum, setAfterLiqPriceBigNum] = useState(
		currentLiqPriceBigNum
	);

	const selectedAccountCanbeLiqd = accountsBeingLiquidated.find(
		(account) => `${account.userKey}` === `${selectedTargetAccountKey}`
	);

	const collateralDisplayPrecision = useDisplayPrecisionForCollateral(
		selectedCollateralMarket
	);

	const targetAccountBankBalance = useAccountTargetSpotBalance(
		selectedCollateralMarket,
		selectedTargetAccountKey,
		false
	);

	const targetAccountTotalNetQuoteValue = BigNum.from(
		selectedTargetAccount?.client?.getNetSpotMarketValue() ?? 0,
		QUOTE_PRECISION_EXP
	);

	const marketId = MarketId.createSpotMarket(
		selectedCollateralMarket.marketIndex
	);

	const spotMarketAccount = driftClient.getSpotMarketAccount(
		marketId.marketIndex
	);

	const markPrice = useMarkPrice(marketId);

	const getMarketInfo = useMarketsInfoStore()?.getMarketInfoByIndexAndType;

	const assetCurrentPrice =
		markPrice?.shiftTo(QUOTE_PRECISION_EXP) ?? BigNum.zero(QUOTE_PRECISION_EXP);

	const collatTransferAmountBignum = BigNum.fromPrint(
		collatTransferAmount ? collatTransferAmount : '0',
		selectedCollateralMarket.precisionExp
	);

	const quoteAmountToAdd =
		selectedCollateralMarket.symbol == 'USDC'
			? collatTransferAmountBignum.shiftTo(QUOTE_PRECISION_EXP)
			: collatTransferAmountBignum
					.shiftTo(QUOTE_PRECISION_EXP)
					.mul(assetCurrentPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

	// # Effect Hooks
	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// If max is selected, keep input value in sync with max. allowed value
	useEffect(() => {
		if (isMax) {
			setCollatTransferAmount(
				targetAccountBankBalance.withdrawLimitBase.printShort()
			);
		}
	}, [isMax, targetAccountBankBalance.withdrawLimitBase?.toString()]);

	// keep the new interest rate and liq price updated with the borrow amount the user inputs
	useEffect(() => {
		if (spotMarketAccount) {
			const newInterestRateBigNum = UI_UTILS.getBorrowRateFromDelta(
				collatTransferAmountBignum.val.neg(),
				spotMarketAccount
			);
			setAfterBorrowInterestRateBigNum(newInterestRateBigNum);

			if (selectedTargetAccount?.client) {
				const afterLiqPrice = BigNum.from(
					selectedTargetAccount.client.spotLiquidationPrice(
						spotMarketAccount?.marketIndex,
						collatTransferAmountBignum?.neg()?.val
					),
					PRICE_PRECISION_EXP
				);

				setAfterLiqPriceBigNum(afterLiqPrice);
			}
		}
	}, [
		collatTransferAmountBignum?.toString(),
		spotMarketAccount?.marketIndex,
		selectedTargetAccount?.userKey,
	]);

	// # Methods
	const handleWithdraw = async () => {
		setSubmitting(true);

		const collateralAmount = BigNum.fromPrint(
			collatTransferAmount,
			selectedCollateralMarket.precisionExp
		);

		const isBorrow =
			targetAccountBankBalance.assetBaseBalance.lt(collateralAmount);

		const result = await actions.withdrawCollateral({
			amount: collateralAmount,
			spotMarketConfig: selectedCollateralMarket,
			userAccount: selectedTargetAccount?.client?.getUserAccount() ?? null,
			isBorrow,
		});

		await actions.fetchMarginAccountInfo(selectedTargetAccountKey);

		setSubmitting(false);

		captureEvent('submitted_borrow', {
			spot_market_symbol: selectedCollateralMarket.symbol,
		});

		if (result === true) {
			onClose();
		}
	};

	const handleValueChange = (newValue: string) => {
		setIsMax(false);
		setCollatTransferAmount(newValue);
	};

	const handleMax = () => {
		setCollatTransferAmount(
			targetAccountBankBalance.withdrawLimitBase.printShort()
		);
		setIsMax(true);
	};

	const isWithdraw =
		collatTransferAmountBignum.gtZero() &&
		targetAccountBankBalance.netBaseBalance.gte(collatTransferAmountBignum);

	const isPartialWithdraw =
		collatTransferAmountBignum.gtZero() &&
		targetAccountBankBalance.netBaseBalance.gtZero() &&
		collatTransferAmountBignum.gt(targetAccountBankBalance.netBaseBalance);

	const exceededMax = collatTransferAmountBignum.gt(
		targetAccountBankBalance.withdrawLimitBase
	);

	const shouldBlockBorrows =
		getShouldBlockBorrows(
			selectedTargetAccount.client.getUserAccount(),
			getMarketInfo
		) && selectedCollateralMarket.marketIndex !== 0;

	const allowConfirmation =
		!shouldBlockBorrows &&
		!submitting &&
		collatTransferAmount &&
		collatTransferAmountBignum.gt(
			BigNum.zero(selectedCollateralMarket.precisionExp),
			true
		) &&
		!selectedAccountCanbeLiqd;

	useEffect(() => {
		if (exceededMax) {
			setCollatTransferAmount(
				targetAccountBankBalance.withdrawLimitBase.print()
			);
		}
	}, [exceededMax]);

	const marketReduceOnly = ENUM_UTILS.match(
		spotMarketAccount.status,
		MarketStatus.REDUCE_ONLY
	);

	// # Rendering
	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					{marketReduceOnly ? (
						<div className="items-center block">
							<Text.P1 className="mr-1 text-warn-yellow">
								Borrows are currently disabled on this market.
							</Text.P1>
						</div>
					) : (
						<div className="items-center block">
							<Text.P1 className="mr-1 text-text-emphasis">
								Borrowed amounts are withdrawn to your wallet.
							</Text.P1>
						</div>
					)}

					<Utility.VERTSPACERXL />

					{selectedPoolId !== MAIN_POOL_ID && (
						<>
							<InfoMessage
								type="warn"
								messageTitle=""
								message={
									<div className="flex flex-col gap-1">
										<Text.BODY2 className="font-semibold text-text-emphasis">
											Collateral Use Limitation
										</Text.BODY2>
										<Text.BODY2>
											This market is in an isolated pool. Only deposits within
											the same pool will be used as collateral for borrowing.
										</Text.BODY2>
										<IsolatedPoolsHelpLink />
									</div>
								}
							/>
							<Utility.VERTSPACERL />
						</>
					)}

					{availableSubaccounts.length > 1 && (
						<>
							<LabelledInput label="Select an account">
								<Select.Subaccount
									id="depositAccountSelection"
									onChange={handleTargetAccountChange}
									placeholderText={
										DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[selectedPoolId]
									}
									options={availableSubaccounts}
									initialSelection={selectedTargetAccountKey}
									disabled={
										!selectedTargetAccountKey && selectedPoolId !== MAIN_POOL_ID
									}
								/>
							</LabelledInput>
							<Utility.VERTSPACERXL />
						</>
					)}

					<CollateralInput
						selectedMarket={selectedCollateralMarket}
						maxAmount={targetAccountBankBalance.withdrawLimitBase}
						onChangeMarket={(newMarket) => {
							updateCollateralIndex(newMarket.marketIndex);
						}}
						label="Asset to borrow and amount"
						value={
							marketReduceOnly
								? ''
								: isMax
								? targetAccountBankBalance.withdrawLimitBase.printShort()
								: collatTransferAmount
						}
						onChangeValue={handleValueChange}
						onMax={handleMax}
						amountLabel="Borrow limit"
						customElementRight={
							<Text.MICRO3
								onClick={() => {
									safePush('/earn/borrow-lend');
									onClose();
								}}
								className="inline-flex items-center text-th-grey-20 hover:cursor-pointer text-text-secondary"
							>
								<div className="mt-0.5">
									{`Borrow APR ${interestRateBigNum.prettyPrint()}%`}
								</div>
								<Open size={14} className="ml-1" />
							</Text.MICRO3>
						}
						tooltipText="Borrow limits are based on your account’s free collateral
						(which includes open orders) and liquidity limits within the
						protocol."
						disabled={marketReduceOnly}
						source={{
							type: 'default',
						}}
						poolId={selectedPoolId}
					/>

					<SettleUnsettledInfoMessage className={'mt-2'} onlyShowIfPositive />

					{shouldBlockBorrows && (
						<>
							<Utility.VERTSPACERL />
							<div className="max-w-[530px]">
								<BorrowsDisabledMessage />
							</div>
						</>
					)}

					{selectedAccountCanbeLiqd && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="error"
								messageTitle="Withdrawals from this account are paused"
								message={
									<>
										Once the account liquidation is complete, withdrawals will
										be enabled again.
									</>
								}
							/>
						</>
					)}

					{isWithdraw && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="info"
								messageTitle="Borrow amount is less than your deposits"
								message={
									<>
										The amount will be withdrawn from your account instead of
										borrowed.
									</>
								}
							/>
						</>
					)}

					{isPartialWithdraw && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="info"
								messageTitle="Total borrow amount will be less"
								message={
									<>
										{`Your ${selectedCollateralMarket.symbol} deposits will be withdrawn and the remaining amount will be borrowed.`}
									</>
								}
							/>
						</>
					)}

					<Utility.VERTSPACERXL />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />
					{(isWithdraw || isPartialWithdraw) && (
						<>
							<ValueDisplay.ValueChange
								label="Asset balance"
								previousValue={targetAccountBankBalance.netBaseBalance}
								afterValue={targetAccountBankBalance.netBaseBalance.sub(
									collatTransferAmountBignum
								)}
								previousValuePrint={`${targetAccountBankBalance.netBaseBalance.prettyPrint()} ${
									selectedCollateralMarket.symbol
								}`}
								afterValuePrint={`${targetAccountBankBalance.netBaseBalance
									.sub(collatTransferAmountBignum)
									.prettyPrint()} ${selectedCollateralMarket.symbol}`}
							/>
							<Utility.VERTSPACERS />
						</>
					)}
					{!isWithdraw && (
						<>
							{isPartialWithdraw ? (
								<ValueDisplay.ValueChange
									label="Total borrowed"
									previousValue={targetAccountBankBalance.liabilityBaseBalance}
									afterValue={targetAccountBankBalance.liabilityBaseBalance.add(
										collatTransferAmountBignum.sub(
											targetAccountBankBalance.assetBaseBalance
										)
									)}
									previousValuePrint={`${targetAccountBankBalance.liabilityBaseBalance.toFixed(
										collateralDisplayPrecision
									)} ${selectedCollateralMarket.symbol}`}
									afterValuePrint={`${targetAccountBankBalance.liabilityBaseBalance
										.add(
											collatTransferAmountBignum.sub(
												targetAccountBankBalance.assetBaseBalance
											)
										)
										.toFixed(collateralDisplayPrecision)} ${
										selectedCollateralMarket.symbol
									}`}
								/>
							) : (
								<ValueDisplay.ValueChange
									label="Total borrowed"
									previousValue={targetAccountBankBalance.liabilityBaseBalance}
									afterValue={targetAccountBankBalance.liabilityBaseBalance.add(
										collatTransferAmountBignum
									)}
									previousValuePrint={`${targetAccountBankBalance.liabilityBaseBalance.toFixed(
										collateralDisplayPrecision
									)} ${selectedCollateralMarket.symbol}`}
									afterValuePrint={`${targetAccountBankBalance.liabilityBaseBalance
										.add(collatTransferAmountBignum)
										.toFixed(collateralDisplayPrecision)} ${
										selectedCollateralMarket.symbol
									}`}
								/>
							)}
							<Utility.VERTSPACERS />
						</>
					)}

					<ValueDisplay.ValueChange
						label="Borrow available"
						previousValue={targetAccountBankBalance.withdrawLimitBase}
						afterValue={targetAccountBankBalance.withdrawLimitBase.sub(
							collatTransferAmountBignum
						)}
						previousValuePrint={`${targetAccountBankBalance.withdrawLimitBase.prettyPrint()} ${
							selectedCollateralMarket.symbol
						}`}
						afterValuePrint={`${targetAccountBankBalance.withdrawLimitBase
							.sub(collatTransferAmountBignum)
							.prettyPrint()} ${selectedCollateralMarket.symbol}`}
					/>
					<Utility.VERTSPACERS />

					<ValueDisplay.ValueChange
						label="Net Account Balance (USD)"
						previousValue={targetAccountTotalNetQuoteValue}
						afterValue={targetAccountTotalNetQuoteValue.sub(quoteAmountToAdd)}
						previousValuePrint={UI_UTILS.toNotional(
							targetAccountTotalNetQuoteValue.toNum()
						)}
						afterValuePrint={UI_UTILS.toNotional(
							targetAccountTotalNetQuoteValue.sub(quoteAmountToAdd).toNum()
						)}
					/>
					<Utility.VERTSPACERS />

					<ValueDisplay.ValueChange
						label="Liquidation Price"
						previousValue={currentLiqPriceBigNum}
						afterValue={afterLiqPriceBigNum}
						previousValuePrint={
							currentLiqPriceBigNum.lteZero()
								? 'None'
								: UI_UTILS.toNotional(currentLiqPriceBigNum.toNum())
						}
						afterValuePrint={
							afterLiqPriceBigNum.lteZero()
								? 'None'
								: UI_UTILS.toNotional(afterLiqPriceBigNum.toNum())
						}
					/>

					<Utility.VERTSPACERS />

					<ValueDisplay.ValueChange
						label="Interest Rate"
						previousValue={interestRateBigNum}
						afterValue={afterBorrowInterestRateBigNum}
						previousValuePrint={`${interestRateBigNum.prettyPrint()}%`}
						afterValuePrint={`${afterBorrowInterestRateBigNum.prettyPrint()}%`}
					/>

					<Utility.VERTSPACERL />

					<Modal.Confirm
						primaryDisabled={!allowConfirmation}
						onConfirm={handleWithdraw}
						customLabel={`Confirm ${isWithdraw ? 'Withdrawal' : 'Borrow'}`}
					/>
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(BorrowModalContent);
