'use client';

import useNotificationPlacementSetting from 'src/hooks/useNotificationPlacementSetting';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Notification from './Notification';
import V2MarketOrderAlert from './MarketOrders/V2AuctionAlert';
import useNotificationStore, {
	Notification as NotificationType,
} from '../../stores/useNotificationStore';
import { twMerge } from 'tailwind-merge';
import Button from '../Button';
import { useEffect, useRef, useState } from 'react';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { SEMANTIC_STATUS } from '../Utils/SemanticChip';
import SwiftAlert from './MarketOrders/SwiftAlert';

const COLLAPSED_NOTIFICATIONS_LIMIT = 3;

// The displayed notifications list is reversed, so we need to get the reverse index
const getReverseIndex = (index: number, length: number) => {
	return length - index - 1;
};

const GenericNotification = ({
	notification,
	index,
	hide,
}: {
	notification: NotificationType;
	index: number;
	hide: (notificationId?: string) => void;
}) => {
	const driftStatus = useDriftStore((s) => s.driftStatus.mainStatus);

	const getNotificationComponent = (
		notification: NotificationType,
		index: number,
		driftStatus: {
			status: SEMANTIC_STATUS;
			message: string;
		}
	) => {
		switch (notification.type) {
			case 'v2MarketOrder':
				return (
					<V2MarketOrderAlert
						data={notification}
						onClose={() => hide(notification.id)}
					/>
				);
			case 'signedMsgOrder':
				return (
					<SwiftAlert
						data={notification}
						onClose={() => hide(notification.id)}
					/>
				);
			case 'auction':
				return null;
			default:
				return (
					<Notification
						key={`${notification.startTimeMs}`}
						notificationData={notification}
						index={index}
						statusWarning={driftStatus.message}
						statusType={driftStatus.status}
					/>
				);
		}
	};

	return (
		<span key={index}>
			{getNotificationComponent(notification, index, driftStatus)}
		</span>
	);
};

/**
 * This will show 3 notifications at a time, and will collapse the rest behind the 3rd toast.
 */
const CollapsedNotificationsList = ({
	notifications,
	hide,
}: {
	notifications: NotificationType[];
	hide: (notificationId?: string) => void;
}) => {
	const firstTwoNotifications = notifications.slice(0, 2);

	if (notifications.length < 3) {
		return null;
	}

	return (
		<>
			{/** First 2 notifications */}
			{firstTwoNotifications.map((n, idx) => (
				<GenericNotification
					key={idx}
					index={getReverseIndex(idx, notifications.length)}
					notification={n}
					hide={hide}
				/>
			))}

			{/** 3rd notification */}
			<div className="pb-4 overflow-hidden">
				<div className="relative">
					<GenericNotification
						index={getReverseIndex(2, notifications.length)}
						notification={notifications[2]}
						hide={hide}
					/>

					{notifications.slice(3, 5).map((n, idx) => (
						<div
							key={idx + 3}
							className="absolute"
							style={{
								transform: `scale(${1 - 0.05 * (idx + 1)})`,
								bottom: -8 * (idx + 1),
								zIndex: -idx,
							}}
						>
							<GenericNotification
								index={getReverseIndex(idx + 3, notifications.length)}
								notification={n}
								hide={hide}
							/>
						</div>
					))}
				</div>
			</div>
		</>
	);
};

const ToastList = () => {
	const isMobile = useIsMobileScreenSize();
	const notifications = useNotificationStore((s) => s.notifications);
	const clearAllNotifications = useNotificationStore(
		(s) => s.clearAllNotifications
	);
	const hideNotificationById = useNotificationStore(
		(s) => s.hideNotificationById
	);
	const [notificationSelection] = useNotificationPlacementSetting();

	const [userSelectedCollapsed, setUserSelectedCollapsed] = useState(true);
	const [
		isFirstToastScrolledPartiallyAwayFromView,
		setIsFirstToastScrolledPartiallyAwayFromView,
	] = useState(false);
	const [
		isLastToastScrolledFullyIntoView,
		setIsLastToastScrolledFullyIntoView,
	] = useState(true);

	const toastListContainerRef = useRef<HTMLDivElement>(null);

	const [topBarHeight, setTopBarHeight] = useState(48);
	const [footerHeight, setFooterHeight] = useState(28);

	useEffect(() => {
		function updateTopBarHeight() {
			const topBar = document.getElementById('topbar');
			if (topBar) {
				const { bottom } = topBar.getBoundingClientRect();
				setTopBarHeight(bottom);
			}
		}

		function updateFooterHeight() {
			const footer = document.getElementById('footer');
			if (footer) {
				const { height } = footer.getBoundingClientRect();
				setFooterHeight(Math.max(height, 26)); // 26px is the minimum height
			}
		}

		setTimeout(() => {
			updateTopBarHeight();
			updateFooterHeight();
		}, 500); // allow time for the topbar/footer to render

		// Optional: Add event listeners if the heights might change dynamically
		window.addEventListener('resize', updateTopBarHeight);
		window.addEventListener('resize', updateFooterHeight);

		return () => {
			window.removeEventListener('resize', updateTopBarHeight);
			window.removeEventListener('resize', updateFooterHeight);
		};
	}, []);

	const displayedNotifications = [...notifications]
		.reverse()
		.filter((n) => !n.hidden);

	const notificationsToUse = notifications.filter((notification) => {
		return !notification.hidden;
	});

	const isNotificationsCollapsed =
		notifications.length > COLLAPSED_NOTIFICATIONS_LIMIT &&
		userSelectedCollapsed;
	const isViewAllNotifications =
		notifications.length > COLLAPSED_NOTIFICATIONS_LIMIT &&
		!userSelectedCollapsed;

	const notificationPositionStyles = isMobile
		? { top: topBarHeight, left: 0, right: 0 }
		: notificationSelection === 'bottomLeft'
		? { left: 0, bottom: footerHeight }
		: notificationSelection === 'bottomRight'
		? { right: 0, bottom: footerHeight }
		: { right: 0, top: topBarHeight };

	// whenever the notification stack restarts, it should start from a collapsed state
	useEffect(() => {
		if (displayedNotifications.length === 0) {
			setUserSelectedCollapsed(true);
		}
	}, [displayedNotifications.length]);

	// handle scroll events to determine displaying of scroll container shadows
	useEffect(() => {
		const handleFirstToastScroll = () => {
			if (toastListContainerRef.current) {
				const firstChild = toastListContainerRef.current
					.firstElementChild as HTMLElement;
				if (
					firstChild &&
					toastListContainerRef.current.scrollTop > firstChild.offsetTop
				) {
					setIsFirstToastScrolledPartiallyAwayFromView(true);
				} else {
					setIsFirstToastScrolledPartiallyAwayFromView(false);
				}
			}
		};

		const handleLastToastScroll = () => {
			if (toastListContainerRef.current) {
				const lastChild = toastListContainerRef.current
					.lastElementChild as HTMLElement;
				if (
					lastChild &&
					toastListContainerRef.current.scrollTop +
						toastListContainerRef.current.clientHeight >=
						lastChild.offsetTop + lastChild.clientHeight
				) {
					setIsLastToastScrolledFullyIntoView(true);
				} else {
					setIsLastToastScrolledFullyIntoView(false);
				}
			}
		};

		const container = toastListContainerRef.current;
		if (container) {
			container.addEventListener('scroll', handleFirstToastScroll);
			container.addEventListener('scroll', handleLastToastScroll);
		}

		// Cleanup function to remove the event listener when the component unmounts
		return () => {
			if (container) {
				container.removeEventListener('scroll', handleFirstToastScroll);
				container.removeEventListener('scroll', handleLastToastScroll);
			}
		};
	}, [toastListContainerRef.current]);

	if (notificationsToUse.length === 0) return null;

	const hide = (notificationId?: string) => {
		hideNotificationById(notificationId);
	};

	return (
		<div
			className={twMerge(`fixed w-full sm:w-auto flex flex-col px-2 pt-1`)}
			style={{
				zIndex: 200,
				...notificationPositionStyles,
			}}
		>
			<div className="absolute inset-0 rounded bg-container-bg -z-10 opacity-[0.97] blur-[15px]">
				{/** List Background */}
			</div>

			{/** Notifications List */}
			<div
				className={`flex flex-col w-full space-y-4 z-10 overflow-y-auto pt-10 hidden-scroll relative`}
				style={{
					maxHeight: `calc(100vh - ${topBarHeight * 2}px - ${footerHeight}px)`, // 2x top bar height because the top starts from topBarHeight
				}}
				ref={toastListContainerRef}
			>
				{isNotificationsCollapsed ? (
					<CollapsedNotificationsList
						notifications={displayedNotifications}
						hide={hide}
					/>
				) : (
					displayedNotifications.map((n, idx) => (
						<GenericNotification
							key={idx}
							index={getReverseIndex(idx, notifications.length)}
							notification={n}
							hide={hide}
						/>
					))
				)}
			</div>

			{/** Shadows that appear when container is scrollable */}
			{isViewAllNotifications && (
				<div
					className={twMerge(
						'absolute inset-x-2 top-0 h-10 bg-gradient-to-b from-container-bg to-100% to-transparent z-30 transition-opacity duration-200 rounded-t pointer-events-none',
						isFirstToastScrolledPartiallyAwayFromView
							? 'opacity-100'
							: 'opacity-0'
					)}
				></div>
			)}
			{isViewAllNotifications && (
				<div
					className={twMerge(
						'absolute inset-x-2 bottom-0 h-10 bg-gradient-to-t from-container-bg to-100% to-transparent z-30 transition-opacity duration-200 rounded-b pointer-events-none',
						!isLastToastScrolledFullyIntoView ? 'opacity-100' : 'opacity-0'
					)}
					style={{
						bottom: footerHeight + 20,
					}}
				></div>
			)}

			{/** Notifications List Actions */}
			{displayedNotifications.length > 3 && (
				<div
					className={twMerge(
						'z-20 flex items-center w-full gap-2 mt-4 bg-container-bg'
					)}
				>
					<Button.Secondary
						size="MEDIUM"
						className="flex-1"
						onClick={clearAllNotifications}
					>
						Clear All
					</Button.Secondary>
					<Button.Secondary
						positiveGreen
						size="MEDIUM"
						className="flex-1"
						onClick={() => {
							setUserSelectedCollapsed(!userSelectedCollapsed);
						}}
					>
						{userSelectedCollapsed ? 'View All' : 'Collapse All'}
					</Button.Secondary>
				</div>
			)}
		</div>
	);
};

export default ToastList;
