'use client';

import XButton from 'src/components/Buttons/XButton';
import Text from 'src/components/Text/Text';
import LightningIcon from 'src/components/Icons/LightningIcon';
import { useLocalStorageStringState } from 'src/hooks/useLocalStorageState';
import React from 'react';
import { usePathname } from 'next/navigation';
import { DriftStore } from '../../stores/DriftStore/useDriftStore';
import ActiveLink from '../Utils/ActiveLink';
import PopupAlertWrapper from './PopupAlertWrapper';
import { JSX } from 'react';
import RoundedGradientBorderBox, {
	RoundedGradientBorderBoxProps,
} from '../RoundedGradientBorderBox';
import { twMerge } from 'tailwind-merge';

const PopupAlert = ({
	alertType,
	heading,
	children,
	featureKey,
	icon,
	withHref,
	className,
	gradientBoxProps,
	onSeen,
}: {
	alertType?: DriftStore['driftFeatureAlert']['alertType'];
	heading: string;
	children?: React.ReactNode;
	featureKey: string;
	icon?: JSX.Element;
	withHref?: string;
	onSeen?: () => void;
	className?: string;
	gradientBoxProps?: RoundedGradientBorderBoxProps;
}) => {
	const pathname = usePathname();
	const [hasSeenAlert, setHasSeenAlert] = useLocalStorageStringState(
		featureKey,
		'false'
	);

	const handleSeen = () => {
		if (onSeen) {
			onSeen?.();
			// NOTE : Not 100% sure whether passing in the optional onSeen method should override or complement the existing alert handling logic. Going with overriding for now.
			return;
		}

		setHasSeenAlert('true');
	};

	if (
		hasSeenAlert === 'true' ||
		pathname.match(/\/onboarding\//g) ||
		pathname.match(/\/bridge\//g)
	)
		return null;

	const noIcon = alertType === 'warning';

	const Content = (
		<RoundedGradientBorderBox
			borderWidth="2px"
			borderRadius="6px"
			{...gradientBoxProps}
		>
			<div
				className={twMerge(
					'relative py-3 pl-3 pr-5 shadow-lg bg-container-bg',
					className
				)}
			>
				<div className="flex flex-row items-start">
					{noIcon ? undefined : (
						<>
							<div className="pt-1 pr-1">
								{icon ? icon : <LightningIcon className="w-6 h-6" />}
							</div>
						</>
					)}
					<div className="pl-2">
						<div className="text-text-emphasis">
							<Text.BODY3>{heading}</Text.BODY3>
						</div>
						<div className="pt-1 text-xs leading-4 text-text-default">
							<Text.BODY3 className="tracking-normal font-extralight">
								{children}
							</Text.BODY3>
						</div>
					</div>
				</div>
				<div className="absolute top-2 sm:top-3 right-2 sm:right-3 ">
					<XButton
						stroke="var(--text-interactive)"
						onClick={(e) => {
							handleSeen();
							e.preventDefault();
							e.stopPropagation();
						}}
						size={20}
					/>
				</div>
			</div>
		</RoundedGradientBorderBox>
	);

	return (
		<PopupAlertWrapper width="430px">
			{withHref ? (
				<ActiveLink href={withHref} skipHoverOpacity onClick={handleSeen}>
					{Content}
				</ActiveLink>
			) : (
				Content
			)}
		</PopupAlertWrapper>
	);
};

export default PopupAlert;
