'use client';

import { MarketId } from '@drift/common';
import { twMerge } from 'tailwind-merge';
import {
	PERP_MARKETS_LOOKUP,
	SPOT_MARKETS_LOOKUP,
} from '../../environmentVariables/EnvironmentVariables';
import useDriftActions from '../../hooks/useDriftActions';
import useDriftClient from '../../hooks/useDriftClient';
import useDriftClientIsReady from '../../hooks/useDriftClientIsReady';
import useIsMobileScreenSize from '../../hooks/useIsMobileScreenSize';
import { getMaxLeverageForMarket } from '../../hooks/useMarketMaxLeverages';
import { GradientButton } from '../Button';
import XButton from '../Buttons/XButton';
import PopupAlertWrapper from '../PopupAlert/PopupAlertWrapper';
import Text from '../Text/Text';
import Link from '../Utils/Link';
import MarketIcon from '../Utils/MarketIcon';
import SvgBgDiv from '../Utils/SvgBgDiv';

export const PREE_MARKET_ALERT_SEEN_STORAGE_KEY = 'seen_pre_market_alert';

const getHasSeenAlertKey = (marketId: MarketId) =>
	`${PREE_MARKET_ALERT_SEEN_STORAGE_KEY}::${marketId.key}`;

export const hasSeenPreLaunchMarketAlertForMarket = (marketId: MarketId) =>
	window?.localStorage
		? !!window?.localStorage.getItem(getHasSeenAlertKey(marketId))
		: false;

const SplashIcon = (props: { className?: string; marketSymbol: string }) => {
	return (
		<MarketIcon
			sizeClass="h-[40px] w-[40px]"
			className={props.className}
			marketSymbol={props.marketSymbol}
			style={{
				boxShadow: '0px 3px 3px 0px rgba(0, 0, 0, 0.45)',
				borderRadius: '50%',
			}}
		/>
	);
};

const MarketIconsSplash = (props: { marketSymbol: string }) => {
	return (
		<div className="relative flex">
			<SplashIcon
				className="relative left-4 top-0.5 z-0"
				marketSymbol={props.marketSymbol}
			/>
			<SplashIcon className="relative z-10" marketSymbol={props.marketSymbol} />
			<SplashIcon
				className="relative right-4 top-0.5 z-0"
				marketSymbol={props.marketSymbol}
			/>
		</div>
	);
};

const PreLaunchMarketingAlertBanner = ({
	marketSymbol,
	marketId,
	handleClose,
}: {
	marketSymbol: string;
	marketId: MarketId;
	handleClose: () => void;
}) => {
	const marketConfig = marketId.isPerp
		? PERP_MARKETS_LOOKUP[marketId.marketIndex]
		: SPOT_MARKETS_LOOKUP[marketId.marketIndex];

	const isMobile = useIsMobileScreenSize();

	const widthToUse = isMobile ? 'w-full' : 'w-[220px]';

	return (
		<div
			className={twMerge(
				'relative bg-gradient-to-b from-[#350961] to-[#213A92]',
				isMobile ? 'h-[180px]' : '',
				widthToUse
			)}
		>
			<SvgBgDiv
				svgPath={`/assets/images/prelaunch/featured-pre-launch-rocket.webp`}
				className={twMerge('text-white h-full', widthToUse)}
			>
				<div className="flex flex-col items-center justify-center h-full p-4 space-y-4">
					<div>
						<MarketIconsSplash marketSymbol={marketSymbol} />
					</div>
					<div className="flex flex-col items-center space-y-1 text-center">
						<Text.BODY3 className="text-[10px]">
							<div>Pre-Launch Market</div>
						</Text.BODY3>
						<Text.XL className="text-[14px]">
							<div>{`${marketConfig.symbol} IS NOW LIVE`}</div>
						</Text.XL>
					</div>
				</div>
			</SvgBgDiv>

			{isMobile && (
				<div className="absolute top-4 right-4">
					<XButton
						stroke="var(--text-interactive)"
						onClick={handleClose}
						className="w-6 h-6"
					/>
				</div>
			)}
		</div>
	);
};

/**
 * This is the alert which shows up to introduce the pre-launch market feature. It shows when the user first visits the drift app and hasn't seen the alert before. A different alert shows to give users more context when they actually try to click on a pre-launch market
 * @returns
 */
const PreLaunchMarketingAlert = (props: {
	marketId: MarketId;
	onContinue: () => void;
}) => {
	const marketConfig = props.marketId.isPerp
		? PERP_MARKETS_LOOKUP[props.marketId.marketIndex]
		: SPOT_MARKETS_LOOKUP[props.marketId.marketIndex];

	const actions = useDriftActions();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const isMobile = useIsMobileScreenSize();

	if (!driftClientIsReady || !driftClient) return null;

	const { maxLeverage } = getMaxLeverageForMarket(
		props.marketId.marketType,
		props.marketId.marketIndex,
		driftClient
	);

	const switchMarket = actions.switchMarket;

	const handleClose = () => {
		localStorage.setItem(getHasSeenAlertKey(props.marketId), 'true');
		props.onContinue();
	};

	const handleTrade = () => {
		switchMarket({
			marketIndex: props.marketId.marketIndex,
			marketType: props.marketId.marketType,
		});
		localStorage.setItem(getHasSeenAlertKey(props.marketId), 'true');
		props.onContinue();
	};

	return (
		<PopupAlertWrapper width="667px" height={isMobile ? undefined : '140px'}>
			<div
				className={twMerge(
					'flex h-full justify-stretch',
					isMobile && 'flex-col'
				)}
			>
				<PreLaunchMarketingAlertBanner
					marketId={props.marketId}
					marketSymbol={marketConfig.symbol}
					handleClose={handleClose}
				/>
				<div
					className={twMerge(
						'flex flex-col p-6 justify-between space-y-4 relative',
						!isMobile && 'relative pr-8'
					)}
				>
					<div className="flex flex-col text-text-emphasis">
						<Text.BODY2>
							{`${marketConfig.symbol} is now available to trade as a Pre-Launch Market with up to ${maxLeverage}x leverage.`}
						</Text.BODY2>
					</div>
					<div
						className={twMerge(
							'flex justify-between items-center',
							isMobile && 'flex-col space-y-4'
						)}
					>
						<GradientButton
							type="app-gradient"
							size={isMobile ? 'LARGE' : 'MEDIUM'}
							onClick={handleTrade}
						>
							<Text.H5>Trade {marketConfig.symbol}</Text.H5>
						</GradientButton>
						<span className="flex justify-center flex-grow">
							<Link href="https://docs.drift.trade/trading/prelaunch-markets">
								About Pre-Launch Markets
							</Link>
						</span>
					</div>
				</div>
				{!isMobile && (
					<div className="absolute top-2 right-2">
						<XButton
							stroke="var(--text-interactive)"
							onClick={handleClose}
							className="w-6 h-6"
						/>
					</div>
				)}
			</div>
		</PopupAlertWrapper>
	);
};

export default PreLaunchMarketingAlert;
