import { dlog } from '../../dev';
import { MarketOrderToastStateTransitionEvent } from './MarketOrderToastStateTypes';
import { ENUM_UTILS } from '@drift/common';
import { OrderStatus } from '@drift-labs/sdk';
import posthog from 'posthog-js';

/**
 * Auction state machine to manage the following mermaid chart state machine:
 * 
 * 
 *  
	state "Placing Order (signing)" as PLACING_ORDER_WAITING_SIGNATURE
    state "Placing Order (confirming)" as PLACING_ORDER_WAITING_CONFIRMATION
    state "Awaiting Auction" as AWAITING_AUCTION
    state "Auction In Progress" as AUCTION_IN_PROGRESS
    state "Completing Order" as COMPLETING_ORDER
    state "Warning - Lost Sync : No Order Record" as WARNING_LOST_SYNC_NO_ORDER_RECORD
    state "Bail - Lost Sync" as BAIL_LOST_SYNC
    state "Bail - Tx Error" as BAIL_TX_ERROR
    state "Cancelled" as CANCELLED
    state "Expired" as EXPIRED

    %% Special Class to represent all possible states
    %% state "MATCH_ALL" as MATCH_ALL

    [*] --> PLACING_ORDER_WAITING_SIGNATURE

	TODO: clean up signed message order state machine logic, some unnecessary transitions
	PLACING_ORDER_WAITING_MESSAGE_SIGNATURE --> SIGNED_MESSAGE_ORDER_PLACED: tx_signed
	SIGNED_MESSAGE_ORDER_PLACED --> AWAITING_AUCTION: receive_confirmation_signed_msg_order
	AWAITING_AUCTION --> AWAITING_AUCTION: receive_confirmation_signed_msg_order
	SIGNED_MESSAGE_ORDER_PLACED --> BAIL_TX_ERROR: tx_failed
	SIGNED_MESSAGE_ORDER_PLACED --> EXPIRED: signed_msg_order_expired

	SIGNED_MESSAGE_ORDER_PLACED --> AWAITING_AUCTION: receive_fill_action
	SIGNED_MESSAGE_ORDER_PLACED --> END_STATE: order_completely_filled


	PLACING_ORDER_WAITING_SIGNATURE --> PLACING_ORDER_WAITING_CONFIRMATION: tx_signed
	PLACING_ORDER_WAITING_SIGNATURE --> BAIL_TX_ERROR: tx_failed
    
    PLACING_ORDER_WAITING_CONFIRMATION --> AWAITING_AUCTION: receive_confirmation
    PLACING_ORDER_WAITING_CONFIRMATION --> AWAITING_AUCTION: receive_fill_action
    PLACING_ORDER_WAITING_CONFIRMATION --> AUCTION_IN_PROGRESS: receive_order_record
    PLACING_ORDER_WAITING_CONFIRMATION --> BAIL_TX_ERROR: tx_failed
    PLACING_ORDER_WAITING_CONFIRMATION --> COMPLETING_ORDER: received_confirmation_and_auction_disabled
    PLACING_ORDER_WAITING_CONFIRMATION --> END_STATE: order_completely_filled

    AWAITING_AUCTION --> AWAITING_AUCTION: receive_confirmation
    AWAITING_AUCTION --> AWAITING_AUCTION: receive_fill_action
    AWAITING_AUCTION --> END_STATE: order_completely_filled
    AWAITING_AUCTION --> AUCTION_IN_PROGRESS: receive_order_record

    AUCTION_IN_PROGRESS --> AUCTION_IN_PROGRESS: receive_order_record
    AUCTION_IN_PROGRESS --> AUCTION_IN_PROGRESS: receive_confirmation
    AUCTION_IN_PROGRESS --> AUCTION_IN_PROGRESS: receive_fill_action
    AUCTION_IN_PROGRESS --> COMPLETING_ORDER: auction_finished
    AUCTION_IN_PROGRESS --> END_STATE: order_completely_filled

    COMPLETING_ORDER --> COMPLETING_ORDER: receive_fill_action
    COMPLETING_ORDER --> END_STATE: order_completely_filled

    %% MATCH_ALL --> CANCELLED: action_cancelled
    %% MATCH_ALL --> EXPIRED: action_expired

    WARNING_LOST_SYNC_NO_ORDER_RECORD --> WARNING_LOST_SYNC_NO_ORDER_RECORD: receive_confirmation
    WARNING_LOST_SYNC_NO_ORDER_RECORD --> AUCTION_IN_PROGRESS: receive_order_record
    WARNING_LOST_SYNC_NO_ORDER_RECORD --> AUCTION_IN_PROGRESS: receive_fill_action
    WARNING_LOST_SYNC_NO_ORDER_RECORD --> COMPLETING_ORDER: auction_finished
    WARNING_LOST_SYNC_NO_ORDER_RECORD --> END_STATE: order_completely_filled

    class PLACING_ORDER, AWAITING_AUCTION, AUCTION_IN_PROGRESS, COMPLETING_ORDER golden_path
    class WARNING_LOST_SYNC_NO_ORDER_RECORD warning
 */

export type MarketOrderStateKey =
	| 'PLACING_ORDER_WAITING_SIGNATURE'
	| 'PLACING_ORDER_WAITING_MESSAGE_SIGNATURE'
	| 'PLACING_ORDER_WAITING_CONFIRMATION'
	| 'AWAITING_AUCTION'
	| 'SIGNED_MESSAGE_ORDER_PLACED'
	| 'AUCTION_IN_PROGRESS'
	| 'COMPLETING_ORDER'
	| 'WARNING_LOST_SYNC_NO_ORDER_RECORD'
	| 'BAIL_LOST_SYNC'
	| 'BAIL_TX_ERROR'
	| 'CANCELLED'
	| 'EXPIRED'
	| 'END_STATE';

type MarketOrderState = {
	key: MarketOrderStateKey;
	class: 'good' | 'warning' | 'bad';
};

// A record to hold all possible auction states, all keys are of type AuctionStateKey and completion should be enforced by typescript
const _MARKET_ORDER_STATES: Record<MarketOrderStateKey, MarketOrderState> = {
	PLACING_ORDER_WAITING_SIGNATURE: {
		key: 'PLACING_ORDER_WAITING_SIGNATURE',
		class: 'good',
	},
	PLACING_ORDER_WAITING_MESSAGE_SIGNATURE: {
		key: 'PLACING_ORDER_WAITING_MESSAGE_SIGNATURE',
		class: 'good',
	},
	PLACING_ORDER_WAITING_CONFIRMATION: {
		key: 'PLACING_ORDER_WAITING_CONFIRMATION',
		class: 'good',
	},
	AWAITING_AUCTION: {
		key: 'AWAITING_AUCTION',
		class: 'good',
	},
	SIGNED_MESSAGE_ORDER_PLACED: {
		key: 'SIGNED_MESSAGE_ORDER_PLACED',
		class: 'good',
	},
	AUCTION_IN_PROGRESS: {
		key: 'AUCTION_IN_PROGRESS',
		class: 'good',
	},
	COMPLETING_ORDER: {
		key: 'COMPLETING_ORDER',
		class: 'good',
	},
	WARNING_LOST_SYNC_NO_ORDER_RECORD: {
		key: 'WARNING_LOST_SYNC_NO_ORDER_RECORD',
		class: 'warning',
	},
	BAIL_LOST_SYNC: {
		key: 'BAIL_LOST_SYNC',
		class: 'bad',
	},
	BAIL_TX_ERROR: {
		key: 'BAIL_TX_ERROR',
		class: 'bad',
	},
	CANCELLED: {
		key: 'CANCELLED',
		class: 'bad',
	},
	EXPIRED: {
		key: 'EXPIRED',
		class: 'bad',
	},
	END_STATE: {
		key: 'END_STATE',
		class: 'good',
	},
};

export class MarketOrderToastStateMachine {
	public currentState: MarketOrderStateKey;

	constructor(initialState: MarketOrderStateKey) {
		this.currentState = initialState;
	}

	private handleBadTransition(event: MarketOrderToastStateTransitionEvent) {
		posthog.capture(`toast_state_machine_bad_transition`, {
			currentState: this.currentState,
			eventType: event.type,
			eventData: event.data,
		});

		throw new Error(
			`Unhandled AuctionStateTransition event:: currentKey:${this.currentState}, event:${event.type}`
		);
	}

	/**
	 * Transition to a new state based on the event
	 * @param eventType
	 */
	public transition(event: MarketOrderToastStateTransitionEvent) {
		const eventType = event.type;

		dlog('v2_auctions', `state:${this.currentState} :: event:${eventType}`);

		if (eventType === 'order_state_read') {
			/**
			 * order_state_read is a special event which doesn't really match the standard "state_machine paradigm" ... these events come when we detect updates to the order state, as opposed to an update from a blockchain event .. instead of being able to handle the event in a regular state machine way, we morso want to _deduce_ the event based on the incoming order state
			 *
			 * By receiving order_state, the order transactions has implicitly confirmed and the auction is active and we know all necessary information to render the toast
			 *
			 * If the order is completely filled, we know we can transition to the END_STATE
			 *
			 * If the auction has ended but the order is not completely filled, we know we can transition to the COMPLETING_ORDER state
			 *
			 * otherwise, we know we can transition to the AUCTION_IN_PROGRESS state
			 */

			// Check order status first
			if (
				event.data.status &&
				ENUM_UTILS.match(event.data.status, OrderStatus.FILLED)
			) {
				this.currentState = 'END_STATE';
				return;
			}
			if (
				event.data.status &&
				ENUM_UTILS.match(event.data.status, OrderStatus.CANCELED)
			) {
				if (this.currentState === 'EXPIRED') {
					return; // If we receive order status CANCELLED on an order we already know is expired, we can skip the transition
				}
				this.currentState = 'CANCELLED';
				return;
			}

			// Existing logic as fallback
			const orderIsCompletelyFilled = event.data.cumulativeBaseAmountFilled.eq(
				event.data.baseAmountOrdered
			);
			if (orderIsCompletelyFilled) {
				this.currentState = 'END_STATE';
				return;
			}

			const auctionHasFinished = event.data.auctionEnded;
			if (auctionHasFinished) {
				this.currentState = 'COMPLETING_ORDER';
				return;
			}

			this.currentState = 'AUCTION_IN_PROGRESS';
			return;
		}

		// Handle __MATCH_ALL__ cases :: we can skip some of them if we're already in the end state
		switch (eventType) {
			case 'action_cancelled':
				if (this.currentState === 'END_STATE') {
					return;
				}
				this.currentState = 'CANCELLED';
				return;
			case 'action_expired':
				if (this.currentState === 'END_STATE') {
					return;
				}
				this.currentState = 'EXPIRED';
				return;
		}

		switch (this.currentState) {
			case 'PLACING_ORDER_WAITING_SIGNATURE': {
				switch (eventType) {
					case 'tx_signed':
						this.currentState = 'PLACING_ORDER_WAITING_CONFIRMATION';
						break;
					case 'tx_failed':
						this.currentState = 'BAIL_TX_ERROR';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'PLACING_ORDER_WAITING_MESSAGE_SIGNATURE': {
				switch (eventType) {
					case 'tx_signed':
						this.currentState = 'SIGNED_MESSAGE_ORDER_PLACED';
						break;
					case 'signed_msg_order_message_slot_expired':
						this.currentState = 'EXPIRED';
						break;
					case 'tx_failed':
						this.currentState = 'BAIL_TX_ERROR';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'PLACING_ORDER_WAITING_CONFIRMATION': {
				switch (eventType) {
					case 'receive_confirmation':
						this.currentState = 'AWAITING_AUCTION';
						break;
					case 'receive_confirmation_signed_msg_order':
						this.currentState = 'AWAITING_AUCTION';
						break;
					case 'receive_fill_action':
						this.currentState = 'AWAITING_AUCTION';
						break;
					case 'receive_order_record':
						this.currentState = 'AUCTION_IN_PROGRESS';
						break;
					case 'tx_failed':
						this.currentState = 'BAIL_TX_ERROR';
						break;
					case 'received_confirmation_and_auction_disabled':
						this.currentState = 'COMPLETING_ORDER';
						break;
					case 'order_completely_filled':
						this.currentState = 'END_STATE';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'SIGNED_MESSAGE_ORDER_PLACED': {
				switch (eventType) {
					case 'receive_order_record':
						break;
					case 'receive_confirmation_signed_msg_order':
						this.currentState = 'AWAITING_AUCTION';
						break;
					case 'receive_fill_action':
						this.currentState = 'AWAITING_AUCTION';
						break;
					case 'received_confirmation_and_auction_disabled':
						this.currentState = 'COMPLETING_ORDER';
						break;
					case 'order_completely_filled':
						this.currentState = 'END_STATE';
						break;
					case 'tx_failed':
						this.currentState = 'BAIL_TX_ERROR';
						break;
					case 'signed_msg_order_expired':
						this.currentState = 'EXPIRED';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'AWAITING_AUCTION': {
				switch (eventType) {
					case 'receive_confirmation':
						this.currentState = 'AWAITING_AUCTION';
						break;
					case 'receive_fill_action':
						this.currentState = 'AWAITING_AUCTION';
						break;
					case 'order_completely_filled':
						this.currentState = 'END_STATE';
						break;
					case 'receive_order_record':
						this.currentState = 'AUCTION_IN_PROGRESS';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'AUCTION_IN_PROGRESS': {
				switch (eventType) {
					case 'received_confirmation_and_auction_disabled':
						throw new Error(
							`Received received_confirmation_and_auction_disabled event in AUCTION_IN_PROGRESS state`
						);
					case 'receive_order_record':
						// do nothing
						break;
					case 'receive_confirmation':
						// do nothing
						break;
					case 'receive_fill_action':
						this.currentState = 'AUCTION_IN_PROGRESS';
						break;
					case 'auction_finished':
						this.currentState = 'COMPLETING_ORDER';
						break;
					case 'order_completely_filled':
						this.currentState = 'END_STATE';
						break;
					case 'receive_confirmation_signed_msg_order':
						this.currentState = 'AUCTION_IN_PROGRESS';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'COMPLETING_ORDER': {
				switch (eventType) {
					case 'received_confirmation_and_auction_disabled':
						break; // Do nothing :: we proactively send these events with any event that confirms the order has been confirmed, multiple of them may trigger and they may come at any time - so it isn't a problem to receive this even when the order is completing
					case 'receive_fill_action':
						this.currentState = 'COMPLETING_ORDER';
						break;
					case 'order_completely_filled':
						this.currentState = 'END_STATE';
						break;
					case 'tx_failed':
						this.currentState = 'BAIL_TX_ERROR';
						break;
					case 'signed_msg_order_expired':
						this.currentState = 'EXPIRED';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'WARNING_LOST_SYNC_NO_ORDER_RECORD': {
				switch (eventType) {
					case 'received_confirmation_and_auction_disabled':
						this.currentState = 'COMPLETING_ORDER';
						break;
					case 'receive_confirmation':
						break;
					case 'receive_order_record':
						this.currentState = 'AUCTION_IN_PROGRESS';
						break;
					case 'auction_finished':
						this.currentState = 'COMPLETING_ORDER';
						break;
					case 'order_completely_filled':
						this.currentState = 'END_STATE';
						break;
					case 'receive_fill_action':
						this.currentState = 'AUCTION_IN_PROGRESS';
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'BAIL_TX_ERROR': {
				switch (eventType) {
					case 'tx_failed':
						// We can get multiple tx_failed events because we have multiple sources of truth, who could all catch and emit the failure : so ignore these
						break;
					case 'auction_finished':
						// do nothing
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'BAIL_LOST_SYNC': {
				switch (eventType) {
					case 'auction_finished':
						// do nothing
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'EXPIRED': {
				switch (eventType) {
					case 'auction_finished':
						// do nothing
						break;
					case 'signed_msg_order_expired':
						// do nothing
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'CANCELLED': {
				switch (eventType) {
					case 'auction_finished':
						// do nothing
						break;
					default: {
						this.handleBadTransition(event);
					}
				}
				break;
			}
			case 'END_STATE': {
				switch (eventType) {
					case 'received_confirmation_and_auction_disabled':
						// do nothing
						break;
					case 'order_completely_filled':
						// do nothing
						break;
					case 'auction_finished':
						// do nothing
						break;
					case 'receive_fill_action':
						// do nothing
						break;
					case 'receive_confirmation_signed_msg_order':
						// do nothing
						break;
					default:
						// Shouldn't get any more events when we've already hit the end state
						this.handleBadTransition(event);
				}
				break;
			}
			default: {
				const _event: never = this.currentState;
				this.handleBadTransition(event);
			}
		}
	}
}
