'use client';

import HistoryDrawer from './HistoryDrawer';
import Text from 'src/components/Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	Serializer,
	UIMarket,
	UISerializableOrderActionRecord,
	UISerializableOrderActionRecordV2,
} from '@drift/common';
import { twMerge } from 'tailwind-merge';
import { OrderActionExplanation } from '@drift-labs/sdk';
import Drawer from 'src/components/Drawer';
import { useEffect, useState } from 'react';
import { DrawerName, useDrawersStore } from 'src/stores/useDrawersStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { ParentOrderInfo } from 'src/components/OrderHistoryTable/ParentOrderRow';
import DataApiClient from 'src/utils/DataApiClient';
import { useTrackNextDataPageToken } from 'src/hooks/historyData/useTrackNextPageToken';
import { OrderBreakdownContent } from './OrderBreakdownContent';

const orderActionRecordsSortFn = (
	recordB: UISerializableOrderActionRecord | UISerializableOrderActionRecordV2,
	recordA: UISerializableOrderActionRecord | UISerializableOrderActionRecordV2
) => {
	if (
		ENUM_UTILS.match(recordA.actionExplanation, OrderActionExplanation.NONE)
	) {
		return -1;
	} else if (recordB.actionExplanation === OrderActionExplanation.NONE) {
		return 1;
	}

	if (!recordA.fillRecordId || !recordB.fillRecordId) {
		return recordA.ts.toNumber() - recordB.ts.toNumber();
	}

	return recordA.fillRecordId.toNumber() - recordB.fillRecordId.toNumber();
};

const OrderDetailsRow = ({
	details,
}: {
	details: { label: string; value: string }[];
}) => {
	return (
		<div className="flex flex-row items-center divide-x divide-container-border">
			{details.map((detail) => (
				<Drawer.DetailBox
					key={detail.label}
					{...detail}
					className="min-w-[110px] pl-4 first:pl-0 "
				/>
			))}
		</div>
	);
};

const OrderSummary = ({ orderInfo }: { orderInfo: ParentOrderInfo }) => {
	const uiMarket = new UIMarket(orderInfo.marketIndex, orderInfo.marketType);

	const orderStatus = orderInfo.lastActionStatus;
	const direction = UI_UTILS.getDirectionString(
		orderInfo.direction,
		uiMarket.isPerp
	);

	const orderType = COMMON_UI_UTILS.getOrderLabelFromOrderDetails(orderInfo);
	const flags = UI_UTILS.getFlagsFromOrder(orderInfo);
	const triggerPrice = UI_UTILS.getTriggerPriceFromOrder(orderInfo);
	const limitPrice = UI_UTILS.getLimitPriceFromOrder(orderInfo);
	const averagePrice = COMMON_UI_UTILS.calculateAverageEntryPrice(
		orderInfo.quoteAssetAmountFilled,
		orderInfo.baseAssetAmountFilled
	).toNotional(true);

	const orderDetailsRowOne = [
		{
			label: 'Type',
			value: orderType,
		},
		{
			label: 'Average Price',
			value: averagePrice,
		},
	];

	const orderDetailsRowTwo = [
		{
			label: 'Trigger',
			value: triggerPrice,
		},
		{
			label: 'Limit',
			value: limitPrice,
		},
		{
			label: 'Flags',
			value: flags,
		},
	];

	return (
		<div className="flex flex-col gap-4">
			{/** Market details */}
			<div className="flex gap-2">
				<Drawer.MarketPosition
					marketSymbol={uiMarket.market.symbol}
					direction={direction}
				/>
				<div>
					<Text.BODY3
						className={twMerge(
							'uppercase bg-button-secondary-bg-hover p-[2px] rounded-sm relative -top-[1px]',
							orderStatus === 'Filled' && 'text-positive-green',
							orderStatus === 'Cancelled' && 'text-negative-red'
						)}
					>
						{orderStatus}
					</Text.BODY3>
				</div>
			</div>

			{/** Order details */}
			<div className="flex flex-col gap-2">
				<OrderDetailsRow details={orderDetailsRowOne} />
				<OrderDetailsRow details={orderDetailsRowTwo} />
			</div>
		</div>
	);
};

// we don't use the generic `useShortTermDataApi` hook because we don't need to cache the data in the store + no filters needed
const useShortTermOrderActionHistory = () => {
	const currentAccountPubKey = useDriftAccountStore(
		(s) => s.getCurrentUserAccount().pubKey
	);
	const [orderActionRecords, setOrderActionRecords] = useState<
		UISerializableOrderActionRecordV2[]
	>([]);
	const [currentPage, setCurrentPage] = useState(0);
	const nextDataPageTokenTracker = useTrackNextDataPageToken();

	const order = useDrawersStore((s) => s.orders.order);

	// fetch initial data
	useEffect(() => {
		if (currentAccountPubKey) {
			DataApiClient.fetchShortTermData('orderActions', undefined, {
				userAccount: currentAccountPubKey.toString(),
				orderId: order.orderId,
			}).then((res) => {
				const deserializedRecords = res.records.map((r) =>
					// @ts-expect-error
					Serializer.Deserialize.UIOrderActionRecordV2(r)
				);
				setOrderActionRecords(
					deserializedRecords.sort(orderActionRecordsSortFn)
				);

				res.meta.nextPage &&
					nextDataPageTokenTracker.addPageToken(1, res.meta.nextPage);
			});
		}
	}, [currentAccountPubKey, order.orderId]);

	const executePageChange = (newPage: number) => {
		setCurrentPage(newPage);
		const nextPageToken = nextDataPageTokenTracker.pageTokens.get(newPage);
		DataApiClient.fetchShortTermData('orderActions', nextPageToken, {
			userAccount: currentAccountPubKey.toString(),
			orderId: order.orderId,
		}).then((res) => {
			const deserializedRecords = res.records.map((r) =>
				// @ts-expect-error
				Serializer.Deserialize.UIOrderActionRecordV2(r)
			);
			setOrderActionRecords((prev) => [...prev, ...deserializedRecords]);

			res.meta.nextPage &&
				nextDataPageTokenTracker.addPageToken(newPage + 1, res.meta.nextPage);
		});
	};

	return {
		orderActionRecords,
		executePageChange,
		currentPage,
		hasNextPage: nextDataPageTokenTracker.pageTokens.has(currentPage + 1),
	};
};

const OrderDrawer = () => {
	const MODAL_STORE_KEY: DrawerName = 'orders';
	const currentAccountPubKey = useDriftAccountStore(
		(s) => s.getCurrentUserAccount().pubKey
	);
	const order = useDrawersStore((s) => s.orders.order);

	const handleCloseHistoryPanel = useDrawersStore((s) => s.handleCloseDrawer);

	const { orderActionRecords, executePageChange, currentPage, hasNextPage } =
		useShortTermOrderActionHistory();

	useEffect(() => {
		if (!currentAccountPubKey) {
			handleCloseHistoryPanel(MODAL_STORE_KEY);
		}
	}, [currentAccountPubKey]);

	const executeNextPage = () => {
		executePageChange(currentPage + 1);
	};

	if (!currentAccountPubKey) {
		return undefined;
	}

	return (
		<HistoryDrawer
			title={'Order'}
			summarySection={<OrderSummary orderInfo={order} />}
			contentSection={
				<OrderBreakdownContent
					orderActionRecords={orderActionRecords ?? []}
					currentAccountPubKey={currentAccountPubKey}
					executeNextPage={executeNextPage}
					hasNextPage={hasNextPage}
				/>
			}
			modalStoreKey={MODAL_STORE_KEY}
		/>
	);
};

export default OrderDrawer;
