import React, { PropsWithChildren, createContext } from 'react';
import { Subscription } from 'rxjs/internal/Subscription';
import { catchError } from 'rxjs/operators';
import WebsocketUtilClass from '../../utils/websocket';
import { dlog } from '../../dev';

/**
 * This is a provider which should abstract a lot of the logic for setting up websockets in an efficient way. Reslies on RXJS websockets under the hook, which you can read about here : https://rxjs.dev/api/webSocket/webSocket
 *
 * The key benefit to using RXJS in this provider is that RXJS lets us set up "virtual" websockets (using the multiplex feature, you can see used below), which prevents a lot of the resource overhead of setting up multiple websocket connections to the same server. We also get access to rxjs's built in error handling utils, and other cool stuff we could add down the line.
 */

type WebsocketSubjectContextState = {
	subscribe: (
		websocketUrl: string, // URL the webhooks connect to
		topicKey: string, // A key to uniquely identify an observable for each websocket
		subscribeProps: {
			onSubMessage: () => string; // Return the message to send when subscribing to a websocket
			onUnSubMessage: () => string; // Return the message to send when unsubscribing from a websocket
			messageFilter: (message: any) => boolean; // Filter messages for this particular subscription
			onMessageCallback: (message: any) => void; // Callback to handle the messages from the webhook subscription
			onErrorCallback: (message?: any) => void; // Handle an error
			errorMessageFilter: (message: any) => boolean; // Returns true if the message represents an error
			onClose?: (message?: any) => void; // Handle a closure .. falls back to the error handler if not provided
		}
	) => { subscription: Subscription; unsubscribe: () => void };
};

const getObservableKey = (websocketUrl: string, topicKey: string) =>
	`${websocketUrl}_${topicKey}`;

const DEFAULT_CONTEXT_VALUE = {
	subscribe: (
		websocketUrl: Parameters<WebsocketSubjectContextState['subscribe']>[0],
		topicKey: Parameters<WebsocketSubjectContextState['subscribe']>[1],
		subscribeProps: Parameters<WebsocketSubjectContextState['subscribe']>[2]
	) => {
		const observableKey = getObservableKey(websocketUrl, topicKey);

		try {
			const { unsubscribe: websocketUnsubscribe, subject } =
				WebsocketUtilClass.createWebsocketListener({
					wsUrl: websocketUrl,
					listenerId: observableKey,
					subscribeMessage: subscribeProps.onSubMessage(),
					unsubscribeMessage: subscribeProps.onUnSubMessage(),
					onError: subscribeProps.onErrorCallback,
				});

			const subscription = subject
				.pipe(
					catchError((err) => {
						console.error('Caught OUTER websocket error', err); // An outer websocket error implies that we failed to set up the connection in the first place
						subscribeProps.onErrorCallback();
						return [] as any[];
					})
				)
				.subscribe({
					next: (message) => {
						try {
							if (!subscribeProps.messageFilter(message)) {
								return;
							}

							if (subscribeProps.errorMessageFilter(message)) {
								subscribeProps.onErrorCallback(message);
							} else {
								subscribeProps.onMessageCallback(message);
							}
						} catch (err) {
							dlog(
								`websocket_debugging`,
								`Caught error handling websocket message`,
								{
									err,
									message,
								}
							);
							subscribeProps.onErrorCallback(err);
						}
					},
					error: (err) => {
						dlog(
							`websocket_debugging`,
							`CAUGHT INNER WEBSOCKET ERROR for ${observableKey}`,
							err
						); // An inner websocket error happens when the websocket connection is fine but the remote server has sent an error message
						subscribeProps.onErrorCallback(err);
					},
					complete: () => {
						dlog(
							`websocket_debugging`,
							`WEBSOCKET CLOSED for ${observableKey}`
						);
						if (subscribeProps.onClose) {
							subscribeProps.onClose();
						} else {
							subscribeProps.onErrorCallback();
						}
					},
				});

			const unsubscribe = () => {
				subscription.unsubscribe();
				websocketUnsubscribe();
			};

			return { subscription, unsubscribe };
		} catch (e) {
			subscribeProps.onErrorCallback(e);
		}
	},
};

export const WebsocketSubjectContext =
	createContext<WebsocketSubjectContextState>(DEFAULT_CONTEXT_VALUE);

const WebsocketSubscriptionProvider = (props: PropsWithChildren<any>) => {
	return (
		<WebsocketSubjectContext value={DEFAULT_CONTEXT_VALUE}>
			{props.children}
		</WebsocketSubjectContext>
	);
};

export default React.memo(WebsocketSubscriptionProvider);
