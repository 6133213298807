import useDriftStore from 'src/stores/DriftStore/useDriftStore';

import useCurrentUserAccount from '../useCurrentUserAccount';

import { JlpMigrationCalculator } from '../jlp/JlpMigrationCalculator';
import { useMemo, useEffect } from 'react';
import useBorrowLendDataForMarket from '../useBorrowLendDataForMarket';
import { JLP_POOL_JLP_MARKET_INDEX } from 'src/environmentVariables/EnvironmentVariables';
import { MarketId } from '@drift/common';
import { useSyncLocalStorage } from '@drift-labs/react';

const JLP_POOL_JLP_MARKET_ID = MarketId.createSpotMarket(
	JLP_POOL_JLP_MARKET_INDEX
);

export const useDisplayJlpMigrationPopupAlert = () => {
	const setDriftStore = useDriftStore((s) => s.set);
	const currentAccount = useCurrentUserAccount();

	const [hasInteractedWithJlpMigrationPopupAlert] = useSyncLocalStorage(
		'hasInteractedWithJlpMigrationPopupAlert',
		false
	);

	const jlpPoolJlpSpotMarketData = useBorrowLendDataForMarket(
		JLP_POOL_JLP_MARKET_ID
	);

	const jlpMigrationCalculator = useMemo(() => {
		if (!currentAccount) return null;

		return new JlpMigrationCalculator({
			marginInfo: currentAccount.marginInfo,
			client: currentAccount.client,
			spotBalances: currentAccount.spotBalances,
		});
	}, [currentAccount]);

	useEffect(() => {
		if (
			!jlpMigrationCalculator ||
			!jlpPoolJlpSpotMarketData ||
			hasInteractedWithJlpMigrationPopupAlert
		)
			return;

		const isMigrationAllowed = jlpMigrationCalculator.checkIsMigrationAllowed(
			jlpPoolJlpSpotMarketData.assetWeight
		);

		if (isMigrationAllowed) {
			setDriftStore((s) => {
				s.modals.showJlpMigrationPopupAlert = true;
			});
		}
	}, [
		jlpMigrationCalculator,
		jlpPoolJlpSpotMarketData,
		hasInteractedWithJlpMigrationPopupAlert,
	]);
};
