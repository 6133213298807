import { Suspense, useState } from 'react';
import useRefreshTokenAccountsOnMount from '../../../hooks/useRefreshTokenAccountsOnMount';
import UnderlinedTabs from 'src/components/TabbedNavigation/UnderlinedTabs';
import LSTSelectList from './LSTSelectList';
import NativeStakeAccountSelectList from './NativeStakeAccountsSelectList';

export type AssetListAssetProps = {
	mint: string;
	ticker: string;
	logo: string;
};

type AssetSelectTab = 'lsts' | 'stakeAccounts';

const ASSET_SELECT_TABS: Array<{
	label: string;
	value: AssetSelectTab;
}> = [
	{
		label: 'LSTs',
		value: 'lsts',
	},
	{
		label: 'Stake Accounts',
		value: 'stakeAccounts',
	},
];

interface AssetSelectModalContentProps {
	assetList: AssetListAssetProps[];
	source: 'wallet' | 'subaccount';
}

const AssetSelectModalContent = ({
	assetList,
	source,
}: AssetSelectModalContentProps) => {
	const [selectedTab, setSelectedTab] = useState<AssetSelectTab>(
		ASSET_SELECT_TABS[0].value
	);
	const renderTab = () => {
		switch (selectedTab) {
			case 'lsts':
				return <LSTSelectList assetList={assetList} source={source} />;
			case 'stakeAccounts':
				return (
					<Suspense fallback={<div>Loading...</div>}>
						<NativeStakeAccountSelectList source={source} />
					</Suspense>
				);
		}
	};

	useRefreshTokenAccountsOnMount(source === 'wallet');

	return (
		<div className="flex flex-col max-h-[70vh] sm:max-h-[50vh]">
			<UnderlinedTabs
				options={ASSET_SELECT_TABS}
				currentSelection={selectedTab}
				onChange={(newSelection: string) => {
					setSelectedTab(newSelection as AssetSelectTab);
				}}
				tabClassName={
					'grow text-center text-[14px] leading-[16px] items-center justify-center'
				}
				gradient
			/>
			<div className="flex flex-col gap-2 overflow-auto thin-scroll">
				{renderTab()}
			</div>
		</div>
	);
};

export default AssetSelectModalContent;
