import { PublicKey } from '@solana/web3.js';
import { useState } from 'react';
import ImageWithFallback from 'src/components/ImageWithFallback';
import { SearchBar } from 'src/components/MarketSelectorDropdown/MarketsSearchBar';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Text from 'src/components/Text/Text';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from 'src/utils/uiUtils';
import useSPLTokenBalance from '../../../hooks/useSPLTokenBalance';
import useDriftAccountStore from '../../../stores/useDriftAccountsStore';
import useDriftStore from '../../../stores/DriftStore/useDriftStore';
import { WRAPPED_SOL_MINT } from '@drift-labs/sdk';
import useAppEventEmitter from 'src/hooks/useAppEventEmitter';
import { AssetListAsset } from 'src/@types/assets';
const emptyIcon = <SkeletonValuePlaceholder className="w-8 h-8 rounded-full" />;

interface LSTSelectListProps {
	assetList: AssetListAsset[];
	onSelect?: (selectedAsset: AssetListAsset) => void;
	source: 'wallet' | 'subaccount';
}

type AssetRenderingProps = AssetListAsset & {
	uiAmount: number;
};

const useWalletAssets = (
	assetList: AssetListAsset[]
): AssetRenderingProps[] => {
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const assetBalances = assetList.map((asset) => {
		if (asset.mint === WRAPPED_SOL_MINT.toBase58()) {
			return solBalance.toNum();
		}
		return useSPLTokenBalance(new PublicKey(asset.mint))[0];
	});

	return assetBalances.map((balance, index) => {
		return {
			...assetList[index],
			uiAmount: balance ?? 0,
		};
	});
};

const useSubaccountAssets = (
	assetList: AssetListAsset[]
): AssetRenderingProps[] => {
	const currentUerAccountState = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]
	);

	const assetsWithBalances = assetList.map((asset) => {
		const userBalances = currentUerAccountState.spotBalances;

		const balance = userBalances.find(
			(b) => b.asset.mint.toString() === asset.mint
		);

		if (balance) {
			return {
				...asset,
				uiAmount: balance.balance.toNum(),
			};
		}

		return {
			...asset,
			uiAmount: 0,
		};
	});

	return assetsWithBalances;
};

const LSTSelectList = ({ assetList, source, onSelect }: LSTSelectListProps) => {
	const [searchValue, setSearchValue] = useState('');
	const appEventEmitter = useAppEventEmitter();
	// Sort assets descending by balance but SOL always at the top
	//// NOTE: We don't have oracle prices for arbitrary assets so we can't sort by notional value or something similar (without lots of extra work)
	const assetsWithBalances = (
		source === 'wallet'
			? useWalletAssets(assetList)
			: useSubaccountAssets(assetList)
	)
		.sort((a, b) => {
			if (a.ticker === 'SOL') {
				return -1;
			}
			if (b.ticker === 'SOL') {
				return 1;
			}
			return b.uiAmount - a.uiAmount;
		})
		.filter((asset) =>
			searchValue
				? asset.ticker.toLowerCase().includes(searchValue.toLowerCase())
				: true
		);

	const handleSelectAsset = (selectedAsset: AssetListAsset) => {
		appEventEmitter.emit('assetSelected', selectedAsset.mint);
		onSelect?.(selectedAsset);
	};

	return (
		<div className="flex flex-col">
			<div className="mt-6 px-4">
				<SearchBar
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					placeholder="Search by token name"
				/>
			</div>
			<div className="flex flex-row items-center justify-between gap-0.5 px-4 py-4 border-b border-container-border">
				<Text.BODY3 className="text-text-label">Token</Text.BODY3>
				<Text.BODY3 className="text-text-label">
					In {source === 'wallet' ? 'Wallet' : 'Subaccount'}
				</Text.BODY3>
			</div>
			<div className="flex flex-col gap-2 overflow-auto thin-scroll">
				{assetsWithBalances.map((asset) => {
					return (
						<div
							key={asset.ticker}
							className="flex justify-between items-center p-3 px-4 hover:bg-container-bg-hover cursor-pointer rounded text-text-default border-b border-container-border last:border-0"
							onClick={() => handleSelectAsset(asset)}
						>
							<div className="flex items-center gap-3 w-full justify-between">
								<div className="flex items-center gap-2">
									<ImageWithFallback
										src={asset.logo}
										className="w-8 h-8 rounded-full"
										loading="lazy"
										fallbackComponent={emptyIcon}
									/>
									<div className="flex flex-col items-start gap-1">
										<Text.BODY1>{asset.ticker}</Text.BODY1>
										<Text.MICRO3 className="text-text-label">
											{UI_UTILS.abbreviateAddress(asset.mint)}
										</Text.MICRO3>
									</div>
								</div>
								<div className="flex flex-col items-end justify-center gap-2 h-6">
									<div className="flex flex-col items-end gap-0.5">
										{!!asset.uiAmount && (
											<>
												<Text.BODY3>
													{NumLib.millify(asset.uiAmount).displayString}{' '}
													{asset.ticker}
												</Text.BODY3>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default LSTSelectList;
