'use client';

import { PublicKey, User } from '@drift-labs/sdk';
import { useEffect, useRef, useState } from 'react';
import useAccountData from './useAccountData';
import useDriftClientIsReady from './useDriftClientIsReady';
import useWalletIsConnected from './useWalletIsConnected';
import UI_UTILS from 'src/utils/uiUtils';
import { AccountData } from 'src/stores/useDriftAccountsStore';

const MAX_RETRY_COUNT = 3;

const useHistoryFetcher = (
	fetcher: (usersToFetch: User[]) => Promise<boolean>,
	historyKey: string
) => {
	const connected = useWalletIsConnected();
	const currentAccount = useAccountData();
	const driftClientIsReady = useDriftClientIsReady();

	const alreadySuccessfullyFetchedForUser = useRef<PublicKey>(undefined);
	const fetchAttempts = useRef<Record<string, boolean>>({});

	const [retryCount, setRetryCount] = useState(0);

	// Reset fetch attempt history when the user switches accounts
	useEffect(() => {
		setRetryCount(0);
		fetchAttempts.current = {};
	}, [currentAccount?.pubKey]);

	// Fetch history
	useEffect(() => {
		if (!currentAccount) return;
		if (!currentAccount?.pubKey) return;
		if (!currentAccount?.client) return;

		// Reset already fetched state when not connected
		if (
			!driftClientIsReady ||
			!connected ||
			!currentAccount?.client?.isSubscribed
		) {
			alreadySuccessfullyFetchedForUser.current = undefined;
			return;
		}

		// Skip if we've already successfully fetched for the current user
		if (
			alreadySuccessfullyFetchedForUser?.current &&
			alreadySuccessfullyFetchedForUser?.current?.equals(currentAccount?.pubKey)
		)
			return;

		// Skip if initialHistoryLoaded already true
		// @ts-ignore
		if (currentAccount[historyKey as keyof AccountData]?.initialHistoryLoaded)
			return;

		// Skip fetching when at max. retry count
		if (retryCount > MAX_RETRY_COUNT) {
			console.log(`HIT MAX RETRY COUNT`);
			return;
		}

		// Sometimes this hook fires multiple times, fetchAttempKey protects against attempting the same fetch multiple times
		const fetchAttempKey = `${currentAccount?.pubKey?.toString()}_${retryCount}`;
		if (fetchAttempts.current[fetchAttempKey]) {
			return;
		}

		// Do the fetch
		(async () => {
			// Exponential backoff for fetch retries
			if (retryCount > 0) {
				await UI_UTILS.sleep(2000 * 2 ** retryCount);
			}

			fetchAttempts.current[fetchAttempKey] = true;

			const success = await fetcher([currentAccount?.client]);

			if (!success) {
				console.log('Fetch failed, incrementing retry count');
				setRetryCount(retryCount + 1);
				return;
			}
		})();
	}, [connected, driftClientIsReady, currentAccount?.client, retryCount]);
};

export default useHistoryFetcher;
