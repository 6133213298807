import {
	BigNum,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import { MarketId } from '@drift/common';
import { UNIQUE_SPOT_MARKETS } from 'src/environmentVariables/EnvironmentVariables';
import useCurrentWalletCollateralBalance from './useCurrentWalletCollateralBalance';
import useGetOraclePriceForMarket from './useGetOraclePriceForMarket';

export default function useTotalWalletQuoteBalance(): {
	totalQuoteBalance: BigNum;
	isLoading: boolean;
} {
	const balances = UNIQUE_SPOT_MARKETS.map((market) =>
		useCurrentWalletCollateralBalance(market)
	);

	const getOraclePrice = useGetOraclePriceForMarket();

	const oraclePrices = UNIQUE_SPOT_MARKETS.map(
		(bank) =>
			getOraclePrice(MarketId.createSpotMarket(bank.marketIndex)) ??
			BigNum.zero(PRICE_PRECISION_EXP)
	);

	const isLoading = balances.some((balance) => balance[1]);

	const totalWalletQuoteBalances = balances.reduce((sum, balance, i) => {
		const oraclePrice = oraclePrices[i];
		const quoteValue = balance[0]
			.shiftTo(PRICE_PRECISION_EXP)
			.mul(oraclePrice)
			.shiftTo(QUOTE_PRECISION_EXP);
		return sum.add(quoteValue);
	}, BigNum.zero(QUOTE_PRECISION_EXP));

	return {
		totalQuoteBalance: totalWalletQuoteBalances,
		isLoading,
	};
}
