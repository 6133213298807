import { LAMPORTS_PER_SOL, PublicKey } from '@solana/web3.js';
import { useState } from 'react';
import ImageWithFallback from 'src/components/ImageWithFallback';
import { SearchBar } from 'src/components/MarketSelectorDropdown/MarketsSearchBar';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import Text from 'src/components/Text/Text';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from 'src/utils/uiUtils';
import { useAuthorityNativeStakeAccounts } from 'src/hooks/staking/useAuthorityNativeStakeAccounts';
import { StakeAccountWithValidatorInfo } from 'src/@types/assets';
import useDriftActions from 'src/hooks/useDriftActions';
import { twMerge } from 'tailwind-merge';
interface NativeStakeAccountSelectListProps {
	onSelect?: (selectedAsset: StakeAccountWithValidatorInfo) => void;
	source: 'wallet' | 'subaccount';
}

const NativeStakeAccountSelectList = ({
	source,
}: NativeStakeAccountSelectListProps) => {
	const [searchValue, setSearchValue] = useState('');
	const actions = useDriftActions();

	const handleSelectStakeAccount = (
		stakeAccount: StakeAccountWithValidatorInfo
	) => {
		if (!stakeAccount.stakeAddress) return;
		actions.deactivateStakeAccount(new PublicKey(stakeAccount.stakeAddress));
	};

	const { data: stakeAccounts, isLoading } = useAuthorityNativeStakeAccounts();

	return (
		<div className="flex flex-col">
			<div className="mt-6 px-4">
				<SearchBar
					searchValue={searchValue}
					setSearchValue={setSearchValue}
					placeholder="Search by validator name to deactivate"
				/>
			</div>
			<div className="flex flex-row items-center justify-between gap-0.5 px-4 py-4 border-b border-container-border">
				<Text.BODY3 className="text-text-label">Validator</Text.BODY3>
				<Text.BODY3 className="text-text-label">
					In {source === 'wallet' ? 'Wallet' : 'Subaccount'}
				</Text.BODY3>
			</div>
			<div className="flex flex-col gap-2 overflow-auto thin-scroll">
				{isLoading ? (
					<div className="flex flex-col gap-2">
						<SkeletonValuePlaceholder
							className="w-full h-12 rounded-md"
							loading
						/>
					</div>
				) : (
					<>
						{stakeAccounts.map((sa) => {
							return (
								<div
									key={sa.stakeAddress}
									className={twMerge(
										'flex justify-between items-center p-3 px-4  rounded text-text-default border-b border-container-border last:border-0',
										sa.status === 'deactivated' || sa.status === 'deactivating'
											? 'opacity-50'
											: 'hover:bg-container-bg-hover cursor-pointer'
									)}
									onClick={
										sa.status === 'deactivated' || sa.status === 'deactivating'
											? undefined
											: () => handleSelectStakeAccount(sa)
									}
								>
									<div className="flex items-center gap-3 w-full justify-between">
										<div className="flex items-center gap-2">
											<ImageWithFallback
												src={sa.validatorLogo}
												className="w-8 h-8 rounded-full"
												loading="lazy"
												fallbackComponent={
													<SkeletonValuePlaceholder className="w-8 h-8 rounded-full" />
												}
											/>
											<div className="flex flex-col items-start gap-1">
												<Text.BODY1>{sa.validatorName}</Text.BODY1>
												<Text.MICRO3 className="text-text-label">
													{UI_UTILS.abbreviateAddress(sa.validatorVoteAccount)}
												</Text.MICRO3>
											</div>
										</div>
										<div className="flex flex-col items-end justify-center gap-2 h-6">
											<div className="flex flex-col items-end gap-0.5">
												{!!sa.lamports && (
													<>
														<Text.BODY3>
															{
																NumLib.millify(sa.lamports / LAMPORTS_PER_SOL)
																	.displayString
															}{' '}
															SOL
														</Text.BODY3>
													</>
												)}
												<div
													className={
														sa.status !== 'active'
															? 'text-text-tertiary'
															: 'text-text-interactive'
													}
												>
													{sa.status}
												</div>
											</div>
										</div>
									</div>
								</div>
							);
						})}
					</>
				)}
			</div>
		</div>
	);
};

export default NativeStakeAccountSelectList;
