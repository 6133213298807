'use client';

import { BigNum, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import useAccountsBeingLiquidated from 'src/hooks/Liquidations/useAccountsBeingLiquidated';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import React, { useEffect, useState } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import Button from './Button';
import { AmountSelector, CollateralInput } from './Inputs/CollateralInput';
import LabelledInput from './Inputs/LabelledInput';
import Select from './Inputs/Select';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import InfoMessage from './TradeForm/InfoMessage';
import ValueDisplay from './ValueDisplay';
import Text from './Text/Text';
import UI_UTILS from '../utils/uiUtils';
import useTargetAccountData from '../hooks/useTargetAccountData';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useMarketStateStore from '../stores/useMarketStateStore';
import { MAIN_POOL_ID, MarketId } from '@drift/common';
import SettleUnsettledInfoMessage from './Utils/SettleUnsettledInfoMessage';
import useCollateralModalState from '../hooks/useCollateralModalState';

const TransferCollateralModalContent = ({
	onClose,
}: {
	onClose: () => void;
}) => {
	const availableSubAccounts = useLazySubAccounts().filter(
		(account) => account.client.getUserAccount().poolId === MAIN_POOL_ID
	);

	const { captureEvent } = usePostHogCapture();

	const {
		updateCollateralIndex,
		handleTargetAccountChange,
		selectedCollateralMarket,
		selectedTargetAccountKey,
		selectedPoolId,
	} = useCollateralModalState();

	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const currentAccountPoolId = useDriftAccountStore(
		(s) => s.accounts[currentUserKey]?.client?.getUserAccount().poolId ?? 0
	);

	const [isMax, setIsMax] = useState(false);
	const [isMaxRepay, setIsMaxRepay] = useState(false);

	const actions = useDriftActions();

	const [donorAccountSelection, setDonorAccountSelection] = useState<string>(
		selectedTargetAccountKey ?? currentUserKey
	);

	const accountsBeingLiquidated = useAccountsBeingLiquidated();
	const selectedAccountCanbeLiqd = accountsBeingLiquidated.find(
		(account) => `${account.userId}` === `${donorAccountSelection}`
	);

	const [recipientAccountSelection, setRecipientAccountSelection] =
		useState<string>();

	useEffect(() => {
		if (donorAccountSelection === undefined) {
			setDonorAccountSelection(currentUserKey);
		}
		if (recipientAccountSelection === undefined) {
			setRecipientAccountSelection(
				availableSubAccounts.find(
					(account) => account.userKey !== currentUserKey
				)?.userKey
			);
		}
	}, [
		availableSubAccounts,
		currentUserKey,
		donorAccountSelection,
		recipientAccountSelection,
	]);

	const noSecondaryAccount = availableSubAccounts.length <= 1;

	const sameDonorAndRecipient =
		recipientAccountSelection === donorAccountSelection;

	const showNewAccountModal = () => {
		actions.hideAllModals();
		actions.showModal('showNewSubaccountModal');
	};

	const [submitting, setSubmitting] = useState(false);

	const markPrice = useMarketStateStore((s) =>
		s.getMarkPriceForMarket(
			MarketId.createSpotMarket(selectedCollateralMarket.marketIndex)
		)
	);

	const [collatTransferAmount, setCollatTransferAmount] = useState('');

	const donorAccount = useTargetAccountData(donorAccountSelection);

	// should transfer allow borrow? saying no as of now
	const donorBalances = useAccountTargetSpotBalance(
		selectedCollateralMarket,
		donorAccountSelection,
		true
	);
	const donorAccountTotalNetQuoteValue = BigNum.from(
		donorAccount?.client?.getNetSpotMarketValue() ?? 0,
		QUOTE_PRECISION_EXP
	);

	const recipientAccount = useTargetAccountData(recipientAccountSelection);
	const recipientBalances = useAccountTargetSpotBalance(
		selectedCollateralMarket,
		recipientAccountSelection,
		true
	);
	const recipientAccountTotalNetQuoteValue = BigNum.from(
		recipientAccount?.client?.getNetSpotMarketValue() ?? 0,
		QUOTE_PRECISION_EXP
	);

	const collatAmountBignum = BigNum.fromPrint(
		collatTransferAmount,
		selectedCollateralMarket.precisionExp
	);

	const assetCurrentPrice =
		markPrice?.shiftTo(QUOTE_PRECISION_EXP) ?? BigNum.zero(QUOTE_PRECISION_EXP);

	const quoteAmountToAdd =
		selectedCollateralMarket.symbol == 'USDC'
			? collatAmountBignum.shiftTo(QUOTE_PRECISION_EXP)
			: collatAmountBignum
					.shiftTo(QUOTE_PRECISION_EXP)
					.mul(assetCurrentPrice)
					.shiftTo(QUOTE_PRECISION_EXP);

	const handleConfirm = async () => {
		setSubmitting(true);
		actions.addLoadingItemToQueue({ key: 'modal' });

		captureEvent('submitted_transfer');

		const result = await actions.transferCollateral(
			collatAmountBignum,
			selectedCollateralMarket,
			donorAccountSelection,
			recipientAccountSelection
		);

		setSubmitting(false);
		actions.removeLoadingItemFromQueue({ key: 'modal' });

		if (result === true) {
			onClose();
		}
	};

	const exceededMax = donorBalances.withdrawLimitBase.lt(collatAmountBignum);

	const allowConfirmation =
		!submitting &&
		!sameDonorAndRecipient &&
		!!collatTransferAmount &&
		collatAmountBignum.gt(BigNum.zero()) &&
		!selectedAccountCanbeLiqd;

	const handleValueChange = (newValue: string, maxRepay = false) => {
		setIsMax(false);
		setCollatTransferAmount(newValue);
		setIsMaxRepay(maxRepay);
	};

	const handleMax = () => {
		setCollatTransferAmount(donorBalances.withdrawLimitBase.print());
		setIsMax(true);
	};

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setCollatTransferAmount('');
	}, [selectedCollateralMarket?.symbol]);

	useEffect(() => {
		if (exceededMax && isMax) {
			setCollatTransferAmount(donorBalances.withdrawLimitBase.print());
		}
	}, [exceededMax, isMax]);

	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					{recipientBalances?.netBaseBalance.isNeg() && (
						<>
							<Text.P1 className="text-text-emphasis">
								If you have open borrows, transfers of the same asset type will
								automatically repay the borrowed amount.
							</Text.P1>
							<Utility.VERTSPACERXL />
						</>
					)}

					{availableSubAccounts.length === 0 && (
						<>
							<InfoMessage
								type="warn"
								messageTitle="No Drift accounts"
								message="Create an account by depositing to enable transfers"
							/>
							<Utility.VERTSPACERM />
						</>
					)}

					{(selectedPoolId !== MAIN_POOL_ID ||
						currentAccountPoolId !== MAIN_POOL_ID) && (
						<>
							<InfoMessage
								type="warn"
								message="Transfers are not available to or from isolated pool subaccounts."
							/>
							<Utility.VERTSPACERM />
						</>
					)}

					<LabelledInput label="Transfer from">
						<Select.Subaccount
							id="donorAccountSelection"
							onChange={(val) => handleTargetAccountChange(val)}
							options={availableSubAccounts}
							initialSelection={
								selectedTargetAccountKey?.toString() ?? undefined
							}
						/>
					</LabelledInput>

					<Utility.VERTSPACERXL />
					{noSecondaryAccount ? (
						<>
							<Utility.FORMLABEL01 label="Transfer to" />
							<Utility.VERTSPACERXS />
							<InfoMessage
								type="info"
								messageTitle="No secondary accounts"
								message={`Add an account to transfer assets to it.`}
							/>

							<Utility.VERTSPACERM />

							<Button.Primary size="MEDIUM" onClick={showNewAccountModal}>
								Add Account
							</Button.Primary>
						</>
					) : (
						<>
							<LabelledInput label="Transfer To">
								<Select.Subaccount
									id="RecipientAccountSelection"
									onChange={(val) => setRecipientAccountSelection(val)}
									options={availableSubAccounts.filter(
										(account) => account.userKey !== donorAccountSelection
									)}
								/>
							</LabelledInput>
							<Utility.VERTSPACERXL />

							<div className="flex flex-col">
								<CollateralInput
									selectedMarket={selectedCollateralMarket}
									maxAmount={donorBalances.withdrawLimitBase}
									onChangeMarket={(val) =>
										updateCollateralIndex(val.marketIndex)
									}
									label="Asset and amount"
									value={
										isMax
											? donorBalances.withdrawLimitBase.print()
											: collatTransferAmount
									}
									onChangeValue={handleValueChange}
									onMax={handleMax}
									amountLabel="Available to transfer"
									source={{
										type: 'userAccount',
										userKey: donorAccountSelection,
									}}
									disabled={selectedPoolId !== MAIN_POOL_ID}
								/>

								{recipientBalances?.netBaseBalance.isNeg() && (
									<div>
										<AmountSelector
											symbol={selectedCollateralMarket.symbol}
											maxAmount={recipientBalances.netBaseBalance.abs()}
											label="Transfer type and Amount"
											onChangeValue={handleValueChange}
											amountLabel="Borrows"
											isNegative
											onMax={() =>
												handleValueChange(
													recipientBalances.netBaseBalance
														.abs()
														.toTradePrecision(),
													true
												)
											}
											disabled={selectedPoolId !== MAIN_POOL_ID}
										/>
									</div>
								)}
							</div>
						</>
					)}

					{selectedAccountCanbeLiqd && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="error"
								messageTitle="You can't transfer from the selected account"
								message={
									<>
										Once the liquidation is complete, transferring will be
										enabled again. Deposits to your Default Account are still
										available.
									</>
								}
							/>
						</>
					)}

					<SettleUnsettledInfoMessage className={'mt-2'} onlyShowIfPositive />

					<Utility.VERTSPACERXL />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERL />
					<Utility.FORMLABEL01
						label={
							availableSubAccounts.find(
								(option) => option.userKey === donorAccountSelection
							)?.name
						}
					/>
					<Utility.VERTSPACERXS />
					<ValueDisplay.ValueChange
						previousValue={donorBalances.netBaseBalance}
						afterValue={donorBalances.netBaseBalance.sub(collatAmountBignum)}
						previousValuePrint={`${donorBalances.netBaseBalance.prettyPrint()} ${
							selectedCollateralMarket.symbol
						}`}
						afterValuePrint={`
						${donorBalances.netBaseBalance.sub(collatAmountBignum).prettyPrint()} ${
							selectedCollateralMarket.symbol
						}`}
						label="Asset balance"
					/>
					<Utility.VERTSPACERXS />
					<ValueDisplay.ValueChange
						previousValue={donorAccountTotalNetQuoteValue}
						afterValue={donorAccountTotalNetQuoteValue.sub(quoteAmountToAdd)}
						previousValuePrint={UI_UTILS.toNotional(
							donorAccountTotalNetQuoteValue.toNum()
						)}
						afterValuePrint={UI_UTILS.toNotional(
							donorAccountTotalNetQuoteValue.sub(quoteAmountToAdd).toNum()
						)}
						label="Net Account Balance (USD)"
					/>

					<Utility.VERTSPACERM />

					<Utility.FORMLABEL01
						label={
							availableSubAccounts.find(
								(option) => option.userKey === recipientAccountSelection
							)?.name
						}
					/>
					<Utility.VERTSPACERXS />
					<ValueDisplay.ValueChange
						previousValue={recipientBalances.netBaseBalance}
						afterValue={
							isMaxRepay
								? BigNum.zero(selectedCollateralMarket.precisionExp)
								: recipientBalances.netBaseBalance.add(collatAmountBignum)
						}
						previousValuePrint={`${recipientBalances.netBaseBalance.prettyPrint()} ${
							selectedCollateralMarket.symbol
						}`}
						afterValuePrint={`${
							isMaxRepay
								? '0'
								: recipientBalances.netBaseBalance
										.add(collatAmountBignum)
										.prettyPrint()
						} ${selectedCollateralMarket.symbol}`}
						label="Asset balance"
					/>
					<Utility.VERTSPACERXS />
					<ValueDisplay.ValueChange
						previousValue={recipientAccountTotalNetQuoteValue}
						afterValue={recipientAccountTotalNetQuoteValue.add(
							quoteAmountToAdd
						)}
						previousValuePrint={UI_UTILS.toNotional(
							recipientAccountTotalNetQuoteValue.toNum()
						)}
						afterValuePrint={UI_UTILS.toNotional(
							recipientAccountTotalNetQuoteValue.add(quoteAmountToAdd).toNum()
						)}
						label="Net Account Balance (USD)"
					/>

					<Utility.VERTSPACERL />
					<Modal.Confirm
						primaryDisabled={!allowConfirmation}
						onConfirm={handleConfirm}
					/>
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(TransferCollateralModalContent);
