'use client';

import { dlog } from '../dev';
import useNotificationStore from '../stores/useNotificationStore';
import useInterval from './useInterval';

const useClearStaleNotifications = () => {
	const removeNotificationByIndex = useNotificationStore(
		(s) => s.removeNotificationByIndex
	);
	const getState = useNotificationStore((s) => s.get);

	useInterval(() => {
		if (!getState().notifications || getState().notifications.length === 0)
			return;

		const indexesToRemove: number[] = [];

		getState().notifications.forEach((notification, index) => {
			if (
				notification.type === 'v2MarketOrder' ||
				notification.type === 'auction' ||
				notification.type === 'signedMsgOrder'
			) {
				// Above notification types have their own logic for removal
				return false;
			}

			let shouldRemove = true;

			if (
				notification.startTimeMs + notification.lengthMs > Date.now() ||
				notification.isHovered ||
				notification.showUntilCancelled
			) {
				shouldRemove = false;
			}

			if (shouldRemove) {
				indexesToRemove.push(index);
			}
		});

		if (indexesToRemove.length > 0) {
			dlog(
				`v2_auctions`,
				`Removing stale notification indexes:`,
				indexesToRemove
			);
		}

		for (const index of indexesToRemove) {
			removeNotificationByIndex(index);
		}
	}, 1000);
};

export default useClearStaleNotifications;
