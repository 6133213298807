'use client';

import { OrderSubscriber } from '@drift-labs/sdk';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { DEFAULT_COMMITMENT_LEVEL } from 'src/constants/constants';

const INITIAL_CONTEXT_VALUE = { orderSubscriber: undefined } as {
	orderSubscriber?: OrderSubscriber;
};

export const OrderSubscriberContext = React.createContext<{
	orderSubscriber?: OrderSubscriber;
}>({ orderSubscriber: undefined });

const OrderSubscriberProvider = (props: PropsWithChildren<any>) => {
	const connection = useDriftStore((s) => s.connection.current);
	const driftClient = useDriftStore((s) => s.driftClient.client);

	const [contextValue, setContextValue] = useState(INITIAL_CONTEXT_VALUE);

	useEffect(() => {
		if (!connection) return;
		if (!driftClient) return;

		const orderSubscriber = new OrderSubscriber({
			driftClient,
			subscriptionConfig: {
				type: 'websocket',
				skipInitialLoad: true,
				commitment: DEFAULT_COMMITMENT_LEVEL,
			},
		});

		setContextValue({
			orderSubscriber,
		});

		return () => {
			// Unsubscribe when we create a new subscriber
			orderSubscriber.usersAccounts?.clear();
			orderSubscriber.unsubscribe();
		};
	}, [connection, driftClient]);

	return (
		<OrderSubscriberContext value={contextValue}>
			{props.children}
		</OrderSubscriberContext>
	);
};

export default OrderSubscriberProvider;
