'use client';

import { Draft } from 'immer';
import posthog, { JsonType } from 'posthog-js';
import { useEffect, useMemo } from 'react';
import { dlog } from 'src/dev';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useFeatureFlagStore, {
	FeatureFlagKey,
	FeatureFlagStore,
} from 'src/stores/useFeatureFlagStore';

const FLAG_OVERRIDES: Partial<FeatureFlagStore['flags']> = {
	// PREFER_EVENTS_SERVER_EVENTS: {
	// 	_enabled: true,
	// },
};

/**
 * ⭐️⭐️⭐️⭐️ SEE readmes/feature_flags.md FOR MORE INFO ⭐️⭐️⭐️⭐️
 */

const RELOAD_REMOTE_FLAGS_EVERY_SESSION = true; // Whether to reload the feature flags for each session, otherwise it may use cached flags

/**
 * Default handler for feature flags that don't need custom handling
 */
const defaultFlagHandler = (
	stateDraft: Draft<FeatureFlagStore>,
	key: FeatureFlagKey,
	enabled: boolean,
	rawPayload?: unknown
) => {
	const hasObjectPayload =
		// @ts-expect-error
		!!rawPayload?.payload && typeof rawPayload?.payload === 'object';

	if (key in FLAG_OVERRIDES) {
		stateDraft.flags[key]._enabled = FLAG_OVERRIDES[key]._enabled;
		stateDraft.flags[key].rawPayload = FLAG_OVERRIDES[key].rawPayload;
	} else {
		stateDraft.flags[key]._enabled = enabled;
		stateDraft.flags[key].rawPayload = rawPayload;

		if (hasObjectPayload) {
			// @ts-expect-error :: Expect the person creating the flag to ensure the payload matches the type in the store
			stateDraft.flags[key].payload = rawPayload?.payload;
		}
	}
};

/**
 * Type for custom flag handlers that need special processing
 */
type CustomFlagHandler = (
	stateDraft: Draft<FeatureFlagStore>,
	enabled: boolean,
	rawPayload?: unknown
) => void;

const getIsEnabledFromPayload = (payload: JsonType) => {
	// Handle boolean values
	if (typeof payload === 'boolean') {
		return payload;
	}

	// Handle string values
	if (typeof payload === 'string') {
		return payload === 'true';
	}

	// Handle object with enabled field
	if (typeof payload === 'object' && payload !== null) {
		if ('enabled' in payload) {
			return Boolean(payload.enabled);
		}
	}

	return false;
};

const useSyncFeatureFlagStateInStore = () => {
	const setFeatureFlagStore = useFeatureFlagStore((s) => s.set);
	const getFeatureFlagStore = useFeatureFlagStore((s) => s.get);
	const appEventEmitter = useDriftStore((s) => s.appEventEmitter);
	const actions = useDriftActions();

	// Only define custom handlers for flags that need special processing
	const CUSTOM_FLAG_HANDLERS: Partial<
		Record<FeatureFlagKey, CustomFlagHandler>
	> = useMemo(
		() => ({
			SKIP_TRANSACTION_PREFLIGHTS: (
				stateDraft,
				enabled: boolean,
				rawPayload?: unknown
			) => {
				defaultFlagHandler(
					stateDraft,
					'SKIP_TRANSACTION_PREFLIGHTS',
					enabled,
					rawPayload
				);
				actions.handleUpdateTransactionPreflights(enabled);
			},
			HIGHLIGHTED_ASSETS: (
				stateDraft,
				enabled: boolean,
				rawPayload?: unknown
			) => {
				const assets =
					Array.isArray(rawPayload) &&
					rawPayload.every((asset) => typeof asset === 'string')
						? rawPayload
						: [];

				if (!Array.isArray(rawPayload) || assets.length === 0) {
					enabled = false;
				}

				defaultFlagHandler(
					stateDraft,
					'HIGHLIGHTED_ASSETS',
					enabled,
					rawPayload
				);
				stateDraft.flags.HIGHLIGHTED_ASSETS.assets = assets;
			},
			HIDDEN_SPOT_MARKETS: (
				stateDraft,
				enabled: boolean,
				rawPayload?: unknown
			) => {
				const markets =
					Array.isArray(rawPayload) &&
					rawPayload.every((market) => typeof market === 'string')
						? rawPayload
						: [];

				if (!Array.isArray(rawPayload) || markets.length === 0) {
					enabled = false;
				}

				defaultFlagHandler(
					stateDraft,
					'HIDDEN_SPOT_MARKETS',
					enabled,
					rawPayload
				);
				stateDraft.flags.HIDDEN_SPOT_MARKETS.markets = markets;
			},
		}),
		[actions]
	);

	// Sync the enabled state of the feature flags in the store
	useEffect(() => {
		const flagsLoadedHandler = (flags: string[]) => {
			const state = getFeatureFlagStore();

			setFeatureFlagStore((stateDraft) => {
				for (const flag of flags) {
					if (flag in state.flags) {
						const matchedFlagPayload = posthog.getFeatureFlagPayload(flag);

						const isEnabled = getIsEnabledFromPayload(matchedFlagPayload);

						dlog(
							`feature_flags`,
							`flag_payload:: ${flag},isEnabled=${isEnabled}`,
							{
								payload: matchedFlagPayload,
							}
						);

						const flagKey = flag as FeatureFlagKey;
						const customHandler = CUSTOM_FLAG_HANDLERS[flagKey];

						if (customHandler) {
							customHandler(stateDraft, isEnabled, matchedFlagPayload);
						} else {
							defaultFlagHandler(
								stateDraft,
								flagKey,
								isEnabled,
								matchedFlagPayload
							);
						}
					} else {
						dlog(`feature_flags`, `flag_not_in_store::${flag}`, flags);
					}
				}
			});
		};

		appEventEmitter.on('featureFlagsLoaded', flagsLoadedHandler);
		return () => {
			appEventEmitter.off('featureFlagsLoaded', flagsLoadedHandler);
		};
	}, [appEventEmitter, getFeatureFlagStore, setFeatureFlagStore]);
};

const useSyncFeatureFlags = () => {
	useEffect(() => {
		if (!RELOAD_REMOTE_FLAGS_EVERY_SESSION) return;
		posthog.reloadFeatureFlags();
	}, []);

	useSyncFeatureFlagStateInStore();
};

export default useSyncFeatureFlags;
