import Button from 'src/components/Button';
import useDriftActions from 'src/hooks/useDriftActions';

export const OrderRetryButton = () => {
	const actions = useDriftActions();
	const handleClick = () => {
		actions.showModal('showTradeConfirmationModal', true);
	};

	return (
		<Button.Primary
			className="bg-positive-green-secondary-bg text-positive-green"
			onClick={handleClick}
			size="MEDIUM"
		>
			Try again
		</Button.Primary>
	);
};
