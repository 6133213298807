import { MarketOrderToastStageState } from 'src/components/MarketOrderToasts/MarketOrderToastStateTypes';
import ProgressIndicator, { ProgressState } from './ProgressIndicator';
import { ErrorFilled, SuccessFilled, WarningFilled } from '@drift-labs/icons';
import { Lightning } from '@drift-labs/icons';
import ArcLoadingSpinner from 'src/components/Icons/LoadingSpinner/ArcLoadingSpinner';
import { twMerge } from 'tailwind-merge';
import Text from 'src/components/Text/Text';

const StageDisplay = ({
	progressState,
	toastState,
}: {
	progressState?: ProgressState;
	toastState: MarketOrderToastStageState;
}) => {
	if (toastState.type === 'none') {
		return null;
	}

	const isJitAuctionStage = toastState.isJitAuction;

	return (
		<>
			{/* Icon Column */}
			<span className="w-full flex justify-center h-[20px] items-center">
				{toastState.type === 'in_progress' ? (
					progressState ? (
						<ProgressIndicator {...progressState} />
					) : (
						<ArcLoadingSpinner size={18} />
					)
				) : toastState.type === 'success' ? (
					<SuccessFilled size={18} />
				) : toastState.type === 'warning' ? (
					<WarningFilled size={18} />
				) : toastState.type === 'warning_in_progress' ? (
					<ArcLoadingSpinner color="var(--warn-yellow)" size={18} />
				) : toastState.type === 'error' ? (
					<ErrorFilled size={18} />
				) : toastState.type === 'neutral' ? (
					<div className="w-[18px] h-[18px] rounded-full bg-container-border" />
				) : null}
			</span>

			{/* Content Column */}
			<div className="flex flex-col">
				<div className="flex items-center space-x-1">
					{isJitAuctionStage && (
						<Lightning size={16} color={'url(#prize-gradient)'} />
					)}
					<span
						className={twMerge(
							`flex h-[20px] items-center mt-[1px]`,
							toastState.type === 'error' ? '' : undefined,
							toastState.type === 'success' ? '' : undefined,
							toastState.type === 'warning_in_progress'
								? 'text-warn-yellow'
								: undefined
						)}
					>
						<Text.BODY3>{toastState.message}</Text.BODY3>
					</span>
				</div>
				{toastState.extraMessage && (
					<Text.MICRO3 className="text-neutrals-30">
						{toastState.extraMessage}
					</Text.MICRO3>
				)}
			</div>
		</>
	);
};

export default StageDisplay;
