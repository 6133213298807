'use client';

import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useWalletIsConnected from '../useWalletIsConnected';
import { useEffect, useState } from 'react';
import useCurrentAuthority from '../useCurrentAuthority';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import { useInterval } from 'react-use';
import useFeatureFlagStore from '../../stores/useFeatureFlagStore';
import Env from 'src/environmentVariables/EnvironmentVariables';
import { BigNum, BN, QUOTE_PRECISION_EXP } from '@drift-labs/sdk';
import { PageRoute } from 'src/constants/constants';

export const FUEL_MILESTONES = [
	{
		label: '$15B',
		value: 15000000000,
		progress: 0.15,
		driftUnlocked: 38200000,
	},
	{
		label: '$25B',
		value: 25000000000,
		progress: 0.25,
		driftUnlocked: 48200000,
	},
	{
		label: '$40B',
		value: 40000000000,
		progress: 0.4,
		driftUnlocked: 58200000,
	},
	{
		label: '$60B',
		value: 60000000000,
		progress: 0.6,
		driftUnlocked: 68200000,
	},
	{
		label: '$100B',
		value: 100000000000,
		progress: 1,
		driftUnlocked: 78200000,
	},
];

const useFuelStats = () => {
	const driftStore = useDriftStore();
	const isConnected = useWalletIsConnected();
	const currentAuthority = useCurrentAuthority();
	const fuelParams = useFeatureFlagStore((s) => s.flags.FUEL_PARAMS);
	const currentPageRoute = useDriftStore((s) => s.currentPageRoute);
	const [refreshRate, setRefreshRate] = useState(1000 * 30);

	const fetchFuelVolume = async () => {
		if (!fuelParams.payload) return;

		const result = await ExchangeHistoryClient.getFuelVolume();

		if (result.success) {
			const volumeDays = result.body.data;
			const fuelVolume = volumeDays.reduce(
				(acc, volumeDay) =>
					acc +
					BigNum.from(new BN(volumeDay.volume), QUOTE_PRECISION_EXP).toNum(),
				0
			);

			const milestonesReached = FUEL_MILESTONES.filter(
				(milestone) => fuelVolume >= milestone.value
			).length;

			driftStore.set((s) => {
				s.fuelStats.tradingVolume = fuelVolume;
				s.fuelStats.milestonesReached = milestonesReached;
			});
		}
	};

	const fetchFuelLeaderboard = async () => {
		const result = await ExchangeHistoryClient.fetchFuelLeaderboard();

		if (result.success) {
			driftStore.set((s) => {
				s.fuelStats.leaderboard = result.body.data;
			});
		}
	};

	const fetchFuelRank = async () => {
		if (isConnected && currentAuthority && Env.enableFuelLeaderboard) {
			const result = await ExchangeHistoryClient.fetchUserFuelRank(
				currentAuthority
			);

			if (result.success) {
				driftStore.set((s) => {
					s.fuelStats.userRank = result.body.data;
				});
			}
		}
	};

	useEffect(() => {
		if (driftStore.get().fuelStats.tradingVolume === 0) {
			fetchFuelVolume();
		}

		if (
			driftStore.get().fuelStats.leaderboard?.length === 0 &&
			Env.enableFuelLeaderboard
		) {
			fetchFuelLeaderboard();
		}

		fetchFuelRank();
	}, [isConnected, currentAuthority, fuelParams]);

	useEffect(() => {
		if (currentPageRoute === PageRoute.fuel) {
			setRefreshRate(1000 * 30);
		} else {
			setRefreshRate(1000 * 300);
		}
	}, [currentPageRoute]);

	// update total volume every 30 seconds if on fuel page, otherwise every 5 minutes
	useInterval(() => {
		fetchFuelVolume();
	}, refreshRate);
};

export default useFuelStats;
