'use client';

import { CurrentPerpMarkets } from '../environmentVariables/EnvironmentVariables';
import useInterval from './useInterval';
import {
	AMM_RESERVE_PRECISION,
	AMM_RESERVE_PRECISION_EXP,
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	MarketType,
	PRICE_PRECISION_EXP,
	PerpMarketConfig,
	QUOTE_PRECISION_EXP,
	ZERO,
} from '@drift-labs/sdk';
import { useEffect, useState } from 'react';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import useAccountData from './useAccountData';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useWalletIsConnected from './useWalletIsConnected';
import useDriftStore, { DriftStore } from 'src/stores/DriftStore/useDriftStore';
import { COMMON_UI_UTILS, OpenPosition } from '@drift/common';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
import UI_UTILS from 'src/utils/uiUtils';

type PoolPerformanceInfo = {
	all: number;
	'30d': number;
	'7d': number;
	'24h': number;
};

export type LiquidityPool = {
	marketConfig: PerpMarketConfig;
	poolPerformance: PoolPerformanceInfo;
	protocolLpShares: BigNum;
	protocolLpQuoteValue: BigNum;
	totalUserLpShares: BigNum;
	totalUserLpQuoteValue: BigNum;
	userLpShares: BigNum;
	userQuoteValue: BigNum;
	userFeesEarned: BigNum;
	userLpProportion: string;
	userLpUtilization: string;
	userPerpPosition: OpenPosition | undefined;
	userUnsettledPnl: BigNum;
	userLpPnlForMarket: BigNum;
	remainderBaseAmount: BigNum;
	removalBurnCost: BigNum;
	minContributionQuoteValue: BigNum;
	userHasAnyLp: boolean;
	poolFeeApr?: number;
	totalFees24h?: BigNum;
};

const REFRESH_INTERVAL = 5000;

const useLiquidityPools = () => {
	const [lpData, setLpData] = useState<{
		currentUserKey: string;
		pools: LiquidityPool[];
	}>();
	const connected = useWalletIsConnected();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();
	const currentAccount = useAccountData();
	const setState = useDriftStore((s) => s.set);
	const getState = useDriftStore((s) => s.get);

	const [poolAprs, setPoolAprs] =
		useState<{ marketIndex: number; aprs: PoolPerformanceInfo }[]>();

	const updateLiquidityPools = async () => {
		if (!driftClientIsReady) return;
		if (!driftClient?.accountSubscriber?.isSubscribed) return;

		let userHasAnyLp = false;

		const updatedLiqPools: LiquidityPool[] = [];

		CurrentPerpMarkets.filter((mkt) =>
			UI_UTILS.applyPredictionsFilter(mkt.marketIndex)
		).forEach((perpMarket) => {
			try {
				const marketAccount = driftClient.getPerpMarketAccount(
					perpMarket.marketIndex
				);

				if (!marketAccount) return;

				const oraclePriceData = driftClient.getOracleDataForPerpMarket(
					perpMarket.marketIndex
				);

				const quoteValuePerLpShare = BigNum.from(
					driftClient.getQuoteValuePerLpShare(perpMarket.marketIndex),
					QUOTE_PRECISION_EXP
				).shiftTo(AMM_RESERVE_PRECISION_EXP);

				const protocolLpShares = BigNum.from(
					marketAccount.amm.sqrtK.sub(marketAccount.amm.userLpShares),
					AMM_RESERVE_PRECISION_EXP
				);

				const totalUserLpShares = BigNum.from(
					marketAccount.amm.userLpShares,
					AMM_RESERVE_PRECISION_EXP
				);

				const minContributionQuoteValue = BigNum.from(
					marketAccount.amm.orderStepSize
						.mul(oraclePriceData.price)
						.div(AMM_RESERVE_PRECISION),
					QUOTE_PRECISION_EXP
				);

				let userLpShares = BigNum.zero(AMM_RESERVE_PRECISION_EXP);
				let userQuoteValue = BigNum.zero(QUOTE_PRECISION_EXP);
				let userUtilization = BigNum.zero();
				let userLpProportion = BigNum.zero();
				let userUnsettledPnl = BigNum.zero(QUOTE_PRECISION_EXP);
				let remainderBaseAmount = BigNum.zero(BASE_PRECISION_EXP);
				let userTotalMarketPnl = BigNum.zero(QUOTE_PRECISION_EXP);
				let removalBurnCost = BigNum.zero(QUOTE_PRECISION_EXP);
				let perpPosition: OpenPosition = undefined;

				if (currentAccount && currentAccount.client) {
					perpPosition = currentAccount?.openPerpPositions?.find(
						(pos) =>
							!pos.lpShares.eq(ZERO) &&
							pos.marketIndex === perpMarket.marketIndex
					);

					if (perpPosition) {
						userHasAnyLp = true;

						remainderBaseAmount = BigNum.from(
							new BN(perpPosition.remainderBaseAmount).abs(),
							BASE_PRECISION_EXP
						);

						userLpShares = BigNum.from(
							perpPosition.lpShares,
							AMM_RESERVE_PRECISION_EXP
						);

						userUnsettledPnl = BigNum.from(
							perpPosition.unsettledPnl,
							QUOTE_PRECISION_EXP
						);

						userTotalMarketPnl = userUnsettledPnl.add(
							BigNum.from(perpPosition.realizedPnl, QUOTE_PRECISION_EXP)
						);

						userLpProportion = userLpShares.scale(
							1,
							Math.max(totalUserLpShares.add(protocolLpShares).toNum(), 1)
						);

						userQuoteValue = userLpShares
							.mul(quoteValuePerLpShare)
							.shiftTo(QUOTE_PRECISION_EXP);

						const [userBids, userAsks] = currentAccount?.client?.getLPBidAsks(
							perpPosition.marketIndex,
							perpPosition.lpShares
						);

						const userTotalBaseInOrdersAndPositions = userBids
							.add(userAsks)
							.add(perpPosition.baseSize);

						const userQuoteValueInUse = BigNum.from(
							userTotalBaseInOrdersAndPositions
								.mul(oraclePriceData.price)
								.div(BASE_PRECISION),
							QUOTE_PRECISION_EXP
						);

						removalBurnCost = remainderBaseAmount
							.shiftTo(PRICE_PRECISION_EXP)
							.mul(BigNum.from(oraclePriceData.price, PRICE_PRECISION_EXP));

						userUtilization = userQuoteValue.gtZero()
							? userQuoteValueInUse.scale(
									10000,
									Math.max(userQuoteValue.toNum() * 10000, 1)
							  )
							: BigNum.zero();
					}
				}

				const userLpProportionStr = `${Math.min(
					userLpProportion.mul(new BN(100)).toNum(),
					100
				).toFixed(2)}%`;
				const userLpUtilizationStr = `${Math.min(
					userUtilization.mul(new BN(100)).toNum(),
					100
				).toFixed(2)}%`;

				const newPoolInfo: LiquidityPool = {
					marketConfig: perpMarket,
					poolPerformance:
						poolAprs?.find(
							(poolAprInfo) =>
								poolAprInfo.marketIndex === perpMarket.marketIndex
						)?.aprs ?? undefined,
					protocolLpShares: protocolLpShares,
					protocolLpQuoteValue: protocolLpShares
						.mul(quoteValuePerLpShare)
						.shiftTo(QUOTE_PRECISION_EXP),
					totalUserLpShares,
					totalUserLpQuoteValue: totalUserLpShares
						.mul(quoteValuePerLpShare)
						.shiftTo(QUOTE_PRECISION_EXP),
					userLpShares,
					userQuoteValue,
					userFeesEarned: BigNum.zero(),
					userLpProportion: userLpProportionStr,
					userLpUtilization: userLpUtilizationStr,
					userPerpPosition: perpPosition,
					userUnsettledPnl,
					userLpPnlForMarket: userTotalMarketPnl,
					remainderBaseAmount,
					removalBurnCost: removalBurnCost.abs(),
					minContributionQuoteValue,
					userHasAnyLp,
				};

				//dont overwrite any info that wasnt set
				const currentPoolInfo = getPoolFromState(
					perpMarket.marketIndex,
					getState
				);

				updatedLiqPools.push({
					...currentPoolInfo,
					...newPoolInfo,
				});
			} catch (e) {
				console.log(
					`Error fetching liquidity data for ${perpMarket.symbol}: `,
					e
				);
				return;
			}
		});

		const sortedUpdatedLiqPools = updatedLiqPools?.sort(
			(a, b) =>
				b.totalUserLpQuoteValue.toNum() - a.totalUserLpQuoteValue.toNum()
		);

		// sort by user liquidity if they have any, followed by overall user provided liquidity
		setLpData({
			currentUserKey: currentAccount?.userKey ?? undefined,
			pools:
				connected && userHasAnyLp
					? sortedUpdatedLiqPools.sort(
							(a, b) => b.userQuoteValue.toNum() - a.userQuoteValue.toNum()
					  )
					: sortedUpdatedLiqPools,
		});
	};

	const fetchPoolAprs = async () => {
		ExchangeHistoryClient.getLpAprs()
			.then((result) => {
				setPoolAprs(result?.body?.data);
			})
			.catch((e) => {
				console.log('Error setting lp aprs', e);
			});
	};

	useInterval(() => {
		updateLiquidityPools();
	}, REFRESH_INTERVAL);

	useEffect(() => {
		updateLiquidityPools();
	}, [currentAccount?.userKey, poolAprs]);

	useEffect(() => {
		if (!poolAprs) {
			fetchPoolAprs();
		}
	}, []);

	useEffect(() => {
		setState((s) => {
			s.liquidityPoolInfo = lpData;
		});
	}, [lpData]);

	return;
};

export const addFeeAprsToPool = async (marketIndex: number) => {
	const setState = useDriftStore((s) => s.set);
	const getState = useDriftStore((s) => s.get);
	const driftClient = useDriftClient();
	const perpMarket = useMarketsInfoStore()?.getMarketInfoByIndexAndType(
		marketIndex,
		MarketType.PERP
	)?.config;

	const [fetching, setFetching] = useState(false);

	const calculatePoolFeeOnlyApr = async () => {
		setFetching(true);
		ExchangeHistoryClient.getLatestFeeData(marketIndex).then((result) => {
			if (result.success) {
				const feeData = result.body.data.feeData;
				let aprsByDay: number[] = [];

				const poolData = feeData;

				const marketAccount = driftClient.getPerpMarketAccount(
					perpMarket.marketIndex
				);

				if (!marketAccount) return;

				const userLpShares = BigNum.from(
					marketAccount.amm.userLpShares,
					AMM_RESERVE_PRECISION_EXP
				);
				const totalLpShares = BigNum.from(
					marketAccount.amm.sqrtK,
					AMM_RESERVE_PRECISION_EXP
				);

				let total24hFees = BigNum.zero();

				if (poolData) {
					aprsByDay = poolData.map((dailyFeeInfo, index) => {
						let scaleNums = [userLpShares.toNum(), totalLpShares.toNum()];

						let dailyUserLpShares = userLpShares;
						let dailyTotalLpShares = totalLpShares;

						// try to calculate apr based on the proportion at that time, but use current proportion if we don't know it
						// starting recording these in june 2023, so for historical apr calculations just use current day proportions.
						if (dailyFeeInfo.userLpShares && dailyFeeInfo.protocolLpShares) {
							dailyUserLpShares = BigNum.fromPrint(
								dailyFeeInfo.userLpShares,
								AMM_RESERVE_PRECISION_EXP
							).div(AMM_RESERVE_PRECISION);

							dailyTotalLpShares = BigNum.fromPrint(
								dailyFeeInfo.protocolLpShares,
								AMM_RESERVE_PRECISION_EXP
							)
								.div(AMM_RESERVE_PRECISION)
								.add(dailyUserLpShares);

							scaleNums = [
								dailyUserLpShares.toNum() * 10000,
								dailyTotalLpShares.toNum() * 10000,
							];
						}

						if (scaleNums[1] === 0) {
							scaleNums = [1, 1];
						}

						// user LPs earn 80% * lp proportion of fees from amm filled trades
						const dailyAmmFees = BigNum.from(
							dailyFeeInfo.fees.find(
								(feeInfo) => feeInfo.feeType === 'orderFilledWithAmm'
							)?.feeAmount ?? 0,
							QUOTE_PRECISION_EXP
						)
							.scale(80, 100)
							.scale(scaleNums[0], scaleNums[1]);

						// user LPs earn 80% * lp proportion of fees from amm jit lp split filled trades
						const dailyAmmJitLpSplitFees = BigNum.from(
							dailyFeeInfo.fees.find(
								(feeInfo) => feeInfo.feeType === 'orderFilledWithAmmJitLpSplit'
							)?.feeAmount ?? 0,
							QUOTE_PRECISION_EXP
						)
							.scale(80, 100)
							.scale(scaleNums[0], scaleNums[1]);

						// user LPs earn 80% of fees from jit lp split filled trades
						const dailyLpJitFees = BigNum.from(
							dailyFeeInfo.fees.find(
								(feeInfo) => feeInfo.feeType === 'orderFilledWithLpJit'
							)?.feeAmount ?? 0,
							QUOTE_PRECISION_EXP
						).scale(80, 100);

						const dailyTotalUserLpFeesEarned = dailyAmmFees
							.add(dailyAmmJitLpSplitFees)
							.add(dailyLpJitFees);

						// fees earned by lps that day divided by lp quote value that day
						const dailyPercentEarned = dailyUserLpShares.gtZero()
							? dailyTotalUserLpFeesEarned
									.scale(
										10000,
										Math.max(
											1,
											COMMON_UI_UTILS.getQuoteValueForLpShares(
												driftClient,
												perpMarket.marketIndex,
												dailyUserLpShares.val
											).toNum() * 10000
										)
									)
									.mul(new BN(100))
									.toNum()
							: 0;

						// if it's the latest day then use this value to 24h fees generated for the market
						if (index === poolData?.length - 1) {
							total24hFees = dailyTotalUserLpFeesEarned;
						}

						// multiply that number by days in a year for apr
						return dailyPercentEarned * 365.25;
					});

					const averageAprForMarket =
						aprsByDay.length > 0
							? aprsByDay.reduce((prev, current) => prev + current, 0) /
							  aprsByDay.length
							: 0;

					setState((s) => {
						console.log('SET STATE');
						const pool = s.liquidityPoolInfo.pools.find(
							(liqPool) => liqPool.marketConfig.marketIndex === marketIndex
						);
						pool.poolFeeApr = averageAprForMarket;
						pool.totalFees24h = total24hFees;
					});
				}

				setFetching(false);
			}
		});
	};

	const pool = getPoolFromState(marketIndex, getState);
	if (
		!fetching &&
		(pool.totalFees24h == undefined || pool.poolFeeApr == undefined)
	) {
		calculatePoolFeeOnlyApr();
		return;
	}

	return;
};

const getPoolFromState = (marketIndex: number, getState: () => DriftStore) => {
	return getState().liquidityPoolInfo?.pools?.find(
		(liqPool) => liqPool.marketConfig.marketIndex === marketIndex
	);
};

export default useLiquidityPools;
