import { useQuery } from '@tanstack/react-query';
import { MarketId } from '@drift/common';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';

export const useMarketRecentTradesCache = (marketId: MarketId) => {
	return useQuery({
		queryKey: ['market-recent-trades', marketId.key],
		queryFn: async () => {
			const newTradesResult = marketId
				? (await ExchangeHistoryClient.getRecentTrades(marketId))?.body
						?.data ?? { trades: [], mostRecentTradeScore: 0 }
				: { trades: [], mostRecentTradeScore: 0 };

			return newTradesResult;
		},
		enabled: !!marketId,
		staleTime: 1000 * 10,
	});
};
