import { Typo } from 'src/components/Text/Typo';

export const VaultDescription = ({ description }: { description: string }) => {
	return (
		<div className="flex flex-col gap-4">
			<Typo.T4 className="text-text-default">Description</Typo.T4>
			<Typo.B4 className="text-text-secondary">{description}</Typo.B4>
		</div>
	);
};
