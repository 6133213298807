'use client';

import { UserStatus } from '@drift-labs/sdk';
import useTickedAllSubAccounts from 'src/hooks/useTickedAllSubAccounts';
import useInterval from 'src/hooks/useInterval';
import { useEffect, useMemo, useState } from 'react';
import useDriftAccountStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import { matchEnum } from '@drift/common';
import { singletonHook } from 'react-singleton-hook';
import useWalletIsConnected from '../useWalletIsConnected';
import { DriftWindow } from '../../window/driftWindow';

const DEFAULT_STATE: AccountData[] = [];

/**
 *
 * @returns
 */
const useAccountsBeingLiquidated_ = () => {
	const connected = useWalletIsConnected();
	const userAccounts = useTickedAllSubAccounts();
	const getDriftAccountStore = useDriftAccountStore((s) => s.get);

	const [accountsBeingLiqd, setAccountsBeingLiqd] =
		useState<AccountData[]>(DEFAULT_STATE);

	const devEventEmitter = DriftWindow?.devEventEmitter;

	useEffect(() => {
		if (devEventEmitter) {
			devEventEmitter.on('force_liquidation_alert', () => {
				const currentAccount =
					getDriftAccountStore().accounts[
						getDriftAccountStore().currentUserKey
					];
				if (currentAccount) {
					setAccountsBeingLiqd([currentAccount]);
				}
			});
		}
	}, [devEventEmitter]);

	useInterval(() => {
		if (!connected) {
			setAccountsBeingLiqd(DEFAULT_STATE);
			return;
		}

		const accountsBeingLiqd = userAccounts.filter((account) => {
			try {
				const userAccount = account.client.getUserAccount();
				const { canBeLiquidated } = account.client.canBeLiquidated();
				const inLiquidationState = matchEnum(
					userAccount.status,
					UserStatus.BEING_LIQUIDATED
				);

				return inLiquidationState || canBeLiquidated;
			} catch (_err) {
				return false;
			}
		});

		setAccountsBeingLiqd(
			accountsBeingLiqd.length > 0 ? accountsBeingLiqd : DEFAULT_STATE
		);
	}, 1000);

	return accountsBeingLiqd;
};

const useAccountsBeingLiquidated = singletonHook(
	DEFAULT_STATE,
	useAccountsBeingLiquidated_
);

export default useAccountsBeingLiquidated;

export const useAccountsBeingLiquidatedWithoutBankruptcies = () => {
	const accountsBeingLiquidated = useAccountsBeingLiquidated();

	const filteredAccountsBeingLiquidated = useMemo(
		() =>
			accountsBeingLiquidated.filter((account) => {
				const userAccount = account.client.getUserAccount();
				const isBankrupt = matchEnum(userAccount.status, UserStatus.BANKRUPT);

				return !isBankrupt;
			}),
		[accountsBeingLiquidated]
	);

	return filteredAccountsBeingLiquidated;
};
