import { UiVaultConfig } from 'src/@types/vaults';
import { VaultPerformanceV2 } from './VaultPerformanceV2';

import { NotionalGrowthStrategyAlert } from '../VaultGraphs';
import { SpotMarketConfig } from '@drift-labs/sdk';
import { UIMarket } from '@drift/common';

export const VaultPerformance = (props: { uiVaultConfig: UiVaultConfig }) => {
	return (
		<div className="flex flex-col w-full gap-6 pb-4">
			{props.uiVaultConfig.isNotionalGrowthStrategy && (
				<NotionalGrowthStrategyAlert />
			)}
			<VaultPerformanceV2
				vaultPubkey={props.uiVaultConfig.vaultPubkeyString}
				depositAssetConfig={
					UIMarket.createSpotMarket(props.uiVaultConfig.depositAsset)
						.market as SpotMarketConfig
				}
			/>
		</div>
	);
};
