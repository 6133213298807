'use client';

import { useState } from 'react';
import { SpotMarketConfig } from '@drift-labs/sdk';
import { isDev } from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import Button from '../Button';
import Utility from '../Inputs/Utility';

const SHOW_FAUCET_BUTTON = isDev();

type AirdropButtonProps = {
	spotMarket: SpotMarketConfig;
	size?: 'SMALL' | 'MEDIUM';
	className?: string;
	showSpacing?: boolean;
};

const AirdropButton = ({
	spotMarket,
	size = 'SMALL',
	className = '',
	showSpacing = true,
}: AirdropButtonProps) => {
	const [airdropping, setAirdropping] = useState(false);
	const actions = useDriftActions();

	const handleAirdrop = async () => {
		if (airdropping) return;
		setAirdropping(true);
		await actions.tryFaucetAirdrop(spotMarket);
		setAirdropping(false);
	};

	if (!SHOW_FAUCET_BUTTON) return null;

	return (
		<div className="flex flex-col items-start">
			<Button.Secondary
				size={size}
				onClick={handleAirdrop}
				disabled={airdropping}
				highlight
				className={className}
			>
				Airdrop {spotMarket.symbol}
			</Button.Secondary>
			{showSpacing && <Utility.VERTSPACERL />}
		</div>
	);
};

export default AirdropButton;
