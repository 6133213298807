'use client';

import {
	SpotMarketConfig,
	BASE_PRECISION_EXP,
	BigNum,
	BN,
} from '@drift-labs/sdk';
import useCurrentWalletCollateralBalance from 'src/hooks/useCurrentWalletCollateralBalance';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import useDriftActions from 'src/hooks/useDriftActions';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import { CollateralInput } from './Inputs/CollateralInput';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import Text from './Text/Text';
import InfoMessage from './TradeForm/InfoMessage';
import ValueDisplay from './ValueDisplay';
import { MIN_LEFTOVER_SOL } from 'src/constants/constants';
import useDisplayPrecisionForCollateral from 'src/hooks/useDisplayPrecisionForCollateral';
import { useTriggerRefreshIfStakeData } from 'src/stores/ifDataStore/useSyncIfStakeData';
import CheckboxInput from './CheckboxInput';
import Button from './Button';
import LabelledInput from './Inputs/LabelledInput';
import Select from './Inputs/Select';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useSubaccountCollateralBalanceForBank from 'src/hooks/useSubaccountCollateralBalanceForBank';
import Tooltip from './Tooltip/Tooltip';
import useAccountsBeingLiquidated from 'src/hooks/Liquidations/useAccountsBeingLiquidated';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import { Info, Open } from '@drift-labs/icons';
import useAccountData from 'src/hooks/useAccountData';
import UI_UTILS from 'src/utils/uiUtils';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useWalletIsConnected from '../hooks/useWalletIsConnected';
import { COMMON_UI_UTILS, IF_STAKE_ACCOUNT_BASE_RENT } from '@drift/common';
import useIfDataStore from '../stores/ifDataStore/useIfDataStore';
import { useSyncWalletBalances } from '../hooks/useSyncWalletBalances';
import Env, {
	DRIFT_TOKEN_SPOT_MARKET,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftClient from 'src/hooks/useDriftClient';
import useDriftClientIsReady from 'src/hooks/useDriftClientIsReady';
import { Arrow } from './ValueChangeDisplay';

// TODO:::: COULD/SHOULD POSSIBLY REFACTOR THESE TO USE THE SAME STATE MANAGEMENT AS THE OTHER COLLATERAL MODALS

const StakeModalContent = (props: { onClose: () => void }) => {
	const walletAdapter = useCurrentWalletAdapter();
	const walletIsConnected = useWalletIsConnected();
	const actions = useDriftActions();
	const isGeoblocked = useDriftStore((s) => s.isGeoblocked);
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	const setState = useDriftStore((s) => s.set);
	const marketFromStore = useDriftStore((s) => s.selectedIfStakeMarket);
	const isDriftStaking = useDriftStore(
		(s) => s.modalsProps.showStakeModal?.isDriftStaking
	);
	const currentAccountData = useAccountData();
	useSyncWalletBalances();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const activeSubaccountId = currentAccountData?.userId;
	const activeSubaccountKey = currentAccountData?.userKey;

	const ifStakeData = useIfDataStore((s) => s.IFData);
	const excludedMarkets = Object.values(ifStakeData)
		.filter((ifData) =>
			isDriftStaking
				? ifData.marketIndex !== DRIFT_TOKEN_SPOT_MARKET.marketIndex
				: ifData.pausedOperations.includes(
						COMMON_UI_UTILS.InsuranceFundOperationsMap.INIT
				  )
		)
		.map((vault) => vault.marketIndex);
	const refreshIfStakeData = useTriggerRefreshIfStakeData();

	const availableSubAccounts = useLazySubAccounts();
	const accountsBeingLiquidated = useAccountsBeingLiquidated();
	const { captureEvent } = usePostHogCapture();

	// # State
	useState(false);
	const [hasAcceptedProjectedApr, setHasAcceptedProjectedApr] = useState(false);
	const [hasAcceptedRisks, setHasAcceptedRisks] = useState(false);
	const [hasReadDocsPage, setHasReadDocsPage] = useState(false);
	const [selectedIfMarket, setSelectedIfMarket] =
		useState<SpotMarketConfig>(marketFromStore);
	const [submitting, setSubmitting] = useState(false);
	const [maxButtonTransition, setMaxButtonTransition] = useState(false);
	const [collatTransferAmount, setCollatTransferAmount] = useState('');
	const [subAccountIdToStakeFrom, setSubAccountIdToStakeFrom] =
		useState(activeSubaccountId);
	const [subAccountKeyToStakeFrom, setSubAccountKeyToStakeFrom] =
		useState(activeSubaccountKey);
	const [stakingFromSubaccount, setStakingFromSubaccount] = useState(false);

	const authorityString = useMemo(() => {
		return walletAdapter?.publicKey?.toString?.() ?? '';
	}, [walletAdapter?.publicKey]);

	const authorityStringShort = `${authorityString.slice(
		0,
		3
	)}...${authorityString.slice(
		authorityString.length - 3,
		authorityString.length
	)}`;

	const stakeFromOptions = [
		{
			label: `${walletAdapter.name} Wallet (${authorityStringShort})`,
			value: 'wallet',
		},
		// For now only showing active subaccount... but in future we could support others?
		...availableSubAccounts
			.filter(
				(account) =>
					!account.isDelegatedTo &&
					account.client.getUserAccount().poolId === selectedIfMarket.poolId
			)
			// .filter((account) => account.userId === activeSubaccountId)
			.map((account) => ({
				label: account.name,
				value: `${account.userId}`,
			})),
	];

	const displayPrecision = useDisplayPrecisionForCollateral(selectedIfMarket);
	const currentIfStakeData = ifStakeData?.[selectedIfMarket?.marketIndex];
	const unstakingPeriod =
		currentIfStakeData?.vault?.account?.unstakingPeriod?.toNumber() ?? 0;
	const unstakingPeriodAsDays = Math.floor(unstakingPeriod / 60 / 60 / 24);
	const ifStakeAccount = currentIfStakeData?.userStake;
	const stakeAccountExists = !!(
		ifStakeAccount?.loaded && ifStakeAccount?.account
	);

	const stakeAccountBalance = ifStakeAccount?.currentStake ?? 0;

	const selectedAccountCanbeLiqd = accountsBeingLiquidated.find(
		(account) => `${account.userKey}` === `${subAccountKeyToStakeFrom}`
	);

	const [walletBalance] = useCurrentWalletCollateralBalance(selectedIfMarket);
	const [transferableWalletBalance] =
		useTransferableWalletCollateralBalance(selectedIfMarket);
	const targetCollatBalances = useAccountTargetSpotBalance(
		selectedIfMarket,
		subAccountKeyToStakeFrom,
		true
	);
	const subAccountBalance = useSubaccountCollateralBalanceForBank({
		accountKey: subAccountKeyToStakeFrom,
		market: selectedIfMarket,
	});
	const withdrawalLimit = targetCollatBalances.withdrawLimitBase.shiftTo(
		selectedIfMarket.precisionExp
	);
	const currentCollatBalanceBigNum = stakingFromSubaccount
		? subAccountBalance
		: walletBalance;
	const depositableBalanceBigNum = stakingFromSubaccount
		? withdrawalLimit
		: transferableWalletBalance;
	const collatTransferAmountBignum = BigNum.fromPrint(
		collatTransferAmount ? collatTransferAmount : '0',
		selectedIfMarket.precisionExp
	);

	const isMaxSolDeposit =
		collatTransferAmount !== '' &&
		collatTransferAmountBignum.eq(depositableBalanceBigNum) &&
		selectedIfMarket.symbol === 'SOL';

	const exceededMax = collatTransferAmountBignum.gt(depositableBalanceBigNum);

	const hasAcceptedIfStakeTerms =
		stakeAccountExists ||
		(hasReadDocsPage &&
			hasAcceptedRisks &&
			(hasAcceptedProjectedApr || isDriftStaking));

	const hasExistingUnstakeRequest =
		!!currentIfStakeData?.userStake?.unstakeRequest?.amount;
	const existingUnstakeRequestAmount =
		currentIfStakeData?.userStake?.unstakeRequest?.amount;
	const existingUnstakeRequestTimestamp = new Date(
		currentIfStakeData?.userStake?.unstakeRequest?.timestamp +
			unstakingPeriod * 1000
	);
	const canWithdraw =
		hasExistingUnstakeRequest &&
		Date.now() >=
			currentIfStakeData?.userStake?.unstakeRequest?.timestamp +
				unstakingPeriod * 1000;

	const allowCancelExistingUnstakeRequest =
		!submitting && hasExistingUnstakeRequest;

	const isInsufficientSolBalance = solBalance.lt(IF_STAKE_ACCOUNT_BASE_RENT);

	const allowConfirmation =
		!hasExistingUnstakeRequest &&
		!isGeoblocked &&
		hasAcceptedIfStakeTerms &&
		!exceededMax &&
		(stakeAccountExists || !isInsufficientSolBalance) &&
		!submitting &&
		collatTransferAmount &&
		collatTransferAmountBignum.gt(BigNum.zero(BASE_PRECISION_EXP), true) &&
		(!subAccountKeyToStakeFrom || !selectedAccountCanbeLiqd);

	const stakeDisabled = currentIfStakeData.pausedOperations.includes(
		COMMON_UI_UTILS.InsuranceFundOperationsMap.ADD
	);

	const spotMarketAccount =
		(driftClientIsReady &&
			driftClient.getSpotMarketAccount(selectedIfMarket.marketIndex)) ||
		undefined;

	const fuelEarnRate = BigNum.from(
		new BN(
			(Env.fuelBaseEarnRates?.staking || 1) *
				(spotMarketAccount.fuelBoostInsurance || 0) || 0
		)
	);

	const oldFuelEarnAmount = BigNum.fromPrint(
		`${stakeAccountBalance}`,
		selectedIfMarket.precisionExp
	).mul(fuelEarnRate);

	const fuelEarnAmount = oldFuelEarnAmount.add(
		collatTransferAmountBignum.mul(fuelEarnRate)
	);

	// # Effect Hooks
	//// Close modal if wallet not connected
	useEffect(() => {
		if (!walletIsConnected) {
			props.onClose();
		}
	}, [walletIsConnected]);

	useEffect(() => {
		if (exceededMax) {
			setCollatTransferAmount(depositableBalanceBigNum.print());
		}
	}, [exceededMax]);

	useEffect(() => {
		captureEvent('opened_stake_modal', {
			spot_market_symbol: selectedIfMarket.symbol,
		});
	}, []);

	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setCollatTransferAmount('');
	}, [selectedIfMarket?.marketIndex]);

	// # Methods
	const updateCollateralType = (newCollat: SpotMarketConfig) => {
		setSelectedIfMarket(newCollat);
		setState((s) => {
			s.selectedIfStakeMarket = newCollat;
		});
	};

	const handleConfirmStake = async () => {
		setSubmitting(true);
		actions.addLoadingItemToQueue({ key: 'modal' });
		await stakeAssets();

		setSubmitting(false);
		refreshIfStakeData();
		actions.removeLoadingItemFromQueue({ key: 'modal' });
		props.onClose();
	};

	const handleValueChange = (newValue: string) => {
		setCollatTransferAmount(newValue);
	};

	const stakeAssets = async () => {
		const collateralAmount = BigNum.fromPrint(
			collatTransferAmount,
			selectedIfMarket.precisionExp
		);

		captureEvent('submitted_add_if_stake', {
			spot_market_symbol: selectedIfMarket.symbol,
		});

		await actions.addInsuranceFundStake({
			amount: collateralAmount.val,
			marketIndex: selectedIfMarket.marketIndex,
			initializeStakeAccount: stakeAccountExists ? false : true,
			fromSubaccount: stakingFromSubaccount ? true : undefined,
			userId: stakingFromSubaccount ? subAccountIdToStakeFrom : undefined,
			userKey: stakingFromSubaccount ? subAccountKeyToStakeFrom : undefined,
		});
	};

	const handleHasAcceptedProjectedApr = useCallback(() => {
		setHasAcceptedProjectedApr(!hasAcceptedProjectedApr);
	}, [hasAcceptedProjectedApr]);

	const handleHasAcceptedRisks = useCallback(() => {
		setHasAcceptedRisks(!hasAcceptedRisks);
	}, [hasAcceptedRisks]);

	const handleHasReadDocsPage = useCallback(() => {
		setHasReadDocsPage(!hasReadDocsPage);
	}, [hasReadDocsPage]);

	// These methods are copied from unstake modal content... not sure if there's a better way to do this but it needs to be on both modals
	// Because the user can't stake more until they finish or cancel their current unstake request.
	const handleCancelUnstakeRequest = async () => {
		setSubmitting(true);

		const result = await actions.cancelInsuranceFundUnstakeRequest({
			marketIndex: selectedIfMarket?.marketIndex,
		});

		captureEvent('canceled_unstake_request', {
			spot_market_symbol: selectedIfMarket.symbol,
		});

		setSubmitting(false);

		if (result === true) {
			props.onClose();
		}
	};

	const handleWithdrawUnstakedAssets = async () => {
		setSubmitting(true);

		const result = await actions.withdrawInsuranceFundUnstakedAssets({
			marketIndex: selectedIfMarket.marketIndex,
		});

		captureEvent('withdrew_unstaked_assets', {
			spot_market_symbol: selectedIfMarket.symbol,
		});

		setSubmitting(false);

		if (result === true) {
			props.onClose();
		}
	};

	// Switch between staking from subaccounts or main wallet
	const handleChangeStakeFromSource = (value: string) => {
		if (value === 'wallet') {
			setStakingFromSubaccount(false);
		} else {
			const newValue = Number(value);
			setStakingFromSubaccount(true);
			setSubAccountIdToStakeFrom(newValue);
			setSubAccountKeyToStakeFrom(
				COMMON_UI_UTILS.getUserKey(Number(newValue), walletAdapter.publicKey)
			);
		}
	};

	// # Rendering
	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					<Utility.VERTSPACERS />

					{stakeAccountExists === false && isInsufficientSolBalance && (
						<>
							<InfoMessage
								type="warn"
								messageTitle="Not enough SOL in your wallet"
								message={`To stake a new asset, you need to have ${IF_STAKE_ACCOUNT_BASE_RENT.toFixed(
									3
								)} SOL in your wallet for stake account creation.`}
							/>
							<Utility.VERTSPACERL />
						</>
					)}

					<div className="items-center block">
						<Text.P1 className="mr-1 text-text-default">
							Staked funds cannot be used as collateral. If you decide to
							unstake, the amount will be subject to a {unstakingPeriodAsDays}
							-day cool down period.
						</Text.P1>
						<Text.P1 className="inline-flex items-center text-text-secondary hover:cursor-pointer">
							<a
								href="https://docs.drift.trade/insurance-fund/insurance-fund-staking"
								target="_blank"
								rel="noreferrer"
								className="inline-flex items-center gap-1 text-text-secondary"
							>
								<div>Learn more</div>
								<Open />
							</a>
						</Text.P1>
					</div>
					<Utility.VERTSPACERXL />

					<LabelledInput label="Stake from">
						<Select.Default
							useFullWidth
							id="stakeFromSelect"
							onChange={(val) => handleChangeStakeFromSource(val)}
							options={stakeFromOptions}
							currentSelection={
								stakingFromSubaccount
									? `${subAccountIdToStakeFrom}`
									: stakeFromOptions[0].value
							}
						/>
					</LabelledInput>

					<Utility.VERTSPACERXL />

					<CollateralInput
						selectedMarket={selectedIfMarket}
						maxAmount={depositableBalanceBigNum}
						currentBalance={currentCollatBalanceBigNum}
						onChangeMarket={updateCollateralType}
						banks={[selectedIfMarket]}
						label={
							isDriftStaking ? 'Amount to stake' : 'Vault and amount to stake'
						}
						value={collatTransferAmount}
						onChangeValue={handleValueChange}
						amountLabel={
							stakingFromSubaccount ? 'Account balance' : 'Wallet balance'
						}
						source={
							stakingFromSubaccount
								? {
										type: 'userAccount',
										userKey: subAccountKeyToStakeFrom,
								  }
								: {
										type: 'wallet',
								  }
						}
						excludedMarketIndices={excludedMarkets}
						poolId={selectedIfMarket.poolId}
					/>
					{stakingFromSubaccount && (
						<>
							<Utility.VERTSPACERL />
							<ValueDisplay.Default
								label={
									<Tooltip
										allowHover
										content={
											<div>
												<div className="mb-2">
													Staking from a Drift account is subject to withdrawal
													limits.
												</div>
												<div>
													Drift limits daily withdrawals to protect the
													protocol.{' '}
													<a
														href="https://docs.drift.trade/risk-and-safety/risk-parameters"
														target="_blank"
														rel="noreferrer"
													>
														Learn More
													</a>
												</div>
											</div>
										}
									>
										<span>Limit</span>
										<Info size={14} className="ml-1" />
									</Tooltip>
								}
								value={`${withdrawalLimit.prettyPrint()} ${
									selectedIfMarket.symbol
								}`}
							/>
						</>
					)}

					{isMaxSolDeposit && !stakingFromSubaccount && (
						<>
							<Utility.VERTSPACERXS />
							<div className="text-text-label">
								<Text.BODY3 className="text-xs leading-4">
									We hold back {MIN_LEFTOVER_SOL.toFixed(2)} SOL on max deposits
									to cover transaction fees
								</Text.BODY3>
							</div>
						</>
					)}

					<Utility.VERTSPACERXL />

					{hasExistingUnstakeRequest &&
						(canWithdraw ? (
							<InfoMessage
								type={'success'}
								message={
									<div className="my-1">
										<Text.BODY1>
											{`You have ${existingUnstakeRequestAmount.toFixed(
												displayPrecision
											)} ${
												selectedIfMarket.symbol
											} unstaked and ready to withdraw.`}
										</Text.BODY1>
										<div className="mt-2 ml-1">
											<Button.Secondary
												size="MEDIUM"
												disabled={submitting}
												onClick={handleWithdrawUnstakedAssets}
											>
												Withdraw{' '}
												{existingUnstakeRequestAmount.toFixed(displayPrecision)}{' '}
												{selectedIfMarket.symbol}
											</Button.Secondary>
										</div>
									</div>
								}
							/>
						) : (
							<InfoMessage
								type={'warn'}
								message={
									<div className="my-1 ml-1">
										<Text.BODY1>
											{`You have an unstake request in progress.`}
											<br />
											{`${existingUnstakeRequestAmount.toFixed(
												displayPrecision
											)} ${
												selectedIfMarket.symbol
											} will be available to withdraw on ${UI_UTILS.printDate(
												existingUnstakeRequestTimestamp
											)}`}
										</Text.BODY1>
										<div className="mt-2">
											<Button.Secondary
												size="MEDIUM"
												disabled={!allowCancelExistingUnstakeRequest}
												onClick={handleCancelUnstakeRequest}
											>
												Cancel unstake request
											</Button.Secondary>
										</div>
									</div>
								}
							/>
						))}

					{isGeoblocked && (
						<InfoMessage
							messageTitle={'Geoblocked'}
							message={`You are not allowed to use Drift from a restricted territory`}
							type={'error'}
						/>
					)}

					{stakingFromSubaccount && selectedAccountCanbeLiqd && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="error"
								messageTitle="Withdrawals from this account are paused"
								message={
									<>
										Once the account liquidation is complete, withdrawals will
										be enabled again.
									</>
								}
							/>
						</>
					)}

					{!isGeoblocked && !stakeAccountExists && (
						<>
							<div>
								<CheckboxInput
									label="I understand the concept of Insurance Fund Staking on Drift and accept all risks associated with electing to participate in the Insurance Fund, including the possible loss of all funds. I understand that there is a 13 day cooldown period to unlock funds."
									checked={hasAcceptedRisks}
									onChange={handleHasAcceptedRisks}
									secondaryStyle
									alignStart
								/>
							</div>
							<Utility.VERTSPACERXL />
							{!isDriftStaking && (
								<>
									<div>
										<CheckboxInput
											label="I understand that projected APR is based only on the last hour of revenue pool earnings and is not guaranteed."
											checked={hasAcceptedProjectedApr}
											onChange={handleHasAcceptedProjectedApr}
											secondaryStyle
											alignStart
										/>
									</div>
									<Utility.VERTSPACERXL />
								</>
							)}
							<div>
								<CheckboxInput
									label={<>I have read the Insurance Fund Staking</>}
									linkElement={
										<a
											href="https://docs.drift.trade/insurance-fund/insurance-fund-staking"
											target="_blank"
											rel="noreferrer"
											className="ml-1"
										>
											docs page
										</a>
									}
									checked={hasReadDocsPage}
									onChange={handleHasReadDocsPage}
									secondaryStyle
									alignStart
								/>
							</div>
						</>
					)}
					<Utility.VERTSPACERXL />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />
					{stakingFromSubaccount ? (
						<ValueDisplay.ValueChange
							className="h-6"
							label={`Account ${selectedIfMarket.symbol} Balance`}
							previousValue={targetCollatBalances.netBaseBalance}
							afterValue={targetCollatBalances.netBaseBalance.sub(
								collatTransferAmountBignum
							)}
							previousValuePrint={`${targetCollatBalances.netBaseBalance.toFixed(
								displayPrecision
							)} ${selectedIfMarket.symbol}`}
							afterValuePrint={`${`${targetCollatBalances.netBaseBalance
								.sub(collatTransferAmountBignum)
								.toFixed(displayPrecision)}`} ${selectedIfMarket.symbol}`}
						/>
					) : (
						<ValueDisplay.ValueChange
							className="h-6"
							label={`Wallet ${selectedIfMarket.symbol} Balance`}
							previousValue={currentCollatBalanceBigNum}
							afterValue={currentCollatBalanceBigNum.sub(
								collatTransferAmountBignum
							)}
							previousValuePrint={`${currentCollatBalanceBigNum.toFixed(
								displayPrecision
							)}`}
							afterValuePrint={`${currentCollatBalanceBigNum
								.sub(collatTransferAmountBignum)
								.toFixed(displayPrecision)} ${selectedIfMarket.symbol}`}
						/>
					)}

					{selectedIfMarket.marketIndex ===
						DRIFT_TOKEN_SPOT_MARKET.marketIndex &&
						fuelEarnAmount.gtZero() && (
							<>
								<ValueDisplay.Default
									label="FUEL earn"
									className={'h-6'}
									value={
										<span className="flex flex-row items-center gap-1">
											<img
												src="/assets/images/fuel/fuel-droplet.svg"
												alt="Fuel droplet"
												className="w-5 h-5"
											/>
											{!oldFuelEarnAmount.eq(fuelEarnAmount) && (
												<>
													<Text.BODY3>
														{oldFuelEarnAmount.toMillified()}
													</Text.BODY3>
													<Arrow />
												</>
											)}
											<Text.BODY3>
												{fuelEarnAmount.toMillified()} FUEL for every 28 days
												staked
											</Text.BODY3>
										</span>
									}
								/>
							</>
						)}

					<ValueDisplay.ValueChange
						className="h-6"
						label={`Staked ${selectedIfMarket.symbol} Balance`}
						previousValue={stakeAccountBalance}
						afterValue={
							collatTransferAmountBignum.toNum() + stakeAccountBalance
						}
						previousValuePrint={stakeAccountBalance.toFixed(2)}
						afterValuePrint={(
							collatTransferAmountBignum.toNum() + stakeAccountBalance
						).toFixed(2)}
					/>

					<Utility.VERTSPACERXL />

					<Modal.Confirm
						primaryDisabled={!allowConfirmation || stakeDisabled}
						onConfirm={handleConfirmStake}
						customLabel={stakeDisabled ? 'Staking Disabled' : 'Confirm Stake'}
					/>

					<Utility.VERTSPACERS />
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(StakeModalContent);
