import { useCallback, useState, useMemo } from 'react';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import { useVaultsStore } from 'src/stores/vaultsStore/useVaultsStore';
import Button from 'src/components/Button';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import Drawer from 'src/components/Drawer';
import MarketIcon from 'src/components/Utils/MarketIcon';

const VaultDisplayFiltersDrawer = () => {
	const handleCloseDrawer = useDrawersStore((s) => s.handleCloseDrawer);
	const {
		getVaultDisplaySettings,
		setVaultDisplaySettings,
		getVaultsStatsList,
	} = useVaultsStore();
	const currentSettings = getVaultDisplaySettings();
	const vaultsStatsList = getVaultsStatsList();

	// Local state to track changes before applying
	const [settings, setSettings] = useState<{
		depositedOnly: boolean;
		targetVaultManager: string;
		targetDepositAsset: string; // Allow both string and number
		targetApyPeriod: string;
		sortedBy: string;
	}>(currentSettings);

	// Get unique vault managers from the vaults list
	const vaultManagerOptions = useMemo(() => {
		const uniqueVaultManagers = vaultsStatsList
			.map((vaultStat) => vaultStat.uiVaultConfig.vaultManager)
			.filter(
				(manager, index, self) =>
					self.findIndex((m) => m.name === manager.name) === index
			);

		return [
			{ value: 'All', label: 'All Traders' },
			...uniqueVaultManagers.map((manager) => ({
				value: manager.name,
				label: manager.name,
			})),
		];
	}, [vaultsStatsList]);

	// Get deposit asset options
	const depositAssetOptions = useMemo(
		() => [
			{ value: 'All', label: 'All Assets' },
			...Array.from(
				new Set(
					vaultsStatsList.map(
						(vaultStats) => vaultStats.uiVaultConfig.depositAsset
					)
				)
			).map((asset) => ({
				value: String(SPOT_MARKETS_LOOKUP[asset].marketIndex),
				label: SPOT_MARKETS_LOOKUP[asset].symbol,
			})),
		],
		[vaultsStatsList]
	);

	const apyOptions = [
		{ value: '7d', label: '7D' },
		{ value: '30d', label: '30D' },
		{ value: '90d', label: '90D' },
	];

	const sortOptions = [
		{ value: 'apy', label: 'APY' },
		{ value: 'tvl', label: 'TVL' },
	];

	const handleApply = useCallback(() => {
		setVaultDisplaySettings(settings);
		handleCloseDrawer('vaultDisplayFilters');
	}, [settings, handleCloseDrawer, setVaultDisplaySettings]);

	const handleCancel = useCallback(() => {
		setSettings(currentSettings); // Reset to current settings
		handleCloseDrawer('vaultDisplayFilters');
	}, [currentSettings, handleCloseDrawer]);

	return (
		<Drawer
			drawerName="vaultDisplayFilters"
			className="w-full sm:max-w-[400px] sm:w-[400px]"
		>
			<Drawer.Header
				titleContent={'Filters'}
				className="py-4"
				onClose={handleCancel}
			/>
			<div className="flex flex-col h-full">
				<div className="flex flex-col gap-6 p-4 flex-1">
					<div className="flex flex-col gap-4">
						<label className="text-sm text-text-label">Trader</label>
						<div className="flex flex-wrap gap-2">
							{vaultManagerOptions.map((option) => (
								<Button.BigInput
									key={option.value}
									className="w-auto text-center mb-0 text-text-emphasis"
									highlighted={settings.targetVaultManager === option.value}
									onClick={() =>
										setSettings((prev) => ({
											...prev,
											targetVaultManager: option.value,
										}))
									}
								>
									{option.label}
								</Button.BigInput>
							))}
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<label className="text-sm text-text-label">Deposit assets</label>
						<div className="flex flex-wrap gap-2">
							{depositAssetOptions.map((option) => (
								<Button.BigInput
									key={option.value}
									className="w-auto text-center mb-0 text-text-emphasis"
									highlighted={settings.targetDepositAsset === option.value}
									onClick={() =>
										setSettings((prev) => ({
											...prev,
											targetDepositAsset: option.value,
										}))
									}
								>
									{option.value !== 'All' && (
										<MarketIcon className="mr-1" marketSymbol={option.label} />
									)}
									{option.label}
								</Button.BigInput>
							))}
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<label className="text-sm text-text-label">APY</label>
						<div className="flex gap-2">
							{apyOptions.map((option) => (
								<Button.BigInput
									key={option.value}
									className="w-auto text-center mb-0 text-text-emphasis"
									highlighted={settings.targetApyPeriod === option.value}
									onClick={() =>
										setSettings((prev) => ({
											...prev,
											targetApyPeriod: option.value,
										}))
									}
								>
									{option.label}
								</Button.BigInput>
							))}
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<label className="text-sm text-text-label">Sort by</label>
						<div className="flex gap-2">
							{sortOptions.map((option) => (
								<Button.BigInput
									key={option.value}
									className="w-auto text-center mb-0 text-text-emphasis"
									highlighted={settings.sortedBy === option.value}
									onClick={() =>
										setSettings((prev) => ({
											...prev,
											sortedBy: option.value,
										}))
									}
								>
									{option.label}
								</Button.BigInput>
							))}
						</div>
					</div>

					<div className="flex flex-col gap-4">
						<label className="text-sm text-text-label">Vault display</label>
						<div className="flex gap-2">
							<Button.BigInput
								highlighted={!settings.depositedOnly}
								className="w-auto text-center mb-0 text-text-emphasis"
								onClick={() =>
									setSettings((prev) => ({
										...prev,
										depositedOnly: false,
									}))
								}
							>
								All
							</Button.BigInput>
							<Button.BigInput
								highlighted={settings.depositedOnly}
								className="w-auto text-center mb-0 text-text-emphasis"
								onClick={() =>
									setSettings((prev) => ({
										...prev,
										depositedOnly: true,
									}))
								}
							>
								Deposited only
							</Button.BigInput>
						</div>
					</div>
				</div>

				<div className="p-4 mt-auto">
					<Button.Secondary
						size="LARGE"
						className="w-full"
						onClick={handleApply}
					>
						Apply
					</Button.Secondary>
				</div>
			</div>
		</Drawer>
	);
};

export default VaultDisplayFiltersDrawer;
