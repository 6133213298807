'use client';

import { BN, BigNum, QUOTE_PRECISION_EXP, ZERO } from '@drift-labs/sdk';
import { COMMON_UI_UTILS } from '@drift/common';
import produce from 'immer';
import { WritableDraft } from 'immer/dist/internal';
import React, {
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
	useRef,
} from 'react';
import { useImmer } from 'use-immer';
import { dlog } from '../../dev';
import useAppEventEmitter from '../../hooks/useAppEventEmitter';
import UI_UTILS from '../../utils/uiUtils';
import { MarketOrderToastEventContext } from './MarketOrderToastEventEmitterProvider';
import {
	MarketOrderStateKey,
	MarketOrderToastStateMachine,
} from './MarketOrderToastStateMachine';
import {
	MarketOrderToastOrderInfo,
	MarketOrderToastStageState,
	MarketOrderToastState,
	MarketOrderToastStateTransitionEvent,
} from './MarketOrderToastStateTypes';
import { MarketOrderToastId } from './MarketOrderToastStateTypes';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import { DriftWindow } from '../../window/driftWindow';
import {
	DEFAULT_COMMITMENT_LEVEL,
	TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL,
} from 'src/constants/constants';
import { OrderRetryButton } from '../Toasts/MarketOrders/OrderRetryButton';

type MarketOrderToastContextState = Record<
	MarketOrderToastId,
	MarketOrderToastState
>;

const DEFAULT_CONTEXT_STATE: MarketOrderToastContextState = {} as Record<
	MarketOrderToastId,
	MarketOrderToastState
>;
const END_STATE_DISPLAY_TIME = 8_000; // How many ms to continue to display a toast for once it has reached its end state

export const MarketOrderStateHandlerContext =
	React.createContext<MarketOrderToastContextState>(DEFAULT_CONTEXT_STATE);

const getInitialToastState = (
	orderDetails: MarketOrderToastOrderInfo
): MarketOrderToastState => {
	const state: MarketOrderToastState = {
		headlineStage: 'placing',
		toastStages: {
			transactionConfirmation: {
				type: 'in_progress',
				message:
					orderDetails.customSignMessage ??
					'Sign the transaction in your wallet',
			},
			jitAuction: {
				type: 'none',
				message: '',
			},
			orderCompletion: {
				type: 'none',
				message: '',
			},
		},
		orderInfo: orderDetails,
		dev_data: [],
	};

	return state;
};

const CONTENT_CONSTANTS = {
	FIRST_STAGE_COMPLETE_MESSAGE: 'Order Submitted',
	DELAY_FOOTER_MESSAGE: 'This is taking longer than usual. Please wait.',
	MESSAGE_SIGNING_EXPIRED_MESSAGE: 'Message Expired for Signing',
};

const calculateNewToastState = (
	currentToastState: MarketOrderToastState,
	previousState: MarketOrderToastStateMachine['currentState'],
	currentStateMachine: MarketOrderToastStateMachine,
	event: MarketOrderToastStateTransitionEvent
): MarketOrderToastState => {
	dlog(
		'v2_auctions',
		`calculateNewToastState currentState: ${currentStateMachine.currentState} event:\n`,
		event
	);
	/**
	 * Adds redundancy to the startTs if we miss the OrderRecord event which actually contains the startTs. The idea is that we can fallback to using the first known clock ts, once the order is confirmed, as the startTs .. any following OrderRecord events will overwrite this value
	 */
	const setStartTsRedundancyValue = (
		draft: WritableDraft<MarketOrderToastState>
	) => {
		if (
			!currentToastState.orderInfo.startTs &&
			currentToastState.toastStages?.transactionConfirmation?.type === 'success'
		) {
			draft.orderInfo.startTs = new BN(
				DriftWindow.chainClock.getState(DEFAULT_COMMITMENT_LEVEL).ts
			);
		}
	};

	/**
	 * This should handle when any state changes jumped to a state where the first stage is complete but the status may not have been updated incrementally to handle it
	 *
	 * This logic assumes that the first stage is always about confirming the transaction
	 */
	const handleFirstStageIsImplicitlyCompleteAndUnhandled = (
		draft: WritableDraft<MarketOrderToastState>
	) => {
		const FIRST_STAGE_IMPLICITLY_COMPLETE_STATES: MarketOrderToastStateMachine['currentState'][] =
			[
				'AUCTION_IN_PROGRESS',
				'AWAITING_AUCTION',
				'CANCELLED',
				'EXPIRED',
				'COMPLETING_ORDER',
				'END_STATE',
				'WARNING_LOST_SYNC_NO_ORDER_RECORD',
			];

		const orderExists = !!currentToastState.orderInfo.orderId;
		const fallbackConfirmationMessage =
			event.type === 'auction_finished'
				? 'Auction Finished.'
				: 'Order Submitted.';
		const confirmationMessage = orderExists
			? CONTENT_CONSTANTS.FIRST_STAGE_COMPLETE_MESSAGE
			: fallbackConfirmationMessage;

		if (
			currentToastState?.toastStages?.transactionConfirmation?.type !==
				'success' &&
			FIRST_STAGE_IMPLICITLY_COMPLETE_STATES.includes(
				currentStateMachine.currentState
			)
		) {
			draft.toastStages.transactionConfirmation.type = 'success';

			const includesTpOrder = draft.orderInfo.includesTpOrder;
			const includesSlOrder = draft.orderInfo.includesSlOrder;
			if (includesTpOrder && includesSlOrder) {
				draft.toastStages.transactionConfirmation.message =
					confirmationMessage + (orderExists ? ' TP and SL placed.' : '');
			} else if (includesSlOrder) {
				draft.toastStages.transactionConfirmation.message =
					confirmationMessage + (orderExists ? ' SL placed.' : '');
			} else if (includesTpOrder) {
				draft.toastStages.transactionConfirmation.message =
					confirmationMessage + (orderExists ? ' TP placed.' : '');
			} else {
				draft.toastStages.transactionConfirmation.message = confirmationMessage;
			}
			draft.toastStages.transactionConfirmation.extraMessage = undefined;
		} else {
			draft.toastStages.transactionConfirmation.message = confirmationMessage;
		}
	};

	const handleMiscStateChanges = (
		draft: WritableDraft<MarketOrderToastState>
	) => {
		if (
			event.type === 'receive_order_record' ||
			event.type === 'order_state_read'
		) {
			draft.orderInfo.startTs = event.data.startTs;
		}

		draft.orderInfo.orderId = event?.data?.orderId;
		draft.orderInfo.baseAmountOrdered = event?.data?.baseAmountOrdered;
		draft.orderInfo.cumulativeQuoteAmountFilled =
			event?.data?.cumulativeQuoteAmountFilled.gt(
				draft.orderInfo.cumulativeQuoteAmountFilled
			)
				? event?.data?.cumulativeQuoteAmountFilled
				: draft.orderInfo.cumulativeQuoteAmountFilled;
		draft.orderInfo.cumulativeBaseAmountFilled =
			event?.data?.cumulativeBaseAmountFilled.gt(
				draft.orderInfo.cumulativeBaseAmountFilled
			)
				? event?.data?.cumulativeBaseAmountFilled
				: draft.orderInfo.cumulativeBaseAmountFilled;
		draft.orderInfo.auctionDuration = event?.data?.auctionDuration;
		draft.orderInfo.auctionStartSlot = event?.data?.auctionStartSlot;
		draft.orderInfo.auctionMaxPrice = event?.data?.auctionMaxPrice;

		if (event.data.transactionExpiryBlockHeight) {
			draft.orderInfo.transactionExpiryBlockHeight =
				event.data.transactionExpiryBlockHeight;
		}

		const avgFillPrice =
			draft.orderInfo.cumulativeQuoteAmountFilled &&
			draft.orderInfo.cumulativeBaseAmountFilled
				? draft.orderInfo.cumulativeQuoteAmountFilled.eq(ZERO)
					? '-'
					: COMMON_UI_UTILS.calculateAverageEntryPrice(
							BigNum.from(
								draft.orderInfo.cumulativeQuoteAmountFilled,
								QUOTE_PRECISION_EXP
							),
							BigNum.from(
								draft.orderInfo.cumulativeBaseAmountFilled,
								UI_UTILS.getMarketPrecision(draft.orderInfo.marketId)
							)
					  ).toNotional(true)
				: undefined;
		draft.orderInfo.avgFillPrice = avgFillPrice
			? `Avg. fill price: ${avgFillPrice}`
			: undefined;

		const pctFilled =
			event.data.baseAmountOrdered &&
			!event.data.baseAmountOrdered.eq(ZERO) &&
			draft.orderInfo.cumulativeBaseAmountFilled
				? draft.orderInfo.cumulativeBaseAmountFilled
						.muln(100)
						.div(event.data.baseAmountOrdered)
				: undefined;
		draft.orderInfo.pctFilled = pctFilled
			? `${pctFilled.toString()}% filled`
			: undefined;

		draft.dev_data = [...currentToastState.dev_data, `${event.type}`];
	};

	const handleImplicitStateChanges = (
		draft: WritableDraft<MarketOrderToastState>
	) => {
		handleFirstStageIsImplicitlyCompleteAndUnhandled(draft);
		handleMiscStateChanges(draft);
		setStartTsRedundancyValue(draft);
	};

	switch (currentStateMachine.currentState) {
		case 'PLACING_ORDER_WAITING_SIGNATURE': {
			const expectedCurrentStage: MarketOrderToastStageState = {
				type: 'in_progress',
				message: 'Sign the transaction in your wallet',
			};

			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.toastStages.transactionConfirmation = expectedCurrentStage;
			});
		}
		case 'PLACING_ORDER_WAITING_MESSAGE_SIGNATURE': {
			const expectedCurrentStage: MarketOrderToastStageState = {
				type: 'in_progress',
				message: 'Sign the message in your wallet',
			};

			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.toastStages.transactionConfirmation = expectedCurrentStage;
			});
		}
		case 'PLACING_ORDER_WAITING_CONFIRMATION': {
			const expectedCurrentStage: MarketOrderToastStageState = {
				type: 'in_progress',
				message: 'Awaiting on-chain confirmation',
			};

			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.toastStages.transactionConfirmation = expectedCurrentStage;
			});
		}
		case 'SIGNED_MESSAGE_ORDER_PLACED': {
			const expectedCurrentStage: MarketOrderToastStageState = {
				type: 'in_progress',
				message: 'Placing order',
			};

			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);
				draft.toastStages.transactionConfirmation = expectedCurrentStage;
			});
		}
		case 'AWAITING_AUCTION':
		case 'AUCTION_IN_PROGRESS': {
			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.headlineStage = 'filling';

				if (currentToastState.orderInfo.auctionEnabled) {
					draft.toastStages.jitAuction.type = 'in_progress';
					draft.toastStages.jitAuction.message = 'Just-In-Time Auction';
					// draft.toastStages.two.extraMessage = currentToastState?.orderInfo
					// 	?.auctionMaxPrice
					// 	? getMaxAuctionPriceMessage()
					// 	: undefined;
					draft.toastStages.jitAuction.extraMessage = undefined; // TODO : Commented out the above because getting the max/min auction prices based on oracle prices here seems sus. I'm either misunderstanding it, or the price could move around as the oracle shifts - and hence be confusing for the user to see? I'm thinking better not to display this data if this is the case.

					draft.toastStages.jitAuction.isJitAuction = true;
				}

				draft.smallFooterText = undefined;
			});
		}
		case 'COMPLETING_ORDER': {
			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				const orderExists = !!currentToastState.orderInfo.orderId;

				if (currentToastState.orderInfo.auctionEnabled) {
					draft.toastStages.jitAuction.type = 'success';
					draft.toastStages.jitAuction.message = 'JIT Auction Finished';
					draft.toastStages.jitAuction.extraMessage = undefined;
				}

				draft.smallFooterText = undefined;

				if (
					event.data.cumulativeBaseAmountFilled.eq(
						currentToastState.orderInfo.baseAmountOrdered
					)
				) {
					draft.toastStages.orderCompletion.type = 'success';
					draft.toastStages.orderCompletion.message = 'Order Complete.';
					draft.toastStages.orderCompletion.extraMessage = undefined;
				} else {
					draft.toastStages.orderCompletion.type = 'in_progress';
					draft.toastStages.orderCompletion.message = orderExists
						? 'Completing order.'
						: 'Completing ';
					draft.toastStages.orderCompletion.extraMessage =
						'Filling against resting liquidity';
				}
			});
		}
		case 'CANCELLED': {
			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.headlineStage = 'cancelled';

				const wasPreviouslyFilled =
					currentToastState.orderInfo.cumulativeBaseAmountFilled.eq(
						currentToastState.orderInfo.baseAmountOrdered
					);

				const isPartiallyFilled =
					event.data.cumulativeBaseAmountFilled.gt(ZERO) &&
					event.data.cumulativeBaseAmountFilled.lt(
						event.data.baseAmountOrdered
					) &&
					!wasPreviouslyFilled;

				if (isPartiallyFilled) {
					draft.toastStages.orderCompletion.type = 'warning';
					draft.toastStages.orderCompletion.message = 'Order Cancelled';
					draft.toastStages.orderCompletion.extraMessage = undefined;

					draft.smallFooterText = undefined;
					draft.bigFooterText = undefined;
				} else {
					draft.toastStages.orderCompletion.type = 'warning';
					draft.toastStages.orderCompletion.message = 'Order Cancelled';
					draft.toastStages.orderCompletion.extraMessage =
						'Cancelled without any fills.';

					draft.smallFooterText = undefined;
					draft.bigFooterText = undefined;
				}
			});
		}
		case 'EXPIRED': {
			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.headlineStage = 'expired';
				const orderExists = !!event.data.orderId;

				if (
					!orderExists &&
					currentToastState.orderInfo.orderRoute === 'signedMsg'
				) {
					// hide this step because it will say something about "Order Pending" or "Order Placed" which doesn't make sense
					draft.toastStages.transactionConfirmation.type = 'none';
				}

				const wasPreviouslyFilled =
					currentToastState.orderInfo.cumulativeBaseAmountFilled.eq(
						currentToastState.orderInfo.baseAmountOrdered
					);

				const isPartiallyFilled =
					event.data.cumulativeBaseAmountFilled.gt(ZERO) &&
					event.data.cumulativeBaseAmountFilled.lt(
						event.data.baseAmountOrdered
					) &&
					!wasPreviouslyFilled;

				if (
					event.type === 'signed_msg_order_message_slot_expired' ||
					currentToastState.toastStages.transactionConfirmation.message ===
						CONTENT_CONSTANTS.MESSAGE_SIGNING_EXPIRED_MESSAGE
				) {
					draft.toastStages.transactionConfirmation.type = 'error';
					draft.toastStages.transactionConfirmation.message =
						CONTENT_CONSTANTS.MESSAGE_SIGNING_EXPIRED_MESSAGE;
					draft.smallFooterText = undefined;
					return;
				}

				if (currentToastState.orderInfo.auctionEnabled) {
					draft.toastStages.jitAuction.type = 'success';
					draft.toastStages.jitAuction.message = 'JIT Auction Finished';
					draft.toastStages.jitAuction.extraMessage = undefined;
				}

				if (isPartiallyFilled) {
					draft.toastStages.orderCompletion.type = 'warning';
					draft.toastStages.orderCompletion.message = 'Order Partially Filled';
					draft.toastStages.orderCompletion.extraMessage = undefined;

					draft.smallFooterText = undefined;
					// draft.bigFooterText = _getFillSummaryMessage();
				} else {
					if (currentToastState.orderInfo.orderRoute === 'signedMsg') {
						draft.toastActionContent = (
							<div className="flex justify-start">
								<OrderRetryButton />
							</div>
						);
					}
					draft.toastStages.orderCompletion.type = 'error';
					draft.toastStages.orderCompletion.message = 'Order Expired';
					draft.toastStages.orderCompletion.extraMessage =
						'Try increasing your slippage tolerance, reducing your order size, or placing a limit order.';
					draft.smallFooterText = undefined;
				}
			});
		}
		case 'END_STATE': {
			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.headlineStage = 'completed';

				/**
				 * EXAMPLE DISPLAY:
				 *
				 * [zero-filled]
				 * - Order Confirmed
				 * - Filled 0 SOL-PERP
				 *
				 * [partially-filled]
				 * - Order Confirmed
				 * - Order Partially Filled
				 * - Filled 0.5 / 1 SOL-PERP with ...
				 *
				 * [fully-filled]
				 * - Order Confirmed
				 * - Order Filled
				 * - Filled 1 / 1 SOL-PERP with ...
				 */

				const isZeroFilled = event.data.cumulativeBaseAmountFilled.eq(ZERO);

				if (isZeroFilled) {
					dlog(
						`v2_auctions`,
						`HIT_END_STATE_ZERO_FILLED::Consider making this just wait until expiration or cancellation`
					);

					if (currentToastState.orderInfo.auctionEnabled) {
						draft.toastStages.jitAuction.type = 'warning';
						// draft.toastStages.jitAuction.message = getFillSummaryMessage();
						draft.toastStages.jitAuction.extraMessage = undefined;
					}
					return;
				}

				const wasPreviouslyFilled =
					currentToastState.orderInfo.cumulativeBaseAmountFilled.eq(
						currentToastState.orderInfo.baseAmountOrdered
					);

				const isPartiallyFilled =
					event.data.cumulativeBaseAmountFilled.gt(ZERO) &&
					event.data.cumulativeBaseAmountFilled.lt(
						event.data.baseAmountOrdered
					) &&
					!wasPreviouslyFilled;

				if (currentToastState.orderInfo.auctionEnabled) {
					draft.toastStages.jitAuction.type = 'success';
					draft.toastStages.jitAuction.message = 'JIT Auction Finished';
					draft.toastStages.jitAuction.extraMessage = undefined;
				}

				draft.toastStages.orderCompletion.type = isPartiallyFilled
					? 'warning'
					: 'success';
				draft.toastStages.orderCompletion.message = isPartiallyFilled
					? 'Order Partially Filled'
					: 'Order Filled';
				draft.toastStages.orderCompletion.extraMessage = undefined;

				draft.smallFooterText = undefined;
			});
		}
		case 'BAIL_LOST_SYNC': {
			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				draft.headlineStage = 'error';

				if (
					draft.toastStages.transactionConfirmation.type === 'in_progress' ||
					draft.toastStages.transactionConfirmation.type ===
						'warning_in_progress'
				) {
					draft.toastStages.transactionConfirmation.type = 'neutral';
				}

				draft.toastStages.orderCompletion.type = 'error';
				draft.toastStages.orderCompletion.message = `Lost sync with market order.`;
				draft.toastStages.orderCompletion.extraMessage = `Please check your open orders and positions to confirm the status of this order.`;

				draft.smallFooterText = undefined;
			});
		}
		case 'BAIL_TX_ERROR': {
			switch (previousState) {
				case 'PLACING_ORDER_WAITING_SIGNATURE': {
					return produce(currentToastState, (draft) => {
						handleImplicitStateChanges(draft);

						draft.headlineStage = 'error';

						draft.toastStages.transactionConfirmation.type = 'error';
						draft.toastStages.transactionConfirmation.message = `Failed to sign the transaction.`;
						draft.smallFooterText = undefined;
					});
				}
				case 'PLACING_ORDER_WAITING_MESSAGE_SIGNATURE': {
					return produce(currentToastState, (draft) => {
						handleImplicitStateChanges(draft);

						draft.headlineStage = 'error';

						draft.toastStages.transactionConfirmation.type = 'error';
						draft.toastStages.transactionConfirmation.message = `Failed to sign the message.`;
						draft.smallFooterText = undefined;
					});
				}
				case 'PLACING_ORDER_WAITING_CONFIRMATION': {
					return produce(currentToastState, (draft) => {
						handleImplicitStateChanges(draft);

						draft.headlineStage = 'error';

						draft.toastStages.transactionConfirmation.type = 'error';
						draft.toastStages.transactionConfirmation.message = `Failed to confirm the transaction.`;
						draft.smallFooterText = undefined;
					});
				}
				case 'SIGNED_MESSAGE_ORDER_PLACED': {
					return produce(currentToastState, (draft) => {
						handleImplicitStateChanges(draft);

						draft.headlineStage = 'error';

						draft.toastStages.transactionConfirmation.type = 'error';
						draft.toastStages.transactionConfirmation.message =
							event.data.failureMessage ?? 'Order failed.';
						draft.smallFooterText = undefined;
					});
				}
				default: {
					return produce(currentToastState, (draft) => {
						draft.headlineStage = 'error';
					});
				}
			}
		}
		case 'WARNING_LOST_SYNC_NO_ORDER_RECORD': {
			return produce(currentToastState, (draft) => {
				handleImplicitStateChanges(draft);

				if (currentToastState.orderInfo.auctionEnabled) {
					draft.toastStages.jitAuction.type = 'warning_in_progress';
					draft.toastStages.jitAuction.message = 'Finding the best price.';
					// draft.toastStages.jitAuction.extraMessage =
					// 	getFilledProgressMessage();
				}

				draft.smallFooterText = CONTENT_CONSTANTS.DELAY_FOOTER_MESSAGE;
			});
		}
		default: {
			const exhaustiveCheck: never = currentStateMachine.currentState;
			throw new Error(
				`Unhandled state in getNewToastState: ${exhaustiveCheck}`
			);
		}
	}
};

const BLOCK_HEIGHT_POLL_INTERVAL = 1000;

const useSubscribeToBlockHeight = (enabled: boolean) => {
	const connection = useDriftStore((s) => s.connection.current);

	useEffect(() => {
		if (!enabled || !connection) {
			return;
		}

		const handler = () => {
			connection
				.getBlockHeight(TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL)
				.then((blockHeight) => {
					DriftWindow.chainClock.update({
						commitment: TX_CONFIRMATION_EXPIRY_COMMITMENT_LEVEL,
						blockHeight,
					});
				});
		};

		handler(); // Run the first time immediately
		const interval = setInterval(handler, BLOCK_HEIGHT_POLL_INTERVAL);

		return () => clearInterval(interval);
	}, [enabled, connection]);
};

export const MarketOrderToastStateHandlerProvider = (
	props: PropsWithChildren<any>
) => {
	const alreadyHandledEndStateRef = useRef(new Set<MarketOrderToastId>());
	const marketOrderEventContext = useContext(MarketOrderToastEventContext);
	const marketOrderEventObservable = useMemo(
		() => marketOrderEventContext.subject.asObservable(),
		[marketOrderEventContext]
	);

	const appEventEmitter = useAppEventEmitter();

	// Initializing the ref with an initial state of type State
	const [orderToastState, setOrderToastState] = useImmer(DEFAULT_CONTEXT_STATE);

	const anyActiveToasts = useMemo(() => {
		return Object.values(orderToastState).length > 0;
	}, [orderToastState]);

	useSubscribeToBlockHeight(anyActiveToasts);

	const clearToastFromState = (orderId: MarketOrderToastId) => {
		appEventEmitter.emit('marketOrderToastRemoved', orderId);
		dlog(`v2_auctions`, `deleting_toast_state : ${orderId}`);
		setOrderToastState((draft: MarketOrderToastContextState) => {
			//@ts-ignore TODO: maybe have a different way to clear toasts than direct state mutability
			delete draft[orderId];
		});
	};

	const stateMachineLookup = useRef<
		Map<MarketOrderToastId, MarketOrderToastStateMachine>
	>(new Map());

	const initializeAndAddStateMachineForOrder = (
		orderId: MarketOrderToastId,
		initialState: MarketOrderStateKey
	) => {
		const stateMachine = new MarketOrderToastStateMachine(initialState);
		stateMachineLookup.current.set(orderId, stateMachine);
		return stateMachine;
	};

	const handleOrderStateReadEvent = (
		event: MarketOrderToastStateTransitionEvent
	) => {
		setOrderToastState((draft) => {
			const toastId = event.toastId;
			//@ts-ignore TODO: maybe have a different way to clear toasts than direct state mutability
			if (!draft[toastId]) {
				return;
			} else {
				//@ts-ignore
				draft[toastId].orderInfo = {
					//@ts-ignore
					...draft[toastId].orderInfo,
					...event.data,
				};
			}
		});
		handleTransitionEvent(event);
	};

	/**
	 * This logic handles cleaning up the state when we know that the toast has entered an ending state and we wouldn't expect any more meaningful updates to come through
	 */
	const handleOrderEnteringEndState = (orderId: MarketOrderToastId) => {
		if (alreadyHandledEndStateRef.current.has(orderId)) {
			dlog(`v2_auctions`, `already_handled_end_state : ${orderId}`);
			return;
		}

		dlog(`v2_auctions`, `order_entering_end_state : ${orderId}`);

		setTimeout(() => {
			dlog(`v2_auctions`, `timeout_removing_end_state_order : ${orderId}`);
			clearToastFromState(orderId);
		}, END_STATE_DISPLAY_TIME);

		// This ensures that we don't try to self-fill or hang on to the order state in other ways. One reason this is important is because if an order fails the next one might try to use the same ID, so we want to clear any state that would be using the same key when the order is done
		appEventEmitter.emit('clearMarketOrder', orderId);
	};

	const handleInitializationEvent = (
		event: MarketOrderToastStateTransitionEvent
	) => {
		const toastId = event.toastId;

		const initialState: MarketOrderStateKey =
			event.data.orderRoute === 'signedMsg'
				? 'PLACING_ORDER_WAITING_MESSAGE_SIGNATURE'
				: 'PLACING_ORDER_WAITING_SIGNATURE';
		initializeAndAddStateMachineForOrder(toastId, initialState);
		const initialToastState = getInitialToastState(
			event.data as MarketOrderToastOrderInfo
		);
		dlog(`v2_auctions`, `initializing_toast_state : ${toastId}`);
		setOrderToastState((draft) => {
			//@ts-ignore
			draft[toastId] = { ...initialToastState };
		});
	};

	const handleUnexpectedEvent = (
		event: MarketOrderToastStateTransitionEvent
	) => {
		const toastId = event.toastId;
		dlog(`v2_auctions`, `no_toast_state_for_order : ${toastId}`);
	};

	const handleTransitionEvent = (
		event: MarketOrderToastStateTransitionEvent
	) => {
		const toastId = event.toastId;

		const stateMachine = stateMachineLookup.current.get(toastId);

		if (!stateMachine) {
			handleUnexpectedEvent(event);
			return;
		}

		const stateBeforeTransition = stateMachine.currentState;

		stateMachine.transition(event);

		setOrderToastState((orderToastStateDraft) => {
			//@ts-ignore
			const toastStateForOrder = orderToastStateDraft[toastId];

			if (!toastStateForOrder) {
				dlog(`v2_auctions`, `caught_no_toast_state_when_handling_transition`);
				return;
			}

			const newToastStateForOrder = calculateNewToastState(
				toastStateForOrder,
				stateBeforeTransition,
				stateMachine,
				event
			);

			dlog(`v2_auctions`, `updating_toast_state`, {
				stateBeforeTransition,
				eventType: event.type,
				stateAfterTransition: stateMachine.currentState,
				newToastStateForOrder,
				event,
			});

			//@ts-ignore
			orderToastStateDraft[toastId] = { ...newToastStateForOrder };
		});

		const END_STATES: MarketOrderToastStateMachine['currentState'][] = [
			'END_STATE',
			'CANCELLED',
			'EXPIRED',
			'BAIL_LOST_SYNC',
			'BAIL_TX_ERROR',
		];

		if (END_STATES.includes(stateMachine.currentState)) {
			dlog(
				`v2_auctions`,
				`caught_should_enter_end_state (${stateMachine.currentState}) : ${toastId}`
			);
			handleOrderEnteringEndState(toastId);
		}
	};

	// TODO: consider if we should use this function, or maybe augment the order schema with the orderRoute so we don't have to do this
	const getOrderRouteByEventTypeAndPreviousState = (
		event: MarketOrderToastStateTransitionEvent,
		previousState: MarketOrderToastStateMachine['currentState']
	) => {
		switch (event.type) {
			case 'signed_msg_order_init':
				return 'signedMsg';
			case 'tx_signed':
				return previousState === 'PLACING_ORDER_WAITING_MESSAGE_SIGNATURE' ||
					previousState === 'SIGNED_MESSAGE_ORDER_PLACED'
					? 'signedMsg'
					: 'jit';
			case 'receive_confirmation_signed_msg_order':
				return 'signedMsg';
			case 'signed_msg_order_sent':
				return 'signedMsg';
			case 'signed_msg_order_expired':
				return 'signedMsg';
			case 'tx_failed':
				return previousState === 'SIGNED_MESSAGE_ORDER_PLACED'
					? 'signedMsg'
					: 'jit';
			case 'auction_finished':
				return previousState === 'SIGNED_MESSAGE_ORDER_PLACED'
					? 'signedMsg'
					: 'jit';
			case 'init':
				return 'jit';
			default:
				return 'jit';
		}
	};

	// # Subscribe to events and handle state transitions
	useEffect(() => {
		marketOrderEventObservable.subscribe((event) => {
			dlog(`v2_auctions`, `received_event (${event.type})`, event);

			const orderRoute = getOrderRouteByEventTypeAndPreviousState(
				event,
				stateMachineLookup.current.get(event.toastId)?.currentState
			);

			dlog('v2_auctions', `orderRoute:${orderRoute}`);

			switch (event.type) {
				case 'order_state_read':
					handleOrderStateReadEvent(event);
					return;

				case 'init':
					handleInitializationEvent({
						...event,
						data: {
							...event.data,
							orderRoute,
						},
					});
					return;

				case 'signed_msg_order_init':
					handleInitializationEvent({
						...event,
						data: {
							...event.data,
							customSignMessage: 'Sign the message in your wallet',
							orderRoute,
						},
					});
					return;

				default:
					break;
			}

			handleTransitionEvent({
				...event,
				data: {
					...event.data,
					orderRoute,
				},
			});
		});
	}, [marketOrderEventObservable]);

	return (
		<MarketOrderStateHandlerContext value={orderToastState}>
			{props.children}
		</MarketOrderStateHandlerContext>
	);
};
