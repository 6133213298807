'use client';

import { useEffect, useRef } from 'react';
import { singletonHook } from 'react-singleton-hook';
import useWalletIsConnected from '../useWalletIsConnected';
import usePostHogCapture from './usePostHogCapture';
import useDriftStore from '../../stores/DriftStore/useDriftStore';
import useCurrentWalletAdapter from '../useCurrentWalletAdapter';
import posthog from 'posthog-js';
import Env from '../../environmentVariables/EnvironmentVariables';

const usePostHogIdentity = () => {
	const connected = useWalletIsConnected();
	const authority = useDriftStore((s) => s.wallet?.currentPublicKeyString);
	const isMagicAuth = useDriftStore((s) => s.wallet?.isMagicAuth);
	const walletAdapter = useCurrentWalletAdapter();
	const walletName = isMagicAuth
		? 'Magic Auth'
		: walletAdapter?.name?.toString();
	const isE2eWallet = walletName === Env.E2E_WALLET.name;

	const wasPreviouslyConnectedRef = useRef(false);

	useEffect(() => {
		if (isE2eWallet) {
			return;
		}

		const isLocalHost = window.location.href.includes('localhost');
		if (isLocalHost) {
			// Skip capturing events during local development
			return;
		}

		if (connected && authority) {
			if (!wasPreviouslyConnectedRef.current) {
				posthog.identify(authority);
			}
			wasPreviouslyConnectedRef.current = true;
		} else {
			if (wasPreviouslyConnectedRef.current) {
				posthog.reset();
			}
			wasPreviouslyConnectedRef.current = false;
		}
	}, [connected, authority, isE2eWallet]);
};

const usePostHogGlobalEvents = (): null => {
	const connected = useWalletIsConnected();
	const wallet = useCurrentWalletAdapter();
	const { captureEvent } = usePostHogCapture();

	// Hook to handle posthog identification
	usePostHogIdentity();

	// Effect to catch wallet connections
	useEffect(() => {
		if (!connected) return;
		if (!wallet?.name) return;

		captureEvent('wallet_connected', {
			name: wallet.name,
		});
	}, [connected, wallet?.name]);

	return null;
};

export default singletonHook(null, usePostHogGlobalEvents);
