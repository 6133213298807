'use client';

import { useEffect } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftClient from './useDriftClient';
import { notify } from 'src/utils/notifications';
import { PERP_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import useCurrentAccountOpenPerpPositions from './useCurrentAccountOpenPerpPositions';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import { UISerializableOrder } from '@drift/common';
import { PerpPosition } from '@drift-labs/sdk';

const EMPTY_OPEN_ORDERS: UISerializableOrder[] = [];

/**
 * Listens to "orderFillComplete" events, and sends out associated closeExistingOrdersTx and/or settlePnlsTx if they exist
 */
const useAutomatedTxnsListener = () => {
	const openOrders =
		useDriftAccountStore((s) => s.accounts[s.currentUserKey]?.openOrders) ||
		EMPTY_OPEN_ORDERS;
	const driftClient = useDriftClient();
	const ordersAwaitingAutoCancel = useDriftStore(
		(s) => s.ordersAwaitingAutoCancel
	);
	const pnlsAwaitingAutoSettle = useDriftStore((s) => s.pnlsAwaitingAutoSettle);
	const openPositions = useCurrentAccountOpenPerpPositions();

	/**
	 * Cancels existing orders for all markets in the market indexes key at once
	 *
	 * For use in conjunction with the "closeMultiplePerpPositions" action
	 *
	 * @param arrIndex - index of tx in ordersAwaitingAutoCancelForMultipleMarkets array to send off anre remove from the array
	 */
	const sendCancelExistingOrdersTx = async ({
		arrIndex,
	}: {
		arrIndex: number;
	}) => {
		const awaitingOrder = ordersAwaitingAutoCancel[arrIndex];
		const toastId =
			'auto-close-tpsl-for-markets-' +
			awaitingOrder.marketIndexesOfOrders.join('-');

		const numMarkets = awaitingOrder.marketIndexesOfOrders.length;

		if (numMarkets < 2) {
			// If there was only one market index specified, we assume this is used from the close single position popup just closing a single position
			const marketIndex = awaitingOrder.marketIndexesOfOrders[0];
			const hasOpenOrdersInMarket =
				openOrders.filter((order) => order.marketIndex === marketIndex).length >
				0;

			if (!hasOpenOrdersInMarket) return;

			const marketName = PERP_MARKETS_LOOKUP[marketIndex].symbol;

			notify({
				id: toastId,
				type: 'awaiting',
				message: `Cancelling open orders for ${marketName}`,
				showUntilCancelled: true,
			});
		} else {
			// Multiple markets together was probably from the user closing all positions at once from the close all positions modal, so we probably don't really need to chceck if there are open orders in every market here
			notify({
				id: toastId,
				type: 'awaiting',
				message: `Cancelling open orders for ${numMarkets} markets`,
				showUntilCancelled: true,
			});
		}

		try {
			await driftClient.sendSignedTx(
				awaitingOrder.signedCancelExistingOrdersTx
			);

			notify({
				id: toastId,
				type: 'success',
				message: `Orders cancelled`,
			});
		} catch (err) {
			notify({
				id: toastId,
				type: 'warning',
				message: `Failed to cancel open orders`,
			});
		}
	};

	/**
	 * Settles pnl for all markets in the market indexes key at once
	 *
	 * @param arrIndex - index of tx in pnlsAwaitingAutoSettleForMultipleMarkets array to send off and remove from the array
	 */
	const sendSettlePnlsTx = async ({ arrIndex }: { arrIndex: number }) => {
		const awaitingSettle = pnlsAwaitingAutoSettle[arrIndex];

		// no notification here, quietly settle pnl
		try {
			console.log('Settling P&L...');
			await driftClient.sendSignedTx(awaitingSettle.signedSettlePnlsTx);
			console.log(
				`Settled P&L succesfully for ${awaitingSettle.marketIndexesOfPnls.join(
					','
				)}`
			);
		} catch (err) {
			console.error('Error settling P&L after close', err);
		}
	};

	const checkRelatedPositionsClosed = (
		positions: Partial<PerpPosition>[],
		marketIndexes: number[]
	) => {
		return !positions.some((pos) => marketIndexes.includes(pos.marketIndex));
	};

	const removeIndexesFromStore = (
		storeKey: 'ordersAwaitingAutoCancel' | 'pnlsAwaitingAutoSettle',
		indexesToRemove: number[]
	) => {
		if (!indexesToRemove || indexesToRemove.length == 0) return;
		setDriftStore((s) => {
			s[storeKey] = s[storeKey].filter(
				(_item, index) => !indexesToRemove.includes(index)
			) as any;
		});
	};

	/* Trigger order cancels if associated positions are gone */
	useEffect(() => {
		if (ordersAwaitingAutoCancel.length > 0) {
			const indexesToRemove: number[] = [];

			ordersAwaitingAutoCancel.forEach((val, arrIndex) => {
				if (
					checkRelatedPositionsClosed(openPositions, val.marketIndexesOfOrders)
				) {
					sendCancelExistingOrdersTx({ arrIndex });
					indexesToRemove.push(arrIndex);
				}
			});

			removeIndexesFromStore('ordersAwaitingAutoCancel', indexesToRemove);
		}
	}, [ordersAwaitingAutoCancel, openPositions?.length]);

	/* Trigger pnl settles if associated positions are gone */
	useEffect(() => {
		if (pnlsAwaitingAutoSettle.length > 0) {
			const indexesToRemove: number[] = [];

			pnlsAwaitingAutoSettle.forEach((val, arrIndex) => {
				if (
					checkRelatedPositionsClosed(openPositions, val.marketIndexesOfPnls)
				) {
					sendSettlePnlsTx({ arrIndex });
					indexesToRemove.push(arrIndex);
				}
			});

			removeIndexesFromStore('pnlsAwaitingAutoSettle', indexesToRemove);
		}
	}, [pnlsAwaitingAutoSettle, openPositions?.length]);

	const setDriftStore = useDriftStore((s) => s.set);
};

export default useAutomatedTxnsListener;
