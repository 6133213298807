'use client';

import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useInterval from './useInterval';
import { MarketDetails24H } from '@drift/common';

const REFRESH_RATE_SECS = 60 * 5;

const use24hMarketDataFromApi = () => {
	const setState = useDriftStore((s) => s.set);

	const pull24hMarketData = async () => {
		const marketData = await ExchangeHistoryClient.get24HourMarketsData();
		if (marketData.success) {
			set24hMarketData(marketData.body.data);
		}
	};

	const set24hMarketData = (newData: MarketDetails24H[]) => {
		setState((s) => {
			s.marketsData24H = newData;
		});
	};

	useInterval(() => {
		pull24hMarketData();
	}, 1000 * REFRESH_RATE_SECS);
};

export default use24hMarketDataFromApi;
