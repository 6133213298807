'use client';

import React from 'react';
import Modal from '../Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { JLP_POOL_ID } from '@drift/common';
import MarketIcon from '../../Utils/MarketIcon';
import { Close } from '@drift-labs/icons';
import Text from '../../Text/Text';
import { POOL_NAMES_BY_POOL_ID } from 'src/constants/constants';
import IsolatedPoolsHelpLink from '../../Earn/IsolatedPoolsHelpLink';
import Button from '../../Button';
import useSafePush from 'src/hooks/useSafePush';
import { twMerge } from 'tailwind-merge';

const JLPMarketAlertModal = () => {
	const safePush = useSafePush();
	const setStore = useDriftStore((s) => s.set);
	const [_, setHasSeenJLPMarketAlert] = useLocalStorageState(
		'hasSeenJLPMarketAlert',
		false
	);

	const handleClose = () => {
		setHasSeenJLPMarketAlert(true);
		setStore((s) => {
			s.modals.showJLPMarketAlertModal = false;
		});
	};

	const handleViewMore = () => {
		handleClose();
		safePush('/earn/borrow-lend');
		setStore((s) => {
			s.scrollToJlpIsolatedPoolEarnSection = true;
		});
	};

	return (
		<Modal onClose={handleClose}>
			<div className="pb-10 mx-5">
				<Modal.Body
					className={twMerge(
						'max-w-[384px] h-fit mx-auto mt-10 rounded-lg pb-0',
						'text-neutrals-20' // The background color of the gradient does not change with theme so we want to use a "hardcoded" colour regardless of the theme
					)}
				>
					<div
						className="w-full flex flex-col items-center justify-center py-8 px-3 sm:px-10 min-h-[220px] relative"
						style={{
							background: 'linear-gradient(160deg, #3F5ECC -10%, #350961 130%)',
						}}
					>
						<div
							className="absolute bottom-0 left-0 w-full h-[120px]"
							style={{
								background:
									'linear-gradient(0deg, rgba(0,0,0,0.7), rgba(0,0,0,0))',
							}}
						/>
						<div className="absolute top-3 right-3">
							<button onClick={handleClose}>
								<Close size={22} color="white" />
							</button>
						</div>
						<div className="relative z-10 flex flex-row items-center justify-center gap-2">
							{CurrentSpotMarkets.filter((m) => m.poolId === JLP_POOL_ID).map(
								(m) => (
									<MarketIcon
										key={m.symbol}
										marketSymbol={m.symbol}
										sizeClass="w-11 h-11"
									/>
								)
							)}
						</div>
						<Text.BODY3 className="relative z-10 mt-3">
							Isolated Pools
						</Text.BODY3>
						<Text.H1 className="text-center z-10 relative text-[24px]">
							{POOL_NAMES_BY_POOL_ID[JLP_POOL_ID]} Isolated Pool is now live
						</Text.H1>
					</div>
					<div className="flex flex-col gap-4 p-4 text-text-default">
						<Text.BODY1 className="leading-5">
							Borrow assets securely with isolated lending pools, designed for
							single-asset focus and risk control. No collateral overlap, just
							streamlined, efficient lending & borrowing.
						</Text.BODY1>
						<Text.BODY1>
							<IsolatedPoolsHelpLink className="text-interactive-link" />
						</Text.BODY1>
						<Button.Secondary size="LARGE" highlight onClick={handleViewMore}>
							<Text.H3 className="normal-case">View More</Text.H3>
						</Button.Secondary>
						<Button.Secondary size="LARGE" onClick={handleClose}>
							<Text.H3 className="normal-case">Close</Text.H3>
						</Button.Secondary>
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default React.memo(JLPMarketAlertModal);
