'use client';

import { useState } from 'react';
import Drawer from 'src/components/Drawer';
import UnderlinedTabs, {
	TabbedNavigationProps,
} from 'src/components/TabbedNavigation/UnderlinedTabs';
import { twMerge } from 'tailwind-merge';
import { DrawerName, useDrawersStore } from 'src/stores/useDrawersStore';
import { JSX } from 'react';
export type HistoryDrawerProps = {
	title: string;
	summarySection: JSX.Element;
	contentSection: JSX.Element;
	modalStoreKey: DrawerName;
	tabs?: TabbedNavigationProps['options'];
	defaultTab?: string;
	tabSwitchCallback?: (newTab: string) => void;
	tabsClassName?: string;
};

const SizeMap = {
	liquidations: 'sm:w-[780px] sm:max-w-[780px]',
	orders: 'sm:w-[400px] sm:max-w-[400px]',
};

const HistoryDrawer = (props: HistoryDrawerProps) => {
	const handleCloseHistoryPanel = useDrawersStore((s) => s.handleCloseDrawer);
	const [currentTab, setCurrentTab] = useState(props.defaultTab);

	const onClose = () => {
		handleCloseHistoryPanel(props.modalStoreKey);
	};

	const widthToUse = SizeMap[props.modalStoreKey as keyof typeof SizeMap];

	return (
		<Drawer drawerName={props.modalStoreKey} className={widthToUse}>
			<Drawer.Header
				titleContent={props.title}
				infoContent={props.summarySection}
				onClose={onClose}
			/>
			{(props.tabs && props.tabs?.length) > 0 && (
				<div className="w-full bg-container-bg-hover">
					<UnderlinedTabs
						options={props.tabs}
						currentSelection={currentTab}
						onChange={(newSelection: string) => {
							props.tabSwitchCallback(newSelection);
							setCurrentTab(newSelection);
						}}
						tabClassName={twMerge(
							'grow text-center text-[14px] leading-[16px] items-center justify-center',
							props.tabsClassName
						)}
						gradient
						fadeOutBorder={false}
					/>
				</div>
			)}
			<div className="w-full p-4 overflow-y-auto thin-scroll grow">
				{props.contentSection}
			</div>
		</Drawer>
	);
};

export default HistoryDrawer;
