'use client';

import React from 'react';
import Modal from './Modal';
import useDriftActions from 'src/hooks/useDriftActions';
import useFeatureFlagStore, {
	FeatureFlagKey,
	getFlagOverrideValue,
	setFlagOverride,
	FEATURE_FLAG_DEFAULTS,
	clearFlagOverride,
} from '../../stores/useFeatureFlagStore';
import Button from '../Button';
import useForceUpdate from '../../hooks/useForceUpdate';

const DevFeatureFlagModal = () => {
	const actions = useDriftActions();
	const featureFlags = useFeatureFlagStore();

	const triggerRefresh = useForceUpdate();

	const hide = () => {
		actions.showModal('showFeatureFlagModal', false);
	};

	// Get all feature flag keys
	const flagKeys = Object.keys(FEATURE_FLAG_DEFAULTS) as FeatureFlagKey[];

	const getOverrideState = (flagKey: FeatureFlagKey) => {
		const override = getFlagOverrideValue(flagKey);
		if (override === undefined) return 'default';
		return override ? 'true' : 'false';
	};

	const cycleOverrideState = (flagKey: FeatureFlagKey) => {
		const currentState = getOverrideState(flagKey);
		switch (currentState) {
			case 'default':
				setFlagOverride(flagKey, true);
				break;
			case 'true':
				setFlagOverride(flagKey, false);
				break;
			case 'false':
				// Remove override by setting item to null
				localStorage.removeItem(`flag_override_${flagKey}`);
				break;
		}
		triggerRefresh();
	};

	return (
		<Modal onClose={hide}>
			<Modal.Body>
				<Modal.Header showX onClose={hide}>
					Feature Flag Configuration
				</Modal.Header>

				<div className="px-5 pt-4">
					<span className="text-xs text-neutral-400">
						Note: These overrides are stored in local storage and will remain
						until cleared
					</span>
				</div>

				<div className="py-6 px-5 flex flex-col space-y-4">
					<div className="mb-4">
						<Button.Secondary
							size="SMALL"
							onClick={() => {
								flagKeys.forEach((key) => {
									clearFlagOverride(key);
								});
								triggerRefresh();
							}}
						>
							Clear All Overrides
						</Button.Secondary>
					</div>

					{flagKeys.map((flagKey) => {
						const overrideState = getOverrideState(flagKey);
						const baseValue = featureFlags.flagEnabled(flagKey, true);
						const isOverridden = overrideState !== 'default';

						return (
							<div key={flagKey} className="flex items-center justify-between">
								<div className="flex flex-col flex-1 min-w-0 mr-4">
									<span className="text-sm font-medium text-text-emphasis break-words">
										{flagKey}
									</span>
									<span className={'text-neutral-400'}>
										Current:{' '}
										<span
											className={baseValue ? 'text-green-500' : 'text-red-500'}
										>
											{baseValue.toString()}
										</span>
									</span>
								</div>
								<Button.Secondary
									size="SMALL"
									onClick={() => cycleOverrideState(flagKey)}
									className={
										isOverridden
											? overrideState === 'true'
												? 'text-green-500 border-green-500'
												: 'text-red-500 border-red-500'
											: ''
									}
								>
									Override: {overrideState}
								</Button.Secondary>
							</div>
						);
					})}
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(DevFeatureFlagModal);
