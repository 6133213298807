import { LabelChip } from 'src/components/Chips/LabelChip';
import Modal from '../Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import { Balance, PointsMono, Success } from '@drift-labs/icons';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import TextField from 'src/components/Inputs/TextField';
import { DEFAULT_ACCOUNT_NAMES_BY_POOL_ID } from 'src/constants/constants';
import {
	ENUM_UTILS,
	JLP_POOL_ID,
	MarketId,
	USDC_SPOT_MARKET_INDEX,
} from '@drift/common';
import useCurrentUserAccount from 'src/hooks/useCurrentUserAccount';
import Slider from 'src/components/Slider';
import { Typo } from 'src/components/Text/Typo';
import { Alert } from 'src/components/Alert';
import Button from 'src/components/Button';
import {
	BigNum,
	BN,
	MarketType,
	PERCENTAGE_PRECISION,
	PRICE_PRECISION,
	QUOTE_PRECISION_EXP,
	SIX,
	SpotBalanceType,
	ZERO,
} from '@drift-labs/sdk';
import ValueDisplay from 'src/components/ValueDisplay';
import {
	useJlpMigration,
	MigrationDisabledReason,
} from 'src/hooks/jlp/useJlpMigration';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import {
	JLP_MARKET_INDEX,
	JLP_POOL_USDC_MARKET_INDEX,
	JLP_POOL_JLP_MARKET_INDEX,
	SPOT_MARKETS_LOOKUP,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftAccountsStore, {
	AccountData,
} from 'src/stores/useDriftAccountsStore';
import MarketIcon from 'src/components/Utils/MarketIcon';
import useBorrowLendDataForMarket from 'src/hooks/useBorrowLendDataForMarket';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import Tooltip from 'src/components/Tooltip/Tooltip';
import { twMerge } from 'tailwind-merge';

const JLP_PRECISION_EXP =
	SPOT_MARKETS_LOOKUP[JLP_MARKET_INDEX]?.precisionExp ?? SIX;
const ZERO_JLP_BIG_NUM = BigNum.from(ZERO, JLP_PRECISION_EXP);
const USDC_PRECISION_EXP =
	SPOT_MARKETS_LOOKUP[USDC_SPOT_MARKET_INDEX]?.precisionExp ?? SIX;
const ZERO_USDC_BORROW_BIG_NUM = BigNum.from(ZERO, USDC_PRECISION_EXP);

type ValueComparison = {
	before: BigNum;
	after: BigNum;
};

type CollapsibleAccountMigrationDetailsProps = {
	accountName: string;
	accountNotional: ValueComparison;
	jlpBalance: ValueComparison;
	borrowedUsdc: ValueComparison;
	accountHealth: {
		before: number;
		after: number;
	};
	newAccount?: boolean;
	displayGraphic?: boolean;
};

const CollapsibleAccountMigrationDetails = (
	props: CollapsibleAccountMigrationDetailsProps
) => {
	const accountCostBreakdown = useAccountCreationCost();
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);

	const baseAccountRent = accountCostBreakdown.baseAccountRent;
	const insufficientSolBalance = solBalance.lt(baseAccountRent);

	return (
		<div className="relative flex flex-col w-full gap-2 p-3">
			<ValueDisplay.ValueChange
				label={
					<Typo.T5 className="overflow-hidden text-text-default text-ellipsis">
						{props.accountName}
					</Typo.T5>
				}
				previousValue={props.accountNotional.before}
				afterValue={props.accountNotional.after}
				previousValuePrint={`$${props.accountNotional.before.prettyPrint(
					true
				)}`}
				afterValuePrint={`$${props.accountNotional.after.prettyPrint(true)}`}
			/>

			<ValueDisplay.ValueChange
				label={'JLP balance'}
				previousValue={props.jlpBalance.before}
				afterValue={props.jlpBalance.after}
				previousValuePrint={props.jlpBalance.before.prettyPrint(true)}
				afterValuePrint={props.jlpBalance.after.prettyPrint(true)}
				icon={<MarketIcon marketSymbol="JLP" className="w-4 h-4" />}
			/>
			<ValueDisplay.ValueChange
				label={'Borrowed USDC'}
				previousValue={props.borrowedUsdc.before}
				afterValue={props.borrowedUsdc.after}
				previousValuePrint={props.borrowedUsdc.before.prettyPrint(true)}
				afterValuePrint={props.borrowedUsdc.after.prettyPrint(true)}
				nullOverride={
					props.borrowedUsdc.before.eqZero() &&
					props.borrowedUsdc.after.eqZero()
				}
				icon={<MarketIcon marketSymbol="USDC" className="w-4 h-4" />}
			/>
			<ValueDisplay.ValueChange
				label={'Account Health'}
				previousValue={props.accountHealth.before}
				afterValue={props.accountHealth.after}
				previousValuePrint={`${props.accountHealth.before}%`}
				afterValuePrint={`${props.accountHealth.after}%`}
			/>

			{props.newAccount && (
				<div className="flex items-center justify-between gap-1">
					<Typo.B5 className="flex items-center gap-1 text-text-secondary">
						<span>New Account Rent</span>
						<Tooltip
							content={
								<Typo.T5>
									Rent is re-claimable after the subaccount is closed.
								</Typo.T5>
							}
						/>
					</Typo.B5>
					<Typo.T5 className="flex items-center gap-1">
						<span
							className={twMerge(insufficientSolBalance && 'text-negative-red')}
						>
							{baseAccountRent.toRounded(4).toNum()}
						</span>
						<MarketIcon marketSymbol="SOL" />
					</Typo.T5>
				</div>
			)}

			{props.displayGraphic && (
				<div className="absolute bottom-0 z-20 flex items-center justify-center -translate-x-1/2 translate-y-1/2 left-1/2 bg-container-bg">
					<img
						src="/assets/images/drift-earn/jlp-migration.svg"
						className="w-[63px] h-[28px]"
					/>
				</div>
			)}
		</div>
	);
};

const JLP_MARKET_ID = new MarketId(JLP_MARKET_INDEX, MarketType.SPOT);
const JLP_POOL_JLP_MARKET_ID = new MarketId(
	JLP_POOL_JLP_MARKET_INDEX,
	MarketType.SPOT
);

const getSignedBalanceFromAccount = (
	account: AccountData,
	marketIndex: number
) => {
	const spotBalance = account.spotBalances.find(
		(b) => b.asset.marketIndex === marketIndex
	);

	if (!spotBalance)
		return BigNum.zero(SPOT_MARKETS_LOOKUP[marketIndex].precisionExp);

	return ENUM_UTILS.match(spotBalance?.balanceType, SpotBalanceType.BORROW)
		? spotBalance?.balance.neg()
		: spotBalance?.balance;
};

export const JLPMigrationFormModal = () => {
	const setDriftStore = useDriftStore((s) => s.set);
	const currentAccount = useCurrentUserAccount();
	const jlpIsolatedPoolAccount = useDriftAccountsStore((s) =>
		Object.values(s.accounts).find((acc) => acc.poolId === JLP_POOL_ID)
	);
	const getOraclePrice = useGetOraclePriceForMarket();
	const jlpPoolJlpSpotMarketData = useBorrowLendDataForMarket(
		JLP_POOL_JLP_MARKET_ID
	);

	const {
		maxJlpMigrationAmountBigNum,
		jlpInputBigNum,
		usdcBorrowDerivedAmount,
		toCreateJlpIsolatedPoolAccount,
		migrationDisabled,
		setJlpInputAmount,
		handleJlpMigration,
	} = useJlpMigration();

	const jlpInputAmountPct = maxJlpMigrationAmountBigNum.eqZero()
		? '0'
		: jlpInputBigNum.toPercentage(maxJlpMigrationAmountBigNum, 2);
	const currentAccountValueComparison = getCurrentAccountValueComparison();
	const isolatedPoolAccountValueComparison =
		getIsolatedPoolAccountValueComparison();

	const onClose = () => {
		setDriftStore((s) => {
			s.modals.showJLPMigrationFormModal = false;
		});
	};

	const handleJlpInputChange = (newValue: string) => {
		if (
			BigNum.fromPrint(newValue, JLP_PRECISION_EXP).gt(
				maxJlpMigrationAmountBigNum
			)
		) {
			setJlpInputAmount(maxJlpMigrationAmountBigNum.toNum().toString());
			return;
		}

		setJlpInputAmount(newValue);
	};

	const handleSliderValChange = (pct: number) => {
		if (pct === 100) {
			setJlpInputAmount(maxJlpMigrationAmountBigNum.toNum().toString());
			return;
		}

		const amount = maxJlpMigrationAmountBigNum
			.mul(new BN(pct))
			.div(new BN(100))
			.toNum()
			.toString();

		setJlpInputAmount(amount);
	};

	const onMaxJlpAmount = () => {
		handleSliderValChange(100);
	};

	function getCurrentAccountValueComparison(): CollapsibleAccountMigrationDetailsProps {
		const jlpOraclePrice = getOraclePrice(JLP_MARKET_ID);
		const jlpMigrationNotional = jlpInputBigNum
			.mul(jlpOraclePrice)
			.shiftTo(QUOTE_PRECISION_EXP);

		// Account notional
		const currentAccountNotional = BigNum.from(
			currentAccount?.marginInfo.netUsdValue ?? ZERO,
			QUOTE_PRECISION_EXP
		);

		const currentAccountNotionalAfterMigration = currentAccountNotional
			.sub(jlpMigrationNotional)
			.add(usdcBorrowDerivedAmount);

		// JLP balance
		const currentJlpBalance = getSignedBalanceFromAccount(
			currentAccount,
			JLP_MARKET_INDEX
		);
		const currentJlpBalanceAfterMigration =
			currentJlpBalance.sub(jlpInputBigNum);

		// USDC borrows
		const usdcSpotBalance = getSignedBalanceFromAccount(
			currentAccount,
			USDC_SPOT_MARKET_INDEX
		);
		const currentUsdcBorrow = usdcSpotBalance.isNeg()
			? usdcSpotBalance
			: ZERO_USDC_BORROW_BIG_NUM;
		const currentUsdcBorrowAfterMigration = currentUsdcBorrow.add(
			usdcBorrowDerivedAmount
		);

		// Account health - uses maintenance weight to derive
		const currentAccountHealth = currentAccount?.client?.getHealth() ?? 0;

		const healthComponents = currentAccount?.client?.getHealthComponents({
			marginCategory: 'Maintenance',
		});
		const jlpMigratedMaintenanceWeight =
			healthComponents?.deposits?.find(
				(d) => d.marketIndex === JLP_MARKET_INDEX
			)?.weight ?? ZERO;
		const jlpMigratedMaintenanceWeightedValue = jlpInputBigNum
			.mul(new BN(100))
			.mul(jlpMigratedMaintenanceWeight)
			.div(PERCENTAGE_PRECISION);

		const totalCollateralAfter =
			currentAccount.marginInfo.totalMaintenanceCollateral.sub(
				jlpMigratedMaintenanceWeightedValue.val
			);
		const totalMarginRequirementAfter =
			currentAccount.marginInfo.maintenanceReq.sub(usdcBorrowDerivedAmount.val); // naive assumption of USDC having 100% liability weight
		const healthAfter = +Math.min(
			100,
			Math.max(
				0,
				(1 -
					totalMarginRequirementAfter.toNumber() /
						totalCollateralAfter.toNumber()) *
					100
			)
		).toFixed(0);

		return {
			accountName: currentAccount?.name,
			accountNotional: {
				before: currentAccountNotional,
				after: currentAccountNotionalAfterMigration,
			},
			jlpBalance: {
				before: currentJlpBalance,
				after: currentJlpBalanceAfterMigration,
			},
			borrowedUsdc: {
				before: currentUsdcBorrow,
				after: currentUsdcBorrowAfterMigration,
			},
			accountHealth: {
				before: currentAccountHealth,
				after: healthAfter,
			},
		};
	}

	function getIsolatedPoolAccountValueComparison(): CollapsibleAccountMigrationDetailsProps {
		const currentJlpIsolatedPoolAccountDetails = jlpIsolatedPoolAccount
			? {
					accountNotional: BigNum.from(
						jlpIsolatedPoolAccount.marginInfo.netUsdValue,
						QUOTE_PRECISION_EXP
					),
					currentJlpBalance: getSignedBalanceFromAccount(
						jlpIsolatedPoolAccount,
						JLP_POOL_JLP_MARKET_INDEX
					),
					currentMaintenanceCollateral:
						jlpIsolatedPoolAccount.marginInfo.totalMaintenanceCollateral,
					currentMaintenanceMarginReq:
						jlpIsolatedPoolAccount.marginInfo.maintenanceReq,
					borrowedUsdc: getSignedBalanceFromAccount(
						jlpIsolatedPoolAccount,
						JLP_POOL_USDC_MARKET_INDEX
					),
					accountHealth: jlpIsolatedPoolAccount.client?.getHealth() ?? 0,
			  }
			: {
					accountNotional: BigNum.from(ZERO, QUOTE_PRECISION_EXP),
					currentJlpBalance: ZERO_JLP_BIG_NUM,
					currentMaintenanceCollateral: ZERO,
					currentMaintenanceMarginReq: ZERO,
					borrowedUsdc: ZERO_USDC_BORROW_BIG_NUM,
					accountHealth: 100,
			  };

		const jlpOraclePrice = getOraclePrice(JLP_POOL_JLP_MARKET_ID);
		const jlpMigrationNotional = jlpInputBigNum
			.mul(jlpOraclePrice)
			.shiftTo(QUOTE_PRECISION_EXP);
		const accountNotionalAfterMigration =
			currentJlpIsolatedPoolAccountDetails.accountNotional
				.add(jlpMigrationNotional)
				.sub(usdcBorrowDerivedAmount);
		const currentJlpBalanceAfterMigration =
			currentJlpIsolatedPoolAccountDetails.currentJlpBalance.add(
				jlpInputBigNum
			);
		const borrowedUsdcAfterMigration =
			currentJlpIsolatedPoolAccountDetails.borrowedUsdc.sub(
				usdcBorrowDerivedAmount
			);

		// Account health - uses maintenance weight to derive
		const jlpWeightedValue = jlpInputBigNum
			.mul(new BN(jlpPoolJlpSpotMarketData?.maintenanceAssetWeight ?? 0))
			.mul(new BN(10_000)) // maintenanceAssetWeight is in percentage already
			.mul(jlpOraclePrice)
			.div(PERCENTAGE_PRECISION)
			.div(PRICE_PRECISION);

		const totalCollateralAfter =
			currentJlpIsolatedPoolAccountDetails.currentMaintenanceCollateral.add(
				jlpWeightedValue.val
			);
		const totalMaintenanceMarginReqAfter =
			currentJlpIsolatedPoolAccountDetails.currentMaintenanceMarginReq.add(
				usdcBorrowDerivedAmount.val // naive assumption of USDC having 100% liability weight
			);
		let healthAfter = +Math.min(
			100,
			Math.max(
				0,
				(1 -
					totalMaintenanceMarginReqAfter.toNumber() /
						totalCollateralAfter.toNumber()) *
					100
			)
		).toFixed(0);

		if (isNaN(healthAfter)) {
			healthAfter = 100;
		}

		return {
			accountName:
				jlpIsolatedPoolAccount?.name ??
				DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[JLP_POOL_ID],
			accountNotional: {
				before: currentJlpIsolatedPoolAccountDetails.accountNotional,
				after: accountNotionalAfterMigration,
			},
			jlpBalance: {
				before: currentJlpIsolatedPoolAccountDetails.currentJlpBalance,
				after: currentJlpBalanceAfterMigration,
			},
			borrowedUsdc: {
				before: currentJlpIsolatedPoolAccountDetails.borrowedUsdc,
				after: borrowedUsdcAfterMigration,
			},
			accountHealth: {
				before: currentJlpIsolatedPoolAccountDetails.accountHealth,
				after: healthAfter,
			},
		};
	}

	return (
		<Modal onClose={onClose} centered>
			<Modal.Body className="w-full md:min-w-[560px] md:w-[560px]">
				<Modal.Header
					onClose={onClose}
					className="flex flex-col gap-1 sm:py-4 md:px-4"
				>
					<div className="flex flex-col gap-1">
						<Modal.Title>
							Free Migration to JLP Market{' '}
							<span className="hidden sm:inline-block">(Isolated Pool)</span>
						</Modal.Title>

						{/** Chips */}
						<div className="flex flex-wrap items-center gap-1">
							<LabelChip
								label="Zero fee"
								type="success"
								icon={
									<span className="items-center justify-center hidden sm:flex">
										<Success size={12} />
									</span>
								}
								className="whitespace-nowrap"
							/>
							<LabelChip
								label="Higher asset efficiency"
								type="success"
								icon={
									<span className="items-center justify-center hidden sm:flex">
										<Balance size={12} />
									</span>
								}
								className="whitespace-nowrap"
							/>
							<LabelChip
								label="Better yield opportunities"
								type="success"
								icon={
									<span className="items-center justify-center hidden sm:flex">
										<PointsMono size={12} />
									</span>
								}
								className="whitespace-nowrap"
							/>
						</div>
					</div>
				</Modal.Header>

				<Modal.Content className="flex flex-col gap-4 px-4">
					{/** Accounts Migration */}
					<div className="flex flex-col items-center gap-2 sm:flex-row">
						<LabelledInput label="Migrate from">
							<TextField.Default
								type="string"
								onChange={() => {}}
								value={currentAccount?.name}
								disabled
								className="!typo-b4"
							/>
						</LabelledInput>
						<LabelledInput label="Migrate to">
							<TextField.Default
								type="string"
								onChange={() => {}}
								value={DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[JLP_POOL_ID]}
								disabled
								className="!typo-b4"
							/>
						</LabelledInput>
					</div>

					{/** JLP/Borrowed USDC amount inputs */}
					<div className="flex flex-col gap-3">
						<div className="flex flex-col items-center gap-2 sm:flex-row">
							<LabelledInput
								label="JLP amount"
								quickAction={
									<Typo.B6
										className="px-1 py-[1px] rounded-sm bg-button-secondary-bg hover:bg-button-secondary-bg-hover text-text-secondary cursor-pointer"
										onClick={onMaxJlpAmount}
									>
										{maxJlpMigrationAmountBigNum.prettyPrint()} JLP
									</Typo.B6>
								}
							>
								<TextField.Default
									type="number"
									onChange={handleJlpInputChange}
									value={jlpInputBigNum.toNum().toString()}
									className="!typo-t3"
									showIconForMarketSymbol="JLP"
								/>
							</LabelledInput>
							<LabelledInput label="USDC borrowed">
								<TextField.Default
									type="string"
									onChange={() => {}}
									value={usdcBorrowDerivedAmount.neg().toNum().toString()}
									showIconForMarketSymbol="USDC"
									disabled
									className="!typo-t3"
								/>
							</LabelledInput>
						</div>

						<Slider
							onDrop={handleSliderValChange}
							onMove={handleSliderValChange}
							step={1}
							value={+jlpInputAmountPct}
							disabled={false}
							type="percent"
							customButtonOptions={[]}
						/>
					</div>

					<Alert
						type="warning"
						message={
							<>
								Only JLP and USDC not used as trading collateral will be
								migrated, including existing JLP-USDC looped positions. Open
								positions may see liquidation prices shift.
								<>
									{toCreateJlpIsolatedPoolAccount
										? ' A subaccount will be created for JLP Market - Isolated Pool deposits.'
										: ''}
								</>
							</>
						}
						noIcon
						className="text-text-default"
					/>

					<Button.Primary
						size="LARGE"
						onClick={handleJlpMigration}
						disabled={migrationDisabled.disabled}
					>
						<Typo.T2 className="normal-case">
							{migrationDisabled.reason ===
							MigrationDisabledReason.NO_SOL_FOR_RENT
								? 'Insufficient SOL'
								: 'Migrate'}
						</Typo.T2>
					</Button.Primary>

					<div className="flex flex-col border divide-y rounded-lg divide-input-border border-input-border">
						<CollapsibleAccountMigrationDetails
							{...currentAccountValueComparison}
							displayGraphic
						/>
						<CollapsibleAccountMigrationDetails
							{...isolatedPoolAccountValueComparison}
							newAccount={toCreateJlpIsolatedPoolAccount}
						/>
					</div>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};
