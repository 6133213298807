'use client';

import { Counter } from '@drift/common';
import { useContext, useEffect, useRef } from 'react';
import { create } from 'zustand';
import { SEMANTIC_STATUS } from '../components/Utils/SemanticChip';
import { SlotContext } from '../providers/currentSlotProvider';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useDevStore from '../stores/useDevStore';
import { notify } from '../utils/notifications';
import useDriftActions from './useDriftActions';
import useInterval from './useInterval';
import produce from 'immer';

// # Config
// Initial delay on startup before doing health checks
const INITIAL_DELAY_SECONDS = 10;

// Run a health check every 10 seconds
const HEALTH_CHECK_FREQUENCY_S = 10;

const IDEAL_SLOT_FREQUENCY_MS = 500;
const IDEAL_SLOTS_PER_SEC = 1000 / IDEAL_SLOT_FREQUENCY_MS;

const IDEAL_SLOT_UPDATES_PER_CHECK =
	IDEAL_SLOTS_PER_SEC * HEALTH_CHECK_FREQUENCY_S;

type HealthySide = 'greater' | 'lesser';

type HealthScale = {
	amber: number;
	red: number;
	healthySide: HealthySide;
};

// This health scale is used to check how many slot updates we think were missed during the check period. E.g. if the number of slot updates we get matches the IDEAL_SLOT_UPDATES_PER_CHECK value, we think we caught every single slot over the period and are at maximum health.
const SLOT_UPDATES_HEALTH_SCALE: HealthScale = {
	// ~1/2 slots missed
	amber: IDEAL_SLOT_UPDATES_PER_CHECK / 2,
	// ~3/4 slots missed
	red: IDEAL_SLOT_UPDATES_PER_CHECK / 4,
	healthySide: 'greater',
};

// This health scale is used to check the delta between the current slot of the bulk account loader and the slot subscriber. These two should remain in sync and if they fall out of sync it implies that something in the UI is not keeping up and won't be correctly displaying the current blockchain state.
const SLOT_DELTA_HEALTH_SCALE: HealthScale = {
	// ~2 seconds delta
	amber: IDEAL_SLOTS_PER_SEC * 2,
	// ~4 seconds delta
	red: IDEAL_SLOTS_PER_SEC * 4,
	healthySide: 'lesser',
};

const getHealthStatusAgainstScale = (
	val: number,
	scale: HealthScale
): SEMANTIC_STATUS => {
	const isRed =
		scale.healthySide === 'lesser' ? val >= scale.red : val <= scale.red;
	if (isRed) return 'red';

	const isAmber =
		scale.healthySide === 'lesser' ? val >= scale.amber : val <= scale.amber;
	if (isAmber) return 'amber';

	return 'green';
};

// # RPC Health State
type State = {
	health: SEMANTIC_STATUS;
};

type Actions = {
	setHealth: (health: SEMANTIC_STATUS) => void;
};

const useCurrentRpcHealthStore = create<State & Actions>((set) => {
	const setState = (fn: (s: State) => void) => set(produce(fn));

	return {
		health: 'green',
		setHealth: (health: SEMANTIC_STATUS) => {
			setState((state) => {
				state.health = health;
			});
		},
	};
});

export const useKeepCurrentRpcHealthInSync = () => {
	const currentSlot = useContext(SlotContext).currentSlot;
	const currentRpc = useDriftStore((s) => s.rpcUrl);
	const rpcHealthStore = useCurrentRpcHealthStore();
	const currentBulkAccountLoader = useDriftStore(
		(s) => s.currentBulkAccountLoader
	);
	const actions = useDriftActions();
	const showSettingsModal = () => {
		actions.showModal('showSettingsModal');
	};

	const delayExpired = useRef<boolean>(false);
	const slotUpdateCounter = useRef<Counter>(new Counter());
	const alreadyShownError = useRef<boolean>(false);

	// # Dev Stuff
	const devTriggerBadHealth = useDevStore((s) => s.triggerBadRpcHealth);

	const showHealthWarning = () => {
		notify({
			type: 'warning',
			message: 'Detected Connection Problems',
			description:
				'If you notice sustained problems using Drift, try refreshing the page or changing to a different RPC Provider.',
			action: {
				label: 'Change RPC',
				callback: showSettingsModal,
			},
		});
		alreadyShownError.current = true;
	};

	const slotSubscriberHealthCheck = () => {
		const slotUpdateCount = slotUpdateCounter.current.get();

		slotUpdateCounter.current.reset();

		return getHealthStatusAgainstScale(
			slotUpdateCount,
			SLOT_UPDATES_HEALTH_SCALE
		);
	};

	const bulkAccountLoaderHealthCheck = (): SEMANTIC_STATUS => {
		try {
			const currentBulkLoaderSlot = currentBulkAccountLoader.mostRecentSlot;

			const deltaBetweenSlots = Math.abs(currentBulkLoaderSlot - currentSlot);

			return getHealthStatusAgainstScale(
				deltaBetweenSlots,
				SLOT_DELTA_HEALTH_SCALE
			);
		} catch (e) {
			return 'red';
		}
	};

	// Show bad health for dev trigger
	useEffect(() => {
		if (devTriggerBadHealth) {
			rpcHealthStore.setHealth('red');
			showHealthWarning();
		}
	}, [devTriggerBadHealth]);

	// Turn off the initial delay gate
	useEffect(() => {
		setTimeout(() => {
			delayExpired.current = true;
		}, INITIAL_DELAY_SECONDS * 1000);
	}, []);

	// Reset state when RPC Changes
	useEffect(() => {
		rpcHealthStore.setHealth('green');
	}, [currentRpc]);

	// Increment counter for every slot change
	useEffect(() => {
		slotUpdateCounter.current.increment();
	}, [currentSlot]);

	// Check rpc health results every 10 seconds
	useInterval(() => {
		if (!delayExpired.current) return;
		if (devTriggerBadHealth) return;

		const healthCheckResults = [
			slotSubscriberHealthCheck(),
			bulkAccountLoaderHealthCheck(),
		];

		if (healthCheckResults.includes('red')) {
			rpcHealthStore.setHealth('red');

			// temp disable rpc health warning
			// if (!alreadyShownError.current) {
			// 	showHealthWarning();
			// }
			return;
		}

		if (healthCheckResults.includes('amber')) {
			rpcHealthStore.setHealth('amber');

			return;
		}

		rpcHealthStore.setHealth('green');
	}, 1000 * HEALTH_CHECK_FREQUENCY_S);
};

// Hook to fetch RPC Health state
export const useCurrentRpcHealth = (): SEMANTIC_STATUS => {
	const rpcHealthStore = useCurrentRpcHealthStore();

	return rpcHealthStore.health;
};
