import Drawer from 'src/components/Drawer';
import { VaultSidebarInfoHeader } from 'src/components/Vaults/VaultSidebarInfoHeader';
import { VaultDetailsDrawerContent } from 'src/components/Vaults/VaultMainContent/VaultDetailsDrawerContent';
import { VaultProvider } from 'src/providers/vaults/VaultProvider';
import { useSyncVaultClient } from 'src/stores/vaultsStore/useSyncVaultClient';
import { useDrawersStore } from 'src/stores/useDrawersStore';

export const VaultDetailsDrawer = () => {
	const handleCloseVaultDetailsDrawer = useDrawersStore(
		(s) => s.handleCloseDrawer
	);
	const { vault } = useDrawersStore((s) => s.vaultDetails);

	const onClose = () => {
		handleCloseVaultDetailsDrawer('vaultDetails');
	};

	useSyncVaultClient();

	return (
		<Drawer
			drawerName="vaultDetails"
			className="w-full sm:max-w-[400px] sm:w-[400px] thin-scroll overflow-y-auto flex flex-col"
		>
			<VaultProvider vaultPubkey={vault.vaultPubkeyString}>
				<Drawer.Header
					titleContent={<VaultSidebarInfoHeader uiVaultConfig={vault} />}
					className="p-4"
					onClose={onClose}
				/>

				<VaultDetailsDrawerContent uiVaultConfig={vault} />
			</VaultProvider>
		</Drawer>
	);
};
