'use client';

import Text from 'src/components/Text/Text';
import React, { useEffect } from 'react';
import Modal from './Modal';
import useDriftActions from 'src/hooks/useDriftActions';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useAppEventEmitter from '../../hooks/useAppEventEmitter';
import AssetSelectModalContent from './AssetSelectModal/AssetSelectModalContent';

const AssetSelectModal = ({ id }: { id?: string }) => {
	const modalProps = useDriftStore((s) => s.modalsProps.showAssetSelectModal);
	const actions = useDriftActions();

	const onClose = () => {
		actions.showModal('showAssetSelectModal', false);
	};

	const appEventEmitter = useAppEventEmitter();

	useEffect(() => {
		appEventEmitter.on('assetSelected', (_) => {
			onClose();
		});
	}, [appEventEmitter, onClose]);

	return (
		<Modal onClose={onClose} id={id} className="h-auto px-4">
			<Modal.Body className="h-auto pb-0 mt-8">
				<Modal.Header
					showX
					onClose={onClose}
					showBack={false}
					borderBottom={false}
				>
					<Text.H2>Select</Text.H2>
				</Modal.Header>
				<div className="w-full">
					<div className="sm:max-h-[50vh]">
						<AssetSelectModalContent
							assetList={modalProps.assetList}
							source={modalProps.source}
						/>
					</div>
				</div>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(AssetSelectModal);
