import { Open } from '@drift-labs/icons';
import {
	BigNum,
	PublicKey,
	QUOTE_PRECISION_EXP,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import { useEffect, useState } from 'react';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { Typo } from 'src/components/Text/Typo';
import MarketIcon from 'src/components/Utils/MarketIcon';
import ValueDisplay from 'src/components/ValueDisplay';
import { UiVaults } from 'src/constants/vaults';
import {
	DEFAULT_PERIOD_APY,
	VAULT_APY_LABEL,
	VAULT_TARGET_APY_TOOLTIP_CONTENT,
	VAULT_TEMP_APY_LABEL,
} from 'src/constants/vaults/misc';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import { useVaultsStore } from 'src/stores/vaultsStore/useVaultsStore';
import { getApyToUse, getVaultApyDays } from 'src/utils/vault/math';
import { VaultPerformanceStatLabel } from '../VaultPerformanceStatLabel';

export const VaultPerformanceV2 = (props: {
	depositAssetConfig: SpotMarketConfig;
	vaultPubkey: string;
}) => {
	const vaultStats = useVaultsStore((s) => s.getVaultStats(props.vaultPubkey));
	const isLoadingVaultStats = !vaultStats?.isOnChainStatsLoaded;
	const uiVaultConfig = UiVaults.getVaultConfig(props.vaultPubkey);

	const { apy, isUsingTemporaryApy } = getApyToUse(
		uiVaultConfig,
		vaultStats?.apys[DEFAULT_PERIOD_APY],
		vaultStats?.numOfVaultSnapshots ?? 0
	);

	const marketSymbol = uiVaultConfig.isNotionalGrowthStrategy
		? 'USDC'
		: props.depositAssetConfig.symbol;

	const vaultClient = useVaultsStore((s) => s.vaultClient);

	const [totalPnl, setTotalPnl] = useState<BigNum | undefined>(undefined);

	useEffect(() => {
		if (vaultStats) {
			if (uiVaultConfig.isNotionalGrowthStrategy) {
				vaultClient
					.getSubscribedVaultUser(
						new PublicKey(uiVaultConfig.driftUserPubkeyString)
					)
					.then((vaultUser) => {
						const vaultNetQuoteDeposits = vaultUser
							.getUserAccount()
							.totalDeposits.sub(vaultUser.getUserAccount().totalWithdraws);
						const currentNotionalPnl = vaultStats.tvlQuote.sub(
							BigNum.from(vaultNetQuoteDeposits, QUOTE_PRECISION_EXP)
						);

						setTotalPnl(currentNotionalPnl);
					});
			} else {
				setTotalPnl(vaultStats.totalBasePnl);
			}
		}
	}, [vaultStats, uiVaultConfig, vaultClient]);

	const stats = [
		{
			label: 'Market',
			value: (
				<div className="flex items-center gap-3">
					{uiVaultConfig.assetsOperatedOn.map((marketIndex) => (
						<MarketIcon
							key={marketIndex}
							marketSymbol={CurrentSpotMarkets[marketIndex].symbol}
							className="w-4 h-4"
						/>
					))}
				</div>
			),
			loading: isLoadingVaultStats,
		},
		{
			label: isUsingTemporaryApy
				? VAULT_TEMP_APY_LABEL
				: `${VAULT_APY_LABEL} (${getVaultApyDays(
						vaultStats?.numOfVaultSnapshots ?? 0
				  )})`,
			value: `${apy?.toFixed(2)}%`,
			loading: isLoadingVaultStats,
			tooltip: isUsingTemporaryApy
				? VAULT_TARGET_APY_TOOLTIP_CONTENT
				: undefined,
		},
		// TODO: need APY to be 7d, 30d, and 90d at some point

		{
			label: '30D Volume',
			value: `$${vaultStats?.volume30Days.toMillified()}`,
			loading: isLoadingVaultStats,
		},
		{
			label: 'Vault Age',
			value: `${vaultStats?.numOfVaultSnapshots} days`,
			loading: isLoadingVaultStats,
		},
		{
			label: 'TVL',
			value: `${vaultStats?.tvlBase.toMillified()}`,
			loading: isLoadingVaultStats,
		},
		{
			label: 'Capacity',
			value: vaultStats?.isUncappedCapacity
				? 'Uncapped'
				: `${vaultStats?.capacityPct.toFixed(2)}%`,
			loading: isLoadingVaultStats,
		},
	];

	const returnStats = [
		{
			label: 'Total Earnings',
			value: (
				<span className="flex items-center gap-1">
					<MarketIcon marketSymbol={marketSymbol} className="w-4 h-4" />
					{totalPnl?.prettyPrint(true)}
				</span>
			),
			loading: isLoadingVaultStats,
			tooltip:
				'The total earnings for the entire vault. Total Earnings = Total Vault TVL - Total Net Deposits.',
		},
		{
			label: 'Max Daily Drawdown',
			value: `${vaultStats?.maxDrawdownPct.toFixed(2)}%`,
			loading: isLoadingVaultStats,
			tooltip: "The maximum day-to-day loss in the vault's history.",
		},
	];

	return (
		<div className="flex flex-col w-full">
			<Typo.T4 className="text-text-default mb-4">About</Typo.T4>
			<div className="flex flex-col gap-2 mb-4">
				{stats.map((stat) => {
					return (
						<ValueDisplay.Default
							key={stat.label}
							label={
								<VaultPerformanceStatLabel
									label={stat.label}
									tooltip={stat.tooltip}
								/>
							}
							value={
								stat.loading ? (
									<SkeletonValuePlaceholder className="w-20 h-6" loading />
								) : (
									stat.value
								)
							}
						/>
					);
				})}
			</div>
			<div className="flex flex-col gap-2 mb-4">
				{returnStats.map((stat) => {
					return (
						<ValueDisplay.Default
							key={stat.label}
							label={
								<VaultPerformanceStatLabel
									label={stat.label}
									tooltip={stat.tooltip}
								/>
							}
							value={
								stat.loading ? (
									<SkeletonValuePlaceholder className="w-20 h-6" loading />
								) : (
									stat.value
								)
							}
						/>
					);
				})}
			</div>
			<ValueDisplay.Default
				key="Social Media"
				label="Social Media"
				value={
					isLoadingVaultStats ? (
						<SkeletonValuePlaceholder className="w-20 h-6" loading />
					) : (
						<a
							href={`https://x.com/${uiVaultConfig.vaultManager.twitterHandle}`}
							target="_blank"
							rel="noreferrer noopner"
							className="flex items-center gap-1"
						>
							<Typo.T4 className="text-text-default">
								@{uiVaultConfig.vaultManager.twitterHandle}
							</Typo.T4>
							<Open color="var(--text-secondary)" />
						</a>
					)
				}
			/>
		</div>
	);
};
