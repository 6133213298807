'use client';

import {
	BN,
	BigNum,
	MarketType,
	OrderAction,
	OrderActionExplanation,
	OrderActionRecord,
	OrderStatus,
	OrderType,
	PRICE_PRECISION_EXP,
	PublicKey,
	WrappedEvent,
	ZERO,
} from '@drift-labs/sdk';
import {
	ENUM_UTILS,
	MarketId,
	Serializer,
	UISerializableOrder,
	getDriftEventKey,
	sleep,
} from '@drift/common';
import React, {
	PropsWithChildren,
	useContext,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react';
import { Subject } from 'rxjs';
import { dlog } from '../../dev';
import useAppEventEmitter from '../../hooks/useAppEventEmitter';
import useCurrentUserAccount from '../../hooks/useCurrentUserAccount';
import { DriftBlockchainEventSubjectContext } from '../../providers/driftEvents/driftEventSubjectProvider';
import useDriftStore, {
	DriftAppEventEmitter,
} from '../../stores/DriftStore/useDriftStore';
import useDriftAccountStore from '../../stores/useDriftAccountsStore';
import {
	MarketOrderToastOrderInfo,
	MarketOrderToastStateTransitionEvent,
} from './MarketOrderToastStateTypes';
import useCurrentSlotRef from '../../hooks/useCurrentSlotRef';
import useInterval from '../../hooks/useInterval';
import useDevMarketOrderEvents from './useDevMarketOrderEvents';
import { DriftWindow } from '../../window/driftWindow';
import { MarketOrderToastId } from './MarketOrderToastStateTypes';
import {
	DEFAULT_COMMITMENT_LEVEL,
	SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY_OFFSET,
} from 'src/constants/constants';
import { getMarketStepSize } from '../../hooks/useMarketStepSize';
import useMarketsInfoStore from '../../stores/useMarketsInfoStore';
import { MarketOrderToastIdHandler } from './toastIdHandlingUtils';
import Env from 'src/environmentVariables/EnvironmentVariables';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import { dedupeSubject } from '../../utils/rxjs/DedupedSubject';
import DataApiClient from '../../utils/DataApiClient';
import useFeatureFlagStore from '../../stores/useFeatureFlagStore';
import { JsonObject } from 'cerializr';

const ARTIFICIAL_DELAY_TIME = 10_000;

const DEV_ARTIFICIALLY_DELAY_ORDER_ACTION_EVENTS = false;
const DEV_ARTIFICIALLY_DELAY_ORDER_RECORD_EVENTS = false;
const DEV_DISABLE_ORDER_ACTION_EVENTS = false;
const DEV_DISABLE_ORDER_RECORD_EVENTS = false;
const DEV_DISABLE_TX_CONFIRMED_EVENT = false;
const DEV_DISABLE_ORDER_STATE_READS = false;
const DEV_FORCE_NO_TX_EXPIRY_ATTCHED = false;

// Redundant event source config
const NO_EVENTS_BAD_HEALTH_THRESHOLD = 5_000; // Consider unhealthy if we have an open order with no events for 5 seconds
const REDUNDANT_EVENT_POLLING_INTERVAL = 2_000; // The frequency we poll for events from the redundant source once it is activated

const getShouldUseBlockHeightOffset = (): boolean => {
	const defaultValue = true;

	const rpcVersion = Env.rpcVersion;

	const solanaCoreVersion = rpcVersion['solana-core'];

	if (!solanaCoreVersion) return defaultValue;

	const majorVersion = solanaCoreVersion.split('.')[0];

	if (!majorVersion) return defaultValue;

	const parsedMajorVersion = parseInt(majorVersion);

	if (isNaN(parsedMajorVersion)) return defaultValue;

	if (parsedMajorVersion >= 2) {
		return false;
	}

	return defaultValue;
};

/**
 * This provider is in charge of emitting events for the MarketOrderToast component. It listens to events from on chain, and other sources, to determine what events to emit for our context. These events should always flow through here so that there's a single source of truth for state changes.
 */

const isRelevantOrderType = (orderType: OrderType) => {
	return (
		ENUM_UTILS.match(orderType, OrderType.MARKET) ||
		ENUM_UTILS.match(orderType, OrderType.ORACLE)
	);
};

export const getIsTaker = (
	event: WrappedEvent<'OrderActionRecord'>,
	user: PublicKey
) => {
	const isTaker = event?.taker?.equals(user);
	const isMaker = event?.maker?.equals(user);

	if (!isTaker && !isMaker) {
		throw new Error(
			`Got user order action event where was not matching taker or maker`
		);
	}

	return isTaker;
};

export const getToastIdFromEvent = (
	event: WrappedEvent<'OrderActionRecord'> | WrappedEvent<'OrderRecord'>,
	user: PublicKey
): MarketOrderToastId => {
	// return event.
	if (event.eventType === 'OrderActionRecord') {
		const marketId = new MarketId(event.marketIndex, event.marketType);

		const isTaker = getIsTaker(event, user);

		const orderId = isTaker ? event.takerOrderId : event.makerOrderId;

		const baseSize = isTaker
			? event.takerOrderBaseAssetAmount
			: event.makerOrderBaseAssetAmount;

		const direction = isTaker
			? event.takerOrderDirection
			: event.makerOrderDirection;

		return MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
			user,
			marketId,
			baseSize,
			orderId,
			direction,
		});
	} else {
		if (!event.user.equals(user)) {
			dlog(`v2_auctions`, `order_record_with_non_matching_user`);
			return null; // Sometimes the order record will have the counterparty's order ID instead of the current user. Don't think anything can be done here -- but it should be fine to return null and let other events keep progressing the toast state.
		} else {
			dlog(`v2_auctions`, `order_record_with_matching_user`);
		}

		const marketId = ENUM_UTILS.match(event.order.marketType, MarketType.PERP)
			? MarketId.createPerpMarket(event.order.marketIndex)
			: MarketId.createSpotMarket(event.order.marketIndex);

		return MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
			user,
			marketId,
			orderId: event.order.orderId,
			direction: event.order.direction,
			baseSize: event.order.baseAssetAmount,
		});
	}
};

type MarketOrderToastEvent = MarketOrderToastStateTransitionEvent;

type MarketOrderToastEventSubject = {
	subject: Subject<MarketOrderToastEvent>;
};

const INITIAL_CONTEXT_VALUE: MarketOrderToastEventSubject = {
	subject: new Subject<MarketOrderToastEvent>(),
};

export const MarketOrderToastEventContext =
	React.createContext<MarketOrderToastEventSubject>(undefined);

/**
 * userEventSubscriber: EventSubscriber for the current user's events
 * globalFillEventSubscriber: EventSubscriber for market-wide order action events
 */
export const MarketOrderToastEventListenerContext = React.createContext(
	INITIAL_CONTEXT_VALUE
);

export const useCurrentUserForMarketOrderEventsRef = () => {
	const currentUserRef = useRef<PublicKey>(null);
	const currentUser = useCurrentUserAccount();
	const appEventEmitter = useDriftStore((s) => s.appEventEmitter);

	useEffect(() => {
		currentUserRef.current = currentUser?.pubKey;
	}, [currentUser?.pubKey]);

	// To support DepositToTrade, need to enable pre-empting the user account which will be linked to market order events before the user account actually exists (or is selected in state) yet
	useEffect(() => {
		if (!appEventEmitter) return;

		const handler = (user: PublicKey) => {
			currentUserRef.current = user;
		};

		appEventEmitter.on('expectedUserAccountForMarketOrderEvents', handler);

		return () => {
			appEventEmitter.off('expectedUserAccountForMarketOrderEvents', handler);
		};
	}, [appEventEmitter]);

	return currentUserRef;
};

export const useUserEventsObservable = () => {
	const driftEventSubjectContext = useContext(
		DriftBlockchainEventSubjectContext
	);
	return driftEventSubjectContext.userEventObservable;
};

export const useGlobalFillEventsObservable = () => {
	const driftEventSubjectContext = useContext(
		DriftBlockchainEventSubjectContext
	);
	return driftEventSubjectContext.globalFillEventObservable;
};

const inputToastDriftEventsSubject = new Subject<WrappedEvent<any>>(); // We can pipe drift events from multiple sources into this subject regardless of being duplicate events or not and they will be handled appropriately.
const outputToastDriftEventsSubject = dedupeSubject(
	inputToastDriftEventsSubject,
	100, // Buffer size for deduping :: this will store the most recent 100 unique events (duplicates do not take up space)
	getDriftEventKey
); // This subject will act as the source of truth for all drift events that need to be handled by the toast logic. Does processing on the input subject to ensure that we only handle each event once, etc.

export type EventHandlingProps = {
	onParsedEvent: (event: MarketOrderToastStateTransitionEvent) => void;
	knownOrders: React.MutableRefObject<
		Map<MarketOrderToastId, MarketOrderToastOrderInfo>
	>;
	setOrUpdateKnownOrder: (
		orderId: MarketOrderToastId,
		newData: Partial<MarketOrderToastOrderInfo>
	) => void;
	checkOrderCompleteAfterUpdate: (orderId: MarketOrderToastId) => boolean;
	clearKnownOrder: (orderId: MarketOrderToastId) => void;
};

const useHandleOrderStateChanges = (props: EventHandlingProps) => {
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();

	// Check if order state syncing is disabled via feature flag
	const orderStateSyncingDisabled = useFeatureFlagStore((s) =>
		s.flagEnabled('DISABLE_ACCOUNT_ORDER_STATE_TOAST_SYNCING')
	);

	// # Add handling for order state read updates. As opposed to only relying on blockchain events, this allows data that we read directly from the user's account to be used to update the toast state as well. It is particularly useful for populating the information about the order if we don't receive the OrderRecord event, but we do receive the following ones.

	const userAccountOrders = useDriftAccountStore(
		(s) => s.accounts[s.currentUserKey]?.client.getUserAccount().orders
	); // Use the raw orders on the user account because the "open orders" in the Drift Store filter out ones which are cancelled/filled which we still want to read the state from for keeping the toasts in sync

	const marketOrders = useMemo(() => {
		if (!userAccountOrders || orderStateSyncingDisabled) {
			return [];
		}

		dlog(
			'v2_auctions',
			'market_orders_state',
			userAccountOrders
				.map((o) => `${o.orderId}:${ENUM_UTILS.toStr(o.status)}`)
				.join(', ')
		);
		return (
			userAccountOrders?.map((order) =>
				Serializer.Deserialize.UIOrder(Serializer.Serialize.Order(order))
			) ?? []
		) // Deserialize the raw orders into ones we care about
			.filter(
				(order) =>
					ENUM_UTILS.match(order.orderType, OrderType.MARKET) ||
					ENUM_UTILS.match(order.orderType, OrderType.ORACLE)
			) // Filter out non-market/oracle orders
			.filter(
				(order) =>
					ENUM_UTILS.match(order.status, OrderStatus.CANCELED) ||
					ENUM_UTILS.match(order.status, OrderStatus.FILLED) ||
					ENUM_UTILS.match(order.status, OrderStatus.OPEN)
			); // Filter orders with relevant status
	}, [userAccountOrders, orderStateSyncingDisabled]);

	const runMarketOrderStateThroughUpdateLogic = (
		marketOrder: UISerializableOrder
	) => {
		dlog('v2_auctions', 'runMarketOrderStateThroughUpdateLogic', marketOrder);
		if (DEV_DISABLE_ORDER_STATE_READS || orderStateSyncingDisabled) {
			return;
		}

		if (!currentUserRef.current) {
			throw new Error(
				'No current user pubkey when trying to handle market order events'
			);
		}

		if (!isRelevantOrderType(marketOrder.orderType)) {
			dlog(
				`v2_auctions`,
				`skipping_order_record::${ENUM_UTILS.toStr(marketOrder.orderType)}`
			);
			return;
		}

		const marketId = new MarketId(
			marketOrder.marketIndex,
			marketOrder.marketType
		);

		const marketOrderId = MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
			user: currentUserRef.current,
			marketId,
			orderId: marketOrder.orderId,
			direction: marketOrder.direction,
			baseSize: marketOrder.baseAssetAmount.val,
		});

		if (!marketOrderId) {
			// We're liable to have orders on the user account that come through here but aren't relevant for any current toasts - so we can just skip them here
			return;
		}

		const knownOrder = props.knownOrders.current.get(marketOrderId);

		// Update the known order state
		props.setOrUpdateKnownOrder(marketOrderId, {
			marketId,
			orderId: marketOrder.orderId,
			toastId: marketOrderId,
			cumulativeQuoteAmountFilled: BN.max(
				knownOrder?.cumulativeQuoteAmountFilled ?? ZERO,
				marketOrder.quoteAssetAmountFilled.val
			),
			cumulativeBaseAmountFilled: BN.max(
				knownOrder?.cumulativeBaseAmountFilled ?? ZERO,
				marketOrder.baseAssetAmountFilled.val
			),
			baseAmountOrdered: marketOrder.baseAssetAmount.val,
			direction: marketOrder.direction,
			auctionStartSlot: marketOrder.slot.toNumber(),
			auctionDuration: marketOrder.auctionDuration,
			maxTs: marketOrder.maxTs,
			auctionMaxPrice: marketOrder.auctionEndPrice.prettyPrint(true),
			startTs: marketOrder.ts,
			status: marketOrder.status,
		});

		const newOrderStateAfterUpdate =
			props.knownOrders.current.get(marketOrderId);

		props.onParsedEvent({
			type: 'order_state_read',
			toastId: marketOrderId,
			data: newOrderStateAfterUpdate,
		});
	};

	useEffect(() => {
		if (
			!marketOrders ||
			marketOrders.length === 0 ||
			orderStateSyncingDisabled
		) {
			return;
		}

		for (const marketOrder of marketOrders) {
			runMarketOrderStateThroughUpdateLogic(marketOrder);
		}
	}, [marketOrders, orderStateSyncingDisabled]);

	useEffect(() => {
		if (orderStateSyncingDisabled) return;

		DriftWindow.devEventEmitter.on(
			'market_order_testing__order_state_change',
			(orderState: UISerializableOrder) => {
				runMarketOrderStateThroughUpdateLogic(orderState);
			}
		);
	}, [orderStateSyncingDisabled]);
};

/**
 * This hook pipes the blockchain events through to the toast events subject to be deduped
 */
const usePipeBlockchainEventsToInput = () => {
	const userEventsObservable = useUserEventsObservable();
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();

	const pipeEventToSubject = (event: WrappedEvent<any>) => {
		inputToastDriftEventsSubject.next(event as OrderActionRecord);
	};

	const blockchainEventSyncingDisabled = useFeatureFlagStore((s) =>
		s.flagEnabled('DISABLE_BLOCKCHAIN_EVENTS_FOR_MARKET_ORDER_TOASTS')
	);

	useEffect(() => {
		if (blockchainEventSyncingDisabled) return;
		if (!userEventsObservable) return;

		const subscription = userEventsObservable.subscribe((event) => {
			if (!currentUserRef.current) {
				console.warn(
					`Got user event when there was no user account in MarketOrderToastEventProvider.`
				);
				return;
			}

			pipeEventToSubject(event);
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [userEventsObservable, blockchainEventSyncingDisabled]);
};

const useHandleOrderRecordFetchEvents = () => {
	const driftAppEventEmitter = useDriftStore((s) => s.appEventEmitter);

	const fetchOrderEvents = async (
		userAccount: PublicKey,
		marketId: MarketId
	) => {
		const dataApiEventSyncingDisabled = useFeatureFlagStore
			.getState()
			.flagEnabled('DISABLE_DATA_API_EVENTS_FOR_MARKET_ORDER_TOASTS');

		if (dataApiEventSyncingDisabled) {
			dlog(`v2_auctions`, `skipping_order_events_fetch_due_to_feature_flag`);
			return;
		}

		// ## Pipe Order Events

		// Fetch order events from the Data API
		const orderEventsResult =
			await DataApiClient.fetchShortTermData<JsonObject>('orders', undefined, {
				marketFilter: marketId.isPerp ? 'perp' : 'spot',
				userAccount: userAccount.toBase58(),
			});

		dlog(
			`v2_auctions`,
			`fetched_order_events::${marketId.key}::${userAccount.toBase58()}`,
			{
				orderEventsResult: orderEventsResult,
			}
		);

		if (orderEventsResult.success) {
			const orderRecords = orderEventsResult.records.map(
				Serializer.Deserialize.DataApiOrderRecord
			);

			for (const event of orderRecords) {
				inputToastDriftEventsSubject.next(event);
			}
		}
	};

	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['fetchMarketOrderToastOrderEvents'] =
			(userAccount, marketId) => {
				fetchOrderEvents(userAccount, marketId);
			};

		driftAppEventEmitter.on('fetchMarketOrderToastOrderEvents', handler);

		return () => {
			driftAppEventEmitter.off('fetchMarketOrderToastOrderEvents', handler);
		};
	}, [driftAppEventEmitter]);
};

const useHandleOrderActionRecordFetchEvents = () => {
	const driftAppEventEmitter = useDriftStore((s) => s.appEventEmitter);

	// ## Pipe Order Action Events
	const fetchOrderActionEvents = async (
		userAccount: PublicKey,
		orderId: number
	) => {
		const dataApiEventSyncingDisabled = useFeatureFlagStore
			.getState()
			.flagEnabled('DISABLE_DATA_API_EVENTS_FOR_MARKET_ORDER_TOASTS');

		if (dataApiEventSyncingDisabled) {
			dlog(
				`v2_auctions`,
				`skipping_order_action_events_fetch_due_to_feature_flag`
			);
			return;
		}

		const orderActionEventsResult =
			await DataApiClient.fetchShortTermData<JsonObject>(
				'orderActions',
				undefined,
				{
					userAccount: userAccount.toBase58(),
					orderId,
				}
			);

		dlog(
			`v2_auctions`,
			`fetched_order_action_events::${userAccount.toBase58()}::${orderId}`,
			{
				orderActionEventsResult,
			}
		);

		if (orderActionEventsResult.success) {
			const orderActionRecords = orderActionEventsResult.records.map(
				Serializer.Deserialize.DataApiOrderActionRecord
			);

			for (const event of orderActionRecords) {
				inputToastDriftEventsSubject.next(event);
			}
		}
	};

	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['fetchMarketOrderToastOrderActionEvents'] =
			(userAccount, orderId) => {
				fetchOrderActionEvents(userAccount, orderId);
			};

		driftAppEventEmitter.on('fetchMarketOrderToastOrderActionEvents', handler);

		return () => {
			driftAppEventEmitter.off(
				'fetchMarketOrderToastOrderActionEvents',
				handler
			);
		};
	}, [driftAppEventEmitter]);
};

/**
 * This hook pipes events from the Data API into the toast event input subject when the event stream is potentially unhealthy
 */
const useHandleFetchEvents = () => {
	useHandleOrderRecordFetchEvents();
	useHandleOrderActionRecordFetchEvents();
};

const useHandleOutputToastDriftEvents = (props: EventHandlingProps) => {
	const eventsObservable = outputToastDriftEventsSubject;
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();

	const knownOrders = props.knownOrders;

	const handleOrderActionRecord = async (
		event: WrappedEvent<'OrderActionRecord'>
	) => {
		if (DEV_ARTIFICIALLY_DELAY_ORDER_ACTION_EVENTS) {
			await sleep(ARTIFICIAL_DELAY_TIME);
		}

		const isPlaceEvent = ENUM_UTILS.match(event.action, OrderAction.PLACE);

		const actionTypeStr = ENUM_UTILS.toStr(event.action);

		const actionExplanationStr = ENUM_UTILS.toStr(event.actionExplanation);

		dlog(
			`v2_auctions`,
			`order_action_record::${actionTypeStr}`,
			`order_action_explanation::${actionExplanationStr}`
		);

		const orderAction = event.action;

		const isTaker = event?.taker?.equals(currentUserRef.current);
		const isMaker = event?.maker?.equals(currentUserRef.current);

		if (!isTaker && !isMaker) {
			return;
		}

		const toastId = getToastIdFromEvent(event, currentUserRef.current);

		if (!toastId) {
			// Bracket orders can create events which we won't have a toast ID to link to, so we're happy to ignore a non-matching toast ID ... this _is_ a problem if it happens outside of bracket orders though
			return;
		}

		const baseAmountOrdered = isTaker
			? event.takerOrderBaseAssetAmount
			: event.makerOrderBaseAssetAmount;

		const cumulativeBaseFilled = isTaker
			? event.takerOrderCumulativeBaseAssetAmountFilled
			: event.makerOrderCumulativeBaseAssetAmountFilled;

		const cumulativeQuoteFilled = isTaker
			? event.takerOrderCumulativeQuoteAssetAmountFilled
			: event.makerOrderCumulativeQuoteAssetAmountFilled;

		const prevKnownOrder = knownOrders.current.get(toastId);

		const orderCompletelyFilled = !!(
			prevKnownOrder?.baseAmountOrdered &&
			cumulativeBaseFilled.eq(prevKnownOrder?.baseAmountOrdered)
		);

		const orderEnded =
			ENUM_UTILS.match(orderAction, OrderAction.CANCEL) ||
			ENUM_UTILS.match(orderAction, OrderAction.EXPIRE);

		const updateData: Partial<MarketOrderToastOrderInfo> = {
			marketId: new MarketId(event.marketIndex, event.marketType),
			orderId: isTaker ? event.takerOrderId : event.makerOrderId,
			toastId: toastId,
			baseAmountOrdered,
			cumulativeQuoteAmountFilled: cumulativeQuoteFilled,
			cumulativeBaseAmountFilled: cumulativeBaseFilled,
			lastAction: event.action,
			lastActionExplanation: event.actionExplanation,
			direction: isTaker
				? event.takerOrderDirection
				: event.makerOrderDirection,
			auctionEnded:
				prevKnownOrder?.auctionEnded || orderCompletelyFilled || orderEnded,
		};

		if (isPlaceEvent) {
			updateData.startTs = event.ts;
		}

		props.setOrUpdateKnownOrder(toastId, updateData);

		// Get new order state after update
		const newOrderState = knownOrders.current.get(toastId);

		if (!newOrderState) {
			return;
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.CANCEL)) {
			if (
				ENUM_UTILS.match(
					event.actionExplanation,
					OrderActionExplanation.ORDER_EXPIRED
				)
			) {
				props.onParsedEvent({
					toastId: toastId,
					type: 'action_expired',
					data: newOrderState,
				});
			} else {
				props.onParsedEvent({
					toastId: toastId,
					type: 'action_cancelled',
					data: newOrderState,
				});
			}
			return;
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.EXPIRE)) {
			props.onParsedEvent({
				toastId: toastId,
				type: 'action_expired',
				data: newOrderState,
			});
			return;
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.FILL)) {
			if (orderCompletelyFilled) {
				// Is a completely filled order
				props.onParsedEvent({
					toastId: toastId,
					type: 'order_completely_filled',
					data: newOrderState,
				});
			} else {
				// Is a partially filled order
				props.onParsedEvent({
					toastId: toastId,
					type: 'receive_fill_action',
					data: newOrderState,
				});
			}
		}

		if (ENUM_UTILS.match(orderAction, OrderAction.PLACE)) {
			if (prevKnownOrder && !prevKnownOrder.auctionEnabled) {
				props.onParsedEvent({
					toastId: toastId,
					type: 'received_confirmation_and_auction_disabled',
					data: newOrderState,
				});
			} else {
				props.onParsedEvent({
					toastId: toastId,
					type:
						prevKnownOrder?.orderRoute === 'signedMsg'
							? 'receive_confirmation_signed_msg_order'
							: 'receive_confirmation',
					data: newOrderState,
				});
			}
		}
	};

	const handleOrderRecord = async (event: WrappedEvent<'OrderRecord'>) => {
		dlog('v2_auctions', 'handleOrderRecord', event);
		if (DEV_ARTIFICIALLY_DELAY_ORDER_RECORD_EVENTS) {
			await sleep(ARTIFICIAL_DELAY_TIME);
		}

		if (!isRelevantOrderType(event.order.orderType)) {
			dlog(
				`v2_auctions`,
				`skipping_order_record::${ENUM_UTILS.toStr(event.order.orderType)}`
			);
			return;
		}

		const marketId = new MarketId(
			event.order.marketIndex,
			event.order.marketType
		);

		const toastId = getToastIdFromEvent(event, currentUserRef.current);

		if (!toastId) {
			// Bracket orders can create events which we won't have a toast ID to link to, so we're happy to ignore a non-matching toast ID ... this _is_ a problem if it happens outside of bracket orders though
			return;
		}

		const knownOrder = knownOrders.current.get(toastId);

		if (!knownOrder) {
			return;
		}

		props.setOrUpdateKnownOrder(toastId, {
			marketId,
			toastId: toastId,
			orderId: event.order.orderId,
			baseAmountOrdered: event.order.baseAssetAmount,
			cumulativeBaseAmountFilled:
				knownOrder?.cumulativeBaseAmountFilled ?? ZERO,
			cumulativeQuoteAmountFilled:
				knownOrder?.cumulativeQuoteAmountFilled ?? ZERO,
			direction: event.order.direction,
			auctionStartSlot: event.order.slot.toNumber(),
			auctionDuration: event.order.auctionDuration,
			maxTs: event.order.maxTs,
			auctionMaxPrice: BigNum.from(
				event.order.auctionEndPrice,
				PRICE_PRECISION_EXP
			).prettyPrint(true),
			startTs: event.ts,
		});

		if (!knownOrder.auctionEnabled) {
			props.onParsedEvent({
				toastId: toastId,
				type: 'received_confirmation_and_auction_disabled',
				data: knownOrder,
			});
			return;
		}

		// Check if the order is already finished .. this can happen if we receive the order actions for the filled order before we receive the order record
		const orderIsFinished = props.checkOrderCompleteAfterUpdate(toastId);

		if (orderIsFinished) {
			const knownOrder = knownOrders.current.get(toastId);

			props.onParsedEvent({
				toastId: toastId,
				type: 'order_completely_filled',
				data: {
					cumulativeBaseAmountFilled: knownOrder.cumulativeBaseAmountFilled,
					cumulativeQuoteAmountFilled: knownOrder.cumulativeQuoteAmountFilled,
					...knownOrder,
				},
			});
		} else {
			props.onParsedEvent({
				toastId: toastId,
				type: 'receive_order_record',
				data: knownOrder,
			});
		}
	};

	useEffect(() => {
		if (!eventsObservable) return;

		const subscription = eventsObservable.subscribe((event) => {
			if (!currentUserRef.current) {
				console.warn(
					`Got user event when there was no user account in MarketOrderToastEventProvider.`
				);
				return;
			}

			switch (event.eventType) {
				case 'OrderActionRecord': {
					if (DEV_DISABLE_ORDER_ACTION_EVENTS) {
						break;
					}
					const typedEvent = event as WrappedEvent<'OrderActionRecord'>;
					handleOrderActionRecord(typedEvent);
					break;
				}
				case 'OrderRecord': {
					if (DEV_DISABLE_ORDER_RECORD_EVENTS) {
						break;
					}
					const typedEvent = event as WrappedEvent<'OrderRecord'>;
					handleOrderRecord(typedEvent);
					break;
				}
				default: {
					// Do nothing - These events don't matter to us
				}
			}
		});

		return () => {
			subscription.unsubscribe();
		};
	}, [eventsObservable]);

	useEffect(() => {
		// Allow dev testing spoof events to go through the regular event handling logic
		DriftWindow.devEventEmitter.on(
			'market_order_testing__order_action_event',
			(event) => {
				handleOrderActionRecord(event);
			}
		);

		DriftWindow.devEventEmitter.on(
			'market_order_testing__order_record_event',
			(event) => {
				handleOrderRecord(event);
			}
		);
	}, []);
};

const useHandleAppEvents = (hookProps: EventHandlingProps) => {
	const appEventEmitter = useAppEventEmitter();
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();
	const connection = useDriftStore((s) => s.connection.current);
	const marketInfoStoreGetter = useMarketsInfoStore((s) => s.get);
	const { captureEvent } = usePostHogCapture();

	// # Handle expect incoming auction
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionInit'] =
			(handlerProps) => {
				const marketId = handlerProps.v2Props.marketId;

				const toastId =
					MarketOrderToastIdHandler.handleNewMarketOrderWithUnknownOrderId({
						idNonce: handlerProps.v2Props.identifierNonce,
						user: currentUserRef.current,
						marketId,
						baseSize: handlerProps.v2Props.baseAmountOrdered,
						direction: handlerProps.v2Props.direction,
					});

				const orderProps: Partial<MarketOrderToastOrderInfo> = {
					marketId: handlerProps.v2Props.marketId,
					toastId: toastId,
					cumulativeBaseAmountFilled: ZERO,
					cumulativeQuoteAmountFilled: ZERO,
					baseAmountOrdered: handlerProps.v2Props.baseAmountOrdered,
					direction: handlerProps.v2Props.direction,
					auctionEnabled: handlerProps.v2Props.auctionEnabled,
					lastAction: undefined,
					lastActionExplanation: undefined,
					auctionStartSlot: undefined,
					auctionDuration: undefined,
					maxTs: undefined,
					includesSlOrder: handlerProps.v2Props.includesSlOrder,
					includesTpOrder: handlerProps.v2Props.includesTpOrder,
					stepSize: getMarketStepSize(marketId, marketInfoStoreGetter),
				};

				hookProps.setOrUpdateKnownOrder(toastId, orderProps);

				hookProps.onParsedEvent({
					type: 'init',
					toastId: toastId,
					data: orderProps,
				});
			};

		appEventEmitter.on('expectedAuctionInit', handler);

		return () => {
			appEventEmitter.off('expectedAuctionInit', handler);
		};
	}, [appEventEmitter]);

	// # Handle cancel expected auction
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionTxFailed'] =
			(handlerProps) => {
				let marketOrderId: MarketOrderToastId;

				try {
					marketOrderId =
						MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
							user: currentUserRef.current,
							idNonce: handlerProps.v2Props.identifierNonce,
							marketId: handlerProps.v2Props.marketId,
							baseSize: handlerProps.v2Props.baseAmountOrdered,
							direction: handlerProps.v2Props.direction,
						});

					MarketOrderToastIdHandler.clearStateForUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.v2Props.identifierNonce,
						marketId: handlerProps.v2Props.marketId,
						baseSize: handlerProps.v2Props.baseAmountOrdered,
						direction: handlerProps.v2Props.direction,
					});
				} catch (e) {
					// It's ok if we can't find the toast id when handling a failure event
					return;
				}

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (!knownOrder) {
					return;
				}

				hookProps.onParsedEvent({
					toastId: marketOrderId,
					type: 'tx_failed',
					data: knownOrder,
				});

				hookProps.clearKnownOrder(marketOrderId);
			};

		appEventEmitter.on('expectedAuctionTxFailed', handler);

		return () => {
			appEventEmitter.off('expectedAuctionTxFailed', handler);
		};
	}, [appEventEmitter]);

	// # Handle market order confirmed
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionTxConfirmed'] =
			(handlerProps) => {
				if (DEV_DISABLE_TX_CONFIRMED_EVENT) {
					return;
				}

				const marketOrderId =
					MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.v2Props.identifierNonce,
						marketId: handlerProps.v2Props.marketId,
						baseSize: handlerProps.v2Props.baseAmountOrdered,
						direction: handlerProps.v2Props.direction,
					});

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (!knownOrder) {
					return;
				}

				hookProps.onParsedEvent({
					toastId: marketOrderId,
					type: knownOrder.auctionEnabled
						? 'receive_confirmation'
						: 'received_confirmation_and_auction_disabled',
					data: knownOrder,
				});
			};

		appEventEmitter.on('expectedAuctionTxConfirmed', handler);

		return () => {
			appEventEmitter.off('expectedAuctionTxConfirmed', handler);
		};
	}, [appEventEmitter]);

	// # Handle Tx Signed
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['expectedAuctionTxSigned'] =
			async (handlerProps) => {
				const toastId = MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
					user: currentUserRef.current,
					idNonce: handlerProps.v2Props.identifierNonce,
					marketId: handlerProps.v2Props.marketId,
					baseSize: handlerProps.v2Props.baseAmountOrdered,
					direction: handlerProps.v2Props.direction,
				});

				const knownOrder = hookProps.knownOrders.current.get(toastId);

				if (!knownOrder) {
					const errorMessage = `Could not find known order for toast id ${toastId} when handling tx signed event`;

					captureEvent('market_order_errors', {
						message: errorMessage,
					});

					throw new Error(errorMessage);
				}

				let signedTxLastValidBlockHeight =
					handlerProps?.signedTxData?.[0]?.lastValidBlockHeight;

				if (!signedTxLastValidBlockHeight || DEV_FORCE_NO_TX_EXPIRY_ATTCHED) {
					const errorMessage = `Received expectedAuctionTxSigned event without a lastValidBlockHeight in the signedTxData`;

					dlog(
						`v2_auctions`,
						`no_last_valid_block_height_on_tx_signed => falling back to current block height`
					);

					captureEvent('market_order_errors', {
						message: errorMessage,
					});

					const currentBlockHeightResult =
						await connection.getLatestBlockhashAndContext();
					signedTxLastValidBlockHeight =
						currentBlockHeightResult.value.lastValidBlockHeight;
				}

				const useOffset = getShouldUseBlockHeightOffset();

				const txExpiryBlockHeight =
					signedTxLastValidBlockHeight +
					(useOffset ? SOLANA_TRANSACTION_BLOCK_HEIGHT_EXPIRY_OFFSET : 0);

				dlog(
					`v2_auctions`,
					`set_expiry_slot_with_tx_signature .. ${txExpiryBlockHeight}`
				);

				hookProps.setOrUpdateKnownOrder(toastId, {
					transactionExpiryBlockHeight: txExpiryBlockHeight,
					signed: true,
				});

				const newOrderStateAfterUpdate =
					hookProps.knownOrders.current.get(toastId);

				hookProps.onParsedEvent({
					toastId: toastId,
					type: 'tx_signed',
					data: newOrderStateAfterUpdate,
				});
			};

		appEventEmitter.on('expectedAuctionTxSigned', handler);

		return () => {
			appEventEmitter.off('expectedAuctionTxSigned', handler);
		};
	}, [appEventEmitter, connection]);

	// # Handle signed message order initialized
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['signedMsgOrderInit'] =
			(handlerProps) => {
				const marketId = handlerProps.orderProps.marketId;

				const orderId =
					MarketOrderToastIdHandler.handleNewMarketOrderWithUnknownOrderId({
						idNonce: handlerProps.orderProps.identifierNonce,
						user: currentUserRef.current,
						marketId,
						baseSize: handlerProps.orderProps.baseAmountOrdered,
						direction: handlerProps.orderProps.direction,
					});

				const orderProps: Partial<MarketOrderToastOrderInfo> = {
					marketId: handlerProps.orderProps.marketId,
					toastId: orderId,
					cumulativeBaseAmountFilled: ZERO,
					cumulativeQuoteAmountFilled: ZERO,
					baseAmountOrdered: handlerProps.orderProps.baseAmountOrdered,
					direction: handlerProps.orderProps.direction,
					auctionEnabled:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration > 0,
					lastAction: undefined,
					lastActionExplanation: undefined,
					auctionStartSlot: handlerProps.signedMsgOrderPayload?.slot.toNumber(),
					auctionDuration:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration,
					maxTs: undefined,
					includesSlOrder: handlerProps.orderProps.includesSlOrder,
					includesTpOrder: handlerProps.orderProps.includesTpOrder,
					stepSize: getMarketStepSize(marketId, marketInfoStoreGetter),
					orderRoute: 'signedMsg',
				};

				hookProps.setOrUpdateKnownOrder(orderId, orderProps);

				hookProps.onParsedEvent({
					type: 'signed_msg_order_init',
					toastId: orderId,
					data: orderProps,
				});
			};

		appEventEmitter.on('signedMsgOrderInit', handler);

		return () => {
			appEventEmitter.off('signedMsgOrderInit', handler);
		};
	}, [appEventEmitter]);

	// # Handle signed message order not signed in time
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['signedMsgOrderMessageSlotExpired'] =
			(handlerProps) => {
				const marketOrderId =
					MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.orderProps.identifierNonce,
						marketId: handlerProps.orderProps.marketId,
						baseSize: handlerProps.orderProps.baseAmountOrdered,
						direction: handlerProps.orderProps.direction,
					});

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (knownOrder?.signed) {
					return;
				}

				const orderProps: Partial<MarketOrderToastOrderInfo> = {
					marketId: handlerProps.orderProps.marketId,
					toastId: marketOrderId,
					cumulativeBaseAmountFilled: ZERO,
					cumulativeQuoteAmountFilled: ZERO,
					baseAmountOrdered: handlerProps.orderProps.baseAmountOrdered,
					direction: handlerProps.orderProps.direction,
					auctionEnabled:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration > 0,
					lastAction: undefined,
					lastActionExplanation: undefined,
					auctionStartSlot: handlerProps.signedMsgOrderPayload?.slot.toNumber(),
					auctionDuration:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration,
					maxTs: undefined,
					includesSlOrder: handlerProps.orderProps.includesSlOrder,
					includesTpOrder: handlerProps.orderProps.includesTpOrder,
					stepSize: getMarketStepSize(
						handlerProps.orderProps.marketId,
						marketInfoStoreGetter
					),
				};

				hookProps.onParsedEvent({
					type: 'signed_msg_order_message_slot_expired',
					toastId: marketOrderId,
					data: orderProps,
				});

				MarketOrderToastIdHandler.clearStateForUnknownOrderId({
					idNonce: handlerProps.orderProps.identifierNonce,
					user: currentUserRef.current,
					marketId: handlerProps.orderProps.marketId,
					baseSize: handlerProps.orderProps.baseAmountOrdered,
					direction: handlerProps.orderProps.direction,
				});
			};

		appEventEmitter.on('signedMsgOrderMessageSlotExpired', handler);

		return () => {
			appEventEmitter.off('signedMsgOrderMessageSlotExpired', handler);
		};
	}, [appEventEmitter]);

	// # Handle signed message order signed
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['signedMsgOrderSigned'] =
			(handlerProps) => {
				const marketOrderId =
					MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.orderProps.identifierNonce,
						marketId: handlerProps.orderProps.marketId,
						baseSize: handlerProps.orderProps.baseAmountOrdered,
						direction: handlerProps.orderProps.direction,
					});

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (!knownOrder) {
					const errorMessage = `Could not find known order for toast id ${marketOrderId} when handling tx signed event`;

					captureEvent('market_order_errors', {
						message: errorMessage,
					});

					throw new Error(errorMessage);
				}

				const orderProps: Partial<MarketOrderToastOrderInfo> = {
					marketId: handlerProps.orderProps.marketId,
					toastId: marketOrderId,
					cumulativeBaseAmountFilled: ZERO,
					cumulativeQuoteAmountFilled: ZERO,
					baseAmountOrdered: handlerProps.orderProps.baseAmountOrdered,
					direction: handlerProps.orderProps.direction,
					auctionEnabled:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration > 0,
					lastAction: undefined,
					lastActionExplanation: undefined,
					auctionStartSlot: handlerProps.signedMsgOrderPayload?.slot.toNumber(),
					auctionDuration:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration,
					maxTs: undefined,
					includesSlOrder: handlerProps.orderProps.includesSlOrder,
					includesTpOrder: handlerProps.orderProps.includesTpOrder,
					stepSize: getMarketStepSize(
						handlerProps.orderProps.marketId,
						marketInfoStoreGetter
					),
					signed: true,
				};

				hookProps.setOrUpdateKnownOrder(marketOrderId, orderProps);

				hookProps.onParsedEvent({
					toastId: marketOrderId,
					type: 'tx_signed',
					data: orderProps,
				});
			};

		appEventEmitter.on('signedMsgOrderSigned', handler);

		return () => {
			appEventEmitter.off('signedMsgOrderSigned', handler);
		};
	}, [appEventEmitter]);

	// # Handle signed message order expired
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['signedMsgOrderExpired'] =
			(handlerProps) => {
				const marketOrderId =
					MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.orderProps.identifierNonce,
						marketId: handlerProps.orderProps.marketId,
						baseSize: handlerProps.orderProps.baseAmountOrdered,
						direction: handlerProps.orderProps.direction,
					});

				const orderProps: Partial<MarketOrderToastOrderInfo> = {
					marketId: handlerProps.orderProps.marketId,
					toastId: marketOrderId,
					cumulativeBaseAmountFilled: ZERO,
					cumulativeQuoteAmountFilled: ZERO,
					baseAmountOrdered: handlerProps.orderProps.baseAmountOrdered,
					direction: handlerProps.orderProps.direction,
					auctionEnabled:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration > 0,
					lastAction: undefined,
					lastActionExplanation: undefined,
					auctionStartSlot: handlerProps.signedMsgOrderPayload?.slot.toNumber(),
					auctionDuration:
						handlerProps.signedMsgOrderPayload?.signedMsgOrderParams
							.auctionDuration,
					maxTs: undefined,
					includesSlOrder: handlerProps.orderProps.includesSlOrder,
					includesTpOrder: handlerProps.orderProps.includesTpOrder,
					stepSize: getMarketStepSize(
						handlerProps.orderProps.marketId,
						marketInfoStoreGetter
					),
				};

				hookProps.setOrUpdateKnownOrder(marketOrderId, orderProps);

				hookProps.onParsedEvent({
					type: 'signed_msg_order_expired',
					toastId: marketOrderId,
					data: orderProps,
				});

				MarketOrderToastIdHandler.clearStateForUnknownOrderId({
					idNonce: handlerProps.orderProps.identifierNonce,
					user: currentUserRef.current,
					marketId: handlerProps.orderProps.marketId,
					baseSize: handlerProps.orderProps.baseAmountOrdered,
					direction: handlerProps.orderProps.direction,
				});
			};

		appEventEmitter.on('signedMsgOrderExpired', handler);

		return () => {
			appEventEmitter.off('signedMsgOrderExpired', handler);
		};
	}, [appEventEmitter]);

	// # Handle signed message order confirmed
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['signedMsgOrderConfirmed'] =
			(handlerProps) => {
				const marketOrderId = handlerProps.orderId
					? MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
							user: currentUserRef.current,
							marketId: handlerProps.orderProps.marketId,
							orderId: handlerProps.orderId,
							baseSize: handlerProps.orderProps.baseAmountOrdered,
							direction: handlerProps.orderProps.direction,
					  })
					: MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
							user: currentUserRef.current,
							idNonce: handlerProps.orderProps.identifierNonce,
							marketId: handlerProps.orderProps.marketId,
							baseSize: handlerProps.orderProps.baseAmountOrdered,
							direction: handlerProps.orderProps.direction,
					  });

				const order = hookProps.knownOrders.current.get(marketOrderId);

				if (!order) {
					return;
				}

				// update the order with the order ID from confirmation
				if (handlerProps.orderId) {
					const orderProps: Partial<MarketOrderToastOrderInfo> = {
						...order,
						orderId: handlerProps.orderId,
					};

					hookProps.setOrUpdateKnownOrder(marketOrderId, orderProps);
				}
				const auctionEnabled = order.auctionEnabled;

				hookProps.onParsedEvent({
					type: auctionEnabled
						? 'receive_confirmation_signed_msg_order'
						: 'received_confirmation_and_auction_disabled',
					toastId: marketOrderId,
					data: order,
				});
			};

		appEventEmitter.on('signedMsgOrderConfirmed', handler);

		return () => {
			appEventEmitter.off('signedMsgOrderConfirmed', handler);
		};
	}, [appEventEmitter]);

	// # Handle signed message order not confirmed
	useEffect(() => {
		const handler: DriftAppEventEmitter[' _eventsType']['signedMsgOrderFailed'] =
			(handlerProps) => {
				let marketOrderId: MarketOrderToastId;

				try {
					marketOrderId = handlerProps.orderId
						? MarketOrderToastIdHandler.getToastIdWithKnownOrderId({
								user: currentUserRef.current,
								marketId: handlerProps.orderProps.marketId,
								orderId: handlerProps.orderId,
								baseSize: handlerProps.orderProps.baseAmountOrdered,
								direction: handlerProps.orderProps.direction,
						  })
						: MarketOrderToastIdHandler.getToastIdWithUnknownOrderId({
								user: currentUserRef.current,
								idNonce: handlerProps.orderProps.identifierNonce,
								marketId: handlerProps.orderProps.marketId,
								baseSize: handlerProps.orderProps.baseAmountOrdered,
								direction: handlerProps.orderProps.direction,
						  });

					MarketOrderToastIdHandler.clearStateForUnknownOrderId({
						user: currentUserRef.current,
						idNonce: handlerProps.orderProps.identifierNonce,
						marketId: handlerProps.orderProps.marketId,
						baseSize: handlerProps.orderProps.baseAmountOrdered,
						direction: handlerProps.orderProps.direction,
					});
				} catch (e) {
					// It's ok if we can't find the toast id when handling a failure event
					return;
				}

				const knownOrder = hookProps.knownOrders.current.get(marketOrderId);

				if (!knownOrder) {
					return;
				}

				hookProps.onParsedEvent({
					toastId: marketOrderId,
					type: 'tx_failed',
					data: {
						...knownOrder,
						failureMessage: handlerProps.message,
					},
				});

				hookProps.clearKnownOrder(marketOrderId);
			};

		appEventEmitter.on('signedMsgOrderFailed', handler);

		return () => {
			appEventEmitter.off('signedMsgOrderFailed', handler);
		};
	}, [appEventEmitter]);
};

/**
 * While there are active open toast orders, we determine the health of the event stream based on the delay since the last time we received an event
 * @param hasActiveOpenToastOrders
 * @returns
 */
const useDetectPotentialUnhealthyBlockchainEvents = (
	hasActiveOpenToastOrders: boolean
) => {
	const globalEventsObservable = useGlobalFillEventsObservable();
	const [lastEventTimestamp, setLastEventTimestamp] = useState<number>(
		Date.now()
	);
	const [isUnhealthy, setIsUnhealthy] = useState(false);

	// Reset the last event timestamp whenever we newly enter the state where there are active open toast orders
	useEffect(() => {
		if (hasActiveOpenToastOrders) {
			setLastEventTimestamp(Date.now());
		}
	}, [hasActiveOpenToastOrders]);

	const blockchainEventSyncingDisabled = useFeatureFlagStore((s) =>
		s.flagEnabled('DISABLE_BLOCKCHAIN_EVENTS_FOR_MARKET_ORDER_TOASTS')
	);

	// Track the timestamps of events coming through
	useEffect(() => {
		if (!globalEventsObservable) return;
		if (blockchainEventSyncingDisabled) return; // Skip if blockchain events are disabled

		const subscription = globalEventsObservable.subscribe(() => {
			setLastEventTimestamp(Date.now());
			setIsUnhealthy(false); // Reset unhealthy state when we get a new event
		});

		return () => subscription.unsubscribe();
	}, [globalEventsObservable, blockchainEventSyncingDisabled]);

	// Periodically check if we've gone too long without events while we have active open toast orders
	useEffect(() => {
		if (!hasActiveOpenToastOrders) {
			setIsUnhealthy(false);
			return;
		}

		const interval = setInterval(() => {
			const timeSinceLastEvent = Date.now() - lastEventTimestamp;
			if (timeSinceLastEvent > NO_EVENTS_BAD_HEALTH_THRESHOLD) {
				setIsUnhealthy(true);
			}
		}, 1000);

		return () => clearInterval(interval);
	}, [hasActiveOpenToastOrders, lastEventTimestamp]);

	return isUnhealthy;
};

/**
 * This hook triggers fetch events when we detect the event stream might be unhealthy and we want to try get events from an alternative source
 * @param knownOrdersRef
 * @param appEventEmitter
 */
const useTriggerFetchEvents = (
	knownOrdersRef: React.MutableRefObject<
		Map<MarketOrderToastId, MarketOrderToastOrderInfo>
	>,
	appEventEmitter: DriftAppEventEmitter
) => {
	const currentUserRef = useCurrentUserForMarketOrderEventsRef();
	const hasActiveOpenToastOrders = Array.from(
		knownOrdersRef.current.values()
	).some(
		(order) => order.signed && !order.auctionEnded // Check that the order has been signed because we don't want to trigger fetches while waiting for the user to sign the transaction
	);
	const hasOpenOrdersWithNoOrderId = Array.from(
		knownOrdersRef.current.values()
	).some(
		(order) => order.orderId == null && order.signed && !order.auctionEnded // Check that the order has been signed because we don't want to trigger fetches while waiting for the user to sign the transaction
	);
	const hasActiveOpenOrdersWithOrderId = Array.from(
		knownOrdersRef.current.values()
	).some((order) => order.orderId != null && !order.auctionEnded);

	const eventStreamIsPotentiallyUnhealthy =
		useDetectPotentialUnhealthyBlockchainEvents(hasActiveOpenToastOrders);

	// If we have open orders with no Order ID and we think we might have an unhealthy event stream, trigger fetches for the order events from an alternative source
	useEffect(() => {
		if (!eventStreamIsPotentiallyUnhealthy) return;
		if (!hasOpenOrdersWithNoOrderId) return;

		dlog(
			`v2_auctions`,
			`unhealthy_stream::polling_for_order_events`,
			knownOrdersRef.current
		);

		const interval = setInterval(() => {
			const orders = Array.from(knownOrdersRef.current.values());
			const relevantOrders = orders.filter((order) => order.orderId == null); // We only need to fetch order events for toasts where we don't have an order ID
			relevantOrders.forEach((orderState) => {
				const userKey = currentUserRef.current;
				const marketId = orderState.marketId;
				appEventEmitter.emit(
					'fetchMarketOrderToastOrderEvents',
					userKey,
					marketId
				);
			});
		}, REDUNDANT_EVENT_POLLING_INTERVAL);

		return () => clearInterval(interval);
	}, [eventStreamIsPotentiallyUnhealthy, hasOpenOrdersWithNoOrderId]);

	// If we have open orders with an Order ID, and we think we might have an unhealthy event stream, trigger fetches for the order actions from an alternative source
	useEffect(() => {
		if (!eventStreamIsPotentiallyUnhealthy) return;
		if (!hasActiveOpenOrdersWithOrderId) return;

		dlog(
			`v2_auctions`,
			`unhealthy_stream::polling_for_order_actions`,
			knownOrdersRef.current
		);

		const interval = setInterval(() => {
			const orders = Array.from(knownOrdersRef.current.values());
			const relevantOrders = orders.filter((order) => order.orderId ?? null); // Relevant toasts are those where we have an order ID for
			relevantOrders.forEach((order) => {
				const userKey = currentUserRef.current;
				appEventEmitter.emit(
					'fetchMarketOrderToastOrderActionEvents',
					userKey,
					order.orderId
				);
			});
		}, REDUNDANT_EVENT_POLLING_INTERVAL);

		return () => clearInterval(interval);
	}, [eventStreamIsPotentiallyUnhealthy, hasActiveOpenOrdersWithOrderId]);
};

/**
 * This hook provides a subject for domain-specific Market Order events. It listens to a variety of sources to determine which events to output, to increase reliability, including blockchain events, user events, and app events.
 * @param props
 * @returns
 */
export const MarketOrderToastEventEmitterProvider = (
	props: PropsWithChildren<any>
) => {
	const contextValue = INITIAL_CONTEXT_VALUE;
	const marketOrderToastEventsSubject = contextValue.subject;
	const currentSlotRef = useCurrentSlotRef(1000);
	const appEventEmitter = useAppEventEmitter();

	const knownOrdersRef = useRef<
		Map<MarketOrderToastId, MarketOrderToastOrderInfo>
	>(new Map());

	const setOrUpdateKnownOrder = (
		orderId: MarketOrderToastId,
		newData: Partial<MarketOrderToastOrderInfo>
	) => {
		const existing = knownOrdersRef.current.get(orderId);

		if (existing) {
			knownOrdersRef.current.set(orderId, {
				...existing,
				...newData,
			});
		} else {
			knownOrdersRef.current.set(orderId, newData as MarketOrderToastOrderInfo);
		}
	};

	const clearKnownOrder = (orderId: MarketOrderToastId) => {
		knownOrdersRef.current.delete(orderId);
	};

	const checkOrderCompleteAfterUpdate = (orderId: MarketOrderToastId) => {
		const order = knownOrdersRef.current.get(orderId);

		if (!order) return false;

		return (
			order.baseAmountOrdered?.eq(order.cumulativeBaseAmountFilled) ?? false
		);
	};

	// # Set up the handler to clear known orders when the toast is removed
	useEffect(() => {
		appEventEmitter.on(
			'marketOrderToastRemoved',
			(toastId: MarketOrderToastId) => {
				clearKnownOrder(toastId);
			}
		);
	}, [appEventEmitter]);

	// #  ⭐️ The CORE logic for triggering market order events happens in the below hooks

	// Pipe events from blockchain source into the input subject
	usePipeBlockchainEventsToInput();

	// ## Handle the output Drift Events which need to be handled by the toast logic
	useHandleOutputToastDriftEvents({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders: knownOrdersRef,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// ## Trigger events from event event emitter source
	useHandleAppEvents({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders: knownOrdersRef,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// ## Trigger events from live order state changes
	useHandleOrderStateChanges({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders: knownOrdersRef,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// ## Event Stream Redundancy hooks
	useTriggerFetchEvents(knownOrdersRef, appEventEmitter); // This hook triggers the fetch events when we think we might have an unhealthy event stream
	useHandleFetchEvents(); // This hook handles the fetch events and passes the redundant events through to the input subject

	// ## Dev testing
	useDevMarketOrderEvents({
		onParsedEvent: (event) => {
			marketOrderToastEventsSubject.next(event);
		},
		knownOrders: knownOrdersRef,
		setOrUpdateKnownOrder,
		checkOrderCompleteAfterUpdate,
		clearKnownOrder,
	});

	// # Handle slot progress
	useInterval(() => {
		knownOrdersRef.current.forEach((order, toastId) => {
			// # Catch Auction Expired
			//// If the auction has already ended or we don't have the necessary data, don't do anything
			const canSkipCheckingAuctionExpired =
				order.orderId == undefined || // If we don't have the order ID then we haven't had the order confirmation necessary to know when the auction will start
				order.auctionEnded ||
				!order?.auctionStartSlot ||
				!order?.auctionDuration ||
				!order?.auctionEnabled ||
				!currentSlotRef.current;

			if (!canSkipCheckingAuctionExpired) {
				const startSlot = order?.auctionStartSlot;
				const duration = order?.auctionDuration;
				const currentSlot = currentSlotRef.current;

				const auctionIsExpired = currentSlot > startSlot + duration;

				setOrUpdateKnownOrder(toastId, {
					auctionEnded: auctionIsExpired || order.auctionEnded,
				});

				if (auctionIsExpired) {
					marketOrderToastEventsSubject.next({
						type: 'auction_finished',
						toastId: toastId,
						data: order,
					});
				}
			}

			// # Catch Order Expired
			const currentTs = DriftWindow.chainClock.getState(
				DEFAULT_COMMITMENT_LEVEL
			).ts;

			const canSkipCheckingOrderExpired = !order?.maxTs || !currentTs;

			if (!canSkipCheckingOrderExpired) {
				const orderIsExpired = currentTs > order.maxTs.toNumber();
				if (orderIsExpired) {
					dlog(
						`v2_auctions`,
						`triggering_implied_expired_event`,
						toastId,
						order
					);
					// This won't happen for swift orders if they don't land on-chain - because there won't be an order record, so no maxTs to compare against
					marketOrderToastEventsSubject.next({
						type: 'action_expired',
						toastId: toastId,
						data: order,
					});
				}
			}
		});
	}, 2_000);

	return (
		<MarketOrderToastEventContext value={contextValue}>
			{props.children}
		</MarketOrderToastEventContext>
	);
};
