'use client';

import {
	dateToS3DateString,
	DownloadPeriod,
	DownloadRecordType,
	getDateRangeFromSelection,
	getFileRedisKeyFromParams,
} from '@drift/common';
import Env, {
	CurrentPerpMarkets,
	CurrentSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import { useEffect, useMemo, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import Button from './Button';
import Select from './Inputs/Select';
import Modal from './Modals/Modal';
import TradeFormInputLabel from './TradeForm/TradeFormInputLabel';
import useCurrentAuthority from 'src/hooks/useCurrentAuthority';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import {
	PerpMarketConfig,
	PublicKey,
	QUOTE_SPOT_MARKET_INDEX,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import useWalletIsConnected from 'src/hooks/useWalletIsConnected';
import { notify } from 'src/utils/notifications';
import { useLocalStorageState } from 'src/utils/localStorageUtils';
import { Info } from '@drift-labs/icons';
import Text from 'src/components/Text/Text';
import UI_UTILS from 'src/utils/uiUtils';
import InfoMessage from './TradeForm/InfoMessage';

export const DownloadPeriods = UI_UTILS.getDownloadPeriods();

export const DownloadTypes = [
	{
		label: 'Trades',
		value: 'trades',
	},
	{
		label: 'Market Trades',
		value: 'market-trades',
	},
	{
		label: 'Settle P&L Records',
		value: 'settle-pnl-records',
	},
	{
		label: 'Funding Rates',
		value: 'funding-rates',
	},
	{
		label: 'Funding Payments',
		value: 'funding-payments',
	},
	{
		label: 'Deposits/Withdrawals',
		value: 'deposits',
	},
	{
		label: 'Liquidations',
		value: 'liquidations',
	},
	{
		label: 'BAL Records',
		value: 'lp-records',
	},
	{
		label: 'IF Stake Records',
		value: 'if-stake-records',
	},
	{
		label: 'Swap Records',
		value: 'swap-records',
	},
];

export const Months = [
	{
		label: 'All',
		value: 0,
	},
	{
		label: 'Jan.',
		value: 1,
	},
	{
		label: 'Feb.',
		value: 2,
	},
	{
		label: 'Mar.',
		value: 3,
	},
	{
		label: 'Apr.',
		value: 4,
	},
	{
		label: 'May',
		value: 5,
	},
	{
		label: 'Jun.',
		value: 6,
	},
	{
		label: 'Jul.',
		value: 7,
	},
	{
		label: 'Aug.',
		value: 8,
	},
	{
		label: 'Sep.',
		value: 9,
	},
	{
		label: 'Oct.',
		value: 10,
	},
	{
		label: 'Nov.',
		value: 11,
	},
	{
		label: 'Dec.',
		value: 12,
	},
];

const Days = [{ label: 'All', value: 0 }].concat(
	Array(31)
		.join()
		.split(',')
		.map(
			function (_a) {
				// @ts-ignore TODO: is there a better way to do this? didn't want to change it during fixing type errors and cause bugs
				return this.i++;
			},
			{ i: 1 } as { i: number }
		)
		.map((val) => {
			return {
				label: val.toString(),
				value: val,
			};
		})
);

const isUserDownload = (downloadType: DownloadRecordType) =>
	[
		'trades',
		'deposits',
		'liquidations',
		'funding-payments',
		'settle-pnl-records',
		'lp-records',
		'swap-records',
	].includes(downloadType);

const isMarketDownload = (downloadType: DownloadRecordType) =>
	downloadType === 'market-trades' || downloadType === 'funding-rates';

const filterDownloadPeriodsForType = (
	downloadPeriod: string,
	downloadType: DownloadRecordType
) => {
	return isUserDownload(downloadType)
		? true
		: downloadPeriod === 'week' ||
				downloadPeriod === 'month' ||
				downloadPeriod.includes('custom');
};

export const Downloader = (props: {
	recordType: DownloadRecordType;
	from: string;
	to: string;
	userKeys: string[];
	handleClose: () => void;
	accountOptions: { userKey: string; name: string; pubKey: PublicKey }[];
	marketSymbol?: string;
}) => {
	const currentAuthority = useCurrentAuthority();
	const isEmulationMode = useDriftStore((s) => s.isEmulatingAccount);

	// Comma-separated list of pending download keys so we can detect if a new one becomes available
	const [pendingDownloadKeys, setPendingDownloadKeys] = useLocalStorageState(
		'pendingDownloadKeys',
		''
	);

	const [infoMessage, setInfoMessage] = useState('');

	const [isDownloading, setIsDownloading] = useState(false);

	const downloadIsValid = useMemo(() => {
		if (isMarketDownload(props.recordType) && !props.marketSymbol) {
			setInfoMessage('Select a market');
			return false;
		}

		if (
			isUserDownload(props.recordType) &&
			(!props.userKeys || props.userKeys.length === 0)
		) {
			setInfoMessage('Select an account');
			return false;
		}

		return true;
	}, [props]);

	const requestFileDownload = async () => {
		const result = await ExchangeHistoryClient.requestFileDownload({
			fileType: props.recordType,
			from: props.from,
			to: props.to,
			userPubKeys: props.userKeys.map(
				(userKey) =>
					props.accountOptions.find((acct) => acct.userKey === userKey)?.pubKey
			),
			authority: currentAuthority?.toString(),
			isEmulationMode,
			marketSymbol: props.marketSymbol,
		});

		if (!result.success) {
			notify({
				type: 'error',
				message: 'Error',
				description:
					'There was an error requesting the statement(s). Please try again later.',
			});

			console.error(result.message);

			return false;
		}

		const currentDate = new Date();
		const utcDate = new Date(
			currentDate.getUTCFullYear(),
			currentDate.getUTCMonth(),
			currentDate.getUTCDate()
		);

		const newKeysRequestedStr = props.userKeys
			.map((userKey) => {
				return getFileRedisKeyFromParams({
					fileType: props.recordType,
					requestedDate: dateToS3DateString(utcDate),
					fromDate: props.from,
					toDate: props.to,
					user: props.accountOptions
						.find((acct) => acct.userKey === userKey)
						?.pubKey?.toString(),
					programId: Env.driftClientProgramId,
					authority: currentAuthority?.toString(),
					isEmulationMode,
					marketSymbol: props.marketSymbol ?? 'none',
				});
			})
			.concat(pendingDownloadKeys?.split(',') ?? [])
			.join(',');

		setPendingDownloadKeys(newKeysRequestedStr);

		notify({
			type: 'info',
			message: 'Generating your statement(s)',
			description:
				'This can take up to 10 minutes. You will be notified when it is ready for download.',
		});

		props.handleClose();

		return true;
	};

	const actions = useDriftActions();
	const showModal = actions.showModal;
	const setLoadingBarDisplay = (display: boolean) =>
		showModal('showLoadingBar', display);

	useEffect(() => {
		setInfoMessage('');
	}, [Object.entries(props).join(',')]);

	useEffect(() => {
		setLoadingBarDisplay(isDownloading);

		return () => {
			setLoadingBarDisplay(false);
		};
	}, [isDownloading]);

	return (
		<div className="flex flex-col items-start justify-between w-full">
			<div className="relative z-40 py-3">
				<div className="flex flex-row items-start py-2 rounded-sm bg-gradient-to-r from-purple-40/20 to-bg-main-bg">
					<div className="relative pt-2 pl-1">
						<div
							className={
								'w-8 h-8 rounded-full bg-opacity-10 p-1.5 bg-purple-50 ml-0.5'
							}
						>
							<div
								className={
									'rounded-full h-full w-full flex items-center justify-center bg-purple-50'
								}
							>
								<Info className="w-6 h-6" color="var(--text-primary-button)" />
							</div>
						</div>
					</div>

					<div className="pl-2">
						<Text.BODY3 className="text-text-default">
							The statement may take time to generate depending on your request.
							You can still navigate away and close the tab. When your statement
							is ready, you can download it from the Export page.
						</Text.BODY3>
					</div>
				</div>
			</div>
			{infoMessage && (
				<div className="flex flex-col items-start justify-between w-full pb-3">
					<InfoMessage type={'warn'} message={infoMessage} />
				</div>
			)}
			<Button.Secondary
				size="MEDIUM"
				highlight
				onClick={async () => {
					setIsDownloading(true);
					await requestFileDownload();
					setIsDownloading(false);
				}}
				disabled={isDownloading || !downloadIsValid}
				className="w-full"
			>
				Export Statement
			</Button.Secondary>
		</div>
	);
};

export const DownloadModal = (props: { initialType?: DownloadRecordType }) => {
	const set = useDriftStore((s) => s.set);
	const showDownloadModal = useDriftStore((s) => s.modals.showDownloadModal);

	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const accounts = useLazySubAccounts();
	const deletedAccounts = useDriftAccountStore((s) => s.deletedAccounts);
	const allAccounts = (accounts as any[]).concat(deletedAccounts);

	const connected = useWalletIsConnected();

	const [downloadType, setDownloadType] = useState<DownloadRecordType>(
		props.initialType ?? 'trades'
	);

	const [downloadPeriod, setDownloadPeriod] = useState<string>('week');
	const [downloadMonth, setDownloadMonth] = useState(1);
	const [downloadDay, setDownloadDay] = useState(1);
	const [downloadMarket, setDownloadMarket] = useState('SOL-PERP');
	const [downloadAccounts, setDownloadAccounts] = useState(
		currentUserKey ? [currentUserKey] : []
	);

	const [accountOptions, setAccountOptions] = useState([]);
	const [defaultSelectLabel, setDefaultSelectLabel] = useState('');

	const [fromDateStr, setFromDateStr] = useState('');
	const [toDateStr, setToDateStr] = useState('');

	const toggleAccountChecked = (toggledKey: string) => {
		const checkedIndex = downloadAccounts.indexOf(toggledKey);
		const newAccountList = [...downloadAccounts];
		if (checkedIndex >= 0) {
			newAccountList.splice(checkedIndex, 1);
		} else {
			newAccountList.push(toggledKey);
		}
		setDownloadAccounts(newAccountList);
	};

	const selectAllOptions = (currentlyChecked: boolean) => {
		if (currentlyChecked) {
			setDownloadAccounts([]);
		} else {
			setDownloadAccounts(allAccounts?.map((acct) => acct.userKey) ?? []);
		}
	};

	useEffect(() => {
		const newDefaultLabel =
			downloadAccounts.length === 1
				? allAccounts.find((acct) => acct.userKey === downloadAccounts[0])
						?.name ?? ''
				: `${downloadAccounts.length} accounts selected`;

		setDefaultSelectLabel(newDefaultLabel);

		const newAcctOptions = allAccounts.map((acct) => {
			return {
				label: acct.name,
				value: acct.userId,
				checked: downloadAccounts.includes(acct.userKey),
				onCheck: () => toggleAccountChecked(acct.userKey),
				switch: false,
			};
		});

		const selectAllChecked =
			newAcctOptions.filter((opt) => opt.checked).length ==
			newAcctOptions.length;

		newAcctOptions.unshift({
			label: 'Select All',
			value: -1,
			checked: selectAllChecked,
			onCheck: () => selectAllOptions(selectAllChecked),
			switch: true,
		});
		setAccountOptions(newAcctOptions);
	}, [accounts?.length, downloadAccounts]);

	// keep the fromDate and toDate updated with user input
	useEffect(() => {
		if (downloadPeriod.includes('custom')) {
			const { from, to } = getDateRangeFromSelection('custom', {
				day: downloadDay === 0 ? undefined : downloadDay,
				month: downloadMonth === 0 ? undefined : downloadMonth - 1,
				year: parseFloat(
					DownloadPeriods.find((period) => period.value === downloadPeriod)
						.label
				),
			});
			setFromDateStr(from);
			setToDateStr(to);
		} else {
			const { from, to } = getDateRangeFromSelection(
				downloadPeriod as DownloadPeriod
			);
			setFromDateStr(from);
			setToDateStr(to);
		}
	}, [downloadPeriod, downloadDay, downloadMonth]);

	useEffect(() => {
		if (!connected) handleClose();
	}, [connected]);

	const handleClose = () => {
		set((s) => {
			s.modals.showDownloadModal = false;
		});
	};

	if (!showDownloadModal) return null;

	return (
		<Modal onClose={handleClose}>
			<Modal.Body overflow>
				<Modal.Header onClose={handleClose}>
					<span>
						<span className="capitalize">Export Statement</span>
					</span>
				</Modal.Header>

				<Modal.Content>
					<div className="block w-full">
						<div className="z-50 w-full py-1">
							<div className="flex flex-col">
								<TradeFormInputLabel className="mt-0">Data</TradeFormInputLabel>
								<Select.Default
									id="recordTypeSelector"
									currentSelection={downloadType}
									options={DownloadTypes}
									onChange={(newValue) => {
										setDownloadType(newValue as DownloadRecordType);
									}}
									maxHeight={150}
								/>
							</div>
						</div>
						{isUserDownload(downloadType) && (
							<div className="z-50 w-full py-1">
								<div className="flex flex-col">
									<TradeFormInputLabel className="mt-0">
										Account
									</TradeFormInputLabel>
									<Select.MultiSelector
										id="accountSelector"
										currentSelection={downloadAccounts}
										options={accountOptions}
										defaultLabel={defaultSelectLabel}
										maxHeight={150}
									/>
								</div>
							</div>
						)}
						<div className="z-50 w-full py-1">
							<div className="flex flex-col">
								<TradeFormInputLabel className="mt-0">Date</TradeFormInputLabel>
								<Select.Default
									id="downloadPeriodSelector"
									currentSelection={downloadPeriod}
									options={DownloadPeriods.filter((period) =>
										filterDownloadPeriodsForType(period.value, downloadType)
									)}
									onChange={(newValue) => {
										setDownloadPeriod(newValue);
									}}
									maxHeight={150}
								/>
							</div>
						</div>
						{downloadPeriod.includes('custom') && (
							<div className="flex justify-between w-full py-1 space-x-2">
								<div className="flex w-full">
									<div className="flex flex-col w-full">
										<TradeFormInputLabel className="mt-0">
											Month
										</TradeFormInputLabel>
										<Select.Default
											id="monthSelector"
											currentSelection={downloadMonth}
											options={Months.filter((mnth) =>
												isUserDownload(downloadType)
													? true
													: mnth.label !== 'All'
											)}
											onChange={(newValue) => {
												setDownloadMonth(newValue);
											}}
											maxHeight={150}
											useTargetWidth
										/>
									</div>
								</div>
								<div className="flex w-full">
									<div className="flex flex-col w-full">
										<TradeFormInputLabel className="mt-0">
											Day
										</TradeFormInputLabel>
										<Select.Default
											id="daySelector"
											currentSelection={downloadDay}
											options={Days}
											onChange={(newValue) => {
												setDownloadDay(newValue);
											}}
											maxHeight={150}
											useTargetWidth
										/>
									</div>
								</div>
							</div>
						)}
						{(downloadType == 'market-trades' ||
							downloadType == 'funding-rates') && (
							<div className="w-full py-1">
								<div className="flex flex-col">
									<TradeFormInputLabel className="mt-0">
										Market
									</TradeFormInputLabel>
									<Select.Default
										id="marketDownloadSelector"
										currentSelection={downloadMarket}
										options={CurrentPerpMarkets.concat(
											//@ts-ignore
											...CurrentSpotMarkets.filter(
												(mkt) => mkt.marketIndex !== QUOTE_SPOT_MARKET_INDEX
											)
										).map((mkt: PerpMarketConfig | SpotMarketConfig) => {
											return {
												value: mkt.symbol,
												//@ts-ignore
												label: mkt.symbol + (mkt.mint ? '/USDC' : ''),
											};
										})}
										onChange={(newValue) => {
											setDownloadMarket(newValue);
										}}
										maxHeight={150}
									/>
								</div>
							</div>
						)}
						<div className="pt-2" />
						<Downloader
							handleClose={handleClose}
							recordType={downloadType}
							from={fromDateStr}
							to={toDateStr}
							userKeys={
								isUserDownload(downloadType)
									? downloadAccounts
									: [allAccounts[0]?.userKey?.toString()]
							}
							accountOptions={allAccounts}
							marketSymbol={
								isMarketDownload(downloadType) ? downloadMarket : undefined
							}
						/>
					</div>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};
