'use client';

import { useContext, useEffect, useState } from 'react';
import { DriftBlockchainEventSubscriberContext } from '../../providers/driftBlockchainEventSubscriberProvider';
import {
	DriftBlockchainEventSubjectContext,
	MERGE_USER_AND_GLOBAL_EVENT_SUBJECTS,
} from '../../providers/driftEvents/driftEventSubjectProvider';
import { dlog } from '../../dev';
import {
	WrappedEvent,
	EventMap,
	OrderActionRecord,
	OrderAction,
	EventType,
} from '@drift-labs/sdk';
import { useDebounce } from 'react-use';
import { ENUM_UTILS } from '@drift/common';
import useSubscriptionStateStore from '../../stores/useSubscriptionStateStore';

const DEV_DISABLE_USER_EVENT_SUBJECT = false;

/**
 * This hook is responsible for managing the drift event subscriptions and piping the events into the relevant subjects.
 */

const useManageDriftEventSubscriptions = () => {
	const eventSubscriberContext = useContext(
		DriftBlockchainEventSubscriberContext
	);
	const eventSubjectContext = useContext(DriftBlockchainEventSubjectContext);
	const selfEventSubscriberType = useSubscriptionStateStore(
		(s) => s.selfEventsState.subscriptionType
	);
	const globalEventSubscriberType = useSubscriptionStateStore(
		(s) => s.globalEventsState.subscriptionType
	);

	/**
	 * # Manage user event subscription
	 * The user event subscription should always be subscribed to the user's events on the blockchain
	 * */
	useEffect(() => {
		const userEventSubscriber = eventSubscriberContext.userEventSubscriber;
		const userEventSubject = eventSubjectContext.userEventSubject;

		if (!userEventSubscriber || !userEventSubject) {
			return;
		}

		dlog(`optimised_event_subscriptions`, `subscribing_to_user_events`);

		const userEventHandler = (event: WrappedEvent<keyof EventMap>) => {
			const isActionRecord = event.eventType === 'OrderActionRecord';
			const isFillRecord =
				isActionRecord &&
				ENUM_UTILS.match((event as OrderActionRecord).action, OrderAction.FILL);
			const shouldMergeWithGlobalEvents = MERGE_USER_AND_GLOBAL_EVENT_SUBJECTS;

			if (isFillRecord && shouldMergeWithGlobalEvents) {
				const typedEvent = event as WrappedEvent<'OrderActionRecord'>;

				eventSubjectContext.globalFillEventSubject.next(typedEvent);
			}

			if (DEV_DISABLE_USER_EVENT_SUBJECT) {
				return;
			}

			dlog(
				`optimised_event_subscriptions`,
				`piping::user_blockchain_event=>user_event_subject`
			);

			userEventSubject.next(event);
		};

		userEventSubscriber.eventEmitter.on('newEvent', userEventHandler);

		return () => {
			userEventSubscriber.eventEmitter.off('newEvent', userEventHandler);
		};
	}, [eventSubscriberContext, eventSubjectContext]);

	// We use this debounced ticker to block the global event subscription handling from running too frequntly
	// TODO : We did this because the websocket was breaking (I think the bursts of connections must have been being rejected) .. a BETTER solution would be handling at its base, catching that it didn't subscribe successfully for example
	const [debouncedGlobalSubjectTicker, setDebouncedGlobalSubjectTicker] =
		useState([]);

	useDebounce(
		() => {
			setDebouncedGlobalSubjectTicker([]);
		},
		1000,
		[
			eventSubscriberContext,
			eventSubjectContext,
			globalEventSubscriberType,
			selfEventSubscriberType,
		]
	);

	/**
	 * # Manage global order action event subscription
	 *
	 * If we're in blockchain mode, we should be subscribing to the global order action event
	 *
	 * If we're NOT in blockchain mode, we should be subscribed only if the websocket trades channel is not enabled
	 *
	 * The global order action event subscription should only be subscribed to the global order action events if we're in blockchain mode.
	 * OTHERWISE - It should be being handled by the more efficient dlob subscriber
	 */
	useEffect(() => {
		const globalEventSubscriberShouldbeSubscribed =
			globalEventSubscriberType !== 'trade-publisher';

		dlog(
			`optimised_event_subscriptions`,
			`${
				globalEventSubscriberShouldbeSubscribed
					? 'SUBSCRIBING'
					: 'UNSUBSCRIBING'
			}_blockchain_global_events${
				!globalEventSubscriberShouldbeSubscribed
					? '_because_global_events_type_is_trade_publisher'
					: ''
			}`
		);

		try {
			if (globalEventSubscriberShouldbeSubscribed) {
				eventSubscriberContext?.globalFillEventSubscriber?.subscribe();
			} else {
				eventSubscriberContext?.globalFillEventSubscriber?.unsubscribe();
			}
		} catch (e) {
			// swallow
		}

		const globalFillEventSubscriber =
			eventSubscriberContext.globalFillEventSubscriber;
		const globalFillEventSubject = eventSubjectContext.globalFillEventSubject;

		if (!globalFillEventSubscriber || !globalFillEventSubject) {
			dlog(
				`optimised_event_subscriptions`,
				`global_events_missing_subscriber_or_subject`
			);
			return;
		}

		const globalEventsHandler = (event: WrappedEvent<'OrderActionRecord'>) => {
			// Send the global events through to the event subject for listeners to consume
			const isActionRecord = event.eventType === 'OrderActionRecord';
			const isFillRecord =
				isActionRecord &&
				ENUM_UTILS.match((event as OrderActionRecord).action, OrderAction.FILL);

			if (!isFillRecord) return;

			dlog(
				`optimised_event_subscriptions`,
				`piping::global_blockchain_fill_event=>global_fill_subject`
			);

			globalFillEventSubject.next(event);
		};

		globalFillEventSubscriber.eventEmitter.on(
			'newEvent',
			globalEventsHandler as (event: WrappedEvent<EventType>) => void
		);

		return () => {
			globalFillEventSubscriber.eventEmitter.off(
				'newEvent',
				globalEventsHandler
			);
		};
		// }, [eventSubscriberContext, eventSubjectContext, currentDataSource]);
	}, [debouncedGlobalSubjectTicker]);
};

export default useManageDriftEventSubscriptions;
