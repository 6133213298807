'use client';

import { BigNum, SpotMarketConfig } from '@drift-labs/sdk';
import Button, { ButtonGroup } from 'src/components/Button';
import { CollateralInput } from 'src/components/Inputs/CollateralInput';
import LabelledInput from 'src/components/Inputs/LabelledInput';
import Select from 'src/components/Inputs/Select';
import TextField from 'src/components/Inputs/TextField';
import Utility from 'src/components/Inputs/Utility';
import Text from 'src/components/Text/Text';
import InfoMessage from 'src/components/TradeForm/InfoMessage';
import ValueDisplay from 'src/components/ValueDisplay';
import Env, {
	OrderedSpotMarkets,
} from 'src/environmentVariables/EnvironmentVariables';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import useSubaccountCollateralBalanceForBank from 'src/hooks/useSubaccountCollateralBalanceForBank';
import React, { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import Modal from './Modal';
import useCurrentWalletAdapter from 'src/hooks/useCurrentWalletAdapter';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import { useAccountCreationCost } from 'src/hooks/useAccountCreationCost';
import useTransferableWalletCollateralBalance from 'src/hooks/useTransferableWalletCollateralBalance';
import { MAIN_POOL_ID, MIN_LEFTOVER_SOL } from '@drift/common';
import NewAccountCreationCost from '../Utils/NewAccountCreationCost';
import { MaxSubaccountsWarning } from '../Utils/MaxSubaccountsWarning';
import { useMaxSubaccountsReached } from 'src/hooks/useMaxSubaccountsReached';
import useDevSwitchIsOn from 'src/hooks/useDevSwitchIsOn';
import {
	FORCED_NAME_POOL_IDS,
	POOL_NAMES_BY_POOL_ID,
} from '../../constants/constants';
import AirdropButton from '../Buttons/AirdropButton';

// TODO - This should come from SDK
const maxChars = 32;

type DepositType = 'wallet' | 'account';

const NewSubaccountModal = () => {
	const intentPoolId = useDriftStore(
		(s) => s.modalsProps.intentState.modalCollateralPoolId ?? MAIN_POOL_ID
	);
	const showNewPoolAccountCreationMessage = intentPoolId !== MAIN_POOL_ID;

	const availableSubAccounts = useLazySubAccounts();
	const actions = useDriftActions();
	const intentCollateralIndex = useDriftStore(
		(s) => s.modalsProps.intentState.modalCollateralIndex
	);
	const { captureEvent } = usePostHogCapture();
	const solBalance = useDriftStore((s) => s.wallet.currentSolBalance);
	const [customAccountName, setCustomAccountName] = useState('');
	const [depositType, setDepositType] = useState<DepositType>('wallet');

	const initialSelectedMarket =
		intentCollateralIndex === null
			? OrderedSpotMarkets[0]
			: OrderedSpotMarkets[intentCollateralIndex];

	const [depositAccountSelection, setDepositAccountSelection] =
		useState<string>(undefined);
	const [selectedCollatMarket, setSelectedCollatMarket] =
		useState<SpotMarketConfig>(initialSelectedMarket);
	const [depositAmount, setDepositAmount] = useState<string>('');
	const [submitting, setSubmitting] = useState(false);
	const [availableAccountOptions, setAvailableAccountOptions] = useState([]);
	const [userHasAgreedToFee, setUserHasAgreedToFee] = useState(false);

	const subaccountBalance = useSubaccountCollateralBalanceForBank({
		accountKey: depositAccountSelection,
		market: selectedCollatMarket,
		useTokenBalance: true,
	});
	const userStatsAccount = useDriftStore((s) => s.userStatsAccount);
	const currentAuthority = useCurrentWalletAdapter()?.publicKey;
	const subaccountCount = userStatsAccount?.numberOfSubAccountsCreated ?? 0;
	const maxSubaccountReached = useMaxSubaccountsReached();
	const isDevMode = useDevSwitchIsOn();

	// Don't show the name customisation fields for PoolIds that have a forced name, or if it's their first subaccount
	const allowCustomName =
		!FORCED_NAME_POOL_IDS.includes(intentPoolId) && subaccountCount > 0;

	const hideCollateralModal = useDriftActions().hideCollateralModal;

	const {
		totalCost: totalAccountCreationCost,
		loaded: accountCreationCostLoaded,
	} = useAccountCreationCost();

	const [walletDepositableBalanceBigNum] =
		useTransferableWalletCollateralBalance(selectedCollatMarket, true);

	const currentCollatBalanceBigNum =
		depositType == 'wallet'
			? walletDepositableBalanceBigNum
			: subaccountBalance;

	const depositAmountBigNum = BigNum.fromPrint(
		depositAmount ? depositAmount : '0',
		selectedCollatMarket.precisionExp
	);

	const defaultNewAccountName = `Subaccount ${(subaccountCount ?? 0) + 1}`;

	const balanceExceeded = depositAmountBigNum.gt(currentCollatBalanceBigNum);

	const isMaxSolDeposit =
		depositAmount !== '' &&
		depositType == 'wallet' &&
		depositAmountBigNum.eq(walletDepositableBalanceBigNum) &&
		selectedCollatMarket.symbol === 'SOL';

	const charsRemaining = maxChars - customAccountName.length;

	const canCreateMoreAccounts =
		isDevMode ||
		Env.maxAccountsPerWallet >
			availableSubAccounts.filter((acct) =>
				acct?.authority?.equals(currentAuthority)
			)?.length;

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setDepositAmount('');
	}, [selectedCollatMarket?.marketIndex]);

	useEffect(() => {
		setDepositAccountSelection(
			depositType == 'account'
				? availableSubAccounts[0]?.userKey ?? undefined
				: undefined
		);
	}, [depositType, availableSubAccounts]);

	useEffect(() => {
		setAvailableAccountOptions(
			availableSubAccounts
				.filter((account) => !account.isDelegatedTo)
				.map((account) => ({
					value: account.userKey ?? null,
					label: account.name,
				}))
		);
	}, [availableSubAccounts]);

	const handleAddAccount = async () => {
		if (!canCreateMoreAccounts) return;

		setSubmitting(true);
		const accountName =
			allowCustomName && customAccountName ? customAccountName : undefined; // If the user hasn't selected a custom name - we can leave this undefined because the Drift Client and action handler will handle this

		captureEvent('creating_new_subaccount');

		const fundsSource = depositAccountSelection
			? availableSubAccounts.find(
					(acct) => acct.userKey == depositAccountSelection
			  )?.userId
			: null;

		const success = await actions.initializeAndDepositCollateralForAccount({
			amount: depositAmountBigNum,
			spotMarketConfig: selectedCollatMarket,
			poolId: selectedCollatMarket.poolId,
			name: accountName,
			fromId: fundsSource,
		});

		setSubmitting(false);
		if (success) {
			hideCollateralModal('newSubaccount');
		}
	};

	return (
		<Modal onClose={() => hideCollateralModal('newSubaccount')}>
			<Modal.Body>
				<Modal.Header
					onClose={() => hideCollateralModal('newSubaccount')}
					showX
				>
					<Modal.Title>Add account</Modal.Title>
				</Modal.Header>
				<Modal.Content>
					<AirdropButton spotMarket={selectedCollatMarket} />

					{!canCreateMoreAccounts ? (
						<div className="flex flex-col items-center text-center">
							<Text.H3>
								This wallet has reached the maximum number of subaccounts (8).
							</Text.H3>
							<Utility.VERTSPACERXL />
							<Button.Secondary
								size="LARGE"
								className="w-full"
								onClick={() => hideCollateralModal('newSubaccount')}
							>
								Done
							</Button.Secondary>
						</div>
					) : (
						<>
							{showNewPoolAccountCreationMessage && (
								<div className="flex flex-col items-end">
									<InfoMessage
										type="info"
										messageTitle="New Subaccount Required"
										message={`Depositing into the ${
											POOL_NAMES_BY_POOL_ID[
												intentPoolId as keyof typeof POOL_NAMES_BY_POOL_ID
											]
										} requires creating a new subaccount. Your funds will be deposited into this new subaccount.`}
									/>
								</div>
							)}

							{allowCustomName && (
								<div className="flex flex-col items-end">
									<LabelledInput label="New Account name">
										<TextField.Default
											type="text"
											value={customAccountName}
											onChange={setCustomAccountName}
											placeholder={defaultNewAccountName}
											disabled={!canCreateMoreAccounts}
										/>
									</LabelledInput>
									<Utility.VERTSPACERXS />
									<Utility.FORMLABEL02
										label={`${charsRemaining} chars remaining`}
									/>

									{charsRemaining < 0 && (
										<>
											<Utility.VERTSPACERM />
											<InfoMessage
												type="error"
												messageTitle="Invalid account name"
												message="You can't exceed the account name character limit"
											/>
										</>
									)}
								</div>
							)}

							{(showNewPoolAccountCreationMessage || allowCustomName) && (
								// Only show the divider if one of the above sections is actually being displayes
								<>
									<div className="flex flex-col">
										<Utility.VERTSPACERXL />
										<Utility.VERTDIVIDER />
										<Utility.VERTSPACERM />
									</div>
								</>
							)}

							<div className="flex flex-col">
								<div className="flex items-center justify-between">
									<Utility.FORMLABEL01 label="Deposit Collateral From" />
									<ButtonGroup.Segmented
										options={[
											{
												label: 'Wallet',
												value: 'wallet',
											},
											{
												label: 'Account',
												value: 'account',
											},
										]}
										selected={depositType}
										size="SMALL"
										selectAction={(val: string | number) => {
											if (typeof val === 'string') {
												setDepositType(val as DepositType);
											}
										}}
									/>
								</div>

								<Utility.VERTSPACERL />

								{depositType === 'account' && (
									<>
										<LabelledInput label="Funding Account">
											<Select.Default
												id="depositAccountSelection"
												currentSelection={depositAccountSelection}
												onChange={setDepositAccountSelection}
												options={availableAccountOptions}
												useFullWidth
											/>
										</LabelledInput>
										<Utility.VERTSPACERM />
									</>
								)}

								<CollateralInput
									selectedMarket={selectedCollatMarket}
									maxAmount={currentCollatBalanceBigNum}
									onChangeMarket={setSelectedCollatMarket}
									label="Transfer type and Amount"
									value={depositAmount}
									onChangeValue={setDepositAmount}
									source={
										depositType === 'wallet'
											? {
													type: 'wallet',
											  }
											: {
													type: 'userAccount',
													userKey: depositAccountSelection,
											  }
									}
									poolId={intentPoolId}
								/>

								<Utility.VERTSPACERS />

								{balanceExceeded && (
									<>
										<Utility.VERTSPACERS />
										<InfoMessage
											type="error"
											messageTitle="Invalid deposit amount"
											message="You can't deposit more than your available account balance"
										/>
									</>
								)}

								<Utility.VERTSPACERS />

								{depositType === 'wallet' && (
									<>
										<Utility.FORMLABEL01
											label="Depositing funds from wallet"
											className="text-neutrals-30"
										/>
										<Utility.VERTSPACERM />
									</>
								)}

								{isMaxSolDeposit && (
									<>
										<div className="text-text-label">
											<Text.BODY2 className="text-xs leading-4">
												We hold back {MIN_LEFTOVER_SOL.toFixed(2)} SOL on max
												deposits to cover transaction fees
											</Text.BODY2>
										</div>
										<Utility.VERTSPACERM />
									</>
								)}

								{maxSubaccountReached ? (
									<MaxSubaccountsWarning />
								) : (
									<NewAccountCreationCost
										checkboxUpdated={(selection) =>
											setUserHasAgreedToFee(selection)
										}
										forceAgreement={true}
									/>
								)}
								<Utility.VERTSPACERXL />

								<Utility.VERTDIVIDER />
								<Utility.VERTSPACERM />

								<ValueDisplay.ValueChange
									label="Asset Balance"
									previousValue={currentCollatBalanceBigNum}
									afterValue={BigNum.max(
										currentCollatBalanceBigNum.sub(depositAmountBigNum),
										BigNum.zero(selectedCollatMarket.precisionExp)
									)}
									previousValuePrint={`${currentCollatBalanceBigNum.prettyPrint()} ${
										selectedCollatMarket.symbol
									}`}
									afterValuePrint={`${currentCollatBalanceBigNum
										.sub(depositAmountBigNum)
										.prettyPrint()} ${selectedCollatMarket.symbol}`}
								/>
								<Utility.VERTSPACERL />

								<Button.BigSemantic
									positive
									onClick={handleAddAccount}
									disabled={
										!accountCreationCostLoaded ||
										solBalance.lt(totalAccountCreationCost) ||
										!canCreateMoreAccounts ||
										submitting ||
										!depositAmount ||
										depositAmountBigNum.lte(
											BigNum.zero(selectedCollatMarket.precisionExp),
											true
										) ||
										balanceExceeded ||
										!userHasAgreedToFee ||
										maxSubaccountReached
									}
								>
									{canCreateMoreAccounts ? 'ADD' : `UNAVAILABLE`}
								</Button.BigSemantic>
							</div>
						</>
					)}
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default React.memo(NewSubaccountModal);
