import { useEffect, useRef } from 'react';
import useFeatureFlagStore from '../stores/useFeatureFlagStore';
import useSubscriptionStateStore from '../stores/useSubscriptionStateStore';
import { useSettingIsDefaultValue } from '../providers/settingsProvider';
import Env from '../environmentVariables/EnvironmentVariables';
import { dlog } from '../dev';
import useInterval from './useInterval';

const DEV_DISABLE_EVENTS_SERVER = false;

const GRACE_PERIOD_MS = 10_000;
const FALLUP_ATTEMPT_INTERVAL_MS = 1000 * 60;

/**
 * Hook responsible for syncing the subscription state store based on various conditions
 * like feature flags and settings
 */
const useSyncSubscriptionState = () => {
	const preferEventsServer = useFeatureFlagStore((state) =>
		state.flagEnabled('PREFER_EVENTS_SERVER_EVENTS')
	);
	const eventSubscriberSettingIsDefault = useSettingIsDefaultValue(
		'eventSubscriberType'
	);
	const updateSelfEventSubscriberType = useSubscriptionStateStore(
		(s) => s.updateSelfEventSubscriberType
	);
	const updateGlobalEventSubscriberType = useSubscriptionStateStore(
		(s) => s.updateGlobalEventSubscriberType
	);

	const alreadySyncedSelfEventsSubscriptionType = useRef(false);
	const alreadySyncedGlobalEventsSubscriptionType = useRef(false);

	// Handle initially syncing the Self Events Subscription state
	useEffect(() => {
		if (alreadySyncedSelfEventsSubscriptionType.current) return;

		if (preferEventsServer && !DEV_DISABLE_EVENTS_SERVER) {
			// We want to override the subscription type if the feature flag is enabled and the setting is default
			if (eventSubscriberSettingIsDefault) {
				dlog(
					`optimised_event_subscriptions`,
					`updating_self_events_to_use_events_server_because_PREFER_EVENTS_SERVER_EVENTS_flag_is_true`
				);
				updateSelfEventSubscriberType('events-server');
			}

			alreadySyncedSelfEventsSubscriptionType.current = true;
		}
	}, [
		preferEventsServer,
		eventSubscriberSettingIsDefault,
		updateSelfEventSubscriberType,
	]);

	// Handle initially syncing the Global Events Subscription state
	useEffect(() => {
		if (alreadySyncedGlobalEventsSubscriptionType.current) return;

		// Env.enableDlobWebsocketTradesChannel will already be available syncronously if it's enabled. If it is enabled - we can consider the state synced
		if (Env.enableDlobWebsocketTradesChannel) {
			dlog(
				`optimised_event_subscriptions`,
				`updating_global_events_to_use_trade_publisher_because_enableDlobWebsocketTradesChannel_is_true`
			);
			updateGlobalEventSubscriberType('trade-publisher');
			alreadySyncedGlobalEventsSubscriptionType.current = true;
			return;
		}

		if (
			// Otherwise, we want to wait for the asynchronous preferEventsServer flag to be set - in which case we will sync the state to 'events-server', and then consider the state synced
			preferEventsServer &&
			!DEV_DISABLE_EVENTS_SERVER
		) {
			if (eventSubscriberSettingIsDefault) {
				dlog(
					`optimised_event_subscriptions`,
					`updating_global_events_to_use_events_server_because_PREFER_EVENTS_SERVER_EVENTS_flag_is_true`
				);
				updateGlobalEventSubscriberType('events-server');
			}

			alreadySyncedGlobalEventsSubscriptionType.current = true;
		}
	}, [preferEventsServer, updateGlobalEventSubscriberType]);

	// Set the synced flags to true after a grace-period. We allow x amount of some for asyncronous stuff to happen otherwise just go with default handling.
	useEffect(() => {
		setTimeout(() => {
			alreadySyncedSelfEventsSubscriptionType.current = true;
			alreadySyncedGlobalEventsSubscriptionType.current = true;
		}, GRACE_PERIOD_MS);
	}, []);

	// Attempt to fall up every x amount of time
	useInterval(() => {
		dlog(`optimised_event_subscriptions`, `fall_up_attempt_interval_running`);
		useSubscriptionStateStore.getState().handleGlobalEventsFallbackUp();
	}, FALLUP_ATTEMPT_INTERVAL_MS);
};

export default useSyncSubscriptionState;
