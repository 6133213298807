'use client';

import { useEffect, useState } from 'react';
import { SEMANTIC_STATUS } from '../components/Utils/SemanticChip';
import useLiquidationStateStore from '../stores/liquidationState/useLiquidationStateStore';
import { AccountData } from '../stores/useDriftAccountsStore';
import useAccountsAtRisk from './Liquidations/useAccountsAtRisk';
import useAccountsBeingLiquidated from './Liquidations/useAccountsBeingLiquidated';
import useDriftActions from './useDriftActions';
import useWalletIsConnected from './useWalletIsConnected';

type AccountRiskResult = [AccountData, 'atRisk' | 'inLiq'];

const useSyncLiquidationAlertState = () => {
	const setLiquidationStoreState = useLiquidationStateStore((s) => s.set);

	let status: SEMANTIC_STATUS = 'green';

	const accountsAtRisk = useAccountsAtRisk();
	const amberAccounts = accountsAtRisk.length;
	const isConnected = useWalletIsConnected();

	if (amberAccounts > 0) status = 'amber';

	const accountsThatCanBeLiqd = useAccountsBeingLiquidated();
	const redAccounts = accountsThatCanBeLiqd.length;

	if (redAccounts > 0) status = 'red';

	const actions = useDriftActions();

	const [tooltipDisabled, setTooltipDisabled] = useState(false);

	const showDeposit = (accountKey: string) => {
		actions.showCollateralModal('deposit', {
			accountKey,
			defaultToBiggestAsset: true,
		});
		setTooltipDisabled(true);
		setTimeout(() => {
			setTooltipDisabled(false);
		}, 1000);
	};

	useEffect(() => {
		const allAccountsWithAWarning = [
			...accountsAtRisk.map((a) => [a, 'atRisk']),
			...accountsThatCanBeLiqd.map((a) => [a, 'inLiq']),
		] as AccountRiskResult[];

		const uniqueAccounts: AccountRiskResult[] = Object.values(
			allAccountsWithAWarning.reduce((prev, [currentAccount, riskLevel]) => {
				return {
					...prev,
					[currentAccount.userKey]: [currentAccount, riskLevel],
				};
			}, {} as Record<number, AccountRiskResult>)
		);

		setLiquidationStoreState((s) => {
			s.status = status;
			s.accountsAtRisk = accountsAtRisk as unknown as AccountRiskResult[];
			s.accountsThatCanBeLiqd =
				accountsThatCanBeLiqd as unknown as AccountRiskResult[];
			s.redAccounts = redAccounts;
			s.amberAccounts = amberAccounts;
			s.uniqueAccounts = uniqueAccounts;
			s.isConnected = isConnected;
			s.showDeposit = showDeposit;
			s.tooltipDisabled = tooltipDisabled;
		});
	}, [accountsAtRisk, accountsThatCanBeLiqd]);
};

export default useSyncLiquidationAlertState;
