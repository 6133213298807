'use client';

import {
	BASE_PRECISION,
	BASE_PRECISION_EXP,
	BN,
	BigNum,
	MarketType,
	QUOTE_PRECISION_EXP,
	User,
	ZERO,
	OrderTriggerCondition,
	OrderType,
	OrderStatus,
} from '@drift-labs/sdk';
import {
	COMMON_UI_UTILS,
	ENUM_UTILS,
	Serializer,
	UISerializableOrder,
	UIMarket,
} from '@drift/common';
import { useEffect } from 'react';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import useLazySubAccounts from './useLazySubAccounts';
import useWalletIsConnected from './useWalletIsConnected';
import { CurrentPerpMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useDriftClient from './useDriftClient';
import useDriftClientIsReady from './useDriftClientIsReady';
import NumLib from 'src/utils/NumLib';
import { notify } from '../utils/notifications';

export type LPOpenOrderData = {
	marketIndex: number;
	baseAmountLong: BigNum;
	baseAmountShort: BigNum;
	quoteAmountLong: BigNum;
	quoteAmountShort: BigNum;
};

const EMPTY_OPEN_ORDERS: any[] = [];

const useOpenOrdersData = () => {
	const connected = useWalletIsConnected();
	const accounts = useLazySubAccounts();
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const setState = useDriftAccountStore((s) => s.set);

	const getAndSetOrdersData = (user: User) => {
		if (!connected) return;
		if (!user || !user.isSubscribed) return;

		const userAccount = user.getUserAccount();

		const userKey = COMMON_UI_UTILS.getUserKey(
			userAccount.subAccountId,
			userAccount.authority
		);

		const currentOrders = userAccount.orders
			.filter((order) => !order.baseAssetAmount.isZero()) // Filter orders with no base size, this is same as a non-existent order
			.filter((order) => {
				return (
					!ENUM_UTILS.match(order.status, OrderStatus.CANCELED) &&
					!ENUM_UTILS.match(order.status, OrderStatus.FILLED)
				);
			}); // Filter orders which are filled or cancelled because these are no longer considered "open"

		const newOpenOrders: UISerializableOrder[] = [];

		currentOrders
			.filter((order) => !order.baseAssetAmount.eq(ZERO))
			.forEach((order) => {
				const newOrderRecord = Serializer.Deserialize.UIOrder(
					Serializer.Serialize.Order(order)
				);

				newOpenOrders.push(newOrderRecord);
			});

		const sortedOrders: UISerializableOrder[] = newOpenOrders.sort(
			(orderA, orderB) => orderB.orderId - orderA.orderId
		);

		const lpOrders: LPOpenOrderData[] = [];

		// append any LP "orders" this user has
		if (driftClientIsReady) {
			CurrentPerpMarkets.forEach((perpMarket) => {
				const [userLpBids, userLpAsks] = user.getLPBidAsks(
					perpMarket.marketIndex
				);

				if (!userLpBids.isZero() || !userLpAsks.isZero()) {
					const oraclePriceData = driftClient.getOracleDataForPerpMarket(
						perpMarket.marketIndex
					);

					const lpOrder: LPOpenOrderData = {
						marketIndex: perpMarket.marketIndex,
						baseAmountLong: BigNum.from(userLpBids, BASE_PRECISION_EXP),
						baseAmountShort: BigNum.from(userLpAsks, BASE_PRECISION_EXP),
						quoteAmountLong: BigNum.from(
							userLpBids.mul(oraclePriceData.price).div(BASE_PRECISION),
							QUOTE_PRECISION_EXP
						),
						quoteAmountShort: BigNum.from(
							userLpAsks.mul(oraclePriceData.price).div(BASE_PRECISION),
							QUOTE_PRECISION_EXP
						),
					};

					lpOrders.push(lpOrder);
				}
			});
		}

		const quoteInOpenOrders = sortedOrders.reduce((prev, current) => {
			const priceToUse = current.price?.gtZero()
				? current.price.val
				: ENUM_UTILS.match(current.marketType, MarketType.PERP)
				? driftClient.getOracleDataForPerpMarket(current.marketIndex)?.price
				: driftClient.getOracleDataForSpotMarket(current.marketIndex)?.price;

			// unfilled base amount * oracle price
			return prev.add(
				(
					current.baseAssetAmount?.val
						?.abs()
						.sub(current?.baseAssetAmountFilled?.val?.abs() ?? new BN(0)) ??
					new BN(0)
				)
					.mul(priceToUse)
					.div(BASE_PRECISION)
			);
		}, new BN(0));

		const quoteInLpOrders = lpOrders.reduce((prev, current) => {
			return prev.add(
				(current.quoteAmountLong?.abs()?.val ?? new BN(0)).add(
					current.quoteAmountShort?.abs()?.val ?? new BN(0)
				)
			);
		}, new BN(0));

		const openOrders =
			useDriftAccountStore.getState().accounts[userKey]?.openOrders || [];

		const triggeredOrders = openOrders.filter((openOrder) => {
			const currentOrder = sortedOrders.find(
				(order) => order.orderId === openOrder.orderId
			);

			const isTriggerOrderType =
				ENUM_UTILS.match(openOrder.orderType, OrderType.TRIGGER_MARKET) ||
				ENUM_UTILS.match(openOrder.orderType, OrderType.TRIGGER_LIMIT);

			const orderWasTriggered =
				ENUM_UTILS.match(
					openOrder.triggerCondition,
					OrderTriggerCondition.TRIGGERED_ABOVE
				) ||
				ENUM_UTILS.match(
					openOrder.triggerCondition,
					OrderTriggerCondition.TRIGGERED_BELOW
				);

			return isTriggerOrderType && !currentOrder && orderWasTriggered;
		});

		triggeredOrders.forEach((order) => {
			const uiMarket = new UIMarket(order.marketIndex, order.marketType);
			const marketName = uiMarket.marketName;

			// Convert TRIGGERED_ABOVE/BELOW to base trigger conditions since util doesn't handle triggered states
			const orderForLabel = {
				...order,
				triggerCondition: ENUM_UTILS.match(
					order.triggerCondition,
					OrderTriggerCondition.TRIGGERED_ABOVE
				)
					? { above: {} }
					: { below: {} },
			};
			const orderType =
				COMMON_UI_UTILS.getOrderLabelFromOrderDetails(orderForLabel);

			const baseSize = order.baseAssetAmount.prettyPrint();
			const triggerPrice = NumLib.formatNum.toTradePrecision(
				order.triggerPrice.toNum()
			);

			notify({
				type: 'success',
				message: `${marketName} ${orderType} Triggered`,
				description: `Your ${baseSize} ${marketName} ${orderType} order with trigger price $${triggerPrice} was just triggered!`,
				action: {
					type: 'link',
					label: 'View market',
					href: `/trade/${marketName.toLowerCase()}`,
				},
				showUntilCancelled: true,
			});
		});

		setState((s) => {
			s.accounts[userKey].openOrders =
				sortedOrders.length > 0 ? sortedOrders : EMPTY_OPEN_ORDERS;
			s.accounts[userKey].ordersLoaded = true;
			s.accounts[userKey].openLpOrders =
				lpOrders.length > 0 ? lpOrders : EMPTY_OPEN_ORDERS;
			s.accounts[userKey].marginInfo.quoteInOpenOrders =
				quoteInOpenOrders.abs();
			s.accounts[userKey].marginInfo.quoteInLpOrders = quoteInLpOrders.abs();
		});
	};

	useEffect(() => {
		if (!connected) return;

		const listenerClosingCallbacks = accounts.map((acct) => {
			const driftClientUser = acct.client;
			if (!driftClientUser) return;

			const updateHandler = () => getAndSetOrdersData(driftClientUser);

			getAndSetOrdersData(driftClientUser);
			driftClientUser.eventEmitter.on('userAccountUpdate', updateHandler);

			return () => {
				driftClientUser.eventEmitter.removeListener(
					'userAccountUpdate',
					updateHandler
				);
			};
		});

		return () => {
			listenerClosingCallbacks.forEach((cb) => cb());
		};
	}, [accounts, connected]);
};

export default useOpenOrdersData;
