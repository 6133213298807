import { useEffect } from 'react';

import { useRef } from 'react';

import { PublicKey } from '@solana/web3.js';
import { OrderActionRecord } from 'src/@types/orders';
import InlineLoadingBar from 'src/components/InlineLoadingBar/InlineLoadingBar';
import { OrderActionRow } from './OrderActionRow';

export const OrderBreakdownContent = ({
	orderActionRecords,
	currentAccountPubKey,
	executeNextPage,
	hasNextPage,
}: {
	orderActionRecords: OrderActionRecord[];
	currentAccountPubKey: PublicKey | undefined;
	executeNextPage: () => void;
	hasNextPage: boolean;
}) => {
	const observerTarget = useRef(null);

	// handles loading more records when the observer target is intersected - simulates infinite scroll
	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				if (entries[0].isIntersecting && hasNextPage) {
					executeNextPage();
				}
			},
			{ threshold: 0.1 }
		);

		if (observerTarget.current) {
			observer.observe(observerTarget.current);
		}

		return () => observer.disconnect();
	}, [hasNextPage, executeNextPage]);

	if (orderActionRecords.length === 0) {
		return (
			<div className="flex items-center justify-center h-full grow">
				<InlineLoadingBar />
			</div>
		);
	}

	return (
		<div className="overflow-hidden grow">
			<div className="flex flex-col gap-4 overflow-y-auto divide-y divide-container-border thin-scroll">
				{orderActionRecords
					.sort((a, b) => b.ts?.toNumber() - a.ts?.toNumber())
					.map((actionRecord, index) => (
						<OrderActionRow
							key={`${actionRecord.txSig}_${index}`}
							orderActionRecord={actionRecord}
							currentAccountPubKey={currentAccountPubKey}
						/>
					))}

				{/** Observer target for loading more records */}
				{hasNextPage && (
					<div ref={observerTarget} className="w-full h-4 bg-white" />
				)}
			</div>
		</div>
	);
};
