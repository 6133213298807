'use client';

import { V2AuctionToastData } from '../../../utils/notifications';
import Toast from '../Toast';
import Text from '../../Text/Text';
import { Close } from '@drift-labs/icons';
import { useEffect, useState } from 'react';
import useDevSwitchIsOn from '../../../hooks/useDevSwitchIsOn';
import Headline from './Headline';
import StageDisplay from './StageDisplay';
import { getProgressStateForStage } from './ProgressIndicator';

const DEV_SHOW_EVENT_HISTORY = false;

const useForceRender = () => {
	const [_, forceRender] = useState(0);
	useEffect(() => {
		const interval = setInterval(() => {
			forceRender((prev) => prev + 1);
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	return _;
};

const V2MarketOrderAlert = ({
	data,
	onClose,
}: {
	data: V2AuctionToastData;
	onClose: () => void;
}) => {
	// Force to render each second for the sake of refreshing progress bars ... TODO .. probs a better way of doing this.
	useForceRender();

	const isDev = useDevSwitchIsOn();
	const devData = data.dev_data;

	return (
		<Toast type="v2MarketOrder">
			<div className="flex flex-col space-y-3">
				<div className="flex items-center justify-between w-full">
					<Headline data={data} />
					<Close
						color="var(--text-default)"
						onClick={(e) => {
							// TODO : Think need to clear data from the toast ID handler
							e.stopPropagation();
							onClose();
						}}
					/>
				</div>
				<div className="grid grid-cols-[20px_1fr] gap-x-2 gap-y-3">
					{Object.entries(data.toastStages).map(([stage, toastState]) => {
						if (!toastState || toastState.type === 'none') {
							return null;
						}

						return (
							<StageDisplay
								key={stage}
								progressState={getProgressStateForStage(
									stage as keyof V2AuctionToastData['toastStages'],
									data
								)}
								toastState={toastState}
							/>
						);
					})}
				</div>

				{data.smallFooterText && (
					<Text.MICRO3 className="text-neutrals-30">
						{data.smallFooterText}
					</Text.MICRO3>
				)}

				{data.bigFooterText && <Text.BODY3>{data.bigFooterText}</Text.BODY3>}

				{/* Status Footers */}
				{data.toastStages?.transactionConfirmation?.type === 'success' && (
					<div className="flex justify-between w-full">
						<Text.MICRO3 className="text-neutrals-30">
							{data.orderInfo.avgFillPrice}
						</Text.MICRO3>
						<Text.MICRO3 className="text-neutrals-30">
							{data.orderInfo.pctFilled}
						</Text.MICRO3>
					</div>
				)}

				{/* Dev Stuff */}
				{DEV_SHOW_EVENT_HISTORY && isDev && devData && devData.length ? (
					<div className="flex flex-wrap w-full">
						<span>{`Dev: `}</span>
						<>
							{devData.map((str, index) => (
								<span key={`${str}_${index}`}>{`${str}, `}</span>
							))}
						</>
					</div>
				) : null}
			</div>
		</Toast>
	);
};

export default V2MarketOrderAlert;
