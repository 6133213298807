'use client';

import useDriftActions from 'src/hooks/useDriftActions';
import UI_UTILS from 'src/utils/uiUtils';
import { OrderedSpotMarkets } from '../environmentVariables/EnvironmentVariables';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import Button from './Button';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import React from 'react';
import InfoMessage from './TradeForm/InfoMessage';

const SwapConfirmationModal = () => {
	const swapDetails = useDriftStore((s) => s.swap.swapDetails);
	const setStore = useDriftStore((s) => s.set);

	const actions = useDriftActions();

	if (!swapDetails) return;

	const fromSpotConfig = OrderedSpotMarkets[swapDetails.fromMarketIndex];
	const toSpotConfig = OrderedSpotMarkets[swapDetails.toMarketIndex];
	const isExactIn = swapDetails.swapMode === 'ExactIn';
	const precision = isExactIn
		? fromSpotConfig.precision
		: toSpotConfig.precision;
	const precisionExp = isExactIn
		? fromSpotConfig.precisionExp
		: toSpotConfig.precisionExp;

	const exactAmount = isExactIn
		? swapDetails.quote.inAmount
		: swapDetails.quote.outAmount;

	const exactAmountString = (
		parseFloat(exactAmount) / precision.toNumber()
	).toFixed(precisionExp.toNumber());

	const slippage = +swapDetails.quote?.slippageBps / 100 || 0;
	const slippageString =
		slippage === 0
			? '0'
			: slippage > 0.01
			? `${slippage.toFixed(2)}%`
			: '< 0.1%';
	const slippageIsHigh = slippage > 2;

	const worstAmount = UI_UTILS.calcSwapAmountAtMaxSlippage(
		isExactIn,
		swapDetails.quote,
		slippage as number,
		fromSpotConfig,
		toSpotConfig
	);

	const priceImpact = +swapDetails.quote?.priceImpactPct || 0;
	const priceImpactString =
		priceImpact === 0
			? '0'
			: priceImpact > 0.001
			? `${(priceImpact * 100).toFixed(2)}%`
			: '< 0.1%';
	const priceImpactIsHigh = priceImpact > 0.01;

	const onClose = () => {
		actions.showModal('showSwapConfirmationModal', false);
		setStore((s) => {
			s.swap.swapDetails = undefined;
		});
	};

	const confirmSwap = () => {
		actions.swap(
			swapDetails.userKey,
			swapDetails.amount,
			swapDetails.fromMarketIndex,
			swapDetails.toMarketIndex,
			swapDetails.swapMode,
			swapDetails.quote,
			swapDetails.onlyDirectRoutes,
			swapDetails.slippageBps
		);
		onClose();
	};

	return (
		<Modal onClose={onClose}>
			<Modal.Body>
				<Modal.Header onClose={onClose}>
					<span>
						<span>{`Swap ${fromSpotConfig.symbol} to ${toSpotConfig.symbol}`}</span>
					</span>
				</Modal.Header>

				<Modal.Content>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">Swap Mode</div>

						<div className="flex items-center">
							<div className={`capitalize text-text-emphasis`}>
								{swapDetails.swapMode}
							</div>
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">
							{swapDetails.swapMode === 'ExactIn'
								? 'Minimum Received'
								: 'Maximum Sent'}
						</div>

						<div className="flex items-center">
							<div className={`capitalize text-text-emphasis`}>
								{`${worstAmount} ${
									isExactIn ? toSpotConfig.symbol : fromSpotConfig.symbol
								}`}
							</div>
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">
							{swapDetails.swapMode === 'ExactIn'
								? 'Send Exactly'
								: 'Receive Exactly'}
						</div>

						<div className="flex items-center">
							<div className={`capitalize text-text-emphasis`}>
								{`${exactAmountString} ${
									isExactIn ? fromSpotConfig.symbol : toSpotConfig.symbol
								}`}
							</div>
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">Price Impact</div>

						<div className="flex items-center">
							<div
								className={
									priceImpactIsHigh ? 'text-warn-yellow' : 'text-text-emphasis'
								}
							>
								{priceImpactString}
							</div>
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">Est. Slippage</div>

						<div className="flex items-center">
							<div
								className={
									slippageIsHigh ? 'text-warn-yellow' : 'text-text-emphasis'
								}
							>
								{slippageString}
							</div>
						</div>
					</div>

					<Utility.VERTSPACERM />

					{priceImpactIsHigh && (
						<>
							<InfoMessage type={'warn'} message={'High price impact'} />
							<Utility.VERTSPACERM />
						</>
					)}

					<Button.BigSemantic positive disabled={false} onClick={confirmSwap}>
						Confirm Swap
					</Button.BigSemantic>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export default SwapConfirmationModal;
