import { UiVaultConfig } from 'src/@types/vaults';
import { VaultManagerIcon } from './VaultManagerIcon';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import { useVaultsStore } from 'src/stores/vaultsStore/useVaultsStore';
import { VaultTags } from './VaultTags';
import { Typo } from '@drift-labs/react';

export const VaultSidebarInfoHeader = (props: {
	uiVaultConfig: UiVaultConfig;
}) => {
	const isMobile = useIsMobileScreenSize();
	const numOfVaultSnapshots = useVaultsStore(
		(s) =>
			s.getVaultStats(props.uiVaultConfig.vaultPubkeyString)
				?.numOfVaultSnapshots ?? 0
	);

	return (
		<div className="flex flex-col gap-4 sm:gap-6">
			<div className="flex flex-col gap-3">
				<div className="flex items-center gap-4">
					<VaultManagerIcon uiVaultConfig={props.uiVaultConfig} size={40} />
					<div className="flex flex-col gap-1 pr-5">
						{isMobile ? (
							<Typo.T2>{props.uiVaultConfig.name}</Typo.T2>
						) : (
							<Typo.T3>{props.uiVaultConfig.name}</Typo.T3>
						)}
						<VaultTags
							uiVaultConfig={props.uiVaultConfig}
							numOfVaultSnapshots={numOfVaultSnapshots}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
