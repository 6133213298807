import { BigNum, PositionDirection, BASE_PRECISION_EXP } from '@drift-labs/sdk';
import { ENUM_UTILS, UIMarket } from '@drift/common';
import { useMarketStepSizeBn } from 'src/hooks/useMarketStepSize';
import UI_UTILS from 'src/utils/uiUtils';
import { SPOT_MARKETS_LOOKUP } from 'src/environmentVariables/EnvironmentVariables';
import { PredictionMarketConfigs } from 'src/hooks/predictionMarkets/predictionMarketConfigs';
import MarketIcon from 'src/components/Utils/MarketIcon';
import Text from '../../Text/Text';
import { twMerge } from 'tailwind-merge';
import { MarketOrderToastState } from 'src/components/MarketOrderToasts/MarketOrderToastStateTypes';

const MarketIconComponent = ({ marketSymbol }: { marketSymbol: string }) => {
	return (
		<span className="p-[1px]">
			<MarketIcon marketSymbol={marketSymbol} sizeClass="w-[18px] h-4" />
		</span>
	);
};

const Direction = ({
	isLong,
	isPredictionMarket,
}: {
	isLong: boolean;
	isPredictionMarket: boolean;
}) => {
	return (
		<span
			className={twMerge(
				'inline',
				isLong ? 'text-positive-green' : 'text-negative-red'
			)}
		>
			{isLong
				? isPredictionMarket
					? 'Yes'
					: 'LONG'
				: isPredictionMarket
				? 'No'
				: 'SHORT'}
		</span>
	);
};

const Headline = (props: { data: MarketOrderToastState }) => {
	const isLong = ENUM_UTILS.match(
		props.data.orderInfo.direction,
		PositionDirection.LONG
	);

	const market = new UIMarket(
		props.data.orderInfo.marketId.marketIndex,
		props.data.orderInfo.marketId.marketType
	);

	const stepSizeBN = useMarketStepSizeBn(props.data.orderInfo.marketId);

	const orderIsPlaced =
		props.data.toastStages.transactionConfirmation.type === 'success';

	const basePrecisionExp = market.isPerp
		? BASE_PRECISION_EXP
		: SPOT_MARKETS_LOOKUP[market.marketIndex].precisionExp;

	const baseOrderedBigNum = BigNum.from(
		props.data.orderInfo.baseAmountOrdered,
		basePrecisionExp
	);

	const roundedBaseOrderedBigNum = UI_UTILS.getBigNumRoundedToStepSize(
		baseOrderedBigNum,
		stepSizeBN
	);

	const baseSizeOrderedDisplay = roundedBaseOrderedBigNum.prettyPrint();

	const isPredictionMarket = market.isPredictionMarket;

	if (orderIsPlaced) {
		const formattedSizeFilled = parseFloat(
			BigNum.from(
				props.data.orderInfo.cumulativeBaseAmountFilled,
				basePrecisionExp
			).print()
		);

		return (
			<span className="flex items-center pb-0 space-x-2">
				<MarketIconComponent marketSymbol={market.symbol} />
				{isPredictionMarket ? (
					<Text.BODY3 className="text-text-emphasis">
						{`Filled ${formattedSizeFilled} / ${roundedBaseOrderedBigNum.prettyPrint()} ${
							PredictionMarketConfigs.get(market.marketIndex).shortTitle
						} `}
						<Direction isPredictionMarket={true} isLong={isLong} />
						{` Shares`}
					</Text.BODY3>
				) : (
					<Text.BODY3 className="text-text-emphasis">
						{`Filled ${formattedSizeFilled} / ${baseSizeOrderedDisplay} `}
						<Direction isPredictionMarket={false} isLong={isLong} />
					</Text.BODY3>
				)}
			</span>
		);
	}

	return (
		<span className="flex items-center pb-0 space-x-2">
			<MarketIconComponent marketSymbol={market.symbol} />
			{isPredictionMarket ? (
				<Text.BODY3 className="text-text-emphasis">
					Placing: {roundedBaseOrderedBigNum.prettyPrint()}{' '}
					<Direction isPredictionMarket={true} isLong={isLong} />
					{` Shares for `}
					{PredictionMarketConfigs.get(market.marketIndex).shortTitle}
				</Text.BODY3>
			) : (
				<Text.BODY3 className="text-text-emphasis">
					{`Placing ${baseSizeOrderedDisplay} `}
					<Direction isPredictionMarket={false} isLong={isLong} />
					{` Order`}
				</Text.BODY3>
			)}
		</span>
	);
};

export default Headline;
