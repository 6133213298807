import {
	getVaultDepositorBalance,
	getVaultDepositorNotionalNetDeposits,
} from 'src/utils/vault/math';
import { BigNum, QUOTE_PRECISION_EXP, SpotMarketConfig } from '@drift-labs/sdk';
import { useVaultContext } from 'src/providers/vaults/VaultProvider';
import { useVaultsStore } from 'src/stores/vaultsStore/useVaultsStore';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
import { MarketId } from '@drift/common';
import { Typo } from 'src/components/Text/Typo';
import { UiVaults } from 'src/constants/vaults';
import MarketIcon from 'src/components/Utils/MarketIcon';
import { useVaultDepositorHistory } from 'src/hooks/vaults/useVaultDepositorHistory';
import { calcModifiedDietz } from '@drift-labs/vaults-sdk';
import ValueDisplay from 'src/components/ValueDisplay';
import SkeletonValuePlaceholder from 'src/components/SkeletonValuePlaceholder/SkeletonValuePlaceholder';
import { VaultPerformanceStatLabel } from '../VaultPerformanceStatLabel';

export const VaultDepositorStatsForSidebar = (props: {
	depositAssetConfig: SpotMarketConfig;
	vaultPubkey: string;
}) => {
	const getOraclePrice = useGetOraclePriceForMarket();
	const vaultStats = useVaultsStore((s) => s.getVaultStats(props.vaultPubkey));
	const vaultTvlBase = vaultStats?.tvlBase;
	const marketId = MarketId.createSpotMarket(
		props.depositAssetConfig.marketIndex
	);
	const uiVaultConfig = UiVaults.getVaultConfig(props.vaultPubkey);
	const { vaultDepositorHistory, isVaultDepositorHistoryLoading } =
		useVaultDepositorHistory(props.vaultPubkey);
	const {
		vaultAccountData,
		vaultDepositorAccountData,
		isVaultDepositorLoaded,
	} = useVaultContext();

	const currentUserBaseBalance = getVaultDepositorBalance(
		vaultDepositorAccountData,
		vaultAccountData,
		vaultTvlBase,
		props.depositAssetConfig.precisionExp,
		true
	);
	const currentUserNotionalBalance = getCurrentUserNotionalBalance();

	const totalBaseEarnings = getTotalBaseEarnings();
	const totalNotionalEarnings = getTotalNotionalEarnings();

	const roi = getTimeWeightedRoi();

	function getCurrentUserNotionalBalance() {
		const oraclePrice = getOraclePrice(marketId);
		const currentUserNotionalBalance = currentUserBaseBalance
			.mul(oraclePrice)
			.shiftTo(QUOTE_PRECISION_EXP);

		return currentUserNotionalBalance;
	}

	function getTotalBaseEarnings() {
		if (!vaultDepositorAccountData || !vaultAccountData)
			return BigNum.from(0, props.depositAssetConfig.precisionExp);

		const totalEarnings = currentUserBaseBalance.sub(
			BigNum.from(
				vaultDepositorAccountData.netDeposits,
				props.depositAssetConfig.precisionExp
			)
		);

		return totalEarnings;
	}

	function getTotalNotionalEarnings() {
		const userNotionalNetDeposits = getVaultDepositorNotionalNetDeposits(
			vaultDepositorHistory
		);

		const totalNotionalEarnings = currentUserNotionalBalance.sub(
			userNotionalNetDeposits
		);

		return totalNotionalEarnings;
	}

	function getTimeWeightedRoi() {
		if (
			!vaultDepositorAccountData ||
			vaultDepositorAccountData.totalDeposits.eqn(0) ||
			!vaultAccountData
		)
			return 0;

		const formattedHistory = vaultDepositorHistory
			.filter((r) => r.action === 'deposit' || r.action === 'withdraw')
			.map((deposit) => {
				return {
					ts: deposit.ts,
					marketIndex: deposit.spotMarketIndex, // not needed
					amount: uiVaultConfig.isNotionalGrowthStrategy
						? deposit.notionalValue
						: deposit.amount,
					direction: deposit.action as 'deposit' | 'withdraw',
				};
			})
			.sort((a, b) => +b.ts - +a.ts); // need to sort in descending order

		if (uiVaultConfig.isNotionalGrowthStrategy) {
			const { returns } = calcModifiedDietz(
				currentUserNotionalBalance,
				QUOTE_PRECISION_EXP,
				formattedHistory
			);

			return returns * 100;
		} else {
			const { returns } = calcModifiedDietz(
				currentUserBaseBalance,
				props.depositAssetConfig.precisionExp,
				formattedHistory
			);

			return returns * 100;
		}
	}

	const stats = [
		{
			label: 'Current Balance',
			value: (
				<span className="flex items-center gap-1">
					<MarketIcon
						marketSymbol={props.depositAssetConfig.symbol}
						className="w-4 h-4"
					/>
					{uiVaultConfig.isNotionalGrowthStrategy
						? currentUserNotionalBalance.prettyPrint()
						: currentUserBaseBalance.prettyPrint()}
				</span>
			),
			marketSymbol: uiVaultConfig.isNotionalGrowthStrategy
				? 'USDC'
				: props.depositAssetConfig.symbol,
			loading:
				!isVaultDepositorLoaded ||
				(uiVaultConfig.isNotionalGrowthStrategy &&
					isVaultDepositorHistoryLoading),
			tooltip:
				'The amount of deposit asset that you currently have in the vault, after fees.',
			subValue: uiVaultConfig.isNotionalGrowthStrategy ? (
				<Typo.B4 className="flex items-center gap-1 text-text-secondary">
					<MarketIcon
						marketSymbol={props.depositAssetConfig.symbol}
						className="w-4 h-4"
					/>
					<span>{currentUserBaseBalance.prettyPrint()}</span>
				</Typo.B4>
			) : undefined,
		},
		{
			label: 'Total Earnings (All Time)',
			value: (
				<span className="flex items-center gap-1">
					<MarketIcon
						marketSymbol={props.depositAssetConfig.symbol}
						className="w-4 h-4"
					/>
					{uiVaultConfig.isNotionalGrowthStrategy
						? totalNotionalEarnings.prettyPrint()
						: totalBaseEarnings.prettyPrint()}
				</span>
			),
			marketSymbol: uiVaultConfig.isNotionalGrowthStrategy
				? 'USDC'
				: props.depositAssetConfig.symbol,
			loading: !isVaultDepositorLoaded,
			tooltip: 'Your total earnings from the vault, after fees.',
			subValue: uiVaultConfig.isNotionalGrowthStrategy ? (
				<Typo.B4 className="flex items-center gap-1 text-text-secondary">
					<MarketIcon
						marketSymbol={props.depositAssetConfig.symbol}
						className="w-4 h-4"
					/>
					<span>{totalBaseEarnings.prettyPrint()}</span>
				</Typo.B4>
			) : undefined,
		},
		{
			label: 'ROI',
			value: `${roi.toFixed(2)}%`,
			loading: !isVaultDepositorLoaded,
		},
	];

	return (
		<div className="flex flex-col w-full">
			<Typo.T4 className="text-text-default mb-4">Your Deposit</Typo.T4>
			<div className="flex flex-col gap-2">
				{stats.map((stat) => {
					return (
						<ValueDisplay.Default
							key={stat.label}
							label={
								<VaultPerformanceStatLabel
									label={stat.label}
									tooltip={stat.tooltip}
								/>
							}
							value={
								stat.loading ? (
									<SkeletonValuePlaceholder className="w-20 h-6" loading />
								) : (
									stat.value
								)
							}
						/>
					);
				})}
			</div>
		</div>
	);
};
