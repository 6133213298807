'use client';

import { BigNum, SpotBalanceType } from '@drift-labs/sdk';
import React, { useEffect, useState } from 'react';
import { CurrentSpotMarkets } from 'src/environmentVariables/EnvironmentVariables';
import useAccountsBeingLiquidated from 'src/hooks/Liquidations/useAccountsBeingLiquidated';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import useDriftActions from 'src/hooks/useDriftActions';
import useLazySubAccounts from 'src/hooks/useLazySubAccounts';
import { CollateralInput } from './Inputs/CollateralInput';
import LabelledInput from './Inputs/LabelledInput';
import Select from './Inputs/Select';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import Tooltip from './Tooltip/Tooltip';
import InfoMessage from './TradeForm/InfoMessage';
import ValueDisplay from './ValueDisplay';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useDriftAccountStore from 'src/stores/useDriftAccountsStore';
import Text from './Text/Text';
import UIHelpTextLink from './UIHelpTextLink';
import UI_UTILS from '../utils/uiUtils';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';
import { MAIN_POOL_ID, matchEnum } from '@drift/common';
import NumLib from 'src/utils/NumLib';
import CustomMaxLeverageCta from './CustomMaxLeverageCta';
import { Info } from '@drift-labs/icons';
import SettleUnsettledInfoMessage from './Utils/SettleUnsettledInfoMessage';
import { DEFAULT_ACCOUNT_NAMES_BY_POOL_ID } from 'src/constants/constants';
import useCollateralModalState from '../hooks/useCollateralModalState';

const WithdrawalModalContent = ({ onClose }: { onClose: () => void }) => {
	// # Constants
	const { captureEvent } = usePostHogCapture();
	const actions = useDriftActions();
	const availableSubaccounts = useLazySubAccounts();
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const accountsBeingLiquidated = useAccountsBeingLiquidated();

	// # State
	const [submitting, setSubmitting] = useState(false);
	const [maxButtonTransition, setMaxButtonTransition] = useState(false);
	const [collatTransferAmount, setCollatTransferAmount] = useState('');
	const [isMax, setIsMax] = useState(false);

	const {
		updateCollateralIndex,
		handleTargetAccountChange,
		selectedCollateralMarket,
		selectedTargetAccountKey,
		selectedPoolId,
		defaultToLargestBalance,
	} = useCollateralModalState();

	const accountBalances = useAccountSpotBalances(currentUserKey, null);
	const hasOpenBorrows = CurrentSpotMarkets.reduce((hasBorrows, bank) => {
		if (hasBorrows) return true;

		const deposits = accountBalances.filter(
			(bal) =>
				bal.asset.marketIndex === bank.marketIndex &&
				matchEnum(bal.balanceType, SpotBalanceType.DEPOSIT)
		);
		const borrows = accountBalances.filter(
			(bal) =>
				bal.asset.marketIndex === bank.marketIndex &&
				matchEnum(bal.balanceType, SpotBalanceType.BORROW)
		);
		const depositsBase = NumLib.sumBigNums(
			deposits.map((depos) => depos.balance),
			bank.precisionExp
		);
		const borrowsBase = NumLib.sumBigNums(
			borrows.map((depos) => depos.balance),
			bank.precisionExp
		);

		const netBase = depositsBase.sub(borrowsBase);

		if (netBase.ltZero()) {
			return true;
		}

		return false;
	}, false) as boolean;

	const [hasOpenPositions, hasOpenOrders] = useDriftAccountStore((s) => {
		return [
			!!s.accounts[selectedTargetAccountKey]?.openPerpPositions.length,
			!!s.accounts[selectedTargetAccountKey]?.openOrders.length,
		];
	});

	const selectedAccountCanbeLiqd = accountsBeingLiquidated.find(
		(account) => account.userKey === selectedTargetAccountKey
	);

	const targetCollatBalances = useAccountTargetSpotBalance(
		selectedCollateralMarket,
		selectedTargetAccountKey,
		true
	);

	// # State-Dependent constants
	const collatTransferAmountBignum = BigNum.fromPrint(
		collatTransferAmount ? collatTransferAmount : '0',
		selectedCollateralMarket.precisionExp
	);

	const withdrawalLimit = targetCollatBalances.withdrawLimitBase.shiftTo(
		selectedCollateralMarket.precisionExp
	);

	// # Effect Hooks

	// turn off slider transition for dragging slider handle interaction
	useEffect(() => {
		if (maxButtonTransition) {
			setMaxButtonTransition(false);
		}
	}, [maxButtonTransition]);

	// If max is selected, keep input value in sync with max. allowed value
	useEffect(() => {
		if (isMax) {
			setCollatTransferAmount(withdrawalLimit.print());
		}
	}, [isMax, withdrawalLimit.print()]);

	// Reset transfer amount if market selection changes
	useEffect(() => {
		setCollatTransferAmount('');
	}, [selectedCollateralMarket?.symbol]);

	// # Methods
	const handleWithdraw = async () => {
		setSubmitting(true);

		const collateralAmount = BigNum.fromPrint(
			collatTransferAmount,
			selectedCollateralMarket.precisionExp
		);
		const targetUserAccount = availableSubaccounts
			.find((acct) => acct.userKey === selectedTargetAccountKey)
			?.client?.getUserAccount();

		const result = await actions.withdrawCollateral({
			amount: collateralAmount,
			spotMarketConfig: selectedCollateralMarket,
			userAccount: targetUserAccount,
			isBorrow: false,
			isMax,
		});

		await actions.fetchMarginAccountInfo(selectedTargetAccountKey);

		setSubmitting(false);

		captureEvent('submitted_withdrawal', {
			spot_market_symbol: selectedCollateralMarket.symbol,
		});

		if (result === true) {
			onClose();
		}
	};

	const handleValueChange = (newValue: string) => {
		setIsMax(false);
		setCollatTransferAmount(newValue);
	};

	const handleMax = () => {
		setCollatTransferAmount(withdrawalLimit.toTradePrecision());
		setIsMax(true);
	};

	const balanceAfterTransfer = targetCollatBalances.netBaseBalance.sub(
		collatTransferAmountBignum
	);

	const exceededMax = collatTransferAmountBignum.gt(withdrawalLimit);

	const allowConfirmation =
		!submitting &&
		collatTransferAmount &&
		collatTransferAmountBignum.gt(
			BigNum.zero(selectedCollateralMarket.precisionExp),
			true
		) &&
		!selectedAccountCanbeLiqd &&
		!exceededMax;

	// if user has borrowws, open orders, or open positions:
	const showMarginWithdrawalLimitMessage =
		hasOpenPositions || hasOpenOrders || hasOpenBorrows;

	const showDailyWithdrawalLimitMessage = withdrawalLimit.lt(
		targetCollatBalances.assetBaseBalance
	);

	useEffect(() => {
		if (exceededMax && isMax) {
			setCollatTransferAmount(withdrawalLimit.print());
		}
	}, [exceededMax, isMax]);

	// # Rendering
	return (
		<>
			<div className="flex flex-col justify-between overflow-auto thin-scroll sm:max-h-[70vh]">
				<Modal.Content>
					{availableSubaccounts.length > 1 && (
						<>
							<LabelledInput label="Withdraw from">
								<Select.Subaccount
									id="depositAccountSelection"
									placeholderText={
										DEFAULT_ACCOUNT_NAMES_BY_POOL_ID[selectedPoolId]
									}
									onChange={handleTargetAccountChange}
									options={availableSubaccounts}
									initialSelection={selectedTargetAccountKey}
									disabled={
										!selectedTargetAccountKey && selectedPoolId !== MAIN_POOL_ID
									}
								/>
							</LabelledInput>
							<Utility.VERTSPACERXL />
						</>
					)}

					<CollateralInput
						selectedMarket={selectedCollateralMarket}
						maxAmount={targetCollatBalances.withdrawLimitBase}
						onChangeMarket={(val) => updateCollateralIndex(val.marketIndex)}
						label="Transfer type and Amount"
						value={
							isMax
								? targetCollatBalances.withdrawLimitBase.print()
								: collatTransferAmount
						}
						onChangeValue={handleValueChange}
						onMax={handleMax}
						amountLabel={
							<Tooltip
								allowHover
								content={
									<>
										This can be impacted by open positions, orders, borrows, or
										daily withdrawal limits programmed as a protocol safety
										measure.
									</>
								}
							>
								<div className="flex flex-row items-center">
									Available to withdraw <Info size={14} className="ml-1" />
								</div>
							</Tooltip>
						}
						source={{
							type: 'userAccount',
							userKey: selectedTargetAccountKey,
						}}
						defaultToHighestBalance={defaultToLargestBalance}
						poolId={selectedPoolId}
					/>

					<Utility.VERTSPACERL />

					{showMarginWithdrawalLimitMessage ? (
						<InfoMessage
							type="info"
							message={
								<Text.BODY1 className="tracking-normal text-text-secondary">
									<div className="mt-1 mb-2 leading-4">
										The available amount to withdraw may be less than your
										balance due to open positions, orders, borrows, and/or daily
										withdrawal limits being reached.
									</div>
									<div className="mt-2 mb-1">
										<UIHelpTextLink
											href="https://docs.drift.trade/trading/block-conditions#withdraws"
											text="Learn more about withdrawal limits"
										/>
									</div>
								</Text.BODY1>
							}
						/>
					) : showDailyWithdrawalLimitMessage ? (
						<InfoMessage
							type="info"
							message={
								<Text.BODY1 className="tracking-normal text-text-secondary">
									<div className="mt-1 mb-2 leading-4">
										Daily withdrawal limits are programmed in the smart contract
										as a protocol safety measure. The funds can be viewed
										on-chain{' '}
										<a
											href={UI_UTILS.getUrlForAccount(
												'JCNCMFXo5M5qwUPg2Utu1u6YWp3MbygxqBsBeXXJfrw'
											)}
											target="_blank"
											rel="noreferrer"
										>
											here
										</a>
										. You can withdraw your funds as the daily limit resets.
									</div>
									<div className="mt-2 mb-1">
										<UIHelpTextLink
											href="https://docs.drift.trade/risk-and-safety/risk-parameters"
											text="Learn more"
										/>
									</div>
								</Text.BODY1>
							}
						/>
					) : null}

					<SettleUnsettledInfoMessage className={'mt-2'} onlyShowIfPositive />

					{selectedAccountCanbeLiqd && (
						<>
							<Utility.VERTSPACERL />
							<InfoMessage
								type="error"
								messageTitle="Withdrawals from this account are paused"
								message={
									<>
										Once the account liquidation is complete, withdrawals will
										be enabled again.
									</>
								}
							/>
						</>
					)}

					<CustomMaxLeverageCta />

					<Utility.VERTSPACERXL />
					<Utility.VERTDIVIDER />
					<Utility.VERTSPACERM />
					<ValueDisplay.ValueChange
						label="Asset Balance"
						previousValue={targetCollatBalances.netBaseBalance}
						afterValue={balanceAfterTransfer}
						previousValuePrint={`${targetCollatBalances.netBaseBalance.prettyPrint()} ${
							selectedCollateralMarket.symbol
						}`}
						afterValuePrint={`${balanceAfterTransfer.prettyPrint()} ${
							selectedCollateralMarket.symbol
						}`}
					/>

					<Utility.VERTSPACERL />

					<Modal.Confirm
						primaryDisabled={!allowConfirmation}
						onConfirm={handleWithdraw}
					/>
				</Modal.Content>
			</div>
		</>
	);
};

export default React.memo(WithdrawalModalContent);
