'use client';

import { User } from '@drift-labs/sdk';
import useDriftAccountsStore from 'src/stores/useDriftAccountsStore';
import ExchangeHistoryClient from 'src/utils/exchangeHistoryClient';
import UI_UTILS from 'src/utils/uiUtils';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import useHistoryFetcher from './useHistoryFetcher';
import { COMMON_UI_UTILS, UISerializableSettlePnlRecord } from '@drift/common';
import { singletonHook } from 'react-singleton-hook';
import { useEffect, useState } from 'react';
import useCurrentUserAccount from './useCurrentUserAccount';

const useSettlePnlHistory = () => {
	const getState = useDriftStore((s) => s.get);

	const setAccountsStore = useDriftAccountsStore((s) => s.set);
	const getAccountsStore = useDriftAccountsStore((s) => s.get);

	// Get the initial settle pnl history, and find out what the total size of the settle pnl history is for the user - to help with pagination
	const getNewSettlePnlsFromHistoryServer = async (users: User[]) => {
		const isConnected =
			getState().wallet.current.adapter.connected &&
			getState().connection.current;
		if (!isConnected) return;

		const pubKeys = users.map((user) => user.getUserAccountPublicKey());
		const userKeys = users.map((user) =>
			COMMON_UI_UTILS.getUserKey(
				user.getUserAccount().subAccountId,
				user.getUserAccount().authority
			)
		);

		const results = await ExchangeHistoryClient.getSettlePnlRecordsForUsers(
			pubKeys,
			0,
			false
		);

		if (!results.success) return false;

		userKeys.forEach((userKey, index) => {
			const newRecordsForUser = results?.body?.data?.records?.[index] ?? [];
			const recordCountForUser = results?.body?.data?.totalCounts?.[index] ?? 0;

			// check for current trades in store in case eventListener has already started
			const alreadyLoadedRecords: UISerializableSettlePnlRecord[] =
				getAccountsStore().accounts[userKey].settlePnlHistory.records ?? [];

			const orderedSettlePnls = UI_UTILS.getUniqueSortedEvents(
				alreadyLoadedRecords,
				newRecordsForUser
			);

			setAccountsStore((s) => {
				s.accounts[userKey].settlePnlHistory.totalCount = recordCountForUser;
				s.accounts[userKey].settlePnlHistory.records = orderedSettlePnls;
				s.accounts[userKey].settlePnlHistory.initialHistoryLoaded = true;
			});
		});

		return true;
	};

	useHistoryFetcher(getNewSettlePnlsFromHistoryServer, 'settlePnlHistory');
};

/**
 * This hook fetches settle pnl histories with the explanation as 'expired_position'.
 */
const _useSettledExpiredPositionsPnlHistory =
	(): UISerializableSettlePnlRecord[] => {
		const currentUserAccount = useCurrentUserAccount();

		const [
			settledExpiredPositionsPnlHistory,
			setSettledExpiredPositionsPnlHistory,
		] = useState<UISerializableSettlePnlRecord[]>([]);

		useEffect(() => {
			if (!currentUserAccount?.pubKey) return;

			const fetchSettledExpiredPositionsPnlHistory = async () => {
				const result = await ExchangeHistoryClient.getSettlePnlRecordsForUsers(
					[currentUserAccount.pubKey],
					0,
					false
				);

				setSettledExpiredPositionsPnlHistory(
					result.body?.data.records[0] ?? []
				);
			};

			fetchSettledExpiredPositionsPnlHistory();
		}, [currentUserAccount?.pubKey]);

		return settledExpiredPositionsPnlHistory;
	};

export const useSettledExpiredPositionsPnlHistory = singletonHook(
	[],
	_useSettledExpiredPositionsPnlHistory
);

export default useSettlePnlHistory;
