import {
	getAssociatedTokenAddressSync,
	TOKEN_2022_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
} from '@solana/spl-token';
import {
	Connection,
	PublicKey,
	ParsedAccountData,
	AccountInfo,
} from '@solana/web3.js';
import { useCallback, useEffect, useRef } from 'react';
import { UNIQUE_SPOT_MARKETS } from 'src/environmentVariables/EnvironmentVariables';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import {
	useTokenAccountsStore,
	TokenAccountInfo,
} from './useTokenAccountsStore';
import useCurrentWalletAdapter from '../../hooks/useCurrentWalletAdapter';
import { dlog } from '../../dev';

/**
 * This hook will sync the token account state for all token accounts for the current wallet
 *
 * This hook does not KEEP the accounts in sync at the moment. It just refreshes them each time the wallet changes, or the refreshTokenAccounts event is emitted
 *
 * the useSPLTokenBalance hook does SUBSCRIBE to token account updates and push them back into this store. See the README.md in this folder.
 */

// Constants
const FILTER_BY_DRIFT_SPOT_MARKETS = false; // If TRUE - will only sync the token account if it matches a drift spot market mint, otherwise all token accounts in the wallet will be synced. NOTE:: This is FALSE now because our dSOL staking flow allows staking non-drift assets into dSOL, so we require the token accounts loaded to display their balances. Note:: By "SYNC" we only mean the one-shot initial sync, not a long term subscription.
const SYNC_ONLY_EXPECTED_ACCOUNTS = true;
const TRACK_BAD_TOKEN_ACCOUNT_STATES = true;
const DRIFT_SPOT_MARKET_MINTS = new Set(
	UNIQUE_SPOT_MARKETS.map((m) => m.mint.toString())
);
const TOKEN_PROGRAMS_TO_FETCH = [
	TOKEN_2022_PROGRAM_ID,
	TOKEN_PROGRAM_ID,
] as const;

// Lookup Maps
const TOKEN_PROGRAM_LOOKUP: Readonly<Record<TokenProgramLabel, PublicKey>> = {
	'spl-token': TOKEN_PROGRAM_ID,
	'spl-token-2022': TOKEN_2022_PROGRAM_ID,
} as const;

// Types
type TokenProgramLabel = 'spl-token' | 'spl-token-2022';
type MintToTokenAccountMap = Map<string, string>;

interface ParsedTokenInfo {
	mint: string;
	tokenAmount: {
		uiAmount: number;
	};
}

type TokenAccountResponse = {
	pubkey: PublicKey;
	account: AccountInfo<ParsedAccountData>;
};

type TokenAccountBadState = {
	mint: string;
	knownAccounts: Set<string>;
	expectedAccount: string;
	accountBalances: Map<string, number>; // Maps token account address to its balance
};

type TokenAccountBadStateMap = Map<string, TokenAccountBadState>;

/**
 * We use this hook to track if the wallet's token accounts are in a bad state and the UI will probably not work as expected. We do this by tracking all of the token accounts for a mint, and then seeing if we have any unexpected token accounts at the end of the sync.
 * @param authority
 * @returns
 */
const useDetectBadTokenAccountStates = (authority: PublicKey) => {
	const badTokenAccountStates = useRef<TokenAccountBadStateMap>(new Map());

	const addTokenAccountToBadStateTracking = (
		mintAddress: string,
		tokenAccount: TokenAccountResponse
	) => {
		if (!TRACK_BAD_TOKEN_ACCOUNT_STATES) return;

		const expectedAccount = getAssociatedTokenAddressSync(
			new PublicKey(mintAddress),
			authority,
			true,
			TOKEN_PROGRAM_LOOKUP[
				tokenAccount.account.data.program as TokenProgramLabel
			]
		);

		const parsedInfo = tokenAccount.account.data.parsed.info as ParsedTokenInfo;
		const balance = parsedInfo.tokenAmount.uiAmount;

		// Update the bad state map with new data
		if (badTokenAccountStates.current.has(mintAddress)) {
			const badState = badTokenAccountStates.current.get(mintAddress);
			badState.knownAccounts.add(tokenAccount.pubkey.toBase58());
			badState.accountBalances.set(tokenAccount.pubkey.toBase58(), balance);
		} else {
			badTokenAccountStates.current.set(mintAddress, {
				mint: mintAddress,
				knownAccounts: new Set([tokenAccount.pubkey.toBase58()]),
				expectedAccount: expectedAccount.toBase58(),
				accountBalances: new Map([[tokenAccount.pubkey.toBase58(), balance]]),
			});
		}
	};

	// Reset the state when the authority changes
	useEffect(() => {
		badTokenAccountStates.current = new Map();
	}, [authority]);

	return {
		addTokenAccountToBadStateTracking,
	};
};

export const useSyncTokenAccountsStore = () => {
	const wallet = useCurrentWalletAdapter();
	const authority = wallet?.publicKey;
	const connection = useDriftStore((s) => s.connection.current);
	const { setTokenAccount, clearAllTokenAccounts, setLastSyncedWallet } =
		useTokenAccountsStore();
	const eventEmitter = useDriftStore((s) => s.appEventEmitter);
	const handledMintsToTokenAccounts = useRef<MintToTokenAccountMap>(new Map());

	const { addTokenAccountToBadStateTracking } =
		useDetectBadTokenAccountStates(authority);

	const shouldSyncTokenAccount = useCallback(
		(
			mintAddress: string,
			accountInfo: TokenAccountResponse,
			authority: PublicKey
		): boolean => {
			const mintIsForDriftSpotMarket = DRIFT_SPOT_MARKET_MINTS.has(mintAddress);

			// If we are filtering by drift spot markets and this mint is not for a drift spot market, skip it
			if (FILTER_BY_DRIFT_SPOT_MARKETS && !mintIsForDriftSpotMarket) {
				return false;
			}

			if (SYNC_ONLY_EXPECTED_ACCOUNTS) {
				const tokenProgramLabel = accountInfo.account.data
					.program as TokenProgramLabel;

				const expectedTokenAccount = getAssociatedTokenAddressSync(
					new PublicKey(mintAddress),
					authority,
					true,
					TOKEN_PROGRAM_LOOKUP[tokenProgramLabel]
				);

				return (
					expectedTokenAccount.toString() === accountInfo.pubkey.toString()
				);
			} else {
				// Else, we can immediately sync the token account if we have not seen this mint before
				if (!handledMintsToTokenAccounts.current.has(mintAddress)) {
					return true;
				}

				// If we have, then we prefer the new token account to the previously synced if it matches the expected token account
				const tokenProgramLabel = accountInfo.account.data
					.program as TokenProgramLabel;

				const expectedTokenAccount = getAssociatedTokenAddressSync(
					new PublicKey(mintAddress),
					authority,
					true,
					TOKEN_PROGRAM_LOOKUP[tokenProgramLabel]
				);

				return (
					expectedTokenAccount.toString() === accountInfo.pubkey.toString()
				);
			}
		},
		[]
	);

	const processTokenAccount = useCallback(
		(accountInfo: TokenAccountResponse) => {
			if (!authority) return;

			const parsedInfo = accountInfo.account.data.parsed
				.info as ParsedTokenInfo;
			const mintAddress = parsedInfo.mint;

			const mintIsForDriftSpotMarket = DRIFT_SPOT_MARKET_MINTS.has(mintAddress);

			if (mintIsForDriftSpotMarket) {
				addTokenAccountToBadStateTracking(mintAddress, accountInfo);
			}

			if (shouldSyncTokenAccount(mintAddress, accountInfo as any, authority)) {
				const account: TokenAccountInfo = {
					pubkey: accountInfo.pubkey,
					mint: new PublicKey(mintAddress),
					programId: TOKEN_PROGRAM_ID,
					parsedBalance: parsedInfo.tokenAmount.uiAmount,
				};

				handledMintsToTokenAccounts.current.set(
					mintAddress,
					accountInfo.pubkey.toBase58()
				);

				dlog(
					`spl_subscription`,
					`syncing_token_account :: ${mintAddress}:${accountInfo.pubkey.toBase58()} => ${
						account.parsedBalance
					}`
				);

				setTokenAccount(mintAddress, account);
			}
		},
		[authority, setTokenAccount, shouldSyncTokenAccount]
	);

	const fetchAndProcessTokenAccountsForProgram = useCallback(
		async (
			connection: Connection,
			authority: PublicKey,
			programId: PublicKey
		) => {
			try {
				const response = await connection.getParsedTokenAccountsByOwner(
					authority,
					{ programId }
				);
				response.value.forEach(processTokenAccount);
			} catch (error) {
				console.error('Error fetching token accounts:', error);
			}
		},
		[processTokenAccount]
	);

	const fetchAndProcessAllTokenAccounts = useCallback(async () => {
		clearAllTokenAccounts();

		if (!authority || !connection) return;

		await Promise.all(
			TOKEN_PROGRAMS_TO_FETCH.map((programId) =>
				fetchAndProcessTokenAccountsForProgram(connection, authority, programId)
			)
		);

		setLastSyncedWallet(authority);
	}, [
		authority,
		connection,
		clearAllTokenAccounts,
		fetchAndProcessTokenAccountsForProgram,
		setLastSyncedWallet,
	]);

	// Sync when wallet changes
	useEffect(() => {
		fetchAndProcessAllTokenAccounts();
	}, [authority, fetchAndProcessAllTokenAccounts]);

	// Listen for refresh events
	useEffect(() => {
		if (!eventEmitter) return;

		eventEmitter.on('refreshTokenAccounts', fetchAndProcessAllTokenAccounts);
		return () => {
			eventEmitter.off('refreshTokenAccounts', fetchAndProcessAllTokenAccounts);
		};
	}, [eventEmitter, fetchAndProcessAllTokenAccounts]);
};
