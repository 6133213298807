import { PublicKey } from '@solana/web3.js';

import { OrderActionRecord } from 'src/@types/orders';
import UI_UTILS from 'src/utils/uiUtils';
import { COMMON_UI_UTILS, ENUM_UTILS } from '@drift/common';
import {
	OrderAction,
	OrderActionExplanation,
	MarketType,
	isVariant,
} from '@drift-labs/sdk';
import { useMemo } from 'react';
import { DEFAULT_PUBLIC_KEY } from 'src/@types/types';
import Text from 'src/components/Text/Text';
import { ErrorFilled, LiqHalf, Open, SuccessFilled } from '@drift-labs/icons';
import { format } from 'timeago.js';
import { getCounterpartyIcon } from 'src/components/Counterparty';
import Drawer from 'src/components/Drawer';
import { twMerge } from 'tailwind-merge';

const ICON_SIZE = 16;
const getOrderActionIcon = (
	status: ReturnType<typeof UI_UTILS.getOrderStatusString>
) => {
	if (status.includes('Partially Filled')) {
		return <LiqHalf size={ICON_SIZE} color="var(--text-label)" />;
	} else if (status === 'Filled') {
		return <SuccessFilled size={ICON_SIZE} />;
	} else if (['Cancelled', 'Expired'].includes(status)) {
		return <ErrorFilled size={ICON_SIZE} />;
	} else if (['Open', 'Triggered'].includes(status)) {
		return (
			<svg
				width={ICON_SIZE}
				height={ICON_SIZE}
				viewBox={`0 0 ${ICON_SIZE} ${ICON_SIZE}`}
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					cx={ICON_SIZE / 2}
					cy={ICON_SIZE / 2}
					r={ICON_SIZE / 2 - 2}
					stroke="var(--text-label)"
					strokeWidth="1"
				/>
			</svg>
		);
	} else {
		return null;
	}
};

export const OrderActionRow = ({
	orderActionRecord,
	currentAccountPubKey,
}: {
	orderActionRecord: OrderActionRecord;
	currentAccountPubKey: PublicKey | undefined;
}) => {
	const tradeInfo = UI_UTILS.getTradeInfoFromOrderActionRecord(
		currentAccountPubKey,
		orderActionRecord
	);

	const orderStatus = UI_UTILS.getOrderStatusString(
		orderActionRecord.action,
		tradeInfo.totalBaseAmountFilled,
		tradeInfo.baseAssetAmount
	);
	const isOpenAction = ENUM_UTILS.match(
		orderActionRecord.action,
		OrderAction.PLACE
	);
	const isCancel = ENUM_UTILS.match(
		orderActionRecord.action,
		OrderAction.CANCEL
	);

	const averagePrice = COMMON_UI_UTILS.calculateAverageEntryPrice(
		tradeInfo.quoteAssetAmountFilled,
		tradeInfo.baseAssetAmountFilled
	).toNotional(true);
	const filledAmount = tradeInfo.baseAssetAmountFilled.toTradePrecision();
	const totalOrderedAmount = tradeInfo.baseAssetAmount.toTradePrecision();

	const counterparty = useMemo(() => {
		return tradeInfo.counterparty?.equals(DEFAULT_PUBLIC_KEY)
			? ''
			: tradeInfo.counterparty?.toString();
	}, [tradeInfo.counterparty, DEFAULT_PUBLIC_KEY]);
	const isPerp = isVariant(MarketType.PERP, tradeInfo.marketType);
	const ammCounterparty = isPerp
		? 'Drift AMM'
		: ENUM_UTILS.match(
				orderActionRecord.actionExplanation,
				OrderActionExplanation.ORDER_FILLED_WITH_SERUM
		  )
		? 'OpenBook'
		: 'Phoenix';

	const cancelExplanation = isCancel
		? ENUM_UTILS.match(
				orderActionRecord.actionExplanation,
				OrderActionExplanation.NONE
		  )
			? 'Cancelled by user'
			: UI_UTILS.lowerCaseNonFirstWords(
					UI_UTILS.splitByCapitalLetters(
						ENUM_UTILS.toStr(orderActionRecord.actionExplanation)
					)
			  )
		: undefined;

	const date = new Date(tradeInfo.ts.toNumber() * 1000);

	return (
		<div className="flex flex-col gap-2 pt-4 pl-5 text-text-default first:pt-0">
			<div className="relative flex justify-between">
				<div className="flex items-start gap-1">
					<Text.BODY1 className="uppercase text-text-emphasis">
						{orderStatus}
					</Text.BODY1>
					<a
						href={UI_UTILS.getUrlForTx(orderActionRecord.txSig)}
						target="_blank"
						rel="noopener noreferrer"
						className="flex items-center"
					>
						<Open color="var(--interactive-link)" size={16} />
					</a>
				</div>

				<div className="flex flex-col text-right">
					<Text.BODY3>{date.toLocaleString().replace(',', '')}</Text.BODY3>
					<Text.BODY3>{format(date)}</Text.BODY3>
				</div>

				<div className="absolute left-0 -translate-x-5 -top-[1px]">
					{getOrderActionIcon(orderStatus)}
				</div>
			</div>
			{!isOpenAction && (
				<>
					<Drawer.DetailBox label="Average Fill Price" value={averagePrice} />
					<Drawer.DetailBox
						label="Filled / Cumulative Filled / Ordered"
						value={
							<div className="flex flex-wrap items-center gap-1">
								<div className="flex items-center">
									<Text.BODY2 className="uppercase">
										{filledAmount} /{' '}
										{tradeInfo.totalBaseAmountFilled.toTradePrecision()} /{' '}
										{totalOrderedAmount}
									</Text.BODY2>
								</div>

								{cancelExplanation && (
									<div
										className={
											'flex items-center gap-1 bg-button-secondary-bg py-[2px] px-1 rounded-sm text-chip-default-text'
										}
									>
										<Text.BODY3 className="first-letter:capitalize">
											{cancelExplanation}
										</Text.BODY3>
									</div>
								)}

								{Number(filledAmount) > 0 && (
									<div
										className={twMerge(
											'flex items-center gap-1 bg-button-secondary-bg py-[2px] px-1 rounded-sm',
											counterparty
												? 'text-interactive-link'
												: 'text-chip-default-text'
										)}
									>
										{getCounterpartyIcon(
											{
												counterparty,
												actionExplanation: orderActionRecord.actionExplanation,
												marketType: ENUM_UTILS.toObj(tradeInfo.marketType),
												isGenericTrade: false,
											},
											counterparty
												? 'var(--interactive-link)'
												: 'var(--chip-default-text)'
										)}
										<Text.BODY3
											className={twMerge(counterparty && 'underline')}
										>
											{counterparty ? (
												<a
													href={UI_UTILS.getUrlForAccount(counterparty)}
													target="_blank"
													rel="noopener noreferrer"
												>
													CounterParty[
													{UI_UTILS.abbreviateAddress(counterparty)}]
												</a>
											) : (
												`Filled by ${ammCounterparty}`
											)}
										</Text.BODY3>
									</div>
								)}
							</div>
						}
					/>
				</>
			)}
		</div>
	);
};
