'use client';

import React from 'react';
import Modal from '../Modal';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import { twMerge } from 'tailwind-merge';
import { Typo } from '@drift-labs/react';
import Button from 'src/components/Button';
import Image from 'next/image';
import { SwiftLightning } from 'src/components/Icons/SwiftLightning';
import { initializeSignedMsgUserOrdersAccount } from 'src/utils/signedMsgs';
const SwiftAnnouncementModal = () => {
	const [setStore, driftClient, walletAdapter] = useDriftStore((s) => [
		s.set,
		s.driftClient.client,
		s.wallet.current.adapter,
	]);
	const [_, setHasSeenSwiftAnnouncement] = useLocalStorageState(
		'hasSeenSwiftAnnouncement',
		false
	);

	const handleClose = () => {
		setHasSeenSwiftAnnouncement(true);
		setStore((s) => {
			s.modals.showSwiftAnnouncementModal = false;
		});
	};

	const handleEnableSwift = async () => {
		const isInitialized = await initializeSignedMsgUserOrdersAccount(
			driftClient,
			walletAdapter
		);
		setStore((s) => {
			s.tradeForm.isSwiftSelected = true;
		});
		if (isInitialized) {
			handleClose();
		}
	};

	return (
		<Modal onClose={handleClose}>
			<div className="pb-10 mx-5">
				<Modal.Body
					className={twMerge(
						'h-fit max-w-[386px] mx-auto mt-10 rounded-lg pb-0',
						'text-neutrals-20'
					)}
				>
					{/* Top Image */}
					<div className="w-ful relative">
						<Image
							src="/assets/images/swift/swift-announcement.png"
							alt="SWIFT Trading"
							className="w-full h-full object-cover rounded-t-lg"
							width={384}
							height={220}
						/>
					</div>

					{/* Content Section */}
					<div className="flex flex-col gap-4 p-4 text-text-default">
						<Typo.B3 className="w-full">
							Faster fills, lower slippage, and gasless trading,{' '}
							<strong>SWIFT</strong> is Drift’s biggest liquidity upgrade since
							launch. Toggle on SWIFT for blazingly fast transactions.
						</Typo.B3>

						{/* Enable Button */}
						<Button.Gradient size="LARGE" highlight onClick={handleEnableSwift}>
							<span>Enable</span>
							<span className="font-bold flex items-center justify-center">
								<SwiftLightning variant="text-primary-button" size="small" />{' '}
								SWIFT
							</span>
						</Button.Gradient>

						<Typo.B6 className="text-text-tertiary">
							{' '}
							Note: A one-time signature is required to activate SWIFT.
						</Typo.B6>
					</div>
				</Modal.Body>
			</div>
		</Modal>
	);
};

export default React.memo(SwiftAnnouncementModal);
