'use client';

import Drawer from 'src/components/Drawer';
import MarketIcon from 'src/components/Utils/MarketIcon';
import { useDrawersStore } from 'src/stores/useDrawersStore';
import Text from 'src/components/Text/Text';
import Utility from 'src/components/Inputs/Utility';
import { useCallback, useState } from 'react';
import Button from 'src/components/Button';
import { UIMarket } from '@drift/common';
import useGetOraclePriceForMarket from 'src/hooks/useGetOraclePriceForMarket';
//import useIsLiteMode from 'src/hooks/useIsLiteMode';
import {
	MarketType,
	BigNum,
	BASE_PRECISION_EXP,
	PRICE_PRECISION_EXP,
	AMM_RESERVE_PRECISION_EXP,
	PERCENTAGE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
} from '@drift-labs/sdk';
import useCalculateTradeFee from 'src/hooks/useCalculateTradeFee';
import useDriftActions from 'src/hooks/useDriftActions';
import { marketHasInsuranceFund } from 'src/hooks/useInfoForCurrentlySelectedMarket';
//import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import useMarketInfoDisplayData from 'src/hooks/useMarketInfoDisplayData';
import useMarketsInfoStore from 'src/stores/useMarketsInfoStore';
//import { getIsPreLaunchMarket } from '../../PreLaunchMarketAlerts/PreLaunchMarketAlertUtils';
import Chevron from '../../Icons/Chevron';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import Tooltip from '../../Tooltip/Tooltip';
import UIHelpTextLink from '../../UIHelpTextLink';
import { ArrowLeft, Info, Swap } from '@drift-labs/icons';
import { PnlLeaderboardPanel } from './PnlLeaderboardPanel';
import { twMerge } from 'tailwind-merge';
import { FundingRateInfoAndTooltip } from 'src/components/PerpPageStats/FundingRateInfo';
import useBorrowLendDataForMarket from 'src/hooks/useBorrowLendDataForMarket';
import { useRolling24HVolume } from 'src/components/PerpPageStats/Rolling24hrVolumeNumberDisplay';
import useGoToRoute from 'src/hooks/useGoToRoute';

const Section = ({
	sectionLabel,
	topRightNode,
	children,
}: {
	sectionLabel: string;
	topRightNode?: React.ReactNode;
	children: React.ReactNode;
}) => {
	return (
		<div className="flex flex-col gap-4">
			<div className="flex items-center justify-between w-full">
				<Text.H5 className="text-text-default">{sectionLabel}</Text.H5>
				{!!topRightNode && (
					<Text.BODY2 className="text-text-label">{topRightNode}</Text.BODY2>
				)}
			</div>
			<div className="flex flex-col gap-2">{children}</div>
		</div>
	);
};

const DetailRow = ({
	label,
	value,
	marketSymbol,
}: {
	label: React.ReactNode;
	value: React.ReactNode;
	marketSymbol?: string;
}) => (
	<div className="flex justify-between w-full h-[16px]">
		<Text.BODY3 className="text-text-label">{label}</Text.BODY3>
		<div className="flex items-center gap-1">
			{marketSymbol && (
				<MarketIcon marketSymbol={marketSymbol} sizeClass="w-4 h-4" />
			)}
			<Text.BODY3 className="text-text-default">{value}</Text.BODY3>
		</div>
	</div>
);

const PerpMarketDetailsSection = ({
	market,
	showPnl = false,
	onClose,
}: {
	market: UIMarket;
	showPnl?: boolean;
	onClose?: () => void;
}) => {
	const getOraclePrice = useGetOraclePriceForMarket();
	const selectedMarketId = market.marketId;

	const oraclePrice = getOraclePrice(market.marketId);
	const actions = useDriftActions();
	// const isLiteMode = useIsLiteMode();
	// const isMobile = useIsMobileScreenSize();
	const goToRoute = useGoToRoute();

	const marketInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.perpLookup[market.marketIndex]
	);

	const marketAccount = marketInfo?.account;

	const marketData = useMarketInfoDisplayData();

	const marketFees = useCalculateTradeFee({
		quoteSize: 0,
		marketType: MarketType.PERP,
		marketIndex: market.marketIndex,
	});

	const imfFactor = marketAccount?.imfFactor;
	const orderTickSize = marketAccount?.amm?.orderTickSize;
	const orderStepSize = marketAccount?.amm?.orderStepSize;

	const extendedInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.perpLookup[market.marketIndex]?.extendedInfo
	);

	const [fundingRateUnit, setFundingRateUnit] = useLocalStorageState<'h' | 'y'>(
		'fundingRateUnitPreference',
		'h'
	);

	const [showPnlLeaders, setShowPnlLeaders] = useState(showPnl);

	const { volume } = useRolling24HVolume(selectedMarketId);

	// const isPreLaunchMarket = getIsPreLaunchMarket(
	// 	selectedMarketId,
	// 	marketAccount
	// );

	const { minOrderSize, marginMaintenance, availableInsurance, pnlPoolValue } =
		extendedInfo || {};

	const displayMinOrderSize = minOrderSize
		? BigNum.from(minOrderSize, BASE_PRECISION_EXP).prettyPrint()
		: null;
	const displayOrderTickSize = orderTickSize
		? BigNum.from(orderTickSize, PRICE_PRECISION_EXP).prettyPrint()
		: null;
	const displayOrderStepSize = BigNum.from(
		orderStepSize,
		AMM_RESERVE_PRECISION_EXP
	).prettyPrint();

	const displayImfFactor = imfFactor
		? BigNum.from(imfFactor, PERCENTAGE_PRECISION_EXP).prettyPrint()
		: null;
	const displayMarginMaintenance = `${marginMaintenance / 100}%`;
	const displayAvailableInsurance = availableInsurance
		? BigNum.from(availableInsurance, QUOTE_PRECISION_EXP).toMillified()
		: null;
	const displayPnlPoolValue = pnlPoolValue
		? `$${BigNum.from(pnlPoolValue, QUOTE_PRECISION_EXP).toMillified()}`
		: null;

	const displayLiquidationFee = `${
		(marketAccount.liquidatorFee + marketAccount.ifLiquidationFee) / 10000
	}%`;

	const hasLimitedInsurance = !marketHasInsuranceFund(marketAccount);

	const fundingIsDue =
		marketData && marketData?.estimatedFundingRateCountdownTs <= 0;

	const updateFundingRate = () => actions.updateFundingRate(market.marketIndex);

	const goToFundingHistory = (e: React.MouseEvent<HTMLButtonElement>) => {
		e.stopPropagation();
		onClose();
		goToRoute(`/stats/funding-history?market=${marketData.symbol}`);
	};

	const openPnlLeaderboard = () => setShowPnlLeaders(true);

	const closePnlLeaderboard = () => setShowPnlLeaders(false);

	const toggleFundingRateUnit = useCallback(() => {
		setFundingRateUnit(fundingRateUnit === 'h' ? 'y' : 'h');
	}, [fundingRateUnit]);

	return (
		<div
			className={twMerge(
				'flex flex-col gap-4 h-full overflow-y-hidden',
				showPnlLeaders ? 'p-2' : 'p-4'
			)}
		>
			{showPnlLeaders ? (
				<div className="w-full h-full">
					<PnlLeaderboardPanel
						marketIndex={market.marketIndex}
						poolBalanceDisplay={displayPnlPoolValue}
						goBackComponent={
							<div
								onClick={closePnlLeaderboard}
								className="inline-flex items-center text-text-emphasis hover:cursor-pointer"
							>
								<ArrowLeft size={14} />
								<Text.BODY2 className="ml-1">P&L Pool</Text.BODY2>
							</div>
						}
					/>
				</div>
			) : (
				<>
					<Section sectionLabel="Market Details">
						<DetailRow label="Oracle Price" value={oraclePrice.prettyPrint()} />
						<DetailRow
							label="P&L Pool"
							value={
								<span
									className="inline-flex items-center hover:cursor-pointer"
									onClick={openPnlLeaderboard}
								>
									{displayPnlPoolValue}
									<Chevron direction="right" colour={'var(--text-label)'} />
								</span>
							}
						/>
						<DetailRow
							label={
								<div className="inline-flex items-center">
									Insurance Fund{' '}
									{hasLimitedInsurance && (
										<Tooltip
											allowHover
											content={
												<div className="flex flex-col space-y-2">
													<span>
														This market uses a limited insurance fund (fee pool
														only) to protect against levered losses.
													</span>{' '}
													<UIHelpTextLink
														href="https://docs.drift.trade/insurance-fund/insurance-fund-intro"
														text="Learn More"
														className="mt-2"
													/>
												</div>
											}
										>
											<Info className="ml-1" />
										</Tooltip>
									)}
								</div>
							}
							value={displayAvailableInsurance}
						/>
						<DetailRow
							label={`Long Open Interest`}
							value={`${marketData?.longOpenInterest} ${marketInfo.genericInfo.baseAssetSymbol}`}
						/>
						<DetailRow
							label={`Short Open Interest`}
							value={`${marketData?.shortOpenInterest} ${marketInfo.genericInfo.baseAssetSymbol}`}
						/>
						<DetailRow
							label={`Max Open Interest`}
							value={`${marketData?.maxOpenInterest} ${marketInfo.genericInfo.baseAssetSymbol}`}
						/>
						<DetailRow
							label={`24h Volume`}
							value={`$${BigNum.from(volume).toMillified()}`}
						/>
					</Section>
					<Utility.VERTDIVIDER />
					<Section
						sectionLabel="Funding Rate"
						topRightNode={
							<Text.BODY2
								onClick={goToFundingHistory}
								className="inline-flex items-center text-text-label hover:cursor-pointer"
							>
								View history <Chevron direction={'right'} />
							</Text.BODY2>
						}
					>
						<DetailRow
							label={
								<span className="inline-flex items-center">
									Predicted / Last 24h{' '}
									<Swap
										className="ml-0.5 hover:cursor-pointer"
										onClick={toggleFundingRateUnit}
										size={14}
									/>
								</span>
							}
							value={
								<FundingRateInfoAndTooltip
									marketSymbol={marketData.symbol}
									selectedMarketId={selectedMarketId}
									textOnlyDisplay
									unit={fundingRateUnit}
								/>
							}
						/>
						<DetailRow
							label="Next funding update"
							value={
								<>
									<Text.BODY3>
										{fundingIsDue && (
											<Button.Secondary
												className="mr-1"
												onClick={updateFundingRate}
												size="SMALL"
											>
												Trigger Update
											</Button.Secondary>
										)}
										{marketData &&
											(fundingIsDue ? (
												<span>Due*</span>
											) : (
												<>
													{`${Math.floor(
														marketData?.estimatedFundingRateCountdownTs / 60
													)
														.toString()
														.padStart(2, '0')}:${(
														marketData?.estimatedFundingRateCountdownTs % 60
													)
														.toString()
														.padStart(2, '0')}`}
												</>
											))}
									</Text.BODY3>
								</>
							}
						/>
					</Section>
					<Utility.VERTDIVIDER />
					<Section sectionLabel="Market Rules">
						<DetailRow
							label="Taker Fee / Maker Rebate"
							value={`${parseFloat(
								marketFees.takerFeePct.toFixed(5)
							)}% / ${parseFloat(
								Math.abs(marketFees.makerFeePct).toFixed(5)
							)}%`}
						/>
						<DetailRow label="Min. Order Size" value={displayMinOrderSize} />
						<DetailRow
							label={
								<div className="inline-flex items-center">
									Order Tick Size{' '}
									<Tooltip
										allowHover
										content={
											'Smallest unit of change for the price of an order'
										}
									>
										<Info className="ml-1" />
									</Tooltip>
								</div>
							}
							value={displayOrderTickSize}
						/>
						<DetailRow
							label={
								<div className="inline-flex items-center">
									Order Step Size{' '}
									<Tooltip
										allowHover
										content={'Smallest unit of change for the size of an order'}
									>
										<Info className="ml-1" />
									</Tooltip>
								</div>
							}
							value={displayOrderStepSize}
						/>
						<DetailRow label="IMF Factor" value={displayImfFactor} />
						<DetailRow
							label="Margin Maintenance"
							value={displayMarginMaintenance}
						/>
						<DetailRow
							label="Total Liquidation Fee"
							value={displayLiquidationFee}
						/>
						<DetailRow label="Market Index" value={market.marketIndex} />
					</Section>{' '}
				</>
			)}
		</div>
	);
};

const SpotMarketDetailsSection = ({
	market,
}: //onClose,
{
	market: UIMarket;
	//onClose?: () => void;
}) => {
	const getOraclePrice = useGetOraclePriceForMarket();
	const selectedMarketId = market.marketId;

	const oraclePrice = getOraclePrice(market.marketId);
	// const isLiteMode = useIsLiteMode();
	// const isMobile = useIsMobileScreenSize();

	const marketInfo = useMarketsInfoStore(
		(s) => s.allMarketsInfo.spotLookup[market.marketIndex]
	);

	const marketAccount = marketInfo?.account;

	const borrowLendData = useBorrowLendDataForMarket(selectedMarketId);

	const marketFees = useCalculateTradeFee({
		quoteSize: 0,
		marketType: MarketType.SPOT,
		marketIndex: market.marketIndex,
	});

	const imfFactor = marketAccount?.imfFactor;
	const orderTickSize = marketAccount?.orderTickSize;
	const orderStepSize = marketAccount?.orderStepSize;
	const minOrderSize = marketAccount?.minOrderSize;
	const marketPrecisionExp = marketInfo.config.precisionExp;

	const displayMinOrderSize = minOrderSize
		? BigNum.from(minOrderSize, marketPrecisionExp).prettyPrint()
		: null;
	const displayOrderTickSize = orderTickSize
		? BigNum.from(orderTickSize, QUOTE_PRECISION_EXP).prettyPrint()
		: null;
	const displayOrderStepSize = BigNum.from(
		orderStepSize,
		marketPrecisionExp
	).prettyPrint();

	const displayImfFactor = imfFactor
		? BigNum.from(imfFactor, PERCENTAGE_PRECISION_EXP).prettyPrint()
		: null;

	return (
		<div className={'flex flex-col gap-4 h-full overflow-y-hidden p-4'}>
			{
				<>
					<Section sectionLabel="Market Details">
						<DetailRow label="Oracle Price" value={oraclePrice.prettyPrint()} />

						<DetailRow
							label={`Available to Borrow`}
							value={`${borrowLendData.borrowLimitBase.prettyPrint(
								false,
								marketPrecisionExp.toNumber()
							)} ${marketInfo.genericInfo.baseAssetSymbol}`}
						/>
					</Section>
					<Utility.VERTDIVIDER />
					<Section sectionLabel="Market Rules">
						<DetailRow
							label="Taker Fee / Maker Rebate"
							value={`${parseFloat(
								marketFees.takerFeePct.toFixed(5)
							)}% / ${parseFloat(
								Math.abs(marketFees.makerFeePct).toFixed(5)
							)}%`}
						/>
						<DetailRow label="Min. Order Size" value={displayMinOrderSize} />
						<DetailRow
							label={
								<div className="inline-flex items-center">
									Order Tick Size{' '}
									<Tooltip
										allowHover
										content={
											'Smallest unit of change for the price of an order'
										}
									>
										<Info className="ml-1" />
									</Tooltip>
								</div>
							}
							value={displayOrderTickSize}
						/>
						<DetailRow
							label={
								<div className="inline-flex items-center">
									Order Step Size{' '}
									<Tooltip
										allowHover
										content={'Smallest unit of change for the size of an order'}
									>
										<Info className="ml-1" />
									</Tooltip>
								</div>
							}
							value={displayOrderStepSize}
						/>
						<DetailRow label="IMF Factor" value={displayImfFactor} />
						<DetailRow
							label="Initial / Maintenance Asset Weight"
							value={`${borrowLendData.assetWeight}% / ${borrowLendData.maintenanceAssetWeight}%`}
						/>
						<DetailRow
							label="Initial / Maintenance Liability Weight"
							value={`${borrowLendData.liabilityWeight}% / ${borrowLendData.maintenanceLiabilityWeight}%`}
						/>
						<DetailRow label="Market Index" value={market.marketIndex} />
					</Section>
				</>
			}
		</div>
	);
};

export const MarketDetailsDrawer = () => {
	const handleCloseHistoryPanel = useDrawersStore((s) => s.handleCloseDrawer);
	const { uiMarket, goToPnl } = useDrawersStore((s) => s.marketDetails);

	const symbol = uiMarket.market.symbol;
	const isSpot = uiMarket.isSpot;

	const onClose = () => {
		handleCloseHistoryPanel('marketDetails');
	};

	return (
		<Drawer
			drawerName="marketDetails"
			className="w-full sm:max-w-[400px] sm:w-[400px] thin-scroll overflow-y-auto flex flex-col"
		>
			<Drawer.Header
				titleContent={
					<div className="flex items-center gap-1">
						<MarketIcon marketSymbol={symbol} />
						<span className="ml-1">{symbol}</span>
					</div>
				}
				className="p-4"
				onClose={onClose}
			/>

			{isSpot ? (
				<SpotMarketDetailsSection market={uiMarket} />
			) : (
				<PerpMarketDetailsSection
					market={uiMarket}
					showPnl={goToPnl}
					onClose={onClose}
				/>
			)}
		</Drawer>
	);
};
