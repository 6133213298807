import { useEffect, useState } from 'react';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';

const DEFAULT_DATA = {
	maxUsers: 0,
	currentUsers: 0,
	reduceOnly: false,
};

const useProtectedMakerSlotsOpen = () => {
	const driftClient = useDriftStore((s) => s.driftClient.client);
	const [data, setData] = useState(DEFAULT_DATA);

	useEffect(() => {
		const fetchProtectedMakerSlotsOpen = async () => {
			const protectedMakerConfig =
				await driftClient.fetchProtectedMakerModeConfig();
			setData(protectedMakerConfig);
		};

		if (driftClient && driftClient.isSubscribed) {
			fetchProtectedMakerSlotsOpen();
		}
	}, [driftClient]);

	return {
		protectedMakerMaxSlots: data.maxUsers,
		protectedMakerSlotsOpen: data.maxUsers - data.currentUsers,
		canJoin: data.maxUsers > data.currentUsers && !data.reduceOnly,
	};
};

export default useProtectedMakerSlotsOpen;
