import { SpotMarketConfig } from '@drift-labs/sdk';

import { MAIN_POOL_ID } from '@drift/common';
import { useState } from 'react';
import {
	CurrentSpotMarkets,
	SPOT_MARKETS_LOOKUP,
} from '../environmentVariables/EnvironmentVariables';
import useDriftStore, { DriftStore } from '../stores/DriftStore/useDriftStore';
import useDriftAccountStore from '../stores/useDriftAccountsStore';
import useDriftActions from './useDriftActions';

export type CollateralModalState = {
	intentState: DriftStore['modalsProps']['intentState'];
	selectedTargetAccountKey: string;
	selectedCollateralMarket: SpotMarketConfig;
};

const useCollateralModalState = () => {
	const intentState = useDriftStore((s) => s.modalsProps.intentState);
	const [selectedTargetAccountKey, setSelectedTargetAccountKey] =
		useState<string>(intentState.modalTargetAccountKey);
	const [selectedCollateralMarket, setSelectedCollateralMarket] =
		useState<SpotMarketConfig>(
			SPOT_MARKETS_LOOKUP[intentState.modalCollateralIndex]
		);
	const selectedTargetAccount = useDriftAccountStore(
		(s) => s.accounts[selectedTargetAccountKey]
	);
	const [selectedPoolId, setSelectedPoolId] = useState<number>(
		intentState.modalCollateralPoolId ??
			selectedCollateralMarket?.poolId ??
			selectedTargetAccount?.poolId ??
			MAIN_POOL_ID
	);
	const getAccountStoreState = useDriftAccountStore((s) => s.get);

	const updateCollateralModalIntentState =
		useDriftActions().updateCollateralModalIntentState;

	const handleTargetAccountChange = (newAccountKey: string) => {
		const newTargetUserAccount = getAccountStoreState().accounts[newAccountKey];
		const poolIdMatches =
			selectedTargetAccount.poolId === newTargetUserAccount.poolId;

		const poolIdToUse = newTargetUserAccount.poolId;

		const newSelectedMarket = poolIdMatches
			? selectedCollateralMarket
			: CurrentSpotMarkets.find(
					(market) => market.poolId === newTargetUserAccount.poolId
			  );

		setSelectedTargetAccountKey(newAccountKey);
		setSelectedPoolId(poolIdToUse);
		setSelectedCollateralMarket(newSelectedMarket);

		updateCollateralModalIntentState({
			modalTargetAccountKey: newAccountKey,
			modalCollateralPoolId: poolIdToUse,
			modalCollateralIndex: newSelectedMarket.marketIndex,
		});
	};

	const updateCollateralIndex = (newMarketIndex: number) => {
		const newMarket = CurrentSpotMarkets.find(
			(market) => market.marketIndex === newMarketIndex
		);
		setSelectedCollateralMarket(newMarket);

		updateCollateralModalIntentState({
			modalCollateralIndex: newMarketIndex,
			modalDefaultLargestBalance: false,
		});
	};

	return {
		intentState,
		selectedCollateralMarket,
		selectedTargetAccountKey,
		selectedPoolId,
		selectedTargetAccount,
		defaultToLargestBalance: intentState.modalDefaultLargestBalance,
		handleTargetAccountChange,
		updateCollateralIndex,
	};
};

export default useCollateralModalState;
