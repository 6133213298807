'use client';

import { useEffect, useState } from 'react';
import { COMMON_UI_UTILS, ENUM_UTILS } from '@drift/common';
import {
	CLOSE_BORROW_OPTIONS,
	CloseBorrowOption,
	CloseBorrowSpotTrade,
} from '../Popups/CloseBorrowPopup';
import UnderlinedTabs from '../TabbedNavigation/UnderlinedTabs';
import Text from '../Text/Text';
import MarketIcon from '../Utils/MarketIcon';
import useDriftAccountStore, {
	AccountSpotBalance,
} from 'src/stores/useDriftAccountsStore';
import useAccountSpotBalances from 'src/hooks/useAccountBankBalances';
import useDriftClient from 'src/hooks/useDriftClient';
import UI_UTILS from 'src/utils/uiUtils';
import { BigNum, SpotBalanceType } from '@drift-labs/sdk';
import CloseBorrowSwapForm from '../Swap/CloseBorrowSwapForm';
import CloseBorrowDepositForm from '../CloseBorrowDepositForm';

import useDriftActions from 'src/hooks/useDriftActions';
import Modal from './Modal';
import useCollateralModalState from '../../hooks/useCollateralModalState';

export default function CloseBorrowModal() {
	const actions = useDriftActions();
	const currentUserKey = useDriftAccountStore((s) => s.currentUserKey);
	const { selectedCollateralMarket } = useCollateralModalState();
	const marketIndex = selectedCollateralMarket?.marketIndex;
	const spotBalances = useAccountSpotBalances(currentUserKey ?? '', null);
	const driftClient = useDriftClient();

	const [assetToBuy, setAssetToBuy] = useState<AccountSpotBalance>(undefined);
	const [closeBorrowOption, setCloseBorrowOption] = useState<string>(
		CloseBorrowOption.Swaps
	);

	const bufferedCloseBorrowAmount = UI_UTILS.getCloseBorrowAmountWithBuffer(
		driftClient,
		assetToBuy?.asset.marketIndex ?? 0,
		assetToBuy?.balance ?? BigNum.zero()
	).print();

	useEffect(() => {
		const foundAssetToBuy = spotBalances?.find(
			(bal) =>
				bal.asset.marketIndex === marketIndex &&
				ENUM_UTILS.match(bal.balanceType, SpotBalanceType.BORROW)
		);
		setAssetToBuy(foundAssetToBuy);
	}, [spotBalances?.length]);

	const handleClose = () => {
		actions.showModal('showCloseBorrowModal', false);
	};

	let closeBorrowContent: React.ReactNode;
	switch (closeBorrowOption) {
		case CloseBorrowOption.SpotTrade:
			closeBorrowContent = (
				<CloseBorrowSpotTrade
					spotMarketIndex={marketIndex}
					onClose={handleClose}
				/>
			);
			break;
		case CloseBorrowOption.Swaps:
			closeBorrowContent = (
				<CloseBorrowSwapForm
					swapToSpotMarketIndex={marketIndex}
					onClose={handleClose}
				/>
			);
			break;
		case CloseBorrowOption.Deposit:
			closeBorrowContent = (
				<CloseBorrowDepositForm
					spotMarketIndex={marketIndex}
					onClose={handleClose}
				/>
			);
			break;
		default:
			closeBorrowContent = (
				<CloseBorrowSpotTrade
					spotMarketIndex={marketIndex}
					onClose={handleClose}
				/>
			);
	}

	return (
		<Modal onClose={handleClose}>
			<Modal.Body>
				<Modal.Header onClose={handleClose} showX className="pt-5 pb-4">
					<Modal.Title>
						<div className="flex flex-col items-start">
							<Text.H4 className="text-text-default">Close Borrow</Text.H4>
							<div className="inline-flex items-center">
								<Text.H4 className="text-text-emphasis">
									-{bufferedCloseBorrowAmount}
								</Text.H4>
								<MarketIcon
									marketSymbol={COMMON_UI_UTILS.getBaseAssetSymbol(
										assetToBuy?.asset.symbol ?? ''
									)}
									className="w-5 h-5 mx-1"
								/>
								<Text.H3 className="text-text-emphasis">
									{assetToBuy?.asset.symbol ?? ''}
								</Text.H3>
							</div>
						</div>
					</Modal.Title>
				</Modal.Header>
				<Modal.Content className="px-5 pt-4">
					<div className="w-full text-text-default space-y-4">
						<UnderlinedTabs
							options={CLOSE_BORROW_OPTIONS}
							onChange={setCloseBorrowOption}
							currentSelection={closeBorrowOption}
						/>

						{closeBorrowContent}
					</div>
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
}
