'use client';

import { UiVaultConfig } from 'src/@types/vaults';
import { VaultPerformance } from './VaultPerformance';
import { UIMarket } from '@drift/common';
import { SpotMarketConfig } from '@drift-labs/sdk';
import { VaultStrategy } from './VaultStrategy';
import { Open } from '@drift-labs/icons';
import Link from 'next/link';
import Utility from 'src/components/Inputs/Utility';
import { VaultPerformanceBreakdownForSidebar } from './VaultPerformance/VaultPerformanceBreakdown';
import Button from 'src/components/Button';
import { Typo } from 'src/components/Text/Typo';
import { VaultDepositorStatsForSidebar } from './UserPerformance/VaultDepositorStatsForSidebar';
import { VaultDescription } from './VaultDescription';

// TODO: need to preserve original single vault page and componentsc
export const VaultDetailsDrawerContent = (props: {
	uiVaultConfig: UiVaultConfig;
}) => {
	const strategyText = Array.isArray(
		props.uiVaultConfig.overviewSection?.[0]?.paragraphs?.[0]?.text
	)
		? props.uiVaultConfig.overviewSection?.[0]?.paragraphs?.[0]?.text[0]
		: props.uiVaultConfig.overviewSection?.[0]?.paragraphs?.[0]?.text;

	const description = props.uiVaultConfig.description;

	return (
		<div className="flex flex-col gap-6">
			<div className="flex flex-col p-4 gap-4">
				<div className="flex flex-col w-full">
					<VaultDepositorStatsForSidebar
						depositAssetConfig={
							UIMarket.createSpotMarket(props.uiVaultConfig.depositAsset)
								.market as SpotMarketConfig
						}
						vaultPubkey={props.uiVaultConfig.vaultPubkeyString}
					/>
				</div>

				<Utility.VERTDIVIDER />

				<VaultPerformance uiVaultConfig={props.uiVaultConfig} />

				<Utility.VERTDIVIDER />

				{strategyText && <VaultStrategy strategy={strategyText} />}

				{description && <VaultDescription description={description} />}

				<Utility.VERTDIVIDER />

				<div>
					<VaultPerformanceBreakdownForSidebar
						vaultPubkey={props.uiVaultConfig.vaultPubkeyString}
						depositAssetMarketIndex={props.uiVaultConfig.depositAsset}
					/>
				</div>

				<div className="p-4">
					<Link
						className="flex items-center justify-between w-full rounded cursor-pointer "
						href={`https://app.drift.trade?authority=${props.uiVaultConfig.vaultPubkeyString}`}
					>
						<Button.Secondary
							size="MEDIUM"
							className="flex items-center justify-center w-full gap-1 py-3 rounded-md"
						>
							<Typo.T2 className="group-hover:opacity-80">View Vault</Typo.T2>
							<Open className="transition-transform -translate-x-2 group-hover:translate-x-0" />
						</Button.Secondary>
					</Link>
				</div>
			</div>
		</div>
	);
};

// export const VaultMainContent = (props: { uiVaultConfig: UiVaultConfig }) => {
// 	const [activeTab, setActiveTab] = useState<ContentTab>(
// 		CONTENT_TAB_OPTIONS[0].value
// 	);

// 	const {
// 		vaultAccountData,
// 		vaultDepositorAccountData,
// 		isVaultDepositorLoaded,
// 	} = useVaultContext();

// 	const isMobile = useIsMobileScreenSize();

// 	const scrollableTabsRef = useRef<HTMLDivElement>(null);

// 	return (
// 		<div className="flex flex-col gap-6">
// 			<MobileScrollEdges
// 				scrollableRef={scrollableTabsRef}
// 				edgeColorClass="from-main-bg"
// 			>
// 				<div
// 					ref={scrollableTabsRef}
// 					className="w-full overflow-auto hidden-scroll"
// 				>
// 					<UnderlinedTabs
// 						onChange={(selection) => setActiveTab(selection as ContentTab)}
// 						currentSelection={activeTab}
// 						options={CONTENT_TAB_OPTIONS}
// 						tabClassName="whitespace-nowrap"
// 					/>
// 				</div>
// 			</MobileScrollEdges>

// 			<div className="flex w-full gap-6">
// 				<a
// 					href={`https://x.com/${props.uiVaultConfig.vaultManager.twitterHandle}`}
// 					target="_blank"
// 					rel="noreferrer noopner"
// 					className="text-text-secondary"
// 				>
// 					<Typo.T4>@{props.uiVaultConfig.vaultManager.twitterHandle}</Typo.T4>
// 				</a>

// 				{activeTab === 'VaultPerformance' && (
// 					<VaultPerformance uiVaultConfig={props.uiVaultConfig} />
// 				)}
// 				{activeTab === 'UserPerformance' && (
// 					<UserPerformance
// 						depositAssetConfig={
// 							UIMarket.createSpotMarket(props.uiVaultConfig.depositAsset)
// 								.market as SpotMarketConfig
// 						}
// 						vaultPubkey={props.uiVaultConfig.vaultPubkeyString}
// 					/>
// 				)}
// 				{activeTab === 'Overview' && (
// 					<VaultOverview uiVaultConfig={props.uiVaultConfig} />
// 				)}
// 				{!isMobile && (
// 					<VaultDepositWithdrawFormWithGradientWrapper
// 						uiVaultConfig={props.uiVaultConfig}
// 						vaultDepositorAccountData={vaultDepositorAccountData}
// 						isVaultDepositorLoaded={isVaultDepositorLoaded}
// 						vaultAccountData={vaultAccountData}
// 					/>
// 				)}
// 			</div>
// 		</div>
// 	);
// };
