'use client';

import Text from 'src/components/Text/Text';
import Switch from 'src/components/Switch';
import Tooltip from '../Tooltip/Tooltip';
import { Info } from '@drift-labs/icons';
import Select from '../Inputs/Select';
import { useState } from 'react';
import { MarginMode } from '@drift-labs/sdk';
import Button from '../Button';
import { twMerge } from 'tailwind-merge';
import useHighLeverageSpotsOpen from 'src/hooks/useHighLeverageSpotsOpen';
import { MAX_LEVERAGE_OPTIONS } from 'src/constants/constants';
import Chevron from '../Icons/Chevron';
import useProtectedMakerSlotsOpen from 'src/hooks/useProtectedMakerSlotsOpen.tsx';

interface MarginUpdateRowProps {
	label: string;
	marginEnabledChecked: boolean;
	currentMaxLev: number | undefined;
	dlpAutoDerisk: boolean;
	pmm: boolean;
	reduceOnly: boolean;
	highLeverageEnabled: boolean;
	onChangeEnabled: (newValue: boolean) => void;
	onChangeRatio: (newValue: number | undefined) => void;
	onChangeDlpAutoDerisk: (newValue: boolean) => void;
	onChangeReduceOnly: (newValue: boolean) => void;
	onChangeMarginMode: (newValue: MarginMode) => void;
	onChangePmm: (newValue: boolean) => void;
	disabled?: boolean;
	spacedOut?: boolean;
	includeLineSpace?: boolean;
	textClass?: string;
	tooltipText?: string;
}

const MarginUpdateRow = ({
	label,
	marginEnabledChecked,
	currentMaxLev,
	dlpAutoDerisk,
	pmm,
	reduceOnly,
	highLeverageEnabled,
	onChangeEnabled,
	onChangeRatio,
	onChangeDlpAutoDerisk,
	onChangeReduceOnly,
	onChangeMarginMode,
	onChangePmm,
	disabled,
	textClass,
	tooltipText,
}: MarginUpdateRowProps) => {
	const [viewMore, setViewMore] = useState(false);
	// const [selection, setSelection] = useState(currentMaxLev);
	const [marginEnabledSelection, setMarginEnabledSelection] =
		useState(marginEnabledChecked);
	const [pmmSelection, setPmmSelection] = useState(pmm);
	const [dlpAutoDeriskSelection, setDlpAutoDeriskSelection] =
		useState(dlpAutoDerisk);

	const [reduceOnlySelection, setReduceOnlySelection] = useState(reduceOnly);
	const { highLeverageMaxUsers, highLeverageSpotsOpen } =
		useHighLeverageSpotsOpen();

	const {
		protectedMakerMaxSlots,
		protectedMakerSlotsOpen,
		canJoin: canJoinProtectedMaker,
	} = useProtectedMakerSlotsOpen();

	return (
		<div
			key={label}
			className={twMerge(
				`w-full flex flex-col p-4 border border-container-border border-b-0 last:border-b gap-3 bg-main-bg`
			)}
		>
			<div className="">
				{tooltipText ? (
					<Tooltip
						className={`inline-flex items-center ${
							textClass ? textClass : 'text-text-label'
						}`}
						content={tooltipText}
					>
						<Text.BODY2>{label}</Text.BODY2>
						<Info size={16} className="relative top-[5px]" />
					</Tooltip>
				) : (
					<div>
						<Text.H4 className={'text-text-default'}>{label}</Text.H4>
					</div>
				)}
			</div>

			<div className="flex items-center justify-between w-full">
				<Text.BODY2 className="text-text-secondary">Margin Mode</Text.BODY2>
				{disabled ? (
					<Text.BODY2 className="text-text-secondary">
						{highLeverageEnabled ? `High Leverage` : 'Normal'}
					</Text.BODY2>
				) : (
					<div className="flex flex-row gap-2">
						<Button.BigInput
							onClick={() => onChangeMarginMode(MarginMode.DEFAULT)}
							className="w-auto mb-0 text-center"
							highlighted={!highLeverageEnabled}
						>
							<Text.BODY2
								className={!highLeverageEnabled && 'text-text-emphasis'}
							>
								Normal
							</Text.BODY2>
						</Button.BigInput>
						<Tooltip
							placement={'top'}
							content={
								<>
									High leverage mode is available to a limited number of Drift
									user accounts at a time.
									<br />
									Spots remaining:{' '}
									<span className="text-warn-yellow">
										{highLeverageSpotsOpen}/{highLeverageMaxUsers}
									</span>
								</>
							}
						>
							<Button.BigInput
								onClick={() => onChangeMarginMode(MarginMode.HIGH_LEVERAGE)}
								className="w-auto mb-0 text-center"
								highlighted={highLeverageEnabled}
								disabled={highLeverageSpotsOpen === 0 && !highLeverageEnabled}
							>
								<Text.BODY2
									className={highLeverageEnabled && 'text-text-emphasis'}
								>
									High Leverage
								</Text.BODY2>
							</Button.BigInput>
						</Tooltip>
					</div>
				)}
			</div>

			<div className="flex items-center justify-between w-full">
				<Tooltip
					content="This is the Max Initial Margin Allowed based on weighted assets/liabilities"
					placement="top"
				>
					<Text.BODY2 className="text-text-secondary">
						Max Leverage <Info size={16} className="relative top-[5px]" />
					</Text.BODY2>
				</Tooltip>
				{disabled ? (
					<Text.BODY2 className="text-text-secondary">
						{currentMaxLev ? `${currentMaxLev}x` : 'No Limit'}
					</Text.BODY2>
				) : (
					<Select.Default
						id="max_lev_selector"
						className={twMerge('w-[100px] justify-center mx-auto')}
						currentSelection={currentMaxLev}
						options={MAX_LEVERAGE_OPTIONS}
						onChange={onChangeRatio}
					/>
				)}
			</div>

			<div
				className={twMerge(
					'flex flex-col gap-3 transition-all',
					!viewMore && 'hidden'
				)}
			>
				<div className="flex items-center justify-between w-full">
					<Text.BODY2 className="text-text-secondary">
						Enable Spot Margin
					</Text.BODY2>
					<Switch
						checked={marginEnabledSelection}
						disabled={disabled}
						onChange={(value: boolean) => {
							setMarginEnabledSelection(value);
							onChangeEnabled(value);
						}}
					/>
				</div>

				<div className="flex items-center justify-between w-full">
					<Tooltip
						content="If disabled, BAL positions will NOT auto de-risk (on next P&L settle) if initial margin requirement is not met. Recommended for advanced users only."
						placement={'top'}
					>
						<Text.BODY2 className="text-text-secondary">
							Auto-Derisk BAL <Info size={16} className="relative top-[5px]" />
						</Text.BODY2>
					</Tooltip>
					<Switch
						className="justify-center"
						checked={dlpAutoDeriskSelection}
						disabled={disabled}
						onChange={(value: boolean) => {
							setDlpAutoDeriskSelection(value);
							onChangeDlpAutoDerisk(value);
						}}
					/>
				</div>

				<div className="flex items-center justify-between w-full">
					<Tooltip
						content="Safety measure; if enabled, account will only be allowed to reduce positions."
						placement="top"
					>
						<Text.BODY2 className="text-text-secondary">
							Reduce Only <Info size={16} className="relative top-[5px]" />
						</Text.BODY2>
					</Tooltip>
					<Switch
						className="justify-center"
						checked={reduceOnlySelection}
						disabled={disabled}
						onChange={(value: boolean) => {
							setReduceOnlySelection(value);
							onChangeReduceOnly(value);
						}}
					/>
				</div>

				<div className="flex items-center justify-between w-full">
					<Tooltip
						content={
							<div className="flex flex-col space-y-1">
								<Text.BODY3>
									Automatically update quotes vs. latency arbitrage.
								</Text.BODY3>
								<Text.BODY3
									className={
										canJoinProtectedMaker
											? 'text-positive-green'
											: 'text-text-default'
									}
								>{`${protectedMakerSlotsOpen}/${protectedMakerMaxSlots} slots available`}</Text.BODY3>
							</div>
						}
						placement="top"
					>
						<Text.BODY2 className="text-text-secondary">
							Protected Maker Mode{' '}
							<Info size={16} className="relative top-[5px]" />
						</Text.BODY2>
					</Tooltip>
					<Switch
						className="justify-center"
						checked={pmmSelection}
						disabled={disabled}
						onChange={(value: boolean) => {
							setPmmSelection(value);
							onChangePmm(value);
						}}
					/>
				</div>
			</div>

			<div className="flex justify-end w-full">
				<button
					className="text-text-label"
					onClick={() => setViewMore((val) => !val)}
				>
					<Text.BODY3>{viewMore ? 'View Less' : 'View More'}</Text.BODY3>{' '}
					<Chevron
						direction={viewMore ? 'up' : 'down'}
						size={18}
						className="relative top-[5px] ml-0.5"
					/>
				</button>
			</div>
		</div>
	);
};

export default MarginUpdateRow;
