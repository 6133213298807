'use client';

import {
	BASE_PRECISION_EXP,
	BigNum,
	BN,
	MarketType,
	PerpMarketAccount,
	PositionDirection,
	PRICE_PRECISION,
	PRICE_PRECISION_EXP,
	QUOTE_PRECISION_EXP,
	SpotMarketAccount,
	SpotMarketConfig,
} from '@drift-labs/sdk';
import { useCallback, useEffect, useState, useMemo } from 'react';
import useAccountData from 'src/hooks/useAccountData';
import useDriftActions from 'src/hooks/useDriftActions';
import useLocalStorageState from 'src/hooks/useLocalStorageState';
import NumLib from 'src/utils/NumLib';
import UI_UTILS from 'src/utils/uiUtils';
import { ZERO } from '../constants/constants';
import { OrderedSpotMarkets } from '../environmentVariables/EnvironmentVariables';
import useDriftClientIsReady from '../hooks/useDriftClientIsReady';
import { useTradeformPriceImpact } from '../hooks/usePriceImpact';
import useDriftStore from '../stores/DriftStore/useDriftStore';
import LoggingService from '../utils/LoggingService';
import Button from './Button';
import Utility from './Inputs/Utility';
import Modal from './Modals/Modal';
import Tooltip from './Tooltip/Tooltip';
import InfoMessage from './TradeForm/InfoMessage';
import ValueChangeBlink from './Utils/ValueChangeBlink';
import useAccountTargetSpotBalance from 'src/hooks/useAccountTargetSpotBalance';
import React from 'react';
import useInfoForCurrentlySelectedMarket from 'src/hooks/useInfoForCurrentlySelectedMarket';
import useIsMobileScreenSize from 'src/hooks/useIsMobileScreenSize';
import usePostHogCapture from 'src/hooks/posthog/usePostHogCapture';
import useMemoizedOraclePrice from 'src/hooks/useMemoizedOraclePrice';
import { COMMON_UI_UTILS, UI_ORDER_TYPES, UIMarket } from '@drift/common';
import { EstEntryPriceTooltip } from './TradeForm/PriceTooltips';
import useCalculateTradeFee from 'src/hooks/useCalculateTradeFee';
import useDriftClient from 'src/hooks/useDriftClient';
import MakerRebateTooltip from './Tooltip/MakerRebateTooltip';
import SettingsSwitch from './Settings/SettingsSwitch';
import PriceDivergenceWarningMessage from './PriceDivergenceWarningMessage';
import { calculateTradeFormNotionalSize } from 'src/utils/trade';
import usePrePreppedTxStore from 'src/stores/usePrePreppedTxStore';
import useMarkPrice from 'src/hooks/useMarkPrice';
import useTradeFormPreviewOrder from './TradeForm/useTradeFormPreviewOrder';
import {
	useCurrentMarketStepSizeBn,
	useMarketStepSize,
} from 'src/hooks/useMarketStepSize';
import ScaledOrdersSplitTable from './TradeForm/ScaledOrdersSplitTable';
import { getSplitOrders } from 'src/utils/scaledOrders';
import Chevron from './Icons/Chevron';
import { AlertTriangle, Gas, Success } from '@drift-labs/icons';
import { twMerge } from 'tailwind-merge';
import {
	BRACKET_ORDER_SL_PAST_LIQ_WARNING,
	NEW_ORDER_SL_PAST_LIQ_WARNING,
} from 'src/constants/warnings';

export const TradeModal = () => {
	const selectedMarket = useDriftStore((s) => s.selectedMarket.current);

	return selectedMarket.isPerp ? <PerpTradeModal /> : <SpotTradeModal />;
};

export const PerpTradeModal = () => {
	const actions = useDriftActions();
	const onClose = () => {
		actions.showModal('showTradeConfirmationModal', false);
	};

	const tradeFormMessage = useDriftStore((s) =>
		s.tradeForm.skipMessageInConfirmationModal ? '' : s.tradeForm.message
	);
	const tradeFormMessageType = useDriftStore((s) => s.tradeForm.messageType);

	const [tradeConfirmViewType, setTradeConfirmView] = useLocalStorageState(
		'tradeConfirmViewTypePreference',
		'simple'
	);

	const { captureEvent } = usePostHogCapture();

	const isMobile = useIsMobileScreenSize();

	const user = useAccountData().client;

	const set = useDriftStore((s) => s.set);

	const closingPosition = useDriftStore((s) => s.tradeForm.closingPosition);

	const loadingElements = useDriftStore((s) => s.loadingElements);

	const isLoading = loadingElements.modal?.isLoading;

	const orderType = useDriftStore((s) => s.tradeForm.orderType);
	const orderLabel = UI_ORDER_TYPES[orderType].label;
	const tradeSide = useDriftStore((s) => s.tradeForm.side);
	const orderCount = useDriftStore((s) => s.tradeForm.scaledOrders.orderCount);
	const sizeDistribution = useDriftStore(
		(s) => s.tradeForm.scaledOrders.sizeDistribution
	);
	const selectedMarketInfo = useInfoForCurrentlySelectedMarket();
	const selectedMarket = new UIMarket(
		selectedMarketInfo.info.config.marketIndex,
		selectedMarketInfo.type
	);
	const selectedMarketId = selectedMarket.marketId;
	const postOnly = useDriftStore((s) => s.tradeForm.postOnly);
	const isPostOnly =
		postOnly &&
		(orderType === 'limit' ||
			orderType === 'oracleLimit' ||
			orderType === 'scaledOrders');

	const orderDescription = orderType === 'market' ? 'Trade' : 'Order';
	const oraclePrice = useMemoizedOraclePrice(selectedMarketId);
	const markPrice = useMarkPrice(selectedMarketId);

	const indexPrice = selectedMarket && oraclePrice ? oraclePrice.toNum() : null;
	const reduceOnly = useDriftStore((s) => s.tradeForm.reduceOnly);
	const tradeFormStoreState = useDriftStore((s) => s.tradeForm);
	const stepSize = useMarketStepSize(selectedMarketId);

	const {
		baseSizeStringValue,
		quoteSizeStringValue,
		priceBoxStringValue,
		secondaryPriceBoxStringValue,
		isSwiftSelected,
	} = useDriftStore((s) => s.tradeForm);

	const baseSize = BigNum.fromPrint(baseSizeStringValue, BASE_PRECISION_EXP);
	const quoteSize = Number(quoteSizeStringValue);
	const priceBoxValue = Number(priceBoxStringValue);
	const secondaryPriceBoxValue = Number(secondaryPriceBoxStringValue);

	const { slippageTolerance, allowInfSlippage } = useDriftStore(
		(s) => s.tradeForm
	);
	const isDynamicSlippage = slippageTolerance === 'dynamic';

	const [baseCurrency] = useState('USDC');
	const marketName = selectedMarket.marketName;
	const tradeCurrency = selectedMarket.baseAssetSymbol();
	const marketIndex = selectedMarket.market.marketIndex;

	const {
		entryPrice: estimatedEntryPrice,
		priceImpact,
		showPriceEstimateOracleDivergenceWarning:
			priceImpactOracleDivergenceWarning,
	} = useTradeformPriceImpact();

	const quoteSizeForFeeCalc =
		orderType === 'oracleLimit'
			? (oraclePrice?.toNum() + priceBoxValue) * baseSize?.toNum()
			: quoteSize;

	const {
		tradeFee: transactionFee,
		makerFeePct,
		// not used since we update bps to always be 2?
		makerFeeBps: _makerFeeBps,
		takerFeePct,
	} = useCalculateTradeFee({
		quoteSize: quoteSizeForFeeCalc,
		marketType: MarketType.PERP,
		marketIndex,
		isPostOnly,
	});

	const previewOrderParams = useTradeFormPreviewOrder();

	const entryPriceToShow = previewOrderParams
		? tradeSide === 'buy'
			? BN.min(estimatedEntryPrice, previewOrderParams.auctionEndPrice)
			: BN.max(estimatedEntryPrice, previewOrderParams.auctionEndPrice)
		: estimatedEntryPrice;

	const showOracleDivergenceWarning = previewOrderParams
		? previewOrderParams.isDivergentFromOracle
		: priceImpactOracleDivergenceWarning;

	const [tpProfit, setTpProfit] = useState(BigNum.zero());
	const [slProfit, setSlProfit] = useState(BigNum.zero());

	const [shouldShowSlWarning, setShouldShowSlWarning] = useState(false);
	const [shouldShowSlBracketWarning, setShouldShowSlBracketWarning] =
		useState(false);

	const [splitOrders, setSplitOrders] = useState<
		{
			size: BigNum;
			price: BigNum;
			percentage: BigNum;
			symbol: string;
		}[]
	>();

	const [hasAgreedToPriceDivergence, setHasAgreedToPriceDivergence] =
		useState(false);

	const currentStepSize = useCurrentMarketStepSizeBn();
	const currentStepSizeBigNum = useMemo(
		() => BigNum.from(currentStepSize, BASE_PRECISION_EXP),
		[currentStepSize]
	);

	const getNotionalSize = () =>
		orderType === 'oracleLimit'
			? BigNum.fromPrint(quoteSizeForFeeCalc.toString(), QUOTE_PRECISION_EXP)
			: calculateTradeFormNotionalSize(
					orderType,
					baseSize,
					priceBoxValue.toString(),
					secondaryPriceBoxValue.toString(),
					BigNum.from(estimatedEntryPrice ?? ZERO, PRICE_PRECISION_EXP),
					orderCount,
					sizeDistribution,
					currentStepSizeBigNum
			  );

	const [notionalSize, setNotionalSize] = useState(getNotionalSize());
	const [showScaledOrders, setShowScaledOrders] = useState(true);

	useEffect(() => {
		if (orderType !== 'market' && orderType !== 'limit') return;

		setNotionalSize(getNotionalSize());
	}, [
		estimatedEntryPrice?.toString(),
		baseSizeStringValue,
		orderType,
		priceBoxStringValue,
	]);

	useEffect(() => {
		// if stopMarket or stopLimit, check that the price is before their current est. liq price
		if (orderType === 'stopMarket' || orderType === 'stopLimit') {
			const currentLiqPrice = user.liquidationPrice(marketIndex);
			if (currentLiqPrice && currentLiqPrice.gt(ZERO)) {
				const triggerPriceBn = BigNum.fromPrint(
					priceBoxValue.toString(),
					PRICE_PRECISION_EXP
				).val;
				setShouldShowSlWarning(
					tradeSide === 'buy'
						? currentLiqPrice.lte(triggerPriceBn)
						: currentLiqPrice.gte(triggerPriceBn)
				);
			} else {
				setShouldShowSlWarning(false);
			}
		} else if (shouldShowSlWarning) {
			setShouldShowSlWarning(false);
		}
	}, [orderType, baseSizeStringValue, oraclePrice.val, marketIndex, tradeSide]);

	const toggleTradeConfirmViewType = () => {
		setTradeConfirmView(
			tradeConfirmViewType === 'simple' ? 'advanced' : 'simple'
		);
	};

	const prePreparedTxs = usePrePreppedTxStore((s) => s.preppedPerpTradeformTxs);

	const handleConfirmation = async () => {
		set((s) => {
			s.modals.showTradeFormModal = false;
		});

		if (closingPosition) {
			captureEvent('closing_position');

			LoggingService.debug(`Trying to close position`);

			actions.closePosition(marketIndex);
		} else {
			captureEvent('opening_new_position', {
				market_type: 'perps',
				trade_base: +tradeFormStoreState.baseSizeStringValue,
				trade_notional: +tradeFormStoreState.quoteSizeStringValue,
				market_symbol: selectedMarketInfo.info.config.symbol,
			});

			actions.openPerpTradeFormOrder(
				{
					...tradeFormStoreState,
					targetMarketIndex: marketIndex,
					oraclePrice: oraclePrice.val,
					markPrice: markPrice?.val,
					perpMarketAccount: selectedMarketInfo.info
						.account as PerpMarketAccount,
				},
				'perp-trade-form',
				prePreparedTxs
			);
		}

		onClose();
	};

	const getLiqPrice = useCallback(
		(estEntryPrice: BN, isLong = true) => {
			let baseAssetBN = baseSize.val;

			if (!isLong) {
				baseAssetBN = baseAssetBN.mul(new BN(-1));
			}

			const liqPriceBn = user.liquidationPrice(
				marketIndex,
				baseAssetBN,
				estEntryPrice
			);

			if (liqPriceBn.eq(new BN(-1))) return 'None';

			const liqPriceNum = NumLib.formatBn.toRawNum(liqPriceBn, PRICE_PRECISION);

			const liqPriceUi = NumLib.formatNum.toDisplayPrice(liqPriceNum);

			return `$${liqPriceUi}`;
		},
		[baseSize.toString()]
	);

	const estimateSymbol =
		orderType === 'market' ||
		orderType === 'stopMarket' ||
		orderType === 'takeProfitMarket'
			? '~'
			: '';

	const auctionStartPriceString = previewOrderParams
		? `$${NumLib.formatNum.toDisplayPrice(
				BigNum.from(
					previewOrderParams.auctionStartPrice,
					PRICE_PRECISION_EXP
				).toNum()
		  )}`
		: 'None';

	const auctionEndPriceString = previewOrderParams
		? `$${NumLib.formatNum.toDisplayPrice(
				BigNum.from(
					previewOrderParams.auctionEndPrice,
					PRICE_PRECISION_EXP
				).toNum()
		  )}`
		: 'None';

	const baseSizeDisplayString = UI_UTILS.roundToStepSizeIfLargeEnough(
		baseSizeStringValue,
		stepSize
	);

	useEffect(() => {
		// if a market order with brackets, update the estimated profit amounts
		if (
			tradeFormStoreState.bracketOrders &&
			(orderType === 'market' || (tpProfit.eqZero() && slProfit.eqZero()))
		) {
			const orderDirection =
				tradeSide === 'buy' ? PositionDirection.LONG : PositionDirection.SHORT;
			const priceToUse =
				tradeFormStoreState.orderType === 'market'
					? BigNum.from(
							tradeFormStoreState.priceImpact.entryPrice,
							PRICE_PRECISION_EXP
					  )
					: BigNum.fromPrint(
							tradeFormStoreState.priceBoxStringValue,
							PRICE_PRECISION_EXP
					  );

			if (tradeFormStoreState.bracketOrders.takeProfit) {
				const { estimatedProfit: newTpProfit } =
					COMMON_UI_UTILS.calculatePotentialProfit({
						currentPositionSize: baseSize,
						currentPositionDirection: orderDirection,
						tradeDirection:
							tradeFormStoreState.bracketOrders.takeProfit.direction,
						exitBaseSize: baseSize,
						exitPrice: tradeFormStoreState.bracketOrders.takeProfit.price,
						currentPositionEntryPrice: priceToUse,
						slippageTolerance: previewOrderParams?.slippage,
						isMarketOrder: true,
						takerFeeBps: 0,
					});

				setTpProfit(newTpProfit);
			}

			if (tradeFormStoreState.bracketOrders.stopLoss) {
				const { estimatedProfit: newSlProfit } =
					COMMON_UI_UTILS.calculatePotentialProfit({
						currentPositionSize: baseSize,
						currentPositionDirection: orderDirection,
						tradeDirection:
							tradeFormStoreState.bracketOrders.stopLoss.direction,
						exitBaseSize: baseSize,
						exitPrice: tradeFormStoreState.bracketOrders.stopLoss.price,
						currentPositionEntryPrice: priceToUse,
						slippageTolerance: previewOrderParams?.slippage,
						isMarketOrder: true,
						takerFeeBps: 0,
					});

				const liqPriceBn = user.liquidationPrice(
					marketIndex,
					tradeSide === 'buy' ? baseSize.val : baseSize.neg().val,
					estimatedEntryPrice
				);

				if (liqPriceBn?.gt(ZERO)) {
					setShouldShowSlBracketWarning(
						tradeSide === 'buy'
							? tradeFormStoreState.bracketOrders.stopLoss.price.val.lte(
									liqPriceBn
							  )
							: tradeFormStoreState.bracketOrders.stopLoss.price.val.gte(
									liqPriceBn
							  )
					);
				} else {
					setShouldShowSlBracketWarning(false);
				}

				setSlProfit(newSlProfit);
			}
		}
	}, [estimatedEntryPrice?.toString(), orderType, previewOrderParams]);

	useEffect(() => {
		setSplitOrders(
			getSplitOrders({
				totalSize: baseSize,
				currentStepSize: currentStepSizeBigNum,
				orderCount,
				orderType,
				priceBoxValue,
				secondaryPriceBoxValue,
				selectedMarketInfo,
				sizeDistribution,
				tradeCurrency,
			})
		);
	}, [
		baseSizeStringValue,
		currentStepSizeBigNum,
		orderCount,
		orderType,
		priceBoxValue,
		secondaryPriceBoxValue,
		selectedMarketInfo,
		sizeDistribution,
		tradeCurrency,
	]);

	return (
		<Modal onClose={onClose}>
			<Modal.Body>
				<Modal.Header onClose={onClose} className="px-3">
					<span>
						<span>{tradeSide === 'buy' ? 'Long' : 'Short'}</span>
						&nbsp;
						{baseSizeDisplayString}
						<span> </span>
						<span>{marketName}</span>
					</span>
				</Modal.Header>

				<Modal.Content>
					{tradeFormStoreState.bracketOrders && (
						<div className="flex justify-between w-full mb-1">
							<div className="font-bold text-text-emphasis">Position Order</div>
						</div>
					)}
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">Order Type</div>

						<div className="flex items-center">
							<div className={`capitalize text-text-emphasis`}>
								{orderLabel}
							</div>
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">Order Size</div>

						<div className="flex items-center">
							<div className="text-text-emphasis">
								{baseSizeDisplayString} {tradeCurrency}
							</div>
						</div>
					</div>

					{orderType === 'market' && (
						<>
							<EstEntryPriceTooltip
								estimatedEntryPrice={entryPriceToShow}
								placement={isMobile ? 'bottom' : 'right'}
								showPriceEstimateOracleDivergenceWarning={
									showOracleDivergenceWarning && orderType === 'market'
								}
							/>
							{tradeConfirmViewType == 'advanced' &&
								!tradeFormStoreState.bracketOrders && (
									<>
										<div className="flex justify-between w-full mb-1">
											<span style={{ display: 'inline' }}>
												<div className="text-text-label">Oracle Price</div>
											</span>

											<div className="flex items-center">
												<ValueChangeBlink value={indexPrice}>
													<div className="text-text-emphasis">
														{'$'}
														{indexPrice
															? NumLib.formatNum.toDisplayPrice(indexPrice)
															: 'N/A'}
													</div>
												</ValueChangeBlink>
											</div>
										</div>
									</>
								)}

							<div className="flex justify-between w-full mb-1">
								<div className={`${'text-text-label'}`}>Est. Price Impact</div>
								<div className={`${'text-text-emphasis'}`}>
									{priceImpact && priceImpact.gt(ZERO)
										? (
												BigNum.from(priceImpact, PRICE_PRECISION_EXP).toNum() *
												100
										  ).toFixed(4)
										: 0}{' '}
									{'%'}
								</div>
							</div>
							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">Slippage Tolerance</div>

								<div className="text-text-emphasis">
									<span>
										{allowInfSlippage
											? `Infinite`
											: isDynamicSlippage
											? `${parseFloat(
													previewOrderParams?.slippage?.toFixed(2)
											  )}%`
											: `${slippageTolerance} %`}
									</span>
								</div>
							</div>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
						</>
					)}

					{orderType === 'limit' && (
						<>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Limit Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(priceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
						</>
					)}

					{orderType === 'oracleLimit' && (
						<>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Limit Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{UI_UTILS.getOracleOffsetLabel(priceBoxValue)}
									</div>
								</div>
							</div>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
						</>
					)}

					{/* Auction Params */}
					{orderType === 'market' &&
						!tradeFormStoreState.bracketOrders &&
						tradeConfirmViewType == 'advanced' && (
							<>
								<Utility.VERTSPACERS />
								<div className="flex justify-between w-full mb-1">
									<span style={{ display: 'inline' }}>
										<div className="text-text-label">Auction Start Price</div>
									</span>

									<div className="flex items-center">
										<ValueChangeBlink value={auctionStartPriceString}>
											<div className="text-text-emphasis">
												{auctionStartPriceString}
											</div>
										</ValueChangeBlink>
									</div>
								</div>

								<div className="flex justify-between w-full mb-1">
									<span style={{ display: 'inline' }}>
										<div className="text-text-label">Auction End Price</div>
									</span>

									<div className="flex items-center">
										<ValueChangeBlink value={auctionEndPriceString}>
											<div className="text-text-emphasis">
												{auctionEndPriceString}
											</div>
										</ValueChangeBlink>
									</div>
								</div>

								<div className="flex justify-between w-full mb-1">
									<span style={{ display: 'inline' }}>
										<div className="text-text-label">Auction Duration</div>
									</span>

									<div className="flex items-center">
										<ValueChangeBlink
											value={previewOrderParams?.auctionDuration}
										>
											<div className="text-text-emphasis">
												{previewOrderParams?.auctionDuration ?? '0'}
											</div>
										</ValueChangeBlink>
									</div>
								</div>
								<Utility.VERTSPACERS />
								<Utility.VERTDIVIDER />
							</>
						)}

					{(orderType === 'stopMarket' || orderType === 'takeProfitMarket') && (
						<>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Trigger Oracle Price</div>
								</span>

								<div className="flex items-center">
									{shouldShowSlWarning && (
										<Tooltip
											className="mr-1"
											content={
												<div className="text-warn-yellow">
													{BRACKET_ORDER_SL_PAST_LIQ_WARNING}
												</div>
											}
										>
											<AlertTriangle size={14} color="var(--warn-yellow)" />
										</Tooltip>
									)}
									<div
										className={
											shouldShowSlWarning
												? 'text-warn-yellow'
												: 'text-text-emphasis'
										}
									>
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(priceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>

							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Reduce Only</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{reduceOnly ? 'TRUE' : 'FALSE'}
									</div>
								</div>
							</div>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
						</>
					)}

					{(orderType === 'stopLimit' || orderType === 'takeProfitLimit') && (
						<>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Trigger Oracle Price</div>
								</span>

								<div className="flex items-center">
									{shouldShowSlWarning && (
										<Tooltip
											className="mr-1"
											content={
												'Trigger Oracle Price is past the current estimated liquidation price. Please consider moving it to execute before liquidation begins. Open orders are force cancelled for accounts in liquidation.'
											}
										>
											<AlertTriangle size={14} color="var(--warn-yellow)" />
										</Tooltip>
									)}
									<div
										className={
											shouldShowSlWarning
												? 'text-warn-yellow'
												: 'text-text-emphasis'
										}
									>
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(priceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Limit Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(secondaryPriceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
						</>
					)}

					{orderType === 'scaledOrders' && (
						<>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<button
										className="flex justify-center text-text-label"
										onClick={() =>
											setShowScaledOrders(
												(showScaledOrders) => !showScaledOrders
											)
										}
									>
										Order count ({orderCount})&nbsp;
										<Chevron direction={showScaledOrders ? 'up' : 'down'} />
									</button>
								</span>
							</div>
							{showScaledOrders && (
								<ScaledOrdersSplitTable orders={splitOrders} />
							)}
						</>
					)}

					<Utility.VERTSPACERS />
					<div className="flex justify-between mb-1 w-half ">
						<div className="flex text-text-label">
							<span>
								{orderDescription} Notional Size
								<sup className="text-yellow-50">
									{orderType === 'oracleLimit' ? '*' : ''}
								</sup>
							</span>
							{orderType === 'oracleLimit' && (
								<Tooltip
									allowHover
									content={<>At current oracle price; may vary</>}
									className="ml-1"
								/>
							)}
						</div>
						{orderType === 'oracleLimit' ? (
							<div className="text-text-emphasis">
								{NumLib.formatNum.toNotionalDisplay(
									(oraclePrice.toNum() + priceBoxValue) * baseSize.toNum()
								)}
							</div>
						) : (
							<div className="text-text-emphasis">
								{estimateSymbol} {notionalSize.toNotional()} {baseCurrency}
							</div>
						)}
					</div>
					{isSwiftSelected && (
						<div className="flex justify-between w-full mb-1">
							<div className="flex items-center gap-1 text-text-label">
								<Gas /> Gas fees
							</div>
							<div className="flex items-center gap-1 text-positive-green">
								<Success /> Free
							</div>
						</div>
					)}
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">
							{isPostOnly ? (
								<div className="flex">
									Rebate (approx.{' '}
									<span className="ml-1.5 text-positive-green">
										{parseFloat(makerFeePct.toFixed(3))}%
									</span>
									) <MakerRebateTooltip />
								</div>
							) : (
								<div className="flex items-start">
									<span>
										Fee ({parseFloat(takerFeePct.toFixed(3))}%)
										<sup className="text-yellow-50">
											{orderType === 'market' || isPostOnly ? '' : '*'}
										</sup>
									</span>
									<Tooltip
										allowHover
										content={
											<>
												<a
													target="_blank"
													rel="noreferrer"
													href={'https://docs.drift.trade/trading/trading-fees'}
													onClick={() => {
														window.open(
															'https://docs.drift.trade/trading/trading-fees',
															'_blank'
														);
													}}
												>
													Learn more about trading fees
												</a>
											</>
										}
										className="ml-1"
									/>
								</div>
							)}
						</div>
						<div
							className={`${
								isPostOnly ? 'text-positive-green' : 'text-text-emphasis'
							}`}
						>
							{estimateSymbol}
							{priceImpact &&
								(transactionFee
									.abs()
									.gt(BigNum.from(1, QUOTE_PRECISION_EXP).scale(1, 100))
									? transactionFee.toNotional()
									: transactionFee.gt(ZERO)
									? '< $0.01'
									: '~ $0.00')}{' '}
							{baseCurrency}
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">
							Total Cost
							<sup className="text-yellow-50">*</sup>
						</div>
						<div className="text-text-emphasis">
							{estimateSymbol}{' '}
							{estimatedEntryPrice
								? notionalSize
										.add(isPostOnly ? transactionFee.neg() : transactionFee)
										.toNotional()
								: 0}{' '}
							{baseCurrency}
						</div>
					</div>

					{orderType === 'market' && (
						<>
							<div className="flex justify-between w-full">
								<div className="text-text-label">Est. Liquidation Price</div>
								<div className="text-text-emphasis">
									{estimatedEntryPrice
										? getLiqPrice(estimatedEntryPrice, tradeSide === 'buy')
										: ``}
								</div>
							</div>
						</>
					)}

					{orderType === 'limit' && (
						<>
							<div className="flex justify-between w-full">
								<div className="flex text-text-label">
									Est. Liquidation Price{' '}
									<Tooltip
										allowHover
										content={
											<>
												Based on current collateral; this may change by the time
												a fill occurs.
											</>
										}
										className="ml-1"
									/>
								</div>
								<div className="text-text-emphasis">
									{estimatedEntryPrice
										? // pass entry price if crossing, limit price if not crossing
										  getLiqPrice(
												previewOrderParams?.auctionDuration
													? estimatedEntryPrice
													: BigNum.fromPrint(
															priceBoxStringValue,
															PRICE_PRECISION_EXP
													  )?.val,
												tradeSide === 'buy'
										  )
										: ``}
								</div>
							</div>
						</>
					)}

					{tradeFormStoreState.bracketOrders?.takeProfit && (
						<>
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full mb-1">
								<div className="font-bold text-text-emphasis">
									Take Profit Market
								</div>
							</div>
							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">Order Size</div>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{baseSize.toTradePrecision()} {tradeCurrency}
									</div>
								</div>
							</div>
							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">Trigger Oracle Price</div>

								<div className="flex items-center">
									<div className={`capitalize text-text-emphasis`}>
										{tradeFormStoreState.bracketOrders.takeProfit.price.toNotional(
											true
										)}
									</div>
								</div>
							</div>
							{orderType === 'limit' && (
								<div className="flex justify-between w-full mb-1">
									<div className="text-text-label">Max. Slippage</div>

									<div className="flex items-center">
										<div className={`capitalize text-text-emphasis`}>
											{tradeFormStoreState.slippageTolerance}%
										</div>
									</div>
								</div>
							)}
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">
									Est. Profit
									<sup className="text-yellow-50">*</sup>
								</div>
								<div className="flex items-center">
									<div className={`capitalize text-positive-green`}>
										{tpProfit.toNotional(true)}
									</div>
								</div>
							</div>
						</>
					)}
					{tradeFormStoreState.bracketOrders?.stopLoss && (
						<>
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full mb-1">
								<div className="font-bold text-text-emphasis">Stop Market</div>
							</div>
							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">Order Size</div>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{baseSize.toTradePrecision()} {tradeCurrency}
									</div>
								</div>
							</div>
							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">Trigger Oracle Price</div>

								<div className="flex items-center">
									<div
										className={twMerge(
											`flex capitalize text-text-emphasis`,
											shouldShowSlBracketWarning && 'text-warn-yellow'
										)}
									>
										{shouldShowSlBracketWarning && (
											<Tooltip
												className="flex"
												content={
													<div className="text-warn-yellow">
														{NEW_ORDER_SL_PAST_LIQ_WARNING}
													</div>
												}
											>
												<AlertTriangle
													className="flex mr-1 xs:w-4 xs:h-4 sm:w-3 sm:h-3"
													color={'var(--text-warn-yellow)'}
												/>
											</Tooltip>
										)}
										{tradeFormStoreState.bracketOrders.stopLoss.price.toNotional(
											true
										)}
									</div>
								</div>
							</div>
							{orderType === 'limit' && (
								<div className="flex justify-between w-full mb-1">
									<div className="text-text-label">Max. Slippage</div>

									<div className="flex items-center">
										<div className={`capitalize text-text-emphasis`}>
											{tradeFormStoreState.slippageTolerance}%
										</div>
									</div>
								</div>
							)}
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">
									Est. Loss
									<sup className="text-yellow-50">*</sup>
								</div>
								<div className="flex items-center">
									<div className={`capitalize text-negative-red`}>
										{slProfit.toNotional(true)}
									</div>
								</div>
							</div>
						</>
					)}

					{allowInfSlippage && orderType === 'market' && (
						<>
							<Utility.VERTSPACERS />
							<InfoMessage
								type="warn"
								message="This trade has infinite slippage tolerance enabled."
							/>
						</>
					)}

					{notionalSize.lt(
						BigNum.from(25, notionalSize.precision).scale(1, 100)
					) ? (
						<>
							<Utility.VERTSPACERS />
							<InfoMessage
								type="error"
								message="Trade sizes of less than $.25 are currently disabled."
							/>
						</>
					) : (
						<></>
					)}

					{closingPosition && (
						<>
							<Utility.VERTSPACERS />
							<div>
								This order will close your current{' '}
								{tradeSide === 'buy' ? `SHORT` : `LONG`} position
							</div>
							<Utility.VERTSPACERS />
						</>
					)}
					<div className="flex justify-between w-full my-1">
						<div className="text-text-label" style={{ fontSize: '10px' }}>
							<span className="text-yellow-50">*</span>
							If order is 100% filled
						</div>
					</div>

					{tradeFormMessage && (
						<>
							<Utility.VERTSPACERS />
							<InfoMessage
								type={tradeFormMessageType}
								message={tradeFormMessage}
							/>
						</>
					)}

					<Utility.VERTSPACERM />

					{showOracleDivergenceWarning && (
						<PriceDivergenceWarningMessage
							hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
							setHasAgreedToPriceDivergence={setHasAgreedToPriceDivergence}
						/>
					)}

					<Utility.VERTSPACERM />

					<Button.BigSemantic
						positive={tradeSide === 'buy'}
						disabled={
							isLoading ||
							notionalSize.lt(
								BigNum.from(25, notionalSize.precision).scale(1, 100)
							) ||
							(showOracleDivergenceWarning && !hasAgreedToPriceDivergence)
						}
						onClick={handleConfirmation}
					>
						{isLoading
							? `Loading`
							: `${
									tradeSide === 'buy' ? `Long` : `Short`
							  } ${baseSizeDisplayString} ${marketName}`}
					</Button.BigSemantic>
					{/* modal too long with advanced + bracket orders, todo: find better way to handle this */}
					{((orderType === 'market' && !tradeFormStoreState.bracketOrders) ||
						orderType === 'limit') && (
						<div className="flex items-center justify-between w-full mt-4">
							<SettingsSwitch
								label="Advanced"
								checked={tradeConfirmViewType === 'advanced'}
								onChange={toggleTradeConfirmViewType}
								spacedOut
								includeLineSpace={false}
								className="w-full"
							/>
						</div>
					)}
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};

export const SpotTradeModal = () => {
	const actions = useDriftActions();
	const onClose = () => {
		actions.showModal('showTradeConfirmationModal', false);
	};

	const tradeFormMessage = useDriftStore((s) =>
		s.tradeForm.skipMessageInConfirmationModal ? '' : s.tradeForm.message
	);
	const tradeFormMessageType = useDriftStore((s) => s.tradeForm.messageType);

	const [tradeConfirmViewType, setTradeConfirmView] = useLocalStorageState(
		'tradeConfirmViewTypePreference',
		'simple'
	);

	const toggleTradeConfirmViewType = () => {
		setTradeConfirmView(
			tradeConfirmViewType === 'simple' ? 'advanced' : 'simple'
		);
	};

	const isMobile = useIsMobileScreenSize();

	const { captureEvent } = usePostHogCapture();
	const set = useDriftStore((s) => s.set);
	const orderType = useDriftStore((s) => s.tradeForm.orderType);
	const orderLabel = UI_ORDER_TYPES[orderType].label;
	const tradeSide = useDriftStore((s) => s.tradeForm.side);
	const selectedMarketInfo = useInfoForCurrentlySelectedMarket();
	const selectedMarket = new UIMarket(
		selectedMarketInfo.info.config.marketIndex,
		selectedMarketInfo.type
	);
	const selectedMarketId = selectedMarket.marketId;
	const postOnly = useDriftStore((s) => s.tradeForm.postOnly);
	const isPostOnly =
		postOnly && (orderType === 'limit' || orderType === 'oracleLimit');
	const currentUserKey = useAccountData()?.userKey;
	const driftClient = useDriftClient();
	const driftClientIsReady = useDriftClientIsReady();

	const userCurrentPosition = useAccountTargetSpotBalance(
		selectedMarket.market as SpotMarketConfig,
		currentUserKey,
		true
	);

	const userUsdcPosition = useAccountTargetSpotBalance(
		OrderedSpotMarkets[0],
		currentUserKey,
		true
	);

	const orderDescription = orderType === 'market' ? 'Trade' : 'Order';

	const oraclePrice = useMemoizedOraclePrice(selectedMarketId);
	const stepSize = useMarketStepSize(selectedMarketId);

	const indexPrice = selectedMarket && oraclePrice ? oraclePrice.toNum() : null;
	const reduceOnly = useDriftStore((s) => s.tradeForm.reduceOnly);
	const tradeFormStoreState = useDriftStore((s) => s.tradeForm);
	const selectedSpotMarketAccount = selectedMarketInfo.info
		.account as SpotMarketAccount;

	const {
		baseSizeStringValue,
		quoteSizeStringValue,
		priceBoxStringValue,
		secondaryPriceBoxStringValue,
	} = useDriftStore((s) => s.tradeForm);

	const baseSize = BigNum.fromPrint(baseSizeStringValue, BASE_PRECISION_EXP);
	const priceBoxValue = Number(priceBoxStringValue);
	const secondaryPriceBoxValue = Number(secondaryPriceBoxStringValue);

	const { slippageTolerance, allowInfSlippage } = useDriftStore(
		(s) => s.tradeForm
	);

	const {
		entryPrice: estimatedEntryPrice,
		priceImpact,
		showPriceEstimateOracleDivergenceWarning:
			priceImpactOracleDivergenceWarning,
	} = useTradeformPriceImpact();

	const [borrowAmount, setBorrowAmount] = useState<BigNum>(undefined);
	const [deltaBorrowApr, setDeltaBorrowApr] = useState<BigNum>(undefined);
	const [borrowCurrency, setBorrowCurrency] = useState<string>(undefined);

	const [baseCurrency] = useState('USDC');
	const tradeCurrency = selectedMarket.baseAssetSymbol();
	const marketIndex = selectedMarket.market.marketIndex;

	const [hasAgreedToPriceDivergence, setHasAgreedToPriceDivergence] =
		useState(false);

	const quoteSizeForFeeCalc =
		orderType === 'oracleLimit'
			? (oraclePrice?.toNum() + priceBoxValue) * baseSize?.toNum()
			: +quoteSizeStringValue;

	const {
		tradeFee: transactionFee,
		makerFeePct,
		makerFeeBps: _makerFeeBps,
		takerFeePct,
	} = useCalculateTradeFee({
		quoteSize: quoteSizeForFeeCalc,
		marketType: MarketType.SPOT,
		marketIndex,
		isPostOnly,
	});

	const getNotionalSize = () =>
		orderType === 'oracleLimit'
			? BigNum.fromPrint(quoteSizeForFeeCalc.toString(), QUOTE_PRECISION_EXP)
			: calculateTradeFormNotionalSize(
					orderType,
					baseSize,
					priceBoxValue.toString(),
					secondaryPriceBoxValue.toString(),
					BigNum.from(estimatedEntryPrice ?? ZERO, PRICE_PRECISION_EXP)
			  );

	const [notionalSize, setNotionalSize] = useState(getNotionalSize());

	const previewOrderParams = useTradeFormPreviewOrder();

	const entryPriceToShow = previewOrderParams
		? tradeSide === 'buy'
			? BN.min(estimatedEntryPrice, previewOrderParams.auctionEndPrice)
			: BN.max(estimatedEntryPrice, previewOrderParams.auctionEndPrice)
		: estimatedEntryPrice;

	const showOracleDivergenceWarning = previewOrderParams
		? previewOrderParams.isDivergentFromOracle
		: priceImpactOracleDivergenceWarning;

	useEffect(() => {
		if (orderType !== 'market' && orderType !== 'limit') return;

		setNotionalSize(getNotionalSize());
	}, [estimatedEntryPrice?.toString(), baseSizeStringValue, orderType]);

	useEffect(() => {
		if (!driftClientIsReady) return;

		if (tradeSide === 'buy') {
			const netAfterTrade = userUsdcPosition.netBaseBalance
				.shiftTo(QUOTE_PRECISION_EXP)
				.sub(notionalSize.abs());
			if (netAfterTrade.isNeg()) {
				const borrowApr = UI_UTILS.getBorrowRateFromDelta(
					netAfterTrade.val,
					driftClient.getSpotMarketAccount(0)
				);

				setBorrowCurrency('USDC');
				setBorrowAmount(netAfterTrade.abs());
				setDeltaBorrowApr(borrowApr);
			} else {
				setBorrowCurrency(undefined);
				setBorrowAmount(undefined);
				setDeltaBorrowApr(undefined);
			}
		} else {
			const netAfterTrade = userCurrentPosition.netBaseBalance.sub(
				baseSize.shiftTo(new BN(selectedSpotMarketAccount.decimals))
			);

			if (netAfterTrade.isNeg()) {
				const borrowApr = UI_UTILS.getBorrowRateFromDelta(
					netAfterTrade.val,
					selectedSpotMarketAccount
				);

				setBorrowCurrency(selectedMarket.market.symbol);
				setBorrowAmount(netAfterTrade.abs());
				setDeltaBorrowApr(borrowApr);
			} else {
				setBorrowCurrency(undefined);
				setBorrowAmount(undefined);
				setDeltaBorrowApr(undefined);
			}
		}
	}, [baseSize?.toString(), driftClientIsReady]);

	const user = useAccountData().client;

	const closingPosition = useDriftStore((s) => s.tradeForm.closingPosition);

	const loadingElements = useDriftStore((s) => s.loadingElements);

	const isLoading = loadingElements.modal?.isLoading;

	const handleConfirmation = async () => {
		set((s) => {
			s.modals.showTradeFormModal = false;
		});

		if (closingPosition) {
			captureEvent('closing_position');
			LoggingService.debug(`Trying to close position`);

			actions.closePosition(marketIndex);
		} else {
			captureEvent('opening_new_position', {
				market_type: 'spot',
				trade_base: +tradeFormStoreState.baseSizeStringValue,
				trade_notional: +tradeFormStoreState.quoteSizeStringValue,
				market_symbol: selectedMarketInfo.info.config.symbol,
			});

			const currentPositionDirection = userCurrentPosition
				? userCurrentPosition.netBaseBalance.isNeg()
					? 'short'
					: 'long'
				: undefined;

			actions.openSpotTradeFormOrder({
				...tradeFormStoreState,
				targetMarketIndex: marketIndex,
				currentPositionBaseSize:
					userCurrentPosition?.netBaseBalance?.abs().val ?? ZERO,
				currentPositionDirection,
				oraclePrice: oraclePrice.val,
				spotMarketAccount: selectedMarketInfo.info.account as SpotMarketAccount,
			});
		}

		onClose();
	};

	const estimateSymbol =
		orderType === 'market' ||
		orderType === 'stopMarket' ||
		orderType === 'takeProfitMarket'
			? '~'
			: '';

	const auctionStartPriceString = previewOrderParams
		? `$${NumLib.formatNum.toDisplayPrice(
				BigNum.from(
					previewOrderParams.auctionStartPrice,
					PRICE_PRECISION_EXP
				).toNum()
		  )}`
		: 'None';

	const auctionEndPriceString = previewOrderParams
		? `$${NumLib.formatNum.toDisplayPrice(
				BigNum.from(
					previewOrderParams.auctionEndPrice,
					PRICE_PRECISION_EXP
				).toNum()
		  )}`
		: 'None';

	const baseSizeDisplayString = UI_UTILS.roundToStepSizeIfLargeEnough(
		baseSizeStringValue,
		stepSize
	);

	return (
		<Modal dataTestId="spot-trade-modal" onClose={onClose}>
			<Modal.Body>
				<Modal.Header onClose={onClose}>
					<span>
						<span className="capitalize">{orderLabel}</span>
						<span> </span>
						<span>{tradeSide === 'buy' ? 'Buy' : 'Sell'}</span>
						<span> </span>
						<span>{tradeCurrency}</span>
					</span>
				</Modal.Header>

				<Modal.Content>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">Order Type</div>

						<div className="flex items-center">
							<div className={`capitalize text-text-emphasis`}>
								{orderLabel}
							</div>
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">{orderDescription} Size</div>

						<div className="flex items-center">
							<div className="text-text-emphasis">
								{baseSizeDisplayString} {tradeCurrency}
							</div>
						</div>
					</div>

					{orderType === 'market' && (
						<>
							<br />
							<EstEntryPriceTooltip
								estimatedEntryPrice={entryPriceToShow}
								placement={isMobile ? 'bottom' : 'right'}
								showPriceEstimateOracleDivergenceWarning={
									showOracleDivergenceWarning && orderType === 'market'
								}
							/>
							{tradeConfirmViewType == 'advanced' && (
								<>
									<div className="flex justify-between w-full mb-1">
										<span style={{ display: 'inline' }}>
											<div className="text-text-label">Oracle Price</div>
										</span>

										<div className="flex items-center">
											<ValueChangeBlink value={indexPrice}>
												<div className="text-text-emphasis">
													{'$'}
													{indexPrice
														? NumLib.formatNum.toDisplayPrice(indexPrice)
														: 'N/A'}
												</div>
											</ValueChangeBlink>
										</div>
									</div>
								</>
							)}

							<div className="flex justify-between w-full mb-1">
								<div className={`${'text-text-label'}`}>Est. Price Impact</div>
								<div className={`${'text-text-emphasis'}`}>
									{priceImpact && priceImpact.gt(ZERO)
										? (
												BigNum.from(priceImpact, PRICE_PRECISION_EXP).toNum() *
												100
										  ).toFixed(4)
										: 0}{' '}
									{'%'}
								</div>
							</div>

							<div className="flex justify-between w-full mb-1">
								<div className="text-text-label">Slippage Tolerance</div>

								<div className="text-text-emphasis">
									<span>
										{/* <Tooltip
								className=""
								content={<div>${limitPrice.toFixed(3)}</div>}
							> */}
										{allowInfSlippage ? `Infinite` : `${slippageTolerance} %`}
										{/* </Tooltip> */}

										{/* <Button>
						<PencilAltIcon className="w-3 h-3 mr-0" />
						</Button> */}
									</span>
								</div>
							</div>
						</>
					)}

					{orderType === 'limit' && (
						<>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Limit Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(priceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>
						</>
					)}

					{/* Auction Params */}
					{orderType === 'market' && tradeConfirmViewType == 'advanced' && (
						<>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full mb-1">
								<span style={{ display: 'inline' }}>
									<div className="text-text-label">Auction Start Price</div>
								</span>

								<div className="flex items-center">
									<ValueChangeBlink value={auctionStartPriceString}>
										<div className="text-text-emphasis">
											{auctionStartPriceString}
										</div>
									</ValueChangeBlink>
								</div>
							</div>

							<div className="flex justify-between w-full mb-1">
								<span style={{ display: 'inline' }}>
									<div className="text-text-label">Auction End Price</div>
								</span>

								<div className="flex items-center">
									<ValueChangeBlink value={auctionEndPriceString}>
										<div className="text-text-emphasis">
											{auctionEndPriceString}
										</div>
									</ValueChangeBlink>
								</div>
							</div>

							<div className="flex justify-between w-full mb-1">
								<span style={{ display: 'inline' }}>
									<div className="text-text-label">Auction Duration</div>
								</span>

								<div className="flex items-center">
									<ValueChangeBlink value={previewOrderParams?.auctionDuration}>
										<div className="text-text-emphasis">
											{previewOrderParams?.auctionDuration ?? '0'}
										</div>
									</ValueChangeBlink>
								</div>
							</div>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
						</>
					)}

					{(orderType === 'stopMarket' || orderType === 'takeProfitMarket') && (
						<>
							<br />
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Trigger Oracle Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(priceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>

							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Reduce Only</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{reduceOnly ? 'TRUE' : 'FALSE'}
									</div>
								</div>
							</div>
						</>
					)}

					{(orderType === 'stopLimit' || orderType === 'takeProfitLimit') && (
						<>
							<br />
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Trigger Oracle Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(priceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Limit Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{'$'}
										{estimatedEntryPrice
											? NumLib.formatNum.toDisplayPrice(secondaryPriceBoxValue)
											: 0.0}{' '}
									</div>
								</div>
							</div>
						</>
					)}

					{orderType === 'oracleLimit' && (
						<>
							<div className="flex justify-between w-full mb-1">
								<span className="text-text-label" style={{ display: 'inline' }}>
									<div className="text-text-label">Limit Price</div>
								</span>

								<div className="flex items-center">
									<div className="text-text-emphasis">
										{UI_UTILS.getOracleOffsetLabel(priceBoxValue)}
									</div>
								</div>
							</div>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
						</>
					)}

					<Utility.VERTSPACERS />
					<div className="flex justify-between mb-1 w-half ">
						<div className="flex text-text-label">
							<span>
								{orderDescription} Notional Size
								<sup className="text-yellow-50">
									{orderType === 'oracleLimit' ? '*' : ''}
								</sup>
							</span>
							{orderType === 'oracleLimit' && (
								<Tooltip
									allowHover
									content={<>At current oracle price; may vary</>}
									className="ml-1"
								/>
							)}
						</div>
						{orderType === 'oracleLimit' ? (
							<div className="text-text-emphasis">
								{NumLib.formatNum.toNotionalDisplay(
									(oraclePrice.toNum() + priceBoxValue) * baseSize.toNum()
								)}
							</div>
						) : (
							<div className="text-text-emphasis">
								{estimateSymbol} {notionalSize.toNotional()} {baseCurrency}
							</div>
						)}
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">
							{isPostOnly ? (
								<div className="flex">
									Rebate (approx.{' '}
									<span className="ml-1.5 text-positive-green">
										{parseFloat(makerFeePct.toFixed(3))}%
									</span>
									) <MakerRebateTooltip />
								</div>
							) : (
								<div className="flex">
									<span>
										Fee ({parseFloat(takerFeePct.toFixed(3))}%)
										<sup className="text-yellow-50">
											{orderType === 'market' || isPostOnly ? '' : '*'}
										</sup>
									</span>
									<Tooltip
										allowHover
										content={
											<>
												<a
													target="_blank"
													rel="noreferrer"
													href={'https://docs.drift.trade/trading/trading-fees'}
													onClick={() => {
														window.open(
															'https://docs.drift.trade/trading/trading-fees',
															'_blank'
														);
													}}
												>
													Learn more about trading fees
												</a>
											</>
										}
										className="ml-1"
									/>
								</div>
							)}
						</div>
						<div
							className={`${
								isPostOnly ? 'text-positive-green' : 'text-text-emphasis'
							}`}
						>
							{estimateSymbol}
							{priceImpact &&
								(transactionFee
									.abs()
									.gt(BigNum.from(1, QUOTE_PRECISION_EXP).scale(1, 100))
									? transactionFee.toNotional()
									: transactionFee.gt(ZERO)
									? '< $0.01'
									: '~ $0.00')}{' '}
							{baseCurrency}
						</div>
					</div>
					<div className="flex justify-between w-full mb-1">
						<div className="text-text-label">
							Total Cost
							<sup className="text-yellow-50">*</sup>
						</div>
						<div className="text-text-emphasis">
							{estimateSymbol}{' '}
							{estimatedEntryPrice
								? notionalSize
										.add(isPostOnly ? transactionFee.neg() : transactionFee)
										.toNotional()
								: 0}{' '}
							{baseCurrency}
						</div>
					</div>

					{orderType === 'market' && (
						<>
							<div className="flex justify-between w-full">
								<div className="text-text-label">Est. Liquidation Price</div>
								<div className="text-text-emphasis">
									{estimatedEntryPrice
										? UI_UTILS.getSpotLiqPriceStr(
												user,
												marketIndex,
												baseSize,
												tradeSide === 'buy'
										  )
										: ``}
								</div>
							</div>
						</>
					)}

					{borrowAmount && deltaBorrowApr && borrowCurrency && (
						<>
							<Utility.VERTSPACERS />
							<Utility.VERTDIVIDER />
							<Utility.VERTSPACERS />
							<div className="flex justify-between w-full">
								<div className="text-text-label">New Borrow Amount</div>
								<div className="text-text-emphasis">
									{`${borrowAmount.printShort()} ${borrowCurrency} (${deltaBorrowApr.printShort()}% APR)`}
								</div>
							</div>
						</>
					)}

					<div className="flex justify-between w-full my-1">
						<div className="text-text-label" style={{ fontSize: '10px' }}>
							<span className="text-yellow-50">*</span>
							If order is 100% filled
						</div>
					</div>

					<Utility.VERTSPACERM />

					{allowInfSlippage && orderType === 'market' && (
						<>
							<Utility.VERTSPACERS />
							<InfoMessage
								type="warn"
								message="This trade has infinite slippage tolerance enabled."
							/>
						</>
					)}

					{tradeFormMessage && (
						<>
							<Utility.VERTSPACERS />
							<InfoMessage
								type={tradeFormMessageType}
								message={tradeFormMessage}
							/>
						</>
					)}

					{notionalSize.lt(
						BigNum.from(25, notionalSize.precision).scale(1, 100)
					) ? (
						<>
							<Utility.VERTSPACERS />
							<InfoMessage
								type="error"
								message="Trade sizes of less than $.25 are currently disabled."
							/>
						</>
					) : (
						<></>
					)}

					{closingPosition && (
						<>
							<Utility.VERTSPACERS />
							<div>
								This order will close your current{' '}
								{tradeSide === 'buy' ? `SHORT` : `LONG`} position
							</div>
						</>
					)}

					<Utility.VERTSPACERM />

					{showOracleDivergenceWarning && (
						<PriceDivergenceWarningMessage
							hasAgreedToPriceDivergence={hasAgreedToPriceDivergence}
							setHasAgreedToPriceDivergence={setHasAgreedToPriceDivergence}
						/>
					)}

					<Utility.VERTSPACERM />

					<Button.BigSemantic
						positive={tradeSide === 'buy'}
						disabled={
							isLoading ||
							notionalSize.lt(
								BigNum.from(25, notionalSize.precision).scale(1, 100)
							) ||
							(showOracleDivergenceWarning && !hasAgreedToPriceDivergence)
						}
						onClick={handleConfirmation}
						dataTestId="spot-trade-modal-confirm-button"
					>
						{isLoading
							? `Loading`
							: `${
									tradeSide === 'buy' ? `Buy` : `Sell`
							  } ${baseSizeDisplayString} ${tradeCurrency}`}
					</Button.BigSemantic>

					{(orderType === 'market' || orderType === 'limit') && (
						<div className="flex items-center justify-between w-full mt-4">
							<SettingsSwitch
								label="Advanced"
								checked={tradeConfirmViewType === 'advanced'}
								onChange={toggleTradeConfirmViewType}
								spacedOut
								includeLineSpace={false}
								className="w-full"
							/>
						</div>
					)}
				</Modal.Content>
			</Modal.Body>
		</Modal>
	);
};
