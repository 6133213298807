'use client';

import React from 'react';
import { ArrowRight, Migration } from '@drift-labs/icons';
import PopupAlert from './PopupAlert';
import useDriftStore from 'src/stores/DriftStore/useDriftStore';
import useCurrentUserAccount from 'src/hooks/useCurrentUserAccount';
import { JlpMigrationCalculator } from 'src/hooks/jlp/JlpMigrationCalculator';
import useBorrowLendDataForMarket from 'src/hooks/useBorrowLendDataForMarket';
import { JLP_POOL_JLP_MARKET_INDEX } from 'src/environmentVariables/EnvironmentVariables';
import { MarketId } from '@drift/common';
import { useSyncLocalStorage } from '@drift-labs/react';

const JLP_POOL_JLP_MARKET_ID = MarketId.createSpotMarket(
	JLP_POOL_JLP_MARKET_INDEX
);

const JlpMigrationPopupAlert = () => {
	const setDriftStore = useDriftStore((s) => s.set);
	const currentAccount = useCurrentUserAccount();
	const jlpPoolJlpSpotMarketData = useBorrowLendDataForMarket(
		JLP_POOL_JLP_MARKET_ID
	);

	const [_, setHasInteractedWithJlpMigrationPopupAlert] = useSyncLocalStorage(
		'hasInteractedWithJlpMigrationPopupAlert',
		false
	);

	const jlpMigrationCalculator = React.useMemo(() => {
		return new JlpMigrationCalculator({
			marginInfo: currentAccount?.marginInfo,
			client: currentAccount?.client,
			spotBalances: currentAccount?.spotBalances ?? [],
		});
	}, [currentAccount]);

	const onMigrate = () => {
		setDriftStore((s) => {
			s.modals.showJLPMigrationFormModal = true;
			s.modals.showJlpMigrationPopupAlert = false;
		});

		setHasInteractedWithJlpMigrationPopupAlert(true);
	};

	const onClose = () => {
		setDriftStore((s) => {
			s.modals.showJlpMigrationPopupAlert = false;
		});
		setHasInteractedWithJlpMigrationPopupAlert(true);
	};

	// Check if migration is allowed
	const isMigrationAllowed = jlpMigrationCalculator.checkIsMigrationAllowed(
		jlpPoolJlpSpotMarketData?.assetWeight ?? 0
	);

	if (!isMigrationAllowed) return null;

	return (
		<PopupAlert
			heading="JLP Migration Available"
			featureKey="jlp-migration-alert"
			icon={<Migration size={24} color={'var(--positive-green)'} />}
			onSeen={onClose}
			gradientBoxProps={{
				borderRadius: '4px',
				borderColour: 'var(--positive-green)',
				borderHoverClass: 'bg-positive-green',
			}}
		>
			Migrate your JLP and USDC from the Main Market to the JLP Market (Isolated
			pool) without disrupting your existing looping strategy.
			<div
				className="flex flex-row items-center gap-1 mt-2 cursor-pointer text-positive-green"
				onClick={onMigrate}
			>
				<span>Migrate now</span>
				<ArrowRight size={16} />
			</div>
		</PopupAlert>
	);
};

export default JlpMigrationPopupAlert;
